@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.header {
	margin-bottom: 0px;

	@media @mobile {
		margin-bottom: 20px;
	}
}

.summary-wrapper {
	display: flex;

	@media @mobile {
		display: block;
	}
}

.setup-wrapper {
	display: flex;

	@media @mobile {
		margin: 0;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}
}

.self-service-summary {
	flex: 1;

	@media @mobile {
		margin: 0 0 20px 0;
	}
}

.center-align-tooltip {
	line-height: 1;
	:global(.tooltip-icon) {
		fill: @gray-light;
	}

	&:hover,
	&:focus {
		:global(.tooltip-icon) {
			fill: @gray-dark;
		}
	}
}

.tooltip-content {
	padding: 12px;
}
