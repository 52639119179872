// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(
	@color;
	@background;
	@border;
	@color-hovered;
	@background-hovered;
	@border-hovered;
	@color-disabled;
	@background-disabled;
	@border-disabled
) {
  color: @color;
  background-color: @background;
  border-color: @border;

  &:focus,
  &.focus {
    color: @color-hovered;
    background-color:  @background-hovered;
        border-color:  @border-hovered;
  }
  &:hover {
    color: @color-hovered;
    background-color:  @background-hovered;
        border-color:  @border-hovered;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color-hovered;
    background-color:  @background-hovered;
        border-color:  @border-hovered;

    &:hover,
    &:focus,
    &.focus {
      color: @color-hovered;
      background-color:  @background-hovered;
          border-color: @border-hovered;
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
	color: @color-disabled;
	background-color: @background-disabled;
	border-color: @border-disabled;
    &:hover,
    &:focus,
    &.focus {
		color: @color-disabled;
      background-color: @background-disabled;
          border-color: @border-disabled;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}
