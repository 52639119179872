.giving-statements {
	@circle-size: 80px;

	.help-block {
		display: flex;

		margin-top: 40px;
		padding-top: 10px;
		padding-bottom: 32px;
		border-top: 1px solid @gray-lightest;
		border-bottom: 1px solid @gray-lighter;

		.help-icon {
			display: inline-block;
			margin: 28px 20px 0 24px;
			.colored-circle(@color-yellow, @circle-size);
			.svg(76px, 76px);
			.svg {
				color: @color-yellow;
				margin: 2px 0 0 2px;
			}
		}

		h3 {
			color: @gray-darker;
		}

		.help-text {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
		}

		@media (max-width: @screen-xs-max) {
			flex-direction: column;

			justify-content: center;
			.help-icon {
				margin-left: auto;
				margin-right: auto;
			}

			.help-text a:after {
				margin-top: 4px;
			}
		}

		span {
			padding-top: @padding-base-vertical*2;
		}

		.arrow-hollow-right-after {
			&:after {
				color: @brand-primary;
			}
		}
	}
}
