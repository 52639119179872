@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	margin-top: @grid-gutter-width;
	margin-bottom: @grid-gutter-width;
	padding-top: @padding-large-vertical;
	border-top: 2px solid @border-color;
	display: flex;
	justify-content: space-between;

	@media @small {
		flex-direction: column;
		padding: @grid-gutter-width @grid-gutter-width 0;
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}
}

.link {
	margin-top: @grid-gutter-width;

	@media @small {
		text-align: center;
		margin-top: @grid-gutter-width + @padding-large-vertical;
		order: 1;
	}
}
