@media (min-width: @grid-float-breakpoint) {
	.navbar-top {
		max-height: @navbar-collapse-max-height;
		display: flex;
		flex-wrap: nowrap;

		.navbar-nav {
			display: flex;
			flex: 0 1 auto;
			flex-wrap: nowrap;

			li {
				flex: 0 1 auto;
				white-space: nowrap;
			}
		}

		.banner {
			display: flex;
			flex: 1 0 auto;
			justify-content: center;
			align-items: center;
			margin: 0;
			padding: 0 @navbar-padding-horizontal;
			border: none;
		}

		a {
			font-size: @font-size-h3;
		}
	}
}

