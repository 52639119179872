@import "email-phone-input";
@import "txt-sent-banner";
@import "login-footer";
@import "mobile-popup";

@import "../../bootstrap-variables";
@import "../../mixins/helpers";

@login-container-max-width: 800px;
@login-container-top-margin: 40px;
@login-panel-body-horizontal-padding: 140px;
@login-floating-admin-toggle-baseline-offset: 28px;
@login-font-size-h1-mobile: 26px;
@login-with-promo-material-min-width: 600px;

.login {
	position: relative;

	.btn-link {
		.icon {
			width: @font-size-base-minus;
			height: @font-size-base-minus;
			margin-right: 0;
		}
	}

	.svg-inline {
		vertical-align: bottom;
	}

	.btn-tertiary {
		margin-top: @line-height-computed;
	}

	.btn-inline {
		margin: 0;
		font-size: @font-size-base-plus;
		vertical-align: baseline;
	}

	.panel {
		/* Reset -@navbar-padding-horizontal (-20px) margin added by panel */
		margin-left: 0;
		margin-right: 0;
	}

	.panel-heading {
		text-align: center;
		padding: @panel-body-padding @panel-body-padding-mobile;
	}

	.panel-footer {
		text-align: center;
		padding: @panel-body-padding;
	}

	@media not @small {
		.panel-body {
			padding: @panel-body-padding @login-panel-body-horizontal-padding;
		}
	}

	@media @small {
		.alert {
			margin-left: @grid-gutter-width;
			margin-right: @grid-gutter-width;
		}

		.panel-plain .panel-body {
			padding: @panel-body-padding @panel-body-padding / 2;
		}

		h1 {
			font-size: @login-font-size-h1-mobile;
		}
	}

	.verification-code-container {
		position: relative;

		.verification-code-icon {
			position: absolute;
			right: @padding-base-horizontal;
			top: @padding-base-horizontal;
			fill: @gray-light;
		}
	}
}

.login-signpost-admin-row {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	@media @small {
		margin-top: 10px;
		flex-direction: column;
	}
}

.login-signpost-admin-links {
	color: black;
	text-decoration: none;

	&:hover {
		color: black;
		text-decoration: none;
	}
}

.login-signpost-admin-row-text {
	/* The font size should match the inline link */
	font-size: @btn-font-size;
	line-height: @btn-static-height;
	margin-left: 10px;
	margin-right: 10px;

	@media @small {
		margin-top: 10px;
		line-height: @line-height-base;
	}
}

.login-signpost-button-row {
	margin-top: @grid-gutter-width;
}

.login-floating-admin-toggle {
	position: absolute;
	top: @login-floating-admin-toggle-baseline-offset;
	right: 0;

	@media @small {
		position: relative;
		text-align: center;
		top: 0;
	}
}

.login-panel-title {
	margin: 0;
	font-size: @font-size-base-plus;
	line-height: 1.5;
	font-weight: 400;
}

.login-panel-signpost-title {
	font-size: @font-size-base-plus;
	line-height: 1.5;
	font-weight: 600;
}

.login-panel-container {
	max-width: @login-container-max-width;
	margin: @login-container-top-margin auto 0 auto;

	@media @small {
		@negative-bootstrap-container-margin: -@grid-gutter-width / 2;
		margin: @login-container-top-margin @negative-bootstrap-container-margin 0 @negative-bootstrap-container-margin;
	}
}

.login-with-promo-material {
	display:flex;
	width:100%;
}

.login-with-promo-material-content {
	flex: 1 1 50%;
	padding: 0 @padding-base-horizontal * 2;
	min-width: @login-with-promo-material-min-width;
}

.login-with-promo-material-promo-container {
	flex: 1 1 50%;
}

.login-with-promo-material-promo-frame {
	border: none;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	display: block;
}

@media @mobile {
	.login-with-promo-material {
		display: block;
	}

	.login-with-promo-material-content {
		display: block;
		padding: 0 @padding-base-horizontal;
		min-width: 0;
	}

	.login-with-promo-material-promo-container {
		display: none;
	}
}
