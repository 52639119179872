@import "../../bootstrap-variables";

.transaction-import-summary-grid {
	@summary-grid-row-padding: 15px 0;
	@summary-grid-row-padding-mobile: 20px 10px;

	margin: @line-height-computed * 2 @padding-base-horizontal @line-height-computed @padding-base-horizontal;

	&.no-vertical-margin {
		margin-top: 0;
		margin-bottom: 0;
	}

	&.small-top-margin {
		margin-top: @line-height-computed;
	}

	@media @small {
		margin-left: -@padding-base-horizontal;
		margin-right: -@padding-base-horizontal;
	}

	.summary-grid-heading {
		margin-bottom: @line-height-computed / 2;

		@media @small {
			padding-left: @padding-base-horizontal;
			padding-right: @padding-base-horizontal;
		}

		.summary-grid-title {
			font-size: @font-size-h2;
			margin-bottom: @line-height-computed / 2;
		}
	}

	.summary-grid-header {
		border-top: 1px solid @gray;
		border-bottom: 1px solid @gray;
		font-size: @font-size-small;
		font-weight: bold;
		padding: @padding-large-vertical 0;
	}

	.summary-grid-row {
		border-top: 1px solid @gray-lighter;
		padding: @summary-grid-row-padding;

		&:last-child {
			border-bottom: 1px solid @gray-lighter;
		}

		&:nth-child(even) {
			background-color: @table-bg-accent;
		}

		@media @small {
			padding: @summary-grid-row-padding-mobile;
		}

		.summary-grid-cell {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			.summary-text-secondary {
				color: @gray-lighter
			}
		}

		.summary-label-responsive {
			@media @small {
				font-weight: bold;
			}
		}
	}
}

