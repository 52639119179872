@import '../../bootstrap-variables';

//
// Sidebar
// --------------------------------------------------

@nav-gutter: 20px;
@sidebar-link-margin-left: 15px;
@sidebar-icon-width: 40px;
@sidebar-icon-margin-left: @nav-gutter - 2px;
@warning-icon-clickable-area: 60px;
@warning-icon-clickable-area-sm: @warning-icon-clickable-area - @nav-gutter;
@warning-icon-size: 30px;

@media @desktop {
	.noauth {
		.sidebar, #cap-navigation {
			top: 12px;
		}
	}
}

.sidebarLink() {
	height: @navbar-height;
	padding: 0;

	display: flex;
	align-items: center;

	* {
		display: flex;
	}

	> i {
		width: @sidebar-icon-width;
		margin-left: @sidebar-icon-margin-left;

		align-content: center;

		display: flex;
		flex-direction: column;

		svg {
			align-self: center;
		}
	}

	h4,
	h6 {
		margin-left: @sidebar-link-margin-left;
	}
}

nav.sidebar {

	.nav-border-right {
		border-right: 1px solid @gray-sidebar-nav;
	}

	.nav-border-top {
		border-top: 1px solid @gray-sidebar-nav;
	}

	.nav-border-bottom {
		border-bottom: 1px solid @gray-sidebar-nav;
	}

	display: flex;
	flex-flow: column nowrap;
	flex: 0 0 @sidebar-width;
	order: -1;

	background-color: @color-form;

	.profile {
		padding: 15px 15px 15px 15px;
		text-align: center;
		.nav-border-right;
		box-sizing: content-box;

		a {
			color: @gray-darker;
			padding-left: 0;

			h4 {
				padding-left: 0;
			}

			img {
				margin: auto;
				max-width: 120px;
				height: 120px;
				margin-bottom: 3px;
				display: block;
			}

			span {
				display: block;
			}
		}
	}

	a {
		color: @gray-darker;
		padding-left: @nav-gutter;

		h4 {
			display: inline-block;
		}

		&:hover {
			text-decoration: none;
			color: @brand-primary;
		}
	}

	/* Sidebar navigation */
	.nav-sidebar {

		& > li {
			.nav-border-top;
			.nav-border-right;

			& > a {
				.sidebarLink();
				.sliding-hover(white);
				z-index: 1;
				&:focus,
				&:hover {
					background-color: transparent;
				}

				span {
					.icon-resources {
						margin-left: 1px;
					}
				}
			}

			&:focus,
			&:hover,
			&.selected {
				border-right: 0;
				background-color: white;
			}

			&:hover:not(.selected) {
				background-color: transparent;
			}

			&.selected > a,
			& > a:hover,
			& > a:focus {
					background-color: transparent;
					color: @brand-primary;
			}

			&:not(.selected){
				> a:hover {
					border-right: 1px solid @gray-sidebar-nav;
				}
			}

			&:hover:not(.selected) {
				background-color: transparent;
				> a {
					border-right: 1px solid @gray-sidebar-nav;
				}
			}

			.svg-community {
				fill: white;
				stroke: @gray-dark;
			}
			.sidebar-icons;
		}

		.has-sidebar-warning-icon {
			position: relative;

			h4 {
				margin-right: @warning-icon-clickable-area;
			}
		}


		// sub menu
		.sub-nav-menu {
			margin-left: @sidebar-link-margin-left + @sidebar-icon-margin-left +  @sidebar-icon-width;
			list-style: none;
			padding: 0;
			padding-bottom: @nav-gutter;
			padding-right: @nav-gutter;

			li {
				line-height: 20px;
				padding-bottom: 15px;
				font-size: @font-size-sub-menu;

				&:last-child {
					padding-bottom: 0;
				}

				a {
					padding: 0;
					&:focus {
						text-decoration: none;
					}
				}

				&.selected {
					> a {
						color: @brand-primary;
						font-weight: bold;
					}
				}
			}
		}

		&:focus,
		&:hover,
		&.selected {
			color: @brand-primary;
		}

		.label-sidebar-new {
			margin-bottom: -3px;
			margin-left: 5px;
		}
	}

	.fill-space {

		> a {
			.sidebarLink();
		}

		@media @desktop {
			flex-grow: 1;
			.nav-border-right;
		}
	}

	.nav-tail {

		a {
			&:focus,
			&:hover,
			&.selected {
				svg {
					color: @color-red;
				}
			}
		}

		li.sidenav-banner {
			background-color: @gray-sidebar-nav;
			.round(8);
			padding: 20px 0 25px 15px;
			.transition(0.2s);
			margin: 15px 0 10px 14px;
			position: relative;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			> a.no-background{
					border-right: transparent;
					outline: none;

				&:hover:before{
					background-color: transparent;
					border-right: transparent;
				}
			}

			&:after{
				content: "";
				background-color: @gray-sidebar-nav;
				z-index: -1;
				position: absolute;
				width: 10px;
				height: 122px;
				.round(8);
				.transition(0.2s);
				left: 225px;
				top: -1px;
				border-top-left-radius: 0;
			}

			&:hover{
				background-color: darken(@gray-sidebar-nav, 5%);
				.transition(0.2s);
				cursor: pointer;
				border-right: transparent;

				&:after{
					background-color: darken(@gray-sidebar-nav, 5%);
					.transition(0.2s);
				}

				.sidebar-banner-content{
						&.arrow-hollow-right-after{
							color: @gray-darker;

					&:before{
						.transition(0.2s);
						background-color: darken(@gray-light, 10%);
					}
				}
			}
		}
	}

		.sidebar-banner-content{
				display: block;

				.user-feedback-lead-intro{
					line-height: @line-height-base;
					color: @gray-dark;
					width: 188px;
					padding-bottom: 5px;
				}

				span{
					font-size: @font-size-base;
					padding-bottom: 0;
					outline: none;

					&.arrow-hollow-right-after{
						font-weight: 600;
						color: @gray-darker;

						&:after{
							padding-top: 4px;
						}
					}
				}

				&:before{
					.transition(0.2s);
					content: "";
					background-color: @gray-light;
					width: 9px;
					height: 18px;
					border-bottom-right-radius: 22px;
					z-index: 2;
					top: 85px;
					left: 211px;
					position: absolute;
					border-top-right-radius: 22px;
				}
		}
	}

	// mobile view
	@media @mobile {

		.flyout-transition();
		right: 0;
		left: 0;
		bottom: 20px;
		overflow-y: scroll;
		display: block;

		.profile {
			display: none;
		}

		&.open {
			display: block;
		}

		li, li a {
			border-right: 0 !important;
		}

		.nav-tail {
			.sidenav-banner {
				border: 1px solid @gray-lighter;

				&.sidenav-banner-mobile{
					margin-left: 20px;
				}

				&:after{
					display: none;
				}
			}
			.sidebar-banner-content {

				.user-feedback-lead-intro {
					width: 100% !important;
				}
				&:before{
					display: none;
				}
			}
		}
	}
}

#shell-navigation {
	height: ~"calc(100vh - @{shell-header-height})";
	position: sticky;
	top: @shell-header-height;
	left: 0;
	z-index: @zindex-navbar-fixed;

	@media @mobile {
		position: fixed;
	}
}

// Remove with UseNewPushpayShell
#cap-navigation {
	width: @sidebar-cap-width;
    height: 100vh;
	z-index: @zindex-navbar-fixed;
	flex: none;

	@media @mobile {
		width: inherit;
		height: inherit;
		box-shadow: none;
	}

	nav {
		font-family: @cap-font-family;
		font-size: @cap-font-size;

		a:hover {
			text-decoration: none;
		}

		footer {
			a:hover {
				color: inherit;
			}
		}
	}
}

//
// mixin to apply the icon changes
// --------------------------------------------------
.sidebar-icons {
	&.ico-hover-red {

		// default fill is in icons.less

		&.selected > a,
		& > a:hover,
		& > a:focus {
			svg {
				color: @color-red;
			}

		}

		.sidebar-warning-icon {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;

			a {
				width: @warning-icon-clickable-area;
				height: @warning-icon-clickable-area;
				padding: (@warning-icon-clickable-area - @warning-icon-size)/2;
				display: inline-block;
			}


			svg {
				fill: white;
				color: @color-red-validation;
				width: @warning-icon-size;
				height: @warning-icon-size;
				.transition(0.2s);
			}

			&:hover {
				svg {
					fill: @color-red-validation;
					color: white;
				}
			}
		}

		.sub-nav-menu {
			.sidebar-warning-icon {
				margin-top: (@warning-icon-clickable-area-sm - @warning-icon-clickable-area)/2;

				a {
					width: @warning-icon-clickable-area-sm;
					height: @warning-icon-clickable-area-sm;
					padding: (@warning-icon-clickable-area-sm - @warning-icon-size)/2;
				}
			}
		}

		@media @desktop {
			&:hover:not(.selected) .sidebar-warning-icon {
				right: 1px;
			}
		}
	}
}
