@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@campaign-progress-color: #27a86c;
@campaign-closed-color: @gray;
@campaign-axis-color: #e9e9e9;
@axis-height: 4px;
@progress-height: @axis-height;
@axis-text-top: -1.7em;

.axis {
	margin: @grid-gutter-width @grid-gutter-width * 2 30px;
	height: @axis-height;
	background-color: @campaign-axis-color;
	display: flex;
	align-items: center;
	position: relative;
}

.start-point,
.end-point {
	width: 3px;
	height: 19px;
	position: absolute;
	top: -8px;
	background-color: @campaign-axis-color;
	&-active {
		background-color: @campaign-progress-color;
	}
	&-closed {
		background-color: @campaign-closed-color;
	}
}
.start-point {
	left: 0;
}
.end-point {
	right: 0;
}

.progress {
	font-size: @font-size-base-minus;
	color: @gray-mid;
	height: @progress-height;
	position: relative;
	border-top-right-radius: @progress-height / 2;
	border-bottom-right-radius: @progress-height / 2;
	&-active {
		background-color: @campaign-progress-color;
	}
	&-closed {
		background-color: @campaign-closed-color;
	}
}

.collected-percentage {
	font-size: @font-size-base-plus;
	position: absolute;
	right: -0.5em;
	top: 0.3em;
}

.goal-reached-bar {
	position: absolute;
	left: 0;
	display: inline-block;
	color: @campaign-progress-color;
	svg {
		position: absolute;
		fill: currentColor;
		right: 0;
		top: -11px;
		width: 23px;
		height: 23px;
		transform: translateX(50%);
	}
	&-text {
		position: absolute;
		white-space: nowrap;
		right: 0;
		top: 14px;
		transform: translateX(50%);
	}
	&-closed {
		color: @campaign-closed-color;
	}
}

@media @small {
	.axis {
		margin: @grid-gutter-width 0;
	}
	.goal-reached-bar-text {
		display: none;
	}
	.collected-percentage {
		top: @axis-text-top;
	}
}
