@import '../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@media (min-width: @screen-sm-min) {
		flex-wrap: nowrap;
	}
}

.button-group {
	flex: 0 0 100%;
	position: static;
	float: none;
	width: 100%;

	@media (min-width: @screen-sm-min) {
		flex-basis: 50%;
	}

	@media (min-width: @screen-lg-min) {
		flex-basis: 33.33%;
	}
}

.message {
	flex: 0 0 100%;
	align-items: center;
	display: flex;
	opacity: 0;
	transition: 0.5s opacity ease-in-out;

	@media (min-width: @screen-sm-min) {
		flex-basis: 50%;
		padding-left: 10px;
	}
}

.visible {
	opacity: 100;
}

.close-container {
	margin-left: 15px;
	flex: 0 0 20px;
	align-items: center;
}

.close-icon {
	fill: @gray-mid;
	vertical-align: middle;
	padding: 2px;
	cursor: pointer;
}
