@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@column-gap: @grid-gutter-width / 2;
@mobile-btn-width: ~'calc(50% - @{column-gap} / 2)';

.form {
	max-width: 1200px;
}

.form-group {
	margin-bottom: 35px;
}

.form-footer {
	padding: 20px;
}

.outcome-bar {
	height: 50px;
	line-height: 50px;
	text-align: right;
	background-color: @color-form-alt;

	@media @mobile {
		text-align: left;
	}
}

.outcome-bar + .form-body {
	padding-top: 30px;
}

.outcome-numbers {
	margin-left: 10px;
	margin-right: 20px;
}

.outcome-number {
	padding: 2px 10px;
	&:first-child {
		border-right: 1px solid gray;
	}
}

.fund-info {
	font-size: @font-size-small;
	margin-top: 5px;
}

.fund-info-link {
	text-decoration: underline;
}

.button {
	margin: 5px;
	@media @small {
		margin: 0;
	}
}

.buttons {
	display: flex;
	justify-content: center;

	@media @small {
		display: grid;
		column-gap: @column-gap;
		row-gap: @grid-gutter-width;
		text-align: center;
		grid-template-columns: @mobile-btn-width @mobile-btn-width;
		grid-template-areas:
			'publish publish'
			'back draft';
	}
}

.back {
	grid-area: back;

	@media @small {
		min-width: 0;
	}
}

.draft {
	grid-area: draft;

	@media @small {
		min-width: 0;
	}
}

.publish {
	grid-area: publish;

	@media not @small {
		justify-self: end;
	}
}
