@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';
@spinner-size: 25px;

.spinner {
	display: block;
	background: none;
	width: @spinner-size;
	height: @spinner-size;
	border-radius: 50%;
	border: 3px solid @white-50;
	border-top-color: #ffffff;
	animation: spinner-rotating 1s linear infinite;
	margin-right: 7px;
}

@keyframes spinner-rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
