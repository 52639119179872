

.batch-entry-history-dialog-grid {
	.data-grid {
		.data-grid-row-header,
		.data-grid-row-data {
			display: flex;
			flex: auto;

			@media @small {
				flex-direction: column;
			}
		}
	}	

	.data-grid-cell-dateandtime {
		@media @small {
			font-weight: bold;
		}

		@media @tablet {
			flex: 0 0 182px;
		}

		@media @desktop {
			flex-basis: 210px;
		}
	}

	.data-grid-cell-ownername {
		@media @tablet {
			flex: 0 0 140px;
		}

		@media @desktop {
			flex-basis: 254px;
		}
	}

	.data-grid-cell-description {
		@media @tablet {
			flex: 1 0 276px;
		}

		@media @desktop {
			flex-basis: 496px;
		}
	}
}
