.default-type-config-label-wrapper {
  padding: 0px 0px 0px 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 15px;
  width: 275px;
}
.default-type-config-label-wrapper label {
  font-size: 16px;
  padding-left: 20px;
}
.default-type-config-container {
  padding: 40px 20px 40px 20px;
  height: 144px;
}
.community-member-type-select-wrapper {
  width: 308px;
}
.community-member-type-select-wrapper option {
  color: #4d4d4d;
}
.community-member-type-select-wrapper option:disabled {
  color: #cccccc;
}
.community-member-type-select-wrapper label {
  display: none;
}
.community-member-type-select-unselected {
  color: #c1c1c1;
}
@media screen and (max-width: 1024px) {
  .default-type-config-label-wrapper {
    width: 100%;
    margin-top: 0px;
  }
  .default-type-config-label-wrapper label {
    font-size: 18px;
    font-weight: normal;
    padding-left: 0px;
  }
  .community-member-type-select-wrapper {
    width: 100%;
    padding: 0px 0px 0px 0px;
  }
  .default-type-config-container {
    padding: 20px 20px 20px 20px;
  }
}
