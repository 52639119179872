@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@icon-size: 96px;

.container {
	text-align: center;
	@media @desktop {
		margin-top: 220px;
	}
}

.intro {
	margin-bottom: 24px;
}

.icon {
	width: @icon-size;
	height: @icon-size;
	fill: @text-color;
}
