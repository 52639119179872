@import '../../bootstrap-variables';
@import '../../mixins/svgs';
@import '../../mixins/helpers';
@import '../tables';

.import-funds {
	.affix-wrapper {
		.position-sensor {
			margin-top: -(@navbar-height - @border-radius-base);
		}

		.affix.affix-top {
			top: @navbar-height - @border-radius-base;
		}
	}

	.panel-default {
		background-color: white;

		.panel-heading {
			border-bottom: 1px solid rgba(204, 204, 204, 0.4);
		}
	}

	.import-funds-pagination {
		margin-bottom: @grid-gutter-width * 2;
	}
}

.header {
	.make-row;
	.affix & {
		display: none;
	}
}

.sticky-header {
	display: none;
	.sticky-label {
		flex-grow: 1;
	}

	.affix & {
		display: flex;
	}

	@media (min-width: @screen-sm-min) {
		.btn {
			padding-left: 45px;
			padding-right: 45px;
		}
		.btn-link {
			padding-left: 20px;
			padding-right: 20px;
		}
		.responsive-btn-group {
			display: block;
		}
	}

	@media (max-width: @screen-xs-max) {
		.sticky-label {
			display: none;
		}
		.responsive-btn-group {
			flex-grow: 1;
		}
	}
}

.import-funds-header {
	line-height: 39px;
	background-color: white;
	padding-left: @grid-gutter-width;
	padding-right: @grid-gutter-width;
	.tooltip-icon {
		margin-left: 12px;
		margin-bottom: -3px;
	}
	.affix & {
		padding-top: 18px;
		padding-bottom: 18px;
	}
	@media (max-width: @screen-xs-max) {
		.btn-link {
			text-align: left;
		}
	}
}

.import-funds-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.import-funds-row {
	.clearfix();
	padding: 10px (@grid-gutter-width / 2) 32px;

	&:nth-child(odd) {
		background-color: @panel-bg;
	}

	@media (max-width: @screen-xs-max) {
		padding-bottom: 12px;
	}

	.pushpay-fund-container {
		.make-sm-column(4);
		display: flex;
		margin-top: 32px;
		position: relative;
		font-size: @font-size-base-plus;
		font-weight: bold;
		.fund-name {
			flex-grow: 1;
			padding-right: 10px;
			word-break: break-word;
		}

		.fund-notes {
			font-size: @font-size-small;
			font-weight: normal;
			word-break: break-word;
			color: @gray;
		}

		.btn-close-fund {
			padding: 5px 0 0;
			color: @gray-dark;
			height: 28px;
			border-bottom: 2px dotted @gray;
			border-radius: 0;
			&:hover {
				color: @brand-primary;
				text-decoration: none;
				border-bottom: 1px solid @brand-primary;
			}
			.icon {
				display: none;
			}
		}
		.svg(24px, 24px);
		.svg {
			color: @gray;
			flex-shrink: 0;
		}
		.fund-code {
			color: @gray;
			font-size: @font-size-base;
			font-weight: normal;
		}
		@media (max-width: @screen-xs-max) {
			font-size: 20px;
			font-weight: 300;
			margin: 25px 0 18px;
		}
	}

	.integration-fund-container {
		.make-sm-column(5);
		display: flex;
		.autocomplete {
			flex-grow: 1;
		}
		.match-fund-label {
			.visible-xs;
		}

		@media (max-width: @screen-xs-max) {
			display:block;

			.floating-label {
				display: none;
			}
		}
	}
	.integration-validation-container {
		.make-sm-column(3);
		margin-top: 31px;
	}

	&.linked {
		background-color: @color-green-lighter;
		+ .linked {
			border-top: 1px solid white;
		}
		.svg {
			fill: @color-green;
		}
	}
}

.import-funds-search-container {
	display: flex;
	width: 100%;
	height: 2.5em;
	max-width: 475px;
	border: 1px solid @border-color;
	border-radius: @border-radius-base;
	flex-wrap: nowrap;
	align-items: stretch;
}

.import-funds-search-input {
	font-size: @font-size-h4;
	border: none;
	flex: 1 0 auto;
	border-radius: @border-radius-base 0 0 @border-radius-base;
	padding: 0 18px;

	&:active,
	&:focus {
		outline: none;
	}
}

.import-funds-search-button {
	border: none;
	background-color: @color-giving;
	color: @theme_colors_common-white;
	border-left: 1px solid @border-color;
	flex: 0 0 auto;
	border-radius: 0 @border-radius-base @border-radius-base 0;
	transition: color, background-color 0.5s;
	padding: 0 12px;

	&:active,
	&:focus,
	&:hover {
		background-color: @color-giving-dark-100;
		outline: none;
	}
}

.import-funds-search-icon {
	width: 1.25em;
	height: 1.25em;
	vertical-align: middle;
}

.import-funds-search-panel {
	display: grid;
	align-items: end;
	grid-template-areas: 'import-funds-search-block';
	margin-bottom: @grid-gutter-width;
}

.import-funds-search-block {
	grid-area: export-block;
	display: flex;
	align-content: end;
	justify-content: end;
	align-items: end;
	justify-content: flex-end;
}

.link-line-container {
	position: relative;
	@link-line-thickness: 2px;
	@link-line-width: 24px;
	@link-line-height: ~"calc(100% + 16px)";
	@link-line-icon-height: 24px;
	@link-line-icon-width: 24px;
	.link-line {
		.hidden-xs;
		.hidden-sm;
		position:absolute;
		top: 0;
		right: @grid-gutter-width / 2 - @link-line-width;
		height: @link-line-height;
		width: @link-line-width;
		margin: 22px 0;
		border: @link-line-thickness solid @gray-lighter;
		border-left: 0;
		border-radius: @link-line-thickness;
		z-index: 20;
	}
	.link-line-icon {
		height: @link-line-icon-height;
		width: @link-line-icon-width;
		position: absolute;
		right: -(@link-line-thickness + @link-line-icon-width)/2;
		top: 50%;
		margin-top: -@link-line-icon-height / 2;
		background-color: @color-form;
		display: flex;
		align-items: center;
		.svg {
			color: @gray;
		}
		.svg(@link-line-icon-width, @link-line-icon-height);

	}
	.linked{
		.svg {
			color: @color-green;
		}
	}
}

.field-container {
	position: relative;
	.disabled-field-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: none;
		background-color: @color-form;
		opacity: 0.66;
		z-index: 10;
		&.active {
			display: block;
		}
	}
	input[disabled], textarea[disabled] {
		background-color: white;
	}
}
