#insights {
	&.liw {
		nav {
			text-align: center;
			padding-bottom: ((@line-height-computed / 2) - 1);
			margin-bottom: @line-height-computed;

			.next {
				padding-left: @padding-base-horizontal;
				a {
					padding-left: @padding-base-horizontal;
					border-left: 1px solid @gray;
				}
			}

			.previous {
				padding-right: @padding-base-horizontal;
				a {
					border-right: 1px solid @gray;
					padding-right: @padding-base-horizontal;
				}
			}
		}
	}
}
