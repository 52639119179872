@import '../../bootstrap_theme/labels.less';
@import '../../bootstrap-variables.less';

.integration-label-question {
	background-color: @gray-lighter;
	color: @gray-darker;
	.label-content-before('?');
}
.integration-label-exclamation {
	background-color: @gray-lighter;
	color: @gray-darker;
	.label-content-before('!');
}

.integration-label-setup-incomplete {
	.label-warning;
	color: @label-color;
}
