@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.input {
	& + :global(.input-group-addon) {
		border: 1px solid @input-border;
		border-left: 0;
	}
}

.input[disabled] {
	& + :global(.input-group-addon) {
		padding-left: 9px;
		padding-right: 9px;
	}
}

.input[readonly] {
	color: @brand-primary;
	background-color: @input-bg;
	border-color: @input-border;
	opacity: 1;

	&:focus {
		border-color: @gray;
		& + :global(.input-group-addon) {
			border-color: @gray;
		}
	}

	& + :global(.input-group-addon) {
		color: @input-color;
		background-color: @input-bg;
		border-color: @input-border;
		padding-left: 9px;
		padding-right: 9px;
	}
}

:global(.tooltip-container.input-group-addon) {
	border: 0;
	padding: 0;
	background-color: transparent;
}
