@import "../../bootstrap-variables";
@import "../../mixins/mixins";

@tutorial-image-width: 430px;

.tutorial-slide {
	display: flex;
	flex-flow: row nowrap;
	padding: @grid-gutter-width 0 0;
	justify-content: center;

	@media @mobile {
		flex-flow: column nowrap;
	}

	.tutorial-image {
		flex-shrink: 0;

		> img {

			@media @mobile {
				max-width: @tutorial-image-width;
				width: 100%;
			}
		}

		@media @mobile {
			text-align: center;
		}
	}

	.tutorial-description {
		margin-left: @grid-gutter-width;
		flex-shrink: 1;
		max-width: 700px;

		@media @mobile {
			padding: @grid-gutter-width 0 0;
			margin-left: 0;
			max-width: inherit;

		}

		> h2 {
			margin-top: 0;
			color: @gray-darker;
		}

		> p {
			color: @gray-mid;
		}
	}
}
