.listing-control {
	white-space: nowrap;
	display: inline-block;

	@media @desktop {
		background-color: @color-form;
		height: 50px;
		.round(4px);
		border: 1px solid @gray-lighter;
		margin-bottom: 30px;

		select {
			display: none;
		}

		> label {
			position: relative;
			top: -1px;
			background-color: @gray-mid;
			height: 100%;
			color: white;
			margin-right: 30px;
			display: inline-block;
			padding: 13px 40px 10px 40px;
			.roundSome(4px, 0, 4px, 0);

			&:after {
				left: 100%;
				top: 50%;
				border: solid transparent;
				content: ' ';
				position: absolute;
				border-left-color: @gray-mid;
				border-width: 25px;
				margin-top: -25px;
			}
		}

		> ul {
			padding: 13px 40px 10px 20px;
			display: inline-block;

			.dropdown {
				display: inline-block;

				> a {
					color: @gray-dark;

					svg {
						margin-left: 8px;
						width: 10px;
						height: 10px;
						transform: rotate(-90deg) scale(1, 1.2);
					}

					&::after {
						display: none;
						font-family: "ArrrowsRegular";
						font-weight: normal;
						content: 'o';
						font-size: 20px;
						position: absolute;
						top: -3px;
						padding-left: 10px;
					}

					> .caret {
						display: none;
						visibility: hidden;
					}
				}
			}
		}
	}

	@media @mobile {
		width: 100%;
		background-color: @gray-mid;
		padding: 10px 20px 20px 20px;

		> label {
			color: white;
			display: inline-block;
			padding-bottom: 10px;
		}

		> ul {
			display: none;
		}

		select {
			display: block;
			width: 100%;
		}
	}

	// dropdown style override
	.dropdown-menu {
		> ul {
			display: none;
		}

		select {
			display: block;
			width: 100%;
		}
	}
}
