@import "transaction-import-header";
@import "transaction-import-fund-row";
@import "transaction-import-summary-panel";
@import "transaction-import-summary-grid";
@import "transaction-import-summary-grid-loading";
@import "transaction-import-progress";
@import "transaction-import-steps";
@import "transaction-import-list";

@import "../../bootstrap-variables";

.transaction-import-title {
	.transaction-import-step-description {
		font-size: @font-size-base-plus;
		margin-top: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	.header-buttons {
		margin-bottom: @line-height-computed / 2;
	}
}

.import-file-upload {
	.form-control[readonly] {
		background-color: white;
		cursor: pointer;
	}
}
