@import '../../../Content/LoggedInWeb/bootstrap-variables.less';
@preconfigured-giving-panel-content-padding: 15.1516%; // 170/1122 (padding/parent container width);
@preconfigured-giving-form-control-labelled-min-width: 400px;
@preconfigured-giving-form-control-labelled-mobile-min-width: 1px;

.share-icon {
	margin-right: 3px;
	vertical-align: middle;
	height: 30px;
	width: 30px;
	fill: white;
}

.preconfigured-giving-radio-button {
	margin-right: 20px;
}

.preconfigured-giving-gift-type-radio-group {
	padding-top: 13px;
}

.preconfigured-giving-gift-type-radio-recurring-active {
	&:before {
		border-color: @panel-default-inner-border;
		background-color: @panel-bg;
		top: 33px;
		left: 20px;
		z-index: 1;
		@media @small {
			top: 31px;
		}
	}
}

.preconfigured-giving-gift-type-recurrence-configurator {
	padding-top: 23px;
	&:before {
		content: none;
	}
}

.preconfigured-giving-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.preconfigured-giving-page-description {
	padding-bottom: 15px;
}

.preconfigured-giving-copy-button {
	margin-left: 5px;
}

.preconfigured-giving-generated-url {
	padding-top: 20px;
	width: 100%;
	text-align: center;
}

.preconfigured-giving-fund-visibility {
	padding-top: 13px;
}

.preconfigured-giving-fund-tooltip-content {
	> p {
		width: 100%;
		text-align: left;
	}
}

.preconfigured-giving-recurring-visibility-tooltip-content {
	> p {
		width: 100%;
		text-align: left;
	}
}

.preconfigured-giving-fund-tooltip-container {
	width: auto;
}

.preconfigured-giving-panel {
	@media @mobile {
		border-bottom: 0;
		box-shadow: none;
	}
}

.preconfigured-giving-panel-body {
	&:global(.panel-body) {
		@media @mobile {
			border-bottom: 1px solid @gray-lighter;
			border-bottom-left-radius: @panel-border-radius - 1;
			border-bottom-right-radius: @panel-border-radius - 1;
			overflow: hidden;
			padding-bottom: 0;
		}
	}

	:global(.form-control-labelled) {
		min-width: @preconfigured-giving-form-control-labelled-min-width;
		@media @mobile {
			min-width: @preconfigured-giving-form-control-labelled-mobile-min-width;
		}
	}
}

.preconfigured-giving-panel-content-left {
	padding-right: @preconfigured-giving-panel-content-padding;
	@media @mobile {
		padding-right: @grid-gutter-width / 2;
	}
}

.preconfigured-giving-panel-content-right {
	padding-right: @preconfigured-giving-panel-content-padding;
	@media @mobile {
		padding: 30px 20px 0 20px;
		background-color: white;
		margin-left: @grid-gutter-width / -2 - 1; // .make-row() gutter width + .panel border-width
		margin-right: @grid-gutter-width / -2 - 1; // .make-row() gutter width + .panel border-width
		border-top-left-radius: @panel-border-radius - 1;
		border-top-right-radius: @panel-border-radius - 1;
	}
	> :global(*:last-child) {
		margin-bottom: 0;
	}
}

.preconfigured-giving-mobile-cta {
	display: none;
	background-color: white;
	border-bottom: 1px solid @gray-lighter;
	border-top: 1px solid @gray-lighter;
	margin-left: @grid-gutter-width / -2 - 1; // .make-row() gutter width + .panel border-width
	margin-right: @grid-gutter-width / -2 - 1; // .make-row() gutter width + .panel border-width
	padding: 20px 10px;
	border-bottom-left-radius: @panel-border-radius - 1;
	border-bottom-right-radius: @panel-border-radius - 1;
	text-align: center;
	@media @mobile {
		display: block;
	}
}

.preconfigured-giving-mobile-cta-btn {
	width: 100%;
}

.preconfigured-giving-scroll-to-top-button {
	display: none;
	margin-top: 22px;
	margin-bottom: 22px;
	@media @mobile {
		display: block;
		margin: 14px 0 24px auto;
	}
}

.preconfigured-giving-desktop-cta {
	display: block;
	padding: 20px 30px;
	@media @mobile {
		display: none;
	}
}

.preconfigured-giving-clipboard-input {
	padding-right: 0;
}

.preconfigured-giving-panel-no-generated-urls {
	.preconfigured-giving-panel-content-right {
		@media @mobile {
			display: none;
		}
	}
	.preconfigured-giving-panel-body {
		&:global(.panel-body) {
			@media @mobile {
				border-bottom: 0;
			}
		}
	}
}

.preconfigured-giving-no-generated-qr-code-url {
	@media @mobile {
		display: none;
	}
}

.preconfigured-giving-no-generated-url {
	@media @mobile {
		display: none;
	}
}

.link-type-container {
	display: block;
	margin-bottom: 28px;

	label {
		display: block;
	}
}

.link-type-btns {
	display: flex;
	flex-wrap: wrap;
}

.link-type-btn {
	border-radius: 0 4px 4px 0;
	padding: 0 10px;
	line-height: 44px;
	height: 44px;
	background-color: @btn-unbranded-color;
	border: 1px #C7CBD1 solid;
	font-size: 14px;
	border-width: 1px 1px 1px 0;
	overflow: hidden;

	&:hover{
		color: @btn-primary-hover-color;
		background-color: @btn-primary-hover-bg;
		border-color: @btn-primary-hover-bg;
	}

	&:first-child {
		margin-right: -1px;
		border-radius: 4px 0 0 4px;
		border-width: 1px 0 1px 1px;
	}
}

.link-type-btn-active {
	color: @btn-primary-color;
	background-color: @btn-link-color;
	border-color: @btn-link-color;
}

.embedded-form-field-input {
	padding-right: 0;
}

.embedded-form-redirect {
	padding-top: 18px;
}

.embedded-form-redirect-fields {
	min-height: 250px;
}

.embedded-form-preview {
	pointer-events: none;
}

.embedded-form-result {
	margin: 0 -10px;
}
