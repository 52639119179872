@import "../bootstrap-variables";

ul.inline-list {
	display: block;
	margin: 0;
	padding: 0;

	> li {
		list-style: none;
		display: inline-block;
		padding: 0 10px;
		margin: 0;
		line-height: 1em;

		&:first-of-type {
			padding-left: 0;
		}

		&:not(:first-of-type) {
			border-left: 1px solid currentColor;
		}
	}

	&.responsive {
		@media @mobile {
			> li {
				display: block;
				line-height: 1.5em;
				padding: 0;

				&:not(:first-of-type) {
					border-left: none;
				}
			}
		}
	}
}
