@import (reference) '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.title {
	grid-column: 1 / span 3;

	@media @mobile {
		grid-column: 1 / span 4;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.details {
	grid-column: 1 / span 3;
	grid-row: 2;
}

.total {
	grid-column: 4;

	@media @mobile {
		grid-row: 3;
		font-weight: bold;
		font-size: @font-size-base;
		margin-bottom: 0;

		.total-prefix {
			top: initial;
			font-size: @font-size-base;
			margin-right: 0;
		}
	}
}

.count {
	grid-column: 4;
	grid-row: 2;

	@media @mobile {
		grid-column: 1 / span 3;
		grid-row: 3;
		text-align: left;
	}
}

.deposit {
	grid-column: 1 / span 2;
	grid-row: 3;

	@media @mobile {
		grid-column: 1 / span 4;
		grid-row: 2;

		.checkbox-green {
			margin-top: 0;
		}
	}
}

.icon-completed {
	width: 22px;
	height: 22px;
	fill: @color-complementary-green;
	margin-bottom: -2px;
}

.qbo-row {
	grid-column: 3 / span 2;
	grid-row: 3;
	color: @color-green;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media @mobile {
		grid-column: 1 / span 4;
		grid-row: 4;
		margin-top: @padding-base-horizontal;
		justify-content: space-between;
	}

	.qbo-total {
		@media @mobile {
			font-size: @font-size-base;
		}

		.qbo-total-prefix {
			margin-right: 2px;

			@media @mobile {
				top: initial;
				font-size: @font-size-base;
				margin-right: 0;
			}
		}
	}

	.sent-text {
		display: flex;
		margin: 10px 0;
	}
}

.empty-row {
	margin: 10px 0;
	height: 22px;
	@media @mobile {
		display: none;
	}
}
