@import "../bootstrap-variables";


.status-panel {
	@status-panel-link-color: white;
	@status-panel-link-hover-color: darken(white, 15%);

	border: none;
	border-radius: @border-radius-base;
	background-color: @gray-mid;
	color: white;
	margin-bottom: @line-height-computed;

	padding: @panel-heading-padding;

	@media (max-width: @screen-xs-max) {
		margin-right: -@navbar-padding-horizontal;
		margin-left: -@navbar-padding-horizontal;
		border-radius: 0;
		border-left: 0;
		border-right: 0;
	}

	@media (max-width: @grid-float-breakpoint) {
		padding: @panel-body-padding-mobile;
	}

	a {
		color: @status-panel-link-color;
		text-decoration: underline;

		&:hover,
		&:focus {
			color: @status-panel-link-hover-color;
		}
	}

	.btn-link {
		color: @status-panel-link-color;
		text-decoration: underline;
		font-weight: normal;

		&:hover,
		&:focus {
			color: @status-panel-link-hover-color;
		}
	}
}
