@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.email-Tab {
	.email-tab-item {
		width: 33.33%;

		@media @small {
			width: 50%;
		}

		& > a {
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}
	}
}

.email-template {
	position: relative;
	background-color: white;
	border: 1px solid @input-border;
	background-color: @color-form-alt;
	padding-bottom: 30px;
}

.overlay {
	position: absolute;
	background-color: @gray;
	opacity: 0.4;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.heading {
	font-size: 24px;
}

.header {
	border-top: 12px solid @color-red;
	text-align: center;
	border-bottom: 1px solid @gray-lighter;
	background-color: white;
}

.logo {
	margin-top: 30px;
	width: 60px;
	height: 60px;
}

.date {
	margin-top: 10px;
	margin-bottom: 20px;
}

.header-divider {
	max-width: 412px;
	width: 70%;
	margin-top: 10px;
	margin-bottom: 5px;
	border-color: @gray-lightest;
}

.body {
	max-width: 550px;
	margin: auto;
	padding: 5px;
}

.reset {
	float: right;
	margin-top: 15px;
}

.email-content-wrapper {
	position: relative;
	z-index: 1;

	.message {
		resize: none;
	}

	.char-used {
		color: white;
		font-style: italic;
		font-size: 13px;
		text-align: right;
		position: absolute;
		right: 0;
		bottom: 100%;
	}

	.limit-exceeded {
		color: @color-red-validation;
	}
}

.message {
	resize: none;
	position: relative;
	z-index: 1;
}

.video-link {
	margin: 30px auto 50px auto;
	position: relative;
	z-index: 1;
}

.gift-details {
	border: 1px solid @gray-lighter;
	border-top: 6px solid @color-red;
	border-radius: 4px;
	background-color: @color-form;
	margin: 40px auto 30px auto;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.33);
}

.gift-header {
	background-color: white;
	padding: 24px;
	text-align: center;
	font-size: 20px;
	border-bottom: 1px solid @gray-lightest;
}

.gift-body {
	padding: 10px 40px;

	@media @small {
		padding: 10px 15px;
	}
}

.info-row {
	padding: 15px;
	border-bottom: 1px solid @gray-lighter;

	&:last-child {
		border-bottom: none;
	}

	.info-label,
	.info-value {
		padding-left: 40px;
		margin: auto;

		@media @small {
			padding-left: 0;
		}
	}

	.info-label {
		font-weight: bold;
	}
}

.action {
	padding: 20px 10px 30px 10px;
	text-align: center;
}

.footer-divider {
	width: 90%;
	border-color: @gray-lighter;
}

.footer {
	margin: auto;
	max-width: 350px;
	text-align: center;
	color: @gray;
	font-size: 11px;
}

.legal-name {
	margin: 10px auto;
	font-size: 13px;
}

.pushpay-logo {
	width: 150px;
}
