@import "../../bootstrap-variables";

@cd-allocation-overview-panel-margin-mobile: -@grid-gutter-width + 8;
@cd-allocation-overview-speed-settings-arrow-position: 75px;
@cd-allocation-overview-speed-settings-arrow-position-small: ~"calc(50% - 12px)";

.cd-allocation-overview {
	.panel {
		@media @mobile {
			margin-left: @cd-allocation-overview-panel-margin-mobile;
			margin-right: @cd-allocation-overview-panel-margin-mobile;
		}

		@media @small {
			overflow: hidden;
		}

		.panel-heading {
			display: flex;
			.text-left;

			@media @small {
				flex-direction: column;
				padding: 0;
			}
		}

		.panel-body {
			padding: 0;
			.text-left;
		}
	}
}

.cd-allocation-overview-listing,
.cd-allocation-overview-check-count,
.cd-allocation-overview-total-amount {
	color: @text-color;

	> h2,
	.h2 {
		margin-top: 0;

		@media @small {
			margin-bottom: 8px;
		}
	}
}

.cd-allocation-overview-listing {
	flex: 1 1 auto;

	@media @small {
		order: 1;
		background: @color-form;
		border-top: 1px solid @panel-default-inner-border;
	}
}

.cd-allocation-overview-totals {
	flex: 0 1 auto;
	display: flex;
}

.cd-allocation-overview-check-count,
.cd-allocation-overview-total-amount {
	flex: 0 1 auto;
	.text-right;
	padding-left: @panel-body-padding;

	@media @small {
		display: flex;
		flex-direction: column;
		padding: @panel-body-padding-mobile;
		width: 50%;
		.text-center;

		:first-child {
			order: 1;
			text-transform: capitalize;
		}
	}
}

.cd-allocation-overview-total-amount .h2 > sup {
	font-size: 55%;
	padding-right: 3px;
}

.cd-allocation-overview-body,
.cd-allocation-overview-speed-settings {
	padding: 20px @panel-body-padding;

	@media @mobile {
		padding: @panel-body-padding-mobile;
	}
}

.cd-allocation-overview-body {
	display: flex;
	align-items: center;

	@media @small {
		flex-direction: column;
		padding: 0;
	}

	> .btn.btn-link {
		flex: 0 1 auto;
		padding: 0;

		@media @small {
			width: 100%;
			margin: 15px 0;
			.text-center;
		}
	}
}

.cd-allocation-overview-dates {
	flex: 1 1 auto;
	display: flex;

	@media @small {
		flex-direction: column;
		width: 100%;
	}

	label > .hidden-xs {
		font-weight: normal;
		font-size: @font-size-base;
	}
}

.cd-allocation-overview-date-created,
.cd-allocation-overview-date-updated {
	flex: 0 1 auto;
	padding-right: 30px;

	> strong {
		white-space: nowrap;
	}

	@media @small {
		border-bottom: 1px solid @panel-default-inner-border;
	}
}

.cd-allocation-overview-listing,
.cd-allocation-overview-date-created,
.cd-allocation-overview-date-updated {
	@media @small {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 15px @panel-body-padding-mobile;

		> label,
		> h2,
		> strong {
			margin: 0;
			line-height: @line-height-base;
		}

		> label {
			flex: 0 1 auto;
		}

		> h2,
		> strong {
			display: block;
			flex: 1 1 auto;
			font-size: @font-size-base;
			font-weight: normal;
			.text-right;
		}
	}
}

.cd-allocation-overview-speed-settings {
	&:before {
		right: @cd-allocation-overview-speed-settings-arrow-position;

		@media @small {
			right: @cd-allocation-overview-speed-settings-arrow-position-small;
		}
	}

	.row > :last-child {
		margin-top: 28px;
		white-space: nowrap;

		@media @small {
			margin-top: 20px;
		}

		.btn {
			height: @input-height-base;
			padding: 0 20px;

			@media @mobile {
				width: 100%;
			}
		}
	}
}

.cd-allocation-overview-speed-settings-help {
	@media @mobile {
		display: flex;
		margin-left: 0;
		margin-bottom: 20px;

		&-icon {
			margin-right: 10px;
		}
	}
}

.cd-allocation-overview-tooltip {
	display: inline-block;
	position: relative;
	.square(24px);
	top: 8px;
	margin-left: @grid-gutter-width;
}
