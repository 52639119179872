@import "../../bootstrap-variables";
@import "../../mixins/svgs";

.multi-select {
	@item-line-height: 20px;
	@min-height: 52px;

	position: relative;


	.selected-item-container {
		.round(4);
		position: relative;
		padding: 7px 10px;
		min-height: @min-height;
		border: 1px solid @gray-lighter;
		background-color: white;
		width: 100%;
		float: left;
		margin-bottom: 0;
		li {
			height: 30px;
			margin: 3px;
			display: block;
			float: left;
			list-style: none;
		}
		.floating-label.as-placeholder {
			.floating-label-placeholder(@min-height);
		}
	}
	&.open .selected-item-container {
		border-color: @color-blue;
	}

	.spillow {
		.round(3);
		background-color: #eee;
		font-size: @font-size-base-minus;
		line-height: @item-line-height;
		.spillow-content {
			max-width: 281px;
			display: block;
			float: left;
			padding: 4px 0 6px 10px;
			.truncate;
			.no-wrap;
		}
		.spillow-close {
			height: 30px;
			padding: 10px;
			display: block;
			float: left;
			line-height: 10px;
			.svg(10px, 10px);
		}
	}

	li.multi-select-input-container {
		position: relative;
		font-size: @font-size-base-plus;
		line-height: @item-line-height;
		margin: 0;
	}
	&.open li.multi-select-input-container {
		min-width: 24px;
		padding: 0 12px 0 3px;
		margin: 3px;
	}

	.multi-select-input {
		font-size: @font-size-base-plus;
		line-height: @item-line-height;
		position: absolute;
		width: 0;
		height: 30px;
		top: 0;
		left: 0;
		border: 0;
		padding:2px 12px 0 3px;
		opacity: 0;
		&:focus {
			outline: none;
		}
	}
	&.open .multi-select-input {
		width: 100%;
		opacity: 1;
		transition: opacity 0s ease-in-out 250ms;
	}

	.display-items {
		.hidden-xs;
		max-height: 266px;
		overflow-y: auto;
		width: 100%;
		margin: 0;
		padding: 0;
		.btn-link {
			.truncate;
			height: 44px;
			font-size: @font-size-base-plus;
			line-height: @font-size-large;
			color: @gray-dark;
			padding: 13px 15px;
			&:disabled {
				background-color: white;
			}
		}
		li:not(:last-child) .btn-link {
			border-bottom: 1px solid @gray-lighter;
		}
		.selected .btn-link {
			background-color: #eff1f2;
		}
	}

	[data-error-highlight] {
		& ~ .select-wrapper .form-control, & ~ .selected-item-container {
			border: 1px solid @color-red-validation;
			.placeholder(@color-red-validation-placeholder);
		}
		& ~ .floating-label:after {
			color: @color-red-validation-placeholder;
		}
	}

	.floating-label.as-placeholder {
		position: relative;
		z-index: 10;
	}

	.multi-select-mobile {
		.visible-xs;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
	}
}
