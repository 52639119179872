.member-type-config-header > span > svg {
    margin-bottom: -7px;
}

.no-ccb-types-found-alert {
    margin-bottom: 0px;
}

@media screen and (max-width: 1024px) {
    .member-type-config-header > span:first-child {
        -webkit-box-flex: 1;
        flex-grow: 1;
    }

    .member-type-config-header:first-child {
        display: flex;
    }
}
