@import '../bootstrap-variables.less';

//
// Alerts
// --------------------------------------------------

// Base styles
// -------------------------

.alert {
	@icon-size: 60px;
	padding-left: @icon-size * 2;
	position: relative;

	h3 {
		margin-top: 0;
		color: inherit;
	}

	ul {
		display: inline-block;
	}

	.ion {
		font-size: @icon-size;
		position: absolute;
		left: @alert-padding;
		top: @alert-padding;
	}
	// this reduces the weight of the icon
	&.alert-success {
		.ion {
			-webkit-text-stroke: 1px @alert-success-bg;
		}
	}

	&.alert-info {
		.ion {
			-webkit-text-stroke: 1px @alert-info-bg;
		}
	}

	&.alert-warning {
		.ion {
			-webkit-text-stroke: 1px @alert-warning-bg;
		}
	}

	&.alert-danger {
		.ion {
			-webkit-text-stroke: 1px @alert-danger-bg;
		}
	}

	.alert-icon {
		margin-right: 10px;
		flex: 0 0 auto;
	}

	&.alert-sm {
		display: flex;
		padding: @alert-padding-sm;
	}
}

.alert-highlight {
	@icon-size: 32px;
	display: block;

	.alert-highlight-color(@highlight-color, @text-color) {
		border: 1px solid @highlight-color;

		.alert-icon {
			fill: @highlight-color;
		}

		@media not @small {
			border-left-width: @alert-highlight-border-width;
		}

		@media @small {
			border-top-width: @alert-highlight-border-width;
		}

		.alert-link {
			position: relative;
			padding-bottom: 2px;
			color: @text-color;
			white-space: nowrap;
			font-weight: normal;

			&::after {
				position: absolute;
				content: '';
				bottom: 0;
				right: 0;
				width: 100%;
				border-bottom: 1px dashed @text-color;
			}

			&:hover,
			&.hover,
			&:focus,
			&.focus {
				text-decoration: none;

				&:after {
					border-bottom-style: solid;
				}
			}
		}
	}

	background: white;
	padding: @alert-padding-sm;

	@media not @small {
		display: flex;
		flex: auto;
		align-items: center;

		.alert-icon {
			flex: none;
			align-self: flex-start;
		}
	}

	.alert-icon {
		.square(@icon-size);
		margin-right: @alert-padding-sm;
	}

	.alert-content {
		flex: auto;
	}

	.affix-wrapper &.affix {
		z-index: @zindex-navbar-fixed + 1;
		top: 100px;
	}

	@media @small {
		text-align: center;

		.alert-icon {
			text-align: center;
			width: 100%;
		}

		.btn-dismiss {
			position: absolute;
			top: @alert-padding-sm;
			right: @alert-padding-sm;
		}
	}

	&.alert-warning {
		/* TODO: @brand-warning is currently set to @color-yellow. @brand-warning should be updated to @color-orange-darker, and this should
		   be updated to @brand-warning. */
		.alert-highlight-color(@brand-warning, @alert-warning-text);
	}

	&.alert-danger {
		.alert-highlight-color(@brand-danger, @alert-danger-text);
	}

	&.alert-success {
		.alert-highlight-color(@brand-success, @alert-success-text);
	}

	&.alert-info {
		/* TODO: @brand-info is currently set to @color-blue. @brand-info should be updated to @gray-mid, and this should be updated to
			@brand-info
		*/
		.alert-highlight-color(@gray-mid, @alert-info-text);
	}

	&.alert-processing {
		.alert-highlight-color(@gray-mid, @alert-info-text);

		.alert-icon {
			animation: rotate 1.2s linear infinite;
		}
	}

	&-validation-list {
		margin-bottom: 0;
	}
}
