@import "../../bootstrap_theme/labels";
@import "../../bootstrap-variables";

.disabled-external-systems-pricing {
	hr {
		margin-top: 18px;
		margin-bottom: 18px;
	}
}

.disabled-external-systems-container {
	display: flex;
	flex-direction: row;

	@media @mobile {
		flex-direction: column;
	}
}

.disabled-external-systems-panel {
	min-width: 455px;
	width: 50%;
	margin-right: 10px;
	display: flex;
	flex-direction: column;

	@media @mobile {
		width: 100%;
		min-width: 0px;
	}
}

.disabled-external-systems-panel-first {
	margin-right: 10px;

	@media @mobile {
		margin-right: 0px;
		margin-left: 0px;
	}
}

.disabled-external-systems-panel-second {
	margin-left: 10px;

	@media @mobile {
		margin-right: 0px;
		margin-left: 0px;
	}
}

.disabled-external-systems-panel-heading {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
}

.disabled-external-systems-panel-heading-desc {
	flex: 1 1 auto;
}

.disabled-external-systems-panel-heading-link {
	flex: 0 0 auto;
	padding-bottom: 20px;
}

.disabled-external-systems-panel-body {
	height: 330px;
	flex: 0 0 auto;

	@media @mobile {
		display: none;
	}
}

.disabled-external-systems-logos {
	margin: auto;
}

.panel.panel-color .panel-heading.disabled-external-systems-panel-heading a.btn {
	font-weight: normal;
	color: @link-color;
}

.disabled-external-systems-help-text {
	font-size: @font-size-base;
}
