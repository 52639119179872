.form-overview-text {
   color: @gray-light;
   margin-bottom: @form-group-margin-bottom;
}

.form-control-text {
   background: transparent;
   border: none;
}

.normal-label {
   font-weight: normal;
}