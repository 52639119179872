@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.grid-panel {
	padding: 0 @grid-gutter-width*2 @grid-gutter-width @grid-gutter-width*2;
	font-size: @font-size-base-minus;

	@media @small {
		padding-left: @grid-gutter-width;
		padding-right: @grid-gutter-width;
	}
}

.grid-row {
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid @gray-lighter;
}

.row-label {
	font-size: @font-size-base * 1.3;
	margin-bottom: @padding-small-vertical;

	@media @small {
		font-weight: bold;
		font-size: @font-size-base-plus;
	}
}

.row-value {
	font-size: @font-size-base * 1.5;
	text-align: right;

	@media @small {
		font-size: @font-size-base-plus;
	}

	.dollar {
		font-size: 0.7em;
		vertical-align: super;
	}
}
