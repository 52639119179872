@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@spinnerSize: 14px;
@savedColor: @gray;
@savingColor: @color-complementary-green;
@unsavedColor: @color-red;

.loading-spinner-container {
	display: flex;
	align-items: center;
}

.spinner() {
	width: @spinnerSize;
	min-width: @spinnerSize;
	height: @spinnerSize;
	min-height: @spinnerSize;
	margin-right: 7px;
}

.saved {
	.spinner();
	fill: @savedColor;
}

.saving {
	.saved();
	fill: @savingColor;
	animation: loading-spinner-rotating 0.8s linear infinite;
}

.unsaved {
	.spinner();
	fill: @unsavedColor;
}

@keyframes loading-spinner-rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}

.spinner-text-wrapper {
	color: @gray;
	font-size: @font-size-base-minus;
	position: relative;
	width: 60px;
	height: 22px;
}

.spinner-text {
	position: absolute;
}

.retry-tooltip {
	padding: 0;
}

.retry-tooltip-content {
	white-space: nowrap;
}
