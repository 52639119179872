@import "../bootstrap-variables.less";

@slider-toggle-width:				55px;

.slider-toggle {
	@slider-toggle-height:				24px;
	@slider-toggle-track-offset:		3px;
	@slider-toggle-track-width:			44px;
	@slider-toggle-track-height:		15px;
	@slider-toggle-ball-width:			20px;
	@slider-toggle-ball-height:			21px;


	@slider-toggle-small-width:			40px;
	@slider-toggle-small-height:		17px;
	@slider-toggle-small-track-width:	29px;
	@slider-toggle-small-track-height:	12px;
	@slider-toggle-small-ball-width:	17px;
	@slider-toggle-small-ball-height:	17px;

	@slider-toggle-ball-top: floor((@slider-toggle-height - @slider-toggle-ball-height)/2);

	input[type=checkbox]&,
	input[type=checkbox] {
		// hide the checkbox
		visibility: hidden;
		display: none;
	}

	input[type=checkbox]& ~ label,
	input[type=checkbox] ~ span {
		padding-left: @slider-toggle-width !important;
		min-width: @slider-toggle-width;
		min-height: @slider-toggle-height;
		line-height: @slider-toggle-height;
		vertical-align: middle;
		position: relative;
		display: inline-block;
		transition: all .3s ease;
		user-select: none;
		font-weight: normal;
		cursor: pointer;
		font-size: @font-size-base;
		color: @gray;

		// Slider track
		&::before {
			content: '';
			position: absolute;
			top: @slider-toggle-ball-top + @slider-toggle-track-offset;
			left: @slider-toggle-track-offset;
			width: @slider-toggle-track-width;
			height: @slider-toggle-track-height;
			border-radius: @slider-toggle-track-height;
			background-color: #c6c5c5;
			transition: all .3s ease;
			visibility: visible;
		}

		// slider disk
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: @slider-toggle-ball-top;
			display: block;
			width: @slider-toggle-ball-width;
			height: @slider-toggle-ball-height;
			border-radius: 100%;
			background: #F1F1F1;
			.box-shadow-small;
			transition: all .3s ease;
			visibility: visible;
		}

		&:active::after {
			transform: scale(1.15, .85);
		}
	}

	input[type=checkbox]&:checked ~ label,
	input[type=checkbox]:checked ~ span {
		color: @color-complementary-green;

		&::before {
			background: @color-complementary-green-faded;
		}

		&::after {
			left: @slider-toggle-track-width - @slider-toggle-track-offset - ceil(@slider-toggle-ball-width/2);
			background: @color-complementary-green;
		}
	}

	input[type=checkbox]&:disabled ~ label,
	input[type=checkbox]&:checked:disabled ~ label,
	input[type=checkbox]:disabled ~ label,
	input[type=checkbox]:checked:disabled ~ label {
		pointer-events: none;

		&::before {
			background-color: #BCBDBC;
		}

		&::after {
			background: #d5d5d5;
		}
	}

	&.small {

		input[type=checkbox]& ~ label,
		input[type=checkbox] ~ span {
			min-height: @slider-toggle-small-height;
			line-height:@slider-toggle-small-height;
			padding-left: @slider-toggle-small-width !important;
			min-width: @slider-toggle-small-width;
			font-size: @font-size-base * 0.86;

			&::before {
				width: @slider-toggle-small-track-width;
				height: @slider-toggle-small-track-height;
				border-radius: @slider-toggle-small-track-height;
				top: @slider-toggle-track-offset;
			}

			&::after {
				width: @slider-toggle-small-ball-width;
				height: @slider-toggle-small-ball-width;
				top: 0;
			}
		}

		input[type=checkbox]&:checked ~ label::after,
		input[type=checkbox]:checked ~ span::after {
			left: @slider-toggle-small-track-width - @slider-toggle-track-offset - ceil(@slider-toggle-small-ball-width/2);
		}
	}
}
