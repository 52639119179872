@import "../bootstrap-variables.less";
@import "../mixins/round.less";

.sidemenu {
	display: flex;
	margin-bottom: @grid-gutter-width;
	border-bottom: 1px solid @nav-tabs-border-color;
	@media @mobile {
		flex-direction: column;
		border-bottom: none;
	}


	> .sidemenu-title {
		flex: 0 1 auto;
	}

	> .nav-tabs {
		flex: 1 1 auto;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		border-bottom: none;
		margin-bottom: 0;
		background-color: transparent;

		@media @mobile {
			flex-direction: column;
			align-items: flex-start;
		}

		>li {
			> a {
				border: none;
				background: transparent;

				&:active, &:hover {
					border: none;
				}
			}

			&.active {
				background: none;
				a:hover {
					background: none;
				}
				a:after {
					display: block;
					position: absolute;
					background: white;
					text-align: center;
					content: " ";
					border-left: 1px solid @nav-tabs-border-color;
					border-bottom: 1px solid @nav-tabs-border-color;
					margin-left: -5px;
					width: 10px;
					height: 10px;
					left: 50%;
					bottom: -5px;
					transform: rotate(-45deg)  skew(-10deg, -10deg);

					@media @mobile {
						display: none;
					}
				}
			}
		}
	}
}
