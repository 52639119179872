@import "../../bootstrap-variables";
@import "../../mixins/svgs";
@import "../../mixins/helpers";

.popup {

	@desktop-width: 800px;
	@readable-width: 700px;

	background: rgba(255, 255, 255, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	z-index: @zindex-popup;
	display: none;

	&.visible {
		display: block;
	}

	.content {
		position: relative;
		max-width: @desktop-width;
		margin: 56px auto 10px;
		box-sizing: content-box;
	}

	.content-text {
		max-width: @readable-width;
	}

	.close {
		opacity: 1;
		margin-right: @padding-large-horizontal;
		color: @gray;
		.svg(23px, 23px);
	}

	.affix-wrapper {
		.pull-right;
	}

	.affix {
		max-width: @desktop-width;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		.close {
			@circle-size: 44px;
			@cross-size: 18px;
			@cross-padding: (@circle-size - @cross-size) / 2;

			.colored-circle(@color-red, @circle-size);
			margin: @padding-large-vertical @padding-large-horizontal 0 0;
			.box-shadow-small;
			opacity: 1;
			animation: revealCloseLink 200ms linear;

			.svg(@cross-size, @cross-size);
			.svg {
				color: white;
				margin: @cross-padding 0 0 @cross-padding;
			}
		}
	}

	@keyframes revealCloseLink {
		from {
			transform: scale3d(0.25, 0.25, 0.25);
			opacity: 0.01;
			box-shadow: none;
		}
	}
}

// this blurs the body contents
// you have to ensure that your popup is located directly below the body element
// otherwise your popup will be blurred
// modals are assumed to appear on top of popups so arent blurred either
body {
	&.popped {
		overflow: hidden;
		> :not(.popup):not(.modal-container) {
			filter: blur(10px);
		}
	}
}

