// This is for Loggedinweb styles
// This is only used when feature flag is used.
// This is currently targeting Merchant Transactions and the details view.
@media print {
	body.print-friendly {
		padding-top: 0 !important;
		display: block !important;

		.body-content {
			max-width: none;
		}

		> header {
			display: none;
		}

		nav.sidebar {
			display: none;
		}

		form > fieldset {
			display: none;
		}

		main {
			margin-top: 0px;
		}

		.grid-mvc {
			overflow-x: visible;
		}

		table.mobile-enabled {
			border-top: none;

			.mobile-right {
				float: none !important;
			}

			.hidden-xs {
				visibility: visible;
				display: table-cell !important;
			}

			.col-email {
				overflow-wrap: break-word;
				overflow: visible;
			}

			thead {
				tr {
					display: table-row;

					th.grid-header {
						visibility: visible;
						display: table-cell !important;

						&.visible-xs-block {
							visibility: collapse !important;
							display: none !important;
						}
					}
				}
			}

			tbody {
				tr {
					border-top: @gray-lighter solid 2px;
					display: table-row;

					td {
						&.grid-cell {

							&:not(.col-mobile) {
								visibility: visible;
								display: table-cell !important;
							}

							&.col-mobile {
								display: table-cell !important;

								&.visible-xs-block {
									visibility: collapse !important;
									display: none !important;
								}
							}
						}

						&.data-list {
							visibility: collapse !important;
							display: none !important;
						}
					}
				}
			}

			tfoot {
				display: none;
			}
		}

		footer {
			display: none;
		}
	}
}
