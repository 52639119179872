@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.panel-row-group {
	padding: @grid-gutter-width / 4 @grid-gutter-width;
	font-size: @font-size-base-minus;

	@media @small {
		padding-left: @grid-gutter-width;
		padding-right: @grid-gutter-width;
	}

	@panel-row-padding-vertical: 15px;
	.panel-row {
		padding-top: @panel-row-padding-vertical;
		padding-bottom: @panel-row-padding-vertical;
		border-bottom: 1px solid @gray-lighter;

		&-no-end-border {
			&:last-child {
				border-bottom: none;
			}
		}

		.panel-row-label {
			font-size: @font-size-base * 1.3;
			margin-bottom: @padding-small-vertical;

			@media @small {
				font-weight: bold;
				font-size: @font-size-base-plus;
			}
		}

		.panel-row-value {
			font-size: @font-size-base * 1.5;
			text-align: right;

			@media @small {
				font-size: @font-size-base-plus;
			}

			.dollar {
				font-size: 0.7em;
				vertical-align: super;
			}
		}

		.panel-row-value-small {
			font-size: @font-size-base-plus;
			text-align: right;

			.dollar {
				font-size: 0.7em;
				vertical-align: super;
			}
		}
	}

	.panel-row-send-email {
		display: flex;
		align-items: center;
		padding-top: @panel-row-padding-vertical;
		padding-bottom: @panel-row-padding-vertical;

		.info-link {
			margin-left: @padding-xs-horizontal;
		}
	}
}

.status-colors() {
	> .issued {
		.label-variant(@color-complementary-green);
	}

	> .generated {
		.label-variant(#93278f);
	}

	> .generating,
	.issuing {
		.label-variant(#ffaa00);
	}

	> .configured {
		.label-variant(@gray-mid);
	}

	> .failed {
		.label-variant(#e51b00);
	}
}

.loading-spinner-wrapper {
	position: relative;
	min-height: 200px;
}
