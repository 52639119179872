#insights {
	.insights-vars;

	.giving-by-time-of-day {

		.chart {

			.horizontal-lines {

				&.bottom {
					stroke: @color-orange;
				}
			}
		}

		.axis {
			&.sub-heading {
				margin-left: 10px;
				span {
					width: 24%;
					text-align: center;
					display: inline-block;
				}
			}

			&.x {

				line {
            		fill: @color-orange;
            		stroke: @color-orange;
            		stroke-width: 2px;

            	}

            	.tick {
            		text {
            			opacity: 0;
            		}
            	}
			}

			&.big {
				.tick {
					opacity: 0;

					/*
					text {
						text-anchor: start;
						x: -3px;
					}
					*/

					&:nth-child(3n+5) {
						opacity: 1;
						text {
							opacity: 1;

						}
					}

					&:first-child {
						opacity: 1;

					}

					&:last-child {
						opacity: 0;
						text {
                        	opacity: 0;
                        }
					}
				}
			}
		}
	}
}
