.cd-allocation-complete-dialog-summary {
	display: flex;
	margin-top: 30px;
	margin-bottom: 20px;
	padding-top: 10px;
	border-top: 1px solid @border-color;
	border-bottom: 1px solid @border-color;

	@media @small {
		flex-wrap: wrap;
		padding: 20px 0;
	}
}

.cd-allocation-complete-dialog-summary-item {
	&:first-child {
		flex: 1 1 auto;
		.text-left;

		@media @small {
			flex-basis: 100%;
			.text-center;
			margin-bottom: 12px;
		}
	}

	&:not(:first-child) {
		.text-right;
		flex: 0 1 auto;
		padding-left: 40px;

		@media @small {
			display: flex;
			flex-direction: column;
			padding: 0;
			flex-basis: 50%;
			.text-center;

			> label {
				order: 1;
			}
		}
	}

	> h3 {
		margin-top: 0;
		margin-bottom: 18px;
		font-size: @font-size-h2;
		font-weight: 300;

		> sup {
			margin-right: 3px;
			font-size: @font-size-base-minus;
		}
	}
}
