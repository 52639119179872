@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.modal-text {
	font-weight: 300;
	text-align: center;
}

.error-icon {
	color: @color-red;
}
