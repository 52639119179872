#insights {
	.insights-vars;

	h1 {
		font-size: @font-size-h1;
	}

	h2 {
		margin-top: 16px;
		font-size: @font-size-h2;
	}

	h3 {
		font-size: @font-size-h3;
	}

	h4 {
		font-size: @font-size-h4;
	}

	h5 {
		font-size: @font-size-h5;
	}

	h6 {
		font-size: @font-size-h6;
	}

	small {
		font-size: @font-size-small;
	}

	@media @mobile {
		.page-header {
			text-align: center;
		}
	}
}
