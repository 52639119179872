@import "../../bootstrap-variables";
@import "../../mixins/svgs";

.status(@color) {
	color: @color;
}

.status-label {
	@size: 25px;

	font-size: @font-size-base;

	.svg,
	span {
		vertical-align: middle;
		line-height: @size;
	}

	.svg {
		width: @size;
		height: @size;
		margin-right: 15px;
	}


	&.status-label-success {
		.status(@brand-success)
	}
	&.status-label-info {
		.status(@brand-info)
	}
	&.status-label-warning {
		.status(@brand-warning)
	}
	&.status-label-danger {
		.status(@brand-danger)
	}
}