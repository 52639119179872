@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.back-to-giving-statements {
	font-size: @font-size-base;

	> svg {
		fill: @btn-link-color;
		width: 14px;
		height: 14px;
	}

	@media @small {
		align-self: flex-end;
		margin: 0 0 10px 0;
	}
}

.spacing {
	margin-bottom: 40px;
}

.separator {
	border-bottom: 1px solid @gray-lighter;

	@media @small {
		display: none;
	}
}
