@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@filters-padding-horizontal-total: @grid-gutter-width*2;
@filters-margin-horizontal-mobile: -@grid-gutter-width - @panel-border-width;

.container {
	display: block;
	padding-top: 0;
	position: relative;

	@media @mobile {
		padding-top: 50px;
	}
}

.filters {
	border-radius: 0;
	margin-bottom: @grid-gutter-width / 2;

	&:before {
		left: percentage(1/6);
	}

	@media @desktop {
		background: none;
		border: none;
		box-shadow: none;
		padding: 0;

		&:before {
			display: none;
		}
	}

	@media @mobile {
		padding: @grid-gutter-width @grid-gutter-width / 2;
		margin: 0 @filters-margin-horizontal-mobile @grid-gutter-width;
	}
}

.filter-summary-controls-row {
	display: flex;
	align-items: baseline;
}

.filter-summary {
	margin: 0;
	flex: auto;
	padding-right: @grid-gutter-width;
}

.controls {
	display: flex;
	flex: none;

	@media @mobile {
		position: absolute;
		top: 0;
		left: @grid-gutter-width;
		width: ~'calc(100% - @{filters-padding-horizontal-total})';
	}
}

.control-item + .control-item {
	@media @mobile {
		border-left: 1px solid @nav-tabs-border-color;
	}
}

.control-item {
	flex: none;
	padding: 0;
	margin-left: @grid-gutter-width;

	@media @mobile {
		margin-left: 0;
		flex-basis: percentage(1/3);
	}
}

.button-refresh {
	@media @desktop {
		min-width: 115px;
	}
}
