
.arr(@vertical-align: middle) {
	font-family: @ionicons-font-family;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	padding-right: 5px;
	text-decoration: none;
	vertical-align: @vertical-align;
	font-style: normal;
}

.arrow-hollow-left-before {

	margin-left: 20px;

	&::before {
		.arr(baseline);
		content: @ionicon-var-chevron-left;
		padding-right: 7px;
		margin-left: -20px;
	}
}

.arrow-hollow-right-after {
	&::after {
		.arr(baseline);
		content: @ionicon-var-chevron-right;
		padding-left: 7px;
	}
}

.arrow-hollow-down-before {

	&::before {
		.arr;
		content: @ionicon-var-chevron-down;
		padding-right: 5px;
	}
}

.arrow-hollow-down-after {

	&::after {
		.arr;
		content: @ionicon-var-chevron-down;
		padding-left: 5px;
	}
}

.arrow-hollow-up-after {

	&::after {
		.arr;
		content: @ionicon-var-chevron-up;
		padding-left: 5px;
	}
}

.arrow-solid-down-after {

	&::after {
		.arr;
		content: @ionicon-var-android-arrow-dropdown;
		padding-left: 5px;
		margin-top: -0.23em;
	}
}

.arrow-solid-up-after {

	&::after {
		.arr;
		content: @ionicon-var-android-arrow-dropup;
		padding-left: 5px;
		margin-top: -0.23em;
	}
}

.arrow-solid-right-after {

	&::after {
		.arr;
		content: @ionicon-var-android-arrow-dropright;
		padding-left: 2px;
	}
}

.arrow-point-right {
	&::before{
		.arr;
		content: @ionicon-var-arrow-right-c;
	}
}

.arrow-link {
	padding-right: 16px;
	.arrow-solid-right-after();
}
