@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';
@import '../../../../Content/LoggedInWeb/mixins/svgs.less';
@import '../../../../Content/LoggedInWeb/bootstrap/mixins/vendor-prefixes.less';

.container {
	@item-line-height: 20px;
	@height: 44px;
	position: relative;

	.selected-item-container {
		border-radius: 4px;
		position: relative;
		padding: 6px 10px;
		border: 1px solid @gray-lighter;
		background-color: white;
		width: 100%;
		margin-bottom: 0;
		li {
			height: 30px;
			margin: 3px;
			display: block;
			float: left;
			list-style: none;
		}
	}
	&.open .selected-item-container {
		border-color: @gray-dark;
	}

	&.open .multi-select-input-container {
		min-width: 24px;
		padding: 0 12px 0 3px;
		margin: 0px;
	}

	.multi-select-input {
		font-size: @font-size-base-plus;
		line-height: @item-line-height;
		position: relative;
		height: 30px;
		top: 0;
		left: 0;
		border: 0;
		padding: 2px 12px 0 3px;
		opacity: 0.5;
		width: 100%;
		.truncate;
		white-space: nowrap;

		&:focus {
			outline: none;
		}
	}

	&.open .multi-select-input,
	.multi-select-input.selected {
		opacity: 1;
		transition: opacity 0s ease-in-out 250ms;
	}

	.display-items {
		max-height: 266px;
		overflow-y: auto;
		width: 100%;
		margin: 0;
		padding: 0;
		float: none;

		@media @small {
			position: fixed;
			top: @navbar-height;
			bottom: 0;
			max-height: none;
			z-index: @zindex-popover;
			border: 1px solid white;
			border-radius: 0px;
		}

		.btn-link {
			.truncate;
			color: @gray-dark;
			&:hover {
				color: inherit;
			}
		}

		li:not(:last-child) .btn-link {
			border-bottom: 1px solid @gray-lighter;
		}

		li:last-child .btn-link {
			@media @small {
				border-bottom: 1px solid @gray-lighter;
			}
		}

		li.selected {
			background-color: @panel-bg;
		}

		li.current {
			background-color: @gray-lightest;
		}

		.selected .tickbox {
			background-color: @color-green;
			border-color: @color-green;
		}

		.tickbox {
			margin-right: 10px;
			.square(20px);
			border-radius: 4px;
			border: 1px solid @gray-light;
			vertical-align: bottom;
			content: ' ';
			display: inline-block;

			.svg {
				.square(100%);
				fill: white;
			}

			&.tickbox-disabled {
				background-color: @gray-lighter;
				border-color: @gray-light;

				.svg {
					fill: @gray;
				}
			}
		}

		.close-button {
			position: relative;
			float: right;
		}

		.close-button .svg {
			width: 22px;
			height: 22px;
			fill: @gray;
		}

		.dropdown-header {
			.truncate;
			height: 44px;
			font-size: @font-size-base-plus;
			line-height: 22px;
			color: @gray-dark;
			padding: 11px 15px;
			border-bottom: 1px solid @gray-lighter;
		}
	}

	[data-error-highlight] {
		& ~ .select-wrapper .form-control,
		& ~ .selected-item-container {
			border: 1px solid @color-red-validation;
			.placeholder(@color-red-validation-placeholder);
		}

		& ~ .floating-label:after {
			color: @color-red-validation-placeholder;
		}
	}
}

.truncate() {
	overflow: hidden;
	text-overflow: ellipsis;
}

.square(@size) {
	width: @size;
	height: @size;
}
