.loading-box {
    z-index: 1;
	.text-center;
	position: absolute;
	top: @line-height-computed;
	left: 0;
	right: 0;

    &-img {
        margin-bottom: @padding-base-vertical;
        animation-name: rotate;
        animation-duration: 1000ms;
        transform-origin: 50% 50%;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
