.noncash-edit-delete-panel li {
   list-style: none;
   display: flex;
   margin-bottom: 15px;

   label {
      width: 25%;
      display: flex;
      align-items: center;
   }

   .phone-group-control {
      width: 50%;
   }
}

.noncash-edit-delete-panel .footer-overview-text {
   margin-top: 15px;
}
