//
// Dropdown menus
// --------------------------------------------------

// Dropdown arrow/caret
.caret {
	margin-left: 8px;
}

.dropdown-menu {
	&.open {
		display: block;
	}

	//need to reset :hover state for the elements when it's controlled in js by setting .hover class
	&.dropdown-menu-hover-controlled {
		li:hover:not(.hover) {
			background-color: transparent;

			button:not(.active) {
				border-color: transparent;
				color: @text-color;
			}
		}
	}
}
