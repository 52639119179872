@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@contentWidth: 90%;

.container {
	border: 1px solid @gray-light;
	border-top: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
}

.email-template {
	position: relative;
	display: flex;
	flex-direction: column;
	border-top: 12px solid @color-red;
	border-radius: 4px;

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		padding: 20px;

		.organization {
			display: flex;
			align-items: center;

			.logo {
				width: 60px;
				height: 60px;
				margin-right: 10px;
			}

			.name {
				font-size: @font-size-base-plus;
			}
		}

		.information-panel {
			align-self: flex-start;
			padding-left: 10%;
			width: 90%;

			div {
				padding-top: 5px;
			}
		}

		.amount-panel {
			font-size: 20px;
			font-weight: 300;

			.dollar-value {
				font-weight: 600;
				float: right;
				margin-left: 5px;
			}

			.text {
				float: left;
			}

			.dots {
				overflow: hidden;
			}
		}

		.greeting {
			font-size: 20px;
			font-weight: 300;
			padding-bottom: 5px;
		}

		.email-heading-input {
			width: @contentWidth;
		}

		.header-divider {
			width: 30%;
			margin: 0 0 20px 0;
		}

		.date {
			font-size: @font-size-base-plus;
			margin-bottom: 20px;
		}

		.button-panel {
			display: flex;
			justify-content: center;
			padding: 24px;
		}

		.download-statement-btn {
			pointer-events: none;
		}

		.problems {
			font-weight: 600;
		}

		.single-access-link {
			font-weight: 600;
			text-decoration: underline;
			padding-top: 10px;
		}

		.more-detail {
			font-weight: 700;
			font-size: 20px;
			margin-top: 20px;
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		padding: 5px 20px 32px;

		.content {
			display: flex;
			flex-direction: column;
			width: @contentWidth;

			.last-update {
				align-self: flex-end;
				font-size: @font-size-base-minus;
				font-style: italic;
			}

			.email-content-wrapper {
				display: flex;
				flex-direction: column;
				margin-top: 10px;
				margin-bottom: 18px;

				.message {
					font-size: @font-size-base-plus;
				}

				.char-limit {
					width: 100%;
					display: flex;
					align-content: center;
					justify-content: space-between;
					font-size: @font-size-base-minus;
					font-style: italic;
				}

				.limit-exceeded {
					color: @color-red;
				}
			}
		}

		.video-link {
			margin-bottom: 30px;
		}

		.enquiry {
			font-size: 13px;
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		padding-bottom: 64px;
		border-bottom: 1px solid @gray-lighter;

		.footerLogoText {
			vertical-align: middle;
		}

		.pushpay-logo {
			width: 115px;
			margin-left: 5px;
			vertical-align: middle;
		}

		.footer-divider {
			color: @gray-lighter;
			width: @contentWidth;
			height: 2px;
		}

		.copyright {
			font-size: 13px;
			color: @gray;
		}
	}
}

.btn-group {
	display: flex;
	justify-content: center;
	background-color: white;
	padding: 20px 40px;

	:global(.btn) {
		flex-grow: 1;
		max-width: 325px;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: 10px;
		margin-right: 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.tooltip-icon {
		fill: @gray-light;
		vertical-align: middle;
	}
}

@media @small {
	.btn-group {
		flex-wrap: wrap;
		padding-left: 20px;
		padding-right: 20px;

		:global(.btn) {
			margin: 0;
			max-width: none;
			flex-basis: 100%;

			&:not(:global(.btn-default)) {
				order: 1;
				margin-top: 20px;
			}
		}
	}
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(77, 77, 77, 0.2);
}

.bust-overlay {
	position: relative;
	z-index: 1;
}

.action-container {
	height: 42px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
