@import "../../bootstrap-variables";

.payment-details-title {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.payment-details-title-text {
		margin-right: 10px;
	}

	.spacer {
		margin-left: auto;
	}

	.payment-details-title-ccblink-container {
		a {
			padding-left: 0;
			padding-right: 0;
		}

		@media @small, @mobile {
			flex: 1 1 100%;
		}
	}
}

.transaction-details {
	display: flex;
	align-items: flex-start;
	margin-top: @grid-gutter-width;

	@media @small {
		flex-direction: column;
	}

	.payment-details-container {
		border: 1px solid @border-color;
		border-radius: @border-radius-base;
		margin-right: @grid-gutter-width * 2;
		margin-bottom: 15px;
		flex: 8 1 auto;
		overflow: auto;

		@media @mobile {
			margin-right: 25px;
		}

		@media @small {
			border-width: 1px 0 0;
			border-radius: 0;
			width: 100vw;
			overflow: hidden;
			margin: 0 -@grid-gutter-width;
		}

		.payment-details-heading {
			margin: 0;
			padding: @panel-heading-padding;
			display: flex;
			align-items: center;

			@media @mobile {
				padding: @grid-gutter-width @grid-gutter-width*1.5;
			}

			@media @small {
				padding: @grid-gutter-width;
			}

			h2 {
				margin: 0;
			}

			.payment-action {
				margin-left: auto;

				.btn {
					.svg {
						&.svg-icon-refund {
							margin-right: @padding-xs-horizontal;
						}
					}

					&:hover .svg,
					&:focus .svg {
						fill: @link-hover-color;
					}

					.refund-disabled,
					&:hover .refund-disabled,
					&:focus .refund-disabled {
						fill: currentColor;
						stroke: transparent;
					}
				}
			}
		}

		.payment-details {
			border-top: 1px solid @border-color;
			margin: 0;
			padding: 0 @grid-gutter-width*2;
			background-color: @color-form;

			@media @mobile {
				padding: 0 @grid-gutter-width*1.5;
			}

			@media @small {
				padding: 0;
			}

			dl {
				width: 100%;
				overflow: hidden;
				padding: @grid-gutter-width*1.5 0 15px;
				margin: 0;

				@media @small {
					padding-left: @grid-gutter-width;
					padding-right: @grid-gutter-width;
				}

				dt {
					width: 30%;
					float: left;
					clear: left;
					margin-bottom: @form-group-margin-bottom-reduced;

					@media @small {
						width: 100%;
						float: none;
						margin: 0;
					}
				}

				dd {
					width: 70%;
					float: left;
					margin-bottom: @form-group-margin-bottom-reduced;

					@media @small {
						width: 100%;
					}

					.svg {
						position: relative;
						top: @thumbnail-padding;
					}

					.note {
						margin: 0;
						font-size: 13px;

						strong {
							font-weight: 600;
						}
					}

					&.long-user-content-with-whitespace {
						overflow-wrap: break-word;
						word-wrap: break-word;
						word-break: break-word;
						white-space: pre-wrap;
					}
				}

				&.references {
					border-top: 1px solid @border-color;
				}
			}

			.btn {
				padding: 0;
				font-size: @font-size-h6;
				line-height: @line-height-base;
				height: auto;
			}
		}

		.page-actions {
			display: flex;
			border-top: 1px solid @border-color;
			flex-direction: row-reverse;
			justify-content: center;
			padding: @grid-gutter-width*1.5 @grid-gutter-width;

			@media @small {
				flex-direction: column;

				.btn {
					margin: 0;
				}
			}

			.btn:not(:first-child) {
				margin-right: @grid-gutter-width;

				@media @small {
					margin: @grid-gutter-width 0 0;
				}
			}
		}
	}

	.admin-details {
		border-top: 1px solid @border-color;
		border-bottom: 1px solid @border-color;
		padding: @grid-gutter-width*1.5 @grid-gutter-width;
		flex: 1 2 auto;

		@media @small {
			width: 100vw;
			overflow: hidden;
			margin-left: -@grid-gutter-width;
			margin-right: -@grid-gutter-width;
		}

		h3 {
			margin: 0 0 @grid-gutter-width;
		}

		dl {
			margin: 0;

			dd {
				margin: 0 0 @form-group-margin-bottom-reduced;
			}

			*:last-child {
				margin: 0;
			}
		}
	}
}
