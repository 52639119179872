@page {
	size: 330mm 427mm;
	margin: 14mm 14mm;
}

@media print {
	body {
		display: block;
	}

	._pendo-resource-center-badge-container {
		display: none;
		visibility: hidden;
	}
}
