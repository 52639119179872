@import "../bootstrap-variables.less";
@import "../mixins/round.less";

.nav-tabs {
	@padding-nav-item-horizontal: 40px;
	@padding-nav-item-vertical: 15px;

	background-color:@color-form;
	margin-bottom: @grid-gutter-width;

	> li {

		> a {
			font-size: @font-size-h4;
			padding: @padding-nav-item-vertical @padding-nav-item-horizontal;
			margin-right: 0;
			.round(0);
			color: @nav-tabs-link-color;
		}

		&.active > a {
			color: @nav-tabs-active-link-color;
		}

		@media @small {
			.text-center;
		}
	}
}
