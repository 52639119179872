@import "../../bootstrap-variables.less";
@import "../../mixins/round.less";

@fund-listings-action-width: 45px;
@fund-listings-item-height: 44px;
@fund-listings-item-color: white;

.fund-listing-container,
.configure-listings-tip-box {
	.fund-listing-item-action {
		width: @fund-listings-action-width;
		text-align: center;
		display: inline-block;
		.svg {
			color: white;
		}
	}

	.fund-listing-item-action-campaign {
		width: 65px;
	}
}

.configure-listings-tip-box {
	.fund-listing-item-action {
		background-color: @icon-bg;
		border-radius: 100%;
		height: @fund-listings-item-height;
		line-height: @fund-listings-item-height;
		display: inline-block;
		color: @fund-listings-item-color;
	}

	.dl-horizontal {
		dt {
			width: @fund-listings-action-width;
		}

		dd {
			margin-left: @fund-listings-action-width + 15px;
		}
	}
}

.fund-listing-container {
	margin-left: -@grid-gutter-width;
	margin-right: -@grid-gutter-width;

	@radius: @font-size-base-plus * 1.25;
	@number-of-actions: 3;

	@item-left-padding: @radius/2;
	@color-item-action-inactive: @white-70;

	@font-labels-small: 12px;

	@margin-between-rows:5px;

	//New fund label
	@color-new-label: white;
	@color-new-label-text: @color-complementary-green;
	@radius-new-label:20px;

	@gap: 10px;

	@media @mobile {
		margin-left: -(@grid-gutter-width / 2);
		margin-right: -(@grid-gutter-width / 2);
	}

	&.is-being-reordered {
		.fund-listing:not(.is-being-reordered){
			opacity: 0.7;

			.fund-listing-item {
				background-color: desaturate(@color-complementary-green, 30%);
				&.is-invisible {
					background-color: desaturate(@color-complementary-green-faded, 30%);
				}
			}
		}
	}

	.fund-listing {
		.make-md-column(6);
		transition: opacity .3s;
		position: relative;
		padding-left: @grid-gutter-width;
		padding-right: @grid-gutter-width;

		@media @mobile {
			padding-left: @grid-gutter-width / 2;
			padding-right: @grid-gutter-width / 2;
		}

		.toggle-default-fund {

			label {
				margin: 0;
				vertical-align: middle;
			}
		}

		.fund-listing-headers {
			.help-block;
			margin-bottom: 0;
			font-size: @font-labels-small;
			display: flex;

			.fund-listing-item-info {
				padding-left: @item-left-padding;
			}
		}

		.fund-listing-item-info {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			flex: 1 1 auto;
		}

		.fund-listing-item-action {
			flex-shrink: 0;
		}

		.fund-listing-item-list {
			list-style: none;
			padding: 0;
			transform-style: preserve-3d;

			li {
				padding-top: @margin-between-rows;
				transform: translate3d(0,0,0);

				&.is-dragged {
					z-index:9999;//I :heart: IE

					.fund-listing-item {
						box-shadow: rgba(50,50,50,0.7) 0 1px 5px 1px;
					}
				}
			}
		}

		.fund-listing-item {
			.round(@radius);
			transition: background-color .3s, box-shadow .3s;
			background-color: @color-complementary-green;//highlight - #218F5C
			color: @fund-listings-item-color;
			font-size: @font-size-base;
			height: @fund-listings-item-height;
			line-height: @fund-listings-item-height;
			overflow: hidden;
			display: flex;
			user-select: none;

			&:not(.is-invisible) {
				cursor: move;
			}

			.fund-listing-item-info {
				display: flex;
				align-items: baseline;
				width: 0;//forcing flex to use minimum possible width

				.fill-width {
					flex-grow: 1;
				}

				span {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.fund-listing-drag-handle {
					color: white;
					fill: currentColor;
					flex-shrink: 0;

					margin: @item-left-padding;
					opacity: 0.7;
					vertical-align: middle;
				}

				.fund-listing-item-code {
					margin-left: @gap;
					font-size: @font-size-small;
					font-weight: 300;
				}

				&:hover .fund-listing-drag-handle {
					opacity: 1;
				}

				.fund-listing-new-label {
					flex-shrink: 0;

					span {
						.label;//bootstrap .label
						background-color: @color-new-label;
						color: @color-green;
						margin-left: @gap;
						display: inline-block;
						vertical-align: middle;
					}
				}

				.fund-listing-drag-handle,
				.fund-listing-new-label {
					line-height: 1;
					display: inline-block;
					align-self: center;
				}

				.fund-listing-item-name,
				.fund-listing-item-code {
					min-width: 40px;
					display: inline-block;
					flex-shrink: 1;
				}
			}

			&.is-invisible .fund-listing-drag-handle {
				visibility: hidden;
			}

			.fund-listing-item-action {
				outline: none;
				border: 0;
				background: 0;

				&:not(.noop):hover {
					background-color: darken(@color-complementary-green, 6%);
				}

				&.noop {
					cursor: default;
				}
			}

			&.is-invisible {
				background-color: @color-complementary-green-faded;//#8FCFB2

				.fund-listing-item-action {
					&:not(.noop):hover {
						background-color: darken(@color-complementary-green-faded, 6%);
					}
				}
			}
		}

		h3 {
			margin-top: 0;
		}
	}

	.tooltip {
		position: absolute;
		right: @grid-gutter-width;
		top: 0;
		margin-top: @fund-listings-action-width - @fund-listings-action-width/4;
		transition: visibility .3s;
		cursor: pointer;
		z-index: @zindex-navbar-fixed - 1;

		.tooltip-arrow {
			left: auto;
			right: @fund-listings-action-width/2 - @tooltip-arrow-width;
		}
	}

	.info-tooltip {
		position: absolute;
		margin-left: 15px;
		margin-top: 4px;
		color: @gray-light;
		fill: currentColor;
	}

	.fund-listing-item-visible-tooltip {
		position: relative;
		margin: 0;
		color: @fund-listings-item-color;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.fund-listing-item-visible-tooltip-content {
	padding: 10px 8px;
}

.manage-funds-listings-control {
	@mobile-line-height: 36px;
	@mobile-padding-vertical: 10px;
	@mobile-label-margin-bottom: 5px;

	.listing-selector-container{
		position: relative;
		z-index: 2;
	}

	.status-panel {
		display: flex;
		align-items: center;

		:first-child {
			flex-grow: 1;
		}

		label {
			.h3;
			font-size: @font-size-h3;
			margin: 0;
		}

		.btn {
			padding-left: 30px;
			padding-right: 30px;
		}

		@media (min-width: @screen-sm-min) {
			.responsive-btn-group {
				display: block;
			}
		}

		.btn-link {
			font-size: @font-size-base;
			padding-left: 0;
			padding-right: 0;
		}

		@media (max-width: @screen-xs-max) {
			display: block;
			text-align: center;
			padding-top: @mobile-padding-vertical;
			padding-bottom: @mobile-padding-vertical;

			label {
				font-size: @font-size-base-plus;
				margin-bottom: @mobile-label-margin-bottom;
			}

			.btn {
				height: @mobile-line-height;
				line-height: @mobile-line-height;
				padding: 0;
			}
		}

	}

	.affix-wrapper {
		&:extend(.clearfix all);
		position: absolute;
		left: floor(@grid-gutter-width/2);
		right: ceil(@grid-gutter-width/2);
		top: 0;

		.position-sensor {
			margin-top: -(@navbar-height - @border-radius-base);
		}

		@affix-transition: box-shadow .3s;

		.affix-top {
			.status-panel {
				box-shadow: none;
				transition: @affix-transition;
			}
		}

		.affix.affix-top {
			top: @navbar-height - @border-radius-base;

			.status-panel {
				transform: translate3d(0,0,0); //needed for safari iOS http://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela
				box-shadow: 0 3px 3px rgba(0, 0, 0, 0.38);
				transition: @affix-transition;
			}

			@media (max-width: @screen-xs-max) {
				.status-panel {
					margin: 0;
				}
			}
		}
	}
}
