@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@match-icon-size: 16px;
@expected-color-transition: ~'color 250ms ease-in-out';

.container {
	display: flex;
	justify-content: flex-end;
	text-align: right;

	@media @small {
		text-align: center;
		justify-content: center;
	}

	@media print {
		page-break-inside: avoid;
	}
}

.item {
	flex: none;
	+ .item {
		margin-left: @grid-gutter-width * 2;
	}
}

.total {
	font-size: @font-size-h2;
	margin-bottom: @padding-base-vertical;
}

.expected-total {
	font-size: @font-size-small;
}

.dollar-sign {
	font-size: 55%;
	padding-right: 3px;
}

.match {
	color: @gray;
	transition: @expected-color-transition;

	&-icon {
		width: @match-icon-size;
		height: @match-icon-size;
		fill: currentColor;
		stroke: currentColor;
		margin-right: @padding-xs-horizontal;
		margin-bottom: -2px;
	}
}

.discrepency {
	color: @color-orange;
	transition: @expected-color-transition;
}
