@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@item-border: 1px solid rgb(204, 204, 204, 0.6);

.download {
	text-align: right;
}

.list-container {
	font-size: @font-size-base-minus;
	line-height: 1.2;
}

.list-name {
	padding: 15px 0;
}

.list-item-container {
	padding: 0;
	margin: 0;
	height: 235px;
	overflow-y: auto;
	position: relative;
}

.list-item {
	display: flex;
	align-content: center;
	justify-content: space-between;
	padding: 15px 0;
	border-bottom: @item-border;
}
