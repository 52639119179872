@import '../mixins/svgs';

#insights {
	.insights-vars;

	.panel {
		max-width: @panel-max-width;

		// by default panels will center align
		margin-left: auto;
		margin-right: auto;
	.panel-heading {

		font-size: @font-size-h3;

		small {
			font-size: @font-size-base;
		}
		}
	}

	.panel-body {
		width: @panel-max-width;
		padding: @panel-body-padding;

		svg {
			vertical-align: text-bottom;
		}

		> p {
			margin: 0 0 20px 0;
		}

		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
			}
		}

		.divider {
			margin-left: -@panel-body-padding;
			margin-right: -@panel-body-padding;
			padding-left: @panel-body-padding;
			padding-right: @panel-body-padding;
			border-top: 1px solid @gray-lightest;
			border-bottom: 1px solid @gray-lightest;
			margin-bottom: @panel-body-padding;

			h4 {
				margin: 22px 10px;
				display: inline-block;
			}
		}

		&.table {
			padding: 0;

			table {
				width: 100%;

				td, th {
					text-align: center;


					&:first-child {
						text-align: left;
					}

					&:last-child {
						text-align: right;
					}

					&.text-right {
						// override because of rules above
						text-align: right !important;
					}
				}

				th {
					vertical-align: top;

					&:first-child,
					&:last-child {
						border-radius: 0 !important;
					}
				}

				td {
					border-bottom: 1px solid @gray-lightest;
				}

				tr {
					&:last-child {
						td {
							border-bottom: none;
						}
					}
				}

				@media (min-width: @panel-max-width) {
					th {
						font-size: @font-size-base-minus;
					}

					td {
						font-size: @font-size-base;
					}
				}

				.total {
					font-weight: bold;
				}
			}
		}

		&.pattern {
			&::before {
				opacity: .01;
			}
		}

		.two-column,
		.three-column,
		.two-by-two,
		&.two-column,
		&.three-column,
		&.two-by-two {

			color: @gray;

			.padding-top {
				padding-top: @panel-body-padding;
			}

			.hr {
				margin-top: 16px;
			}

			@media (max-width: @panel-max-width) {
				font-size: @font-size-small;
			}
		}

		.three-column,
		&.three-column {

			li {
				&:first-child {
					height: 32px; // this is set to the biggest svg
				}
			}

			.amount {
				line-height: 22px;
				font-size: @font-size-h4;
			}

			.col-xs-4 {
				padding: 10px;
			}

			h2 {
				margin-top: 5px;
			}
		}

		.two-by-two,
		&.two-by-two {

			.row {
				margin: 0;
			}

			.col-xs-6,
			.col-xs-12{
				padding: 0;
			}

			padding: @panel-body-padding 13px;

			.row {
				&:nth-child(2) {
					border-top: 1px solid @gray-lighter;
				}

				.col-xs-6 {
					&:first-child {
						border-right: 1px solid @gray-lighter;
					}
				}
			}

			ul {
				li {
					&:first-child {
						> strong {
							color: @gray-dark;
						}
					}
				}

				padding: 20px;
			}

			.amount {
				line-height: 36px;
				font-size: @font-size-h2;
			}
		}

		.hr {
			width: @padding-base-vertical*4;
			border-color: @gray;
			border-bottom: 2px solid @gray;
			margin: 10px auto 10px auto;
			height: 1px;
			display: block;

			&.long {
				width: @padding-base-vertical*8;
			}

			&.thin {
				border-width: 1px;
			}

			&.narrow {
				margin-top: 5px;
				margin-bottom: 3px;
			}
		}
	}

	.panel-footer {

		h3, h4 {
			margin-top: 0;
			margin-bottom: 16px;
		}

		a, button {
			margin-top: 18px;
		}
	}

	// desktop view
	@media @desktop {

		&.liw {
			.panel {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	// less than 550px
	@media (max-width: @panel-max-width) {

		.panel {
			max-width: none;
			margin-right: -@navbar-padding-horizontal;
			margin-left:  -@navbar-padding-horizontal;
			border-radius: 0;
		}

		.panel-heading {
			border-radius: 0;

			&.compound-heading {

				padding: @panel-body-padding/2;

				span {
					line-height: inherit;
					display: block;
				}
			}
		}

		.panel-body {
			width: auto;
			border-radius: 0;
			max-width: none;
		}
	}

	// override box-shadow for liw
	&.liw {
		.panel {
			.box-shadow-small;
		}
	}
}
