@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	padding: (@grid-gutter-width / 2) 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	@media @mobile {
		flex-direction: column;
	}
}

.logo {
	padding: 0 (@grid-gutter-width * 2);

	@media @mobile {
		padding: (@grid-gutter-width * 2.5) 0 (@grid-gutter-width * 1.5);
		text-align: center;
	}
}

.text {
	padding: 0 0 0 @grid-gutter-width;

	@media @mobile {
		padding: 0;
	}
}
