@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@success-green-color: #179044;
@animation-stroke-dash: 1000;

.tick-wrapper {
	display: block;
	margin: 0 auto @tipbox-padding-horizontal / 2;
	width: 100px;
	height: 100px;
}

.tick-border {
	stroke-dasharray: 710;
	stroke-dashoffset: 400;
	fill: none;
	stroke: @success-green-color;
	stroke-width: 3px;
	animation: tick-border-animate 1.2s linear forwards;
}

.tick {
	stroke-dasharray: 710;
	stroke-dashoffset: 400;
	fill: none;
	stroke: @success-green-color;
	stroke-width: 3px;
	animation: tick-border-animate 2.8s linear forwards;
}

@keyframes tick-border-animate {
	from {
		stroke-dashoffset: @animation-stroke-dash;
	}

	to {
		stroke-dashoffset: 0;
	}
}
