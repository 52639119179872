@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	margin: -1px;

	&:global(.payer-search-omnibox-focused) {
		z-index: 1;
	}

	@media @small {
		width: 100%;
		border-left: 0;
		border-right: 0;
	}
}
