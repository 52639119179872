@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@iphone-height: 667px;
@iphone-width: 375px;

.iframe-container {
	background: @color-form-alt;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	@media @mobile {
		width: 100%;
		align-items: flex-start;
	}

	// overlay to ensure the iframe is not clickable
	&::before {
		content: ' ';
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	:global(.loading-box) {
		top: 200px;
	}
}

.iframe-desktop {
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.iframe-mobile {
	width: @iphone-width;
	height: @iphone-height;
	pointer-events: none;
	overflow: hidden;
	border: 1px solid @gray-lighter;
	border-radius: @border-radius-base;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	background-color: @gray-lightest;
	position: relative;
	z-index: 1;

	@media @small {
		width: 100%;
	}
}

.mobile-header {
	width: 100%;
	height: 50px;
	border: 10px solid white;
	border-radius: @border-radius-base;
	background-color: @color-form-alt;
}

.mobile-frame {
	position: relative;

	@media @small {
		width: 100%;
	}
}

.mobile-frame-phone-background {
	display: none;

	@media not @small {
		display: block;
		position: absolute;
		z-index: 0;
		width: 609px;
		height: 953px;
		margin: -94px -117px;
	}
}

.iframe {
	width: 100%;
	z-index: 1;
}

.loading {
	visibility: hidden;
}
