@import "./transaction-details";
@import "./related-check-payments";
@import "./split-payment-info-tooltip";


.portal-transaction-date-range {
	white-space: nowrap;
}

.portal-transaction-totals {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
}

.portal-transaction-total-transactions {
	flex: 0 0 14em;
}

.portal-transaction-legacyLinkContainer {
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	display: flex;
	position: absolute;
	align-items: center;
	padding-right: 40px;
	justify-content: flex-end;

	.root {
		position: relative;
		padding: 10px;
	}

	.legacySwitch {
		cursor: pointer;
	}

	.wrapper {
		height: 1.5em;
		display: flex;
		justify-content: space-between;

		input {
			width: 0px;
			opacity: 0;
			z-index: -1;
			position: absolute;
		}
	}

	.switch {
		color: #1173BC;
		width: 3em;
		border: 2px solid #E3E5E9;
		height: 1.5em;
		position: relative;
		box-sizing: content-box;
		transition: all 0.3s;
		border-radius: 1.5em;
		background-color: #E3E5E9;
		z-index: 100;
	}

	.thumb {
		left: 0;
		width: 1.5em;
		height: 1.5em;
		position: absolute;
		transition: all 0.3s;
		border-radius: 50%;
		background-color: #FFF;
	}

	.fieldWithIcon {
		padding-right: calc(24px + 12px);
	}

	.field {
		font: 15px "Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
		color: #4D4C4E;
		width: 100%;
		border: 1px solid #CACBD1;
		outline: none;
		padding: 10px 12px;
		border-radius: 3px;
		background-color: #FFF;
	}

	.legacyLink {
		color: @btn-link-color;
	}
}
