@import "../bootstrap-variables";
@import "../mixins/helpers";

.form-group {
	label {
		.info-tooltip,
		.radio-tooltip {
			position: absolute;
			padding-top: 0;
			padding-bottom: 0;

			svg {
				vertical-align: top;
				fill: currentColor;
			}

			.tooltip-icon {
				color: @gray-light;
			}
		}

		.radio-tooltip {
			margin-left: 5px;
		}
	}

	.tooltip-icon-gray {
		color: @gray-light;
		fill: currentColor;
	}

	@media (max-width: @screen-sm) {
		margin-bottom: @form-group-margin-bottom-reduced;
	}
}

//
// Horizontal forms
// --------------------------------------------------
.form-horizontal {
	@media (min-width: @screen-sm-min) {
		.control-label {
			text-align: left;
		}
	}

	.form-group {
		margin-bottom: @form-group-margin-bottom-reduced;
	}
}

.control-label {
	> .help-block {
		display: inline-block;
		font-weight: 200;
	}
}

label{
	font-size: @font-size-base-minus;

	span{
		&.optional{
			font-weight: 200;
		}
	}
}

//
// Common form controls
// --------------------------------------------------
.form-control {
	padding: @padding-base-vertical @padding-base-horizontal;
	.box-shadow(none);
	font-size: @font-size-base-plus;

	&:focus {
		.box-shadow(none);
	}

	textarea& {
		min-height: 44px;
		max-width: 100%;
		-webkit-appearance: none;
	}

	select& {
		&.form-control-unselected:not(:focus):not(.input-validation-error):not([data-error-highlight]) {
			color: @input-color-placeholder;
		}
	}
	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		color: @input-color-disabled;
		border-color: @input-border-disabled;
		background-color: @input-bg-disabled;
		opacity: @input-opacity-disabled;
	}
}

.form-control-without-label {
	margin-top: 29px;
}

.form-control-dragover {
	input {
		box-shadow: 0px 0px 2px 0px black;
	}
}

.radio label, .radio-inline {
	padding-left: @radio-inline-padding;
}

//
// note that this dropdown's affect the navbar dropdowns too
//

.dropdown {

	.select-wrapper {
		display: none;
	}

	@media @mobile {
		&.mobile-enabled {
			> button {
				display: none;
			}

			.mobile-menu {
				display: none;
			}

			.select-wrapper {
				display: inline-block;
				width: 100%;
			}
		}
	}
}


.dropdown-menu {

	li {
		&.dropdown-header {
			cursor: default;
		}

		&.hover,
		&:not(.dropdown-header):hover {

			background-color: @color-form-alt;

			.dropdown-menu-item,
			> button,
			> a {
				color: @brand-primary;
				text-decoration: none;
				border-color: @brand-primary;
				background-color: transparent;
			}
		}

		&.active:hover {
			background-color: @dropdown-link-active-bg;
			.dropdown-menu-item,
			> button,
			> a {
				color: white;
			}
		}

		.dropdown-menu-item,
		> button,
		> a {
			color: @dropdown-link-color;
			border: none;
			border-left: 3px solid transparent;
			padding-left: @grid-gutter-width;
			padding-right: @grid-gutter-width;
			text-align: left;
			font-weight: normal;
			width: 100%;
			border-radius: 0;

			&:focus,
			&:active {
				color: @brand-primary;
				text-decoration: none;
				border-color: @brand-primary;
			}

			&.active {
				font-weight: bold;
				border-left: 3px solid @brand-primary;
			}
		}

		> a.btn {
			padding-top: 9px;
		}
	}
}

label.error,
.field-validation-error {
	.help-block;
	.has-error .form-control-feedback;
	font-size: 13px;
}

.input-validation-error,
input[data-error-highlight],
textarea[data-error-highlight] {
	.validation-red-border;
	.placeholder(@color-red-validation-placeholder);
}

select.input-validation-error,
select[data-error-highlight] {
	.validation-red-border;
	color: @color-red-validation-placeholder;
	option,
	optgroup {
		color: @text-color;
	}
}

.validation-red-border() {
	border-color: @color-red-validation;
	&:focus{
      border-color: darken(@color-red-validation, 10%);
	}
}

.validation-summary-valid
{
	.hidden
}

.validation-summary-errors {
	&:extend(.alert all);
	&:extend(.alert-danger all);

	.ico {
		&:extend(.alert .ion all);
		&:extend(.alert.alert-danger .ion all);
		line-height: 1;

		&:before {
			&:extend(.ion);
			content: @ionicon-var-ios-close-outline;
		}
	}

	.panel & {
		//inverted color inside a panel
		background: @body-bg;
	}

	.modal-form & ul {
		padding-left: 0;
		list-style: none;
	}
}


//
// Inline forms
// ---------------------------------------------

.form-inline {
	.select-wrapper {
		@media (min-width: @screen-sm-min) {
			display: inline-block;
		}
	}

	label {
		margin-right: @grid-gutter-width;
	}
}

//
// Filter forms
// ---------------------------------------------
@filter-gutter-width: @grid-gutter-width;

.filter-form {
	padding: @filter-gutter-width @filter-gutter-width;
	margin-bottom: 10px;

	h4 {
		.make-xs-column(6, @filter-gutter-width);
		margin-bottom: 15px;
		margin-top: 0;
	}

	.form-group {
		margin-bottom: 0;
	}
}

.filter-form .form-group {
	.make-row(@filter-gutter-width);
}

.filter-control {
	.make-sm-column(6, @filter-gutter-width);
	.make-md-column(4, @filter-gutter-width);
	.make-lg-column(3, @filter-gutter-width);
	margin-bottom: @filter-gutter-width;

	&:not(:nth-last-child(2)) {
		&:nth-child(2n) {
			@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
				margin-right: percentage(4 / @grid-columns);
			}
		}
		&:nth-child(3n) {
			@media (min-width: @screen-lg-min) {
				margin-right: percentage(3 / @grid-columns);
			}
		}
	}
}

.filter-control-multi {
	.make-sm-column(6, @filter-gutter-width);
	.make-md-column(6, @filter-gutter-width);
	.make-lg-column(4, @filter-gutter-width);
	margin-bottom: @filter-gutter-width;
}

.filter-control-submit {
	.make-sm-column(6, @filter-gutter-width);
	.make-md-column(6, @filter-gutter-width);
	.make-lg-column(4, @filter-gutter-width);
}

.filter-header {
	.make-sm-column(12, @filter-gutter-width);
	.make-md-column(12, @filter-gutter-width);
	.make-lg-column(12, @filter-gutter-width);

	h4 {
		padding-left: 0px;
	}
}

.filter-reset {
	.make-sm-column(6, @filter-gutter-width);
	.make-md-column(4, @filter-gutter-width);
	.make-lg-column(3, @filter-gutter-width);
	text-align: right;

	margin-bottom: @filter-gutter-width;
	@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		margin-right: percentage(4 / @grid-columns);
	}
	@media (min-width: @screen-lg-min) {
		margin-right: percentage(3 / @grid-columns);
		margin-left: percentage(3 / @grid-columns);
	}
}

.advanced-filters-section {

	&.is-expanded {
		.btn-more-filters {
			display: none;
		}
	}

	&:not(.is-expanded) {
		.filter-control.is-advanced {
			display: none;
		}
	}
}

.btn.btn-more-filters {
	.btn-sm;
	.btn-gray-link;
	padding: 0;
}

.date-group {
	position: relative;
	.make-row(@filter-gutter-width);

	&::before {
		padding-right: 5px;
		display: inline-block;
		position: absolute;
		font-size: 20px;
		top: 50%;
		left: 50%;
		margin: -0.3em 0 0 -0.35em;
	}
	> div {
		.make-xs-column(6, @filter-gutter-width);
	}

	+ label.error,
	+ .field-validation-error {
		margin-bottom: 0;
		transform: translateY(-@form-group-margin-bottom);
	}
}

.date-picker {
	position: relative;

	.form-control {
		// hide the native Chrome desktop datepicker arrow behind the calendar icon, but maintain interactability so the widget can still be launched
		&::-webkit-calendar-picker-indicator {
			opacity: 0;
		}

		&::-webkit-inner-spin-button {
			display: none;
			-webkit-appearance: none;
		}

		&::-webkit-clear-button {
			position: relative;
			left: -10px;
		}

		&::-ms-clear {
			transform: translateX(-20px);
		}
	}

	.svg(17px, 18px);
	.svg {
		color: @icon-color;
		position: absolute;
		top: 50%;
		margin-top: -0.7em;
		right: @grid-gutter-width;
		pointer-events: none;
		.form-control-labelled & {
			right: @grid-gutter-width / 2;
		}
	}
}

.date-range {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	.make-row(@filter-gutter-width);

	&::before {
		display: inline-block;
		position: absolute;
		font-size: 20px;
		left: 50%;
		bottom: @input-height-base / 2;
		transform: translate(-50%, 50%);
		padding-left: 5px;
	}

	> .form-group-item {
		flex-basis: ~"calc(50% - @{filter-gutter-width})";
		margin: 0 @filter-gutter-width / 2;
	}
}

.select-wrapper {
	position: relative;
	display: block;
	.arrow-hollow-down-after();

	select {
		cursor: pointer;
	}

	&:after {
		pointer-events: none;
		color: @gray-mid;
		padding: 12px @padding-base-horizontal;
		position: absolute;
		top: 2px;
		right: 0;

		.input-group & {
			z-index: 3;
		}
	}

	&:hover {
		&:after {
			color: @brand-primary;
		}
	}

	select {
		padding-right: 47px;
	}
}

.control-label {
	i {
		display: inline-flex;
		justify-content: center;
		margin-left: 10px;
		vertical-align: middle;
	}
}

.actions .btn {
    margin: 0 0 0 1.5%;
	direction: ltr;
}

.filter-form-help {
	.make-xs-column(12, @grid-gutter-width);
	.text-right;
	@media (min-width: @screen-sm-min) {
		padding-right: 100px;
	}
}

// fix appearance on IOS
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"],
select {
	&.form-control {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
}
select {
	&.form-control::-ms-expand {
		display: none;
	}
}

// override BS line-height stuff - it mainly breaks for IOS ¯\_(ツ)_/¯
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		&.form-control {
			line-height: @line-height-base;
		}

		&.input-sm,
		.input-group-sm & {
			line-height: @line-height-small;
		}

		&.input-lg,
		.input-group-lg & {
			line-height: @line-height-large;
		}
	}
}

.form-control-label {
	.truncate;
	.no-wrap;
	vertical-align: top;
}

.form-control-input-counts {
	margin-top: 5px;
	font-size: @font-size-small;
	text-align: right;

	&.maxed {
		color: @color-red;
	}
}

.form-control-description {
	font-size: @font-size-base-minus;
}

//Disabled form via the fieldset
fieldset[disabled] {
	// Work around React bug button with child elements still fires onClick when disabled through the fieldset
	// https://github.com/facebook/react/issues/8308
	.btn {
		pointer-events: none;
	}
	label {
		color: @input-color-disabled;
	}
	.form-control-label {
		color: @gray;
	}
	.input-group-addon {
		color: @input-color-disabled;
		background-color: @input-bg-disabled;
		border-color: @input-border-disabled;
		opacity: @input-opacity-disabled;
	}
}

// For applying placeholder on html date input
input[type="date"].with-placeholder {
	position: absolute;
	top: 0;

	&:not(:focus):not(.input-validation-error) {
		opacity: 0;
	}
}
