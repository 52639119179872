@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@delete-icon-size: 16px;

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.pledge-already-added {
	&-existing-amount {
		white-space: nowrap;
	}

	&-message {
		font-size: @font-size-base-plus;
	}

	&-delete-icon {
		fill: currentColor;
		width: @delete-icon-size;
		height: @delete-icon-size;
		margin-right: @padding-xs-horizontal;
		margin-top: -3px;
	}

	&-or {
		margin-top: @padding-large-vertical;
	}
}

.pledge-delete-confirm {
	&-delete-btn {
		color: @text-color;
	}
}
