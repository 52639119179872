@import "../../bootstrap-variables";

.multi-selected-items {
	.selected-item-container {
		padding: 0px;
		float: left;
		margin-bottom: 20px;
		li {
			float: left;
			list-style: none;
		}
	}

	.spillow {
		border-radius: @border-radius-base;
		background-color: @gray-dark;
		font-size: @font-size-small;
		line-height: 17px;
		display: inline-block;
		margin-right: 6px;
		color: white;
		fill: white;
		&:hover {
			background-color: @gray-mid;
		}
		.spillow-content {
			max-width: 281px;
			display: block;
			float: left;
			padding: 4px 0 4px 7px;
			.truncate;
			.no-wrap;
		}
		.spillow-close {
			width: 25px;
			height: 25px;
			padding: 7px;
			display: block;
			float: left;
		}
	}

	.spillow-fade {
		&-enter {
			opacity: 0.01;
			&-active {
				opacity: 1;
				.transition(opacity .2s ease-in);
			}
		}

		&-leave {
			opacity: 1;
			&-active {
				opacity: 0.01;
				.transition(opacity .2s ease-in);
			}
		}
	}

	&.tooltip.top {
		margin-top: 15px;
	}
	&.tooltip.top .tooltip-inner {
		color: @gray-dark;
		background-color: white;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2)
	}
	&.tooltip.top .tooltip-arrow {
		color: @gray-dark;
		border-top-color: white;
	}
}
