.preview {
	width: 100%;
	height: 1200px;
	border: none;
	box-shadow: none;
	background: none;
}

.delete-button {
	position: absolute;
	right: 0;
	bottom: 0;
}
