@import '../bootstrap-variables';
@import '../mixins/round';
@import '../mixins/helpers';

.panel {
	border-width: @panel-border-width;

	@media (max-width: @screen-xs-max) {
		margin-right: -@navbar-padding-horizontal;
		margin-left:  -@navbar-padding-horizontal;
		border-radius: 0;
		border-left: 0;
		border-right: 0;
	}

	.panel-body {

		@media @mobile {
			padding: @panel-body-padding-mobile;
		}

		+ .panel-heading {
			border-top: 1px solid @panel-inner-border;
			border-radius: 0;
		}

		.panel-default& {
			background-color: @panel-bg;
		}
	}
}

.panel-heading {
	font-size: @font-size-panel-header;
	font-weight: 200;
	.roundSome(@border-radius-base, @border-radius-base, 0, 0);
	line-height: @panel-heading-line-height;

	.panel-default > &,
	.panel-default & {
		border-bottom: 1px solid @panel-default-inner-border;
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}

	@media @mobile {
		padding: @panel-heading-padding-mobile;
	}
}

.panel-footer {
	&.panel-footer-btn-group {
		.responsive-btn-group-centered;
		@media @small {
			padding-left: 20px;
			padding-right: 20px;
			flex-wrap: wrap;

			> .btn {
				flex-basis: 100%;
				max-width: none;
				margin: 0;

				&:not(.btn-default) {
					order: 1;
					margin-top: 20px;
				}
			}
		}
	}
}

.affix .panel-heading, .affix .panel-footer {
	transform: translate3d(0,0,0);
	border: 1px solid @gray-lighter;
	box-shadow: rgba(180, 180, 180, 0.7) 0 0 5px 1px;
	transition: box-shadow 0.3s;
}

/* IE11 flex bug https://connect.microsoft.com/IE/feedbackdetail/view/951267/horizontal-scrolling-with-flex-grow-max-width */
_:-ms-fullscreen, :root .panel-footer-btn-group .btn {
	&:first-child:last-child {
		margin-left: -242px;
	}
	@media (min-width: 581px) {
		&:first-child:not(:last-child) {
			margin-left: -475px;
		}
	}
}

.panel-invert {
	>.panel-body {
		background-color: white;
	}
}

.panel {

	&.panel-color {

		.headingFill(@color, @fontColor: white) {
			.panel-heading {
				&.fill {
					background-color: @color;
					color: @fontColor;
				}
			}
		}

		.headingTopBorder(@color) {
			.panel-heading {
				&.top-border {
					border-top: 5px solid @color;
					color: @color;
				}
			}
		}

		.panelTableBody(@color) {
			.panel-body {
				&.table {
					th {
						background-color: @color;
					}
				}
			}
		}

		.baseColor(@color) {
			.base-color {
				color: @color;
			}
		}

		.headless(@color) {
			&.headless {
				border-top: 5px solid @color;
			}
		}

		.insightsPanel(@color, @fontColor: white) {
			.headingFill(@color, @fontColor);
			.headingTopBorder(@color);
			.panelTableBody(@color);
			.baseColor(@color);
			.headless(@color);
		}

		border: none;
		.box-shadow-small;
		border-radius: @border-radius-base;
		margin-bottom: @panel-body-padding;

		&.red {
			.insightsPanel(@color-complementary-red);
		}

		&.pp-red {
			.insightsPanel(@brand-primary);
		}

		&.green {
			.insightsPanel(@color-complementary-green);
		}

		&.orange {
			.insightsPanel(@color-orange);
		}

		&.pink {//TODO - change class name to purple
			.insightsPanel(@color-purple);
		}

		&.yellow {
			.insightsPanel(@color-yellow);
		}

		&.blue {
			.insightsPanel(@color-blue);
		}

		&.gray {
			.insightsPanel(@gray-dark);
		}

		&.midgray {
			box-shadow: none;
			.insightsPanel(@gray-mid);
			.panel-header {
				border: 1px solid @gray-lighter;
				border-bottom-color: transparent;
			}
			.panel-body {
				background-color: white;
				border-left: 1px solid @gray-lighter;
				border-right: 1px solid @gray-lighter;
			}
			.panel-footer {
				background-color: white;
				border: 1px solid @gray-lighter;
			}
		}

		&.white {
			.headingFill(white, @gray-dark);

			.panel-body {
				&.red {
					.baseColor(@color-complementary-red);
				}

				&.green {
					.baseColor(@color-complementary-green);
				}

				&.orange {
					.baseColor(@color-orange);
				}

				&.pink {//TODO - change class name to purple
					.baseColor(@color-purple);
				}

				&.yellow {
					.baseColor(@color-yellow);
				}

				&.blue {
					.baseColor(@color-blue);
				}
			}
		}

		.panel-heading {

			text-align: center;
			font-size: @font-size-h3;
			color: white;

			&.fill {
				padding: 13px;
				border-top: none;
			}

			&.compound-heading {

				padding: 0;
				span {
					line-height: 96px;
					vertical-align: middle;
				}

				.amount {
					small {
						font-size: 20px;
						top: -20px;
					}

					font-size: 50px;
					padding-right: 15px;
				}
			}

			&.multi-line {
				padding: 20px;

				small {
					padding-top: 7px;
					display: block;
				}
			}

			small {
				font-size: @font-size-base;
			}

			a {
				color: white;
				font-weight: bold;
			}
		}

		.panel-body {
			background-color: @color-form;
			text-align: center;
			padding: @panel-body-padding;
			width: 100%;
			border-radius: 0 0 @border-radius-base @border-radius-base;
		}

		.panel-footer {
			border-top: 1px solid @gray-lightest;
			text-align: center;
			background-color: @color-form;
			padding: @panel-body-padding;
		}

		@media (max-width: @screen-xs-max) {

			.panel {
				max-width: none;
				margin-right: -@navbar-padding-horizontal;
				margin-left: -@navbar-padding-horizontal;
				border-radius: 0;
			}

			.panel-heading {
				border-radius: 0;

				&.compound-heading {

					padding: @panel-body-padding/2;

					span {
						line-height: inherit;
						display: block;
					}
				}
			}

			.panel-body {
				width: auto;
				border-radius: 0;
				max-width: none;
			}
		}
	}

	&.panel-split{
		@border: 1px solid @gray-lighter;
		border-top: 0;

		@media (max-width: @screen-xs-max) {
			margin-left: 0;
			margin-right: 0;
			border-radius: 4px;
			border: @border;
		}

		.panel-heading {
			.text-center;
			.roundSome(3px, 3px, 0, 0);
			font-size: @font-size-h1;
			background-color: @panel-bg;
			border-top: 5px solid @gray-mid;
			padding-bottom: 20px;
			padding-top: @padding-base-vertical * 2;
			border-bottom: none;
			@media (max-width: @screen-xs-max) {
				> div {
					border-bottom: @border;
					padding-bottom: 20px;
				}
			}
		}
		.panel-body {
			padding-top: 0;
			.responsive-btn-group {
				margin-bottom: 20px;
			}
		}
		.panel-top {
			padding-bottom: 10px;
			margin-bottom: 30px;
			.text-center;
			@media (max-width: @screen-xs-max) {
				border-bottom: @border;
			}
		}
		.panel-top-mob {
			.visible-xs;
			.text-center;
			margin-bottom: 30px;
		}
		.panel-content {
			.make-row();
			.equal-height-columns;
			.panel-left, .panel-right {
				.make-sm-column(6, 80px);
				justify-content: center;
				> div {
					justify-content: center;
				}
			}
			.panel-right {
				margin-left: -1px;
			}
			@media (max-width: @screen-xs-max) {
				.panel-left, .panel-right {
					width: 100%;
					padding-left: 20px;
					padding-right: 20px;
					p {
						.hidden-xs;
					}
					div {
						width: 100%;
					}
				}
				.panel-left-flex, .panel-right-flex {
					width: auto;
					display: flex;
					flex-grow: 1;
					> a {
						display: flex;
						&:before, &:after {
							margin-top: 4px;
						}
					}
				}
				.panel-left-flex {
					border-right: @border;
				}
			}
			@media (min-width: @screen-sm-min) {
				.panel-left, .panel-right {
					> div {
						width: 260px;
					}
				}
				.panel-left {
					justify-content: flex-end;
					border-right: @border;
				}
				.panel-right {
					justify-content: flex-start;
				}
			}
		}
	}
}

.panel-expander {
	.notch;
	border-top: @panel-border-width solid @panel-inner-border;

	&:before {
		border-color: @panel-inner-border;
		background-color: @panel-bg;
	}

	.panel-default & {
		border-top-color: @panel-default-inner-border;

		&:before {
			border-color: @panel-default-inner-border;
		}
	}
}

.panel {
	.panel-full-width-control {
		margin: -1px;
		display: block;
		position: relative;
		width: auto;
		max-width: none;
		padding: 10px @panel-body-padding;
		border: 1px solid @input-border;
		border-color: @border-color;
		background: @input-bg;
		.transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
		@media @mobile {
			padding-left: @panel-body-padding-mobile;
			padding-right: @panel-body-padding-mobile;
		}
		@media @small {
			margin-left: 0;
			margin-right: 0;
			border-left: 0;
			border-right: 0;
		}
		.form-control {
			border: 0;
			padding: 0;
		}
		&-focused {
			border-color: @input-border-focus;
			z-index: 1;
		}
	}

	&>.panel-full-width-control {
		@media not @small {
			&:first-child {
				border-top-left-radius: @panel-border-radius;
				border-top-right-radius: @panel-border-radius;
			}

			&:last-child {
				border-bottom-left-radius: @panel-border-radius;
				border-bottom-right-radius: @panel-border-radius;
			}

			&:only-child {
				border-radius: @panel-border-radius;
			}
		}
	}
}

.panel-highlight {
	border: none;
	.box-shadow-small;

	.panel-heading {
		background: white;
		border-bottom: 1px solid @panel-inner-border;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
	}

	@media not @small {
		border-top: 6px solid @panel-highlight-border-top;
	}

	@media @small {
		border-top: 1px solid @panel-default-border;
	}

	@media @mobile {
		.panel-footer {
			/* TODO: the default panel style is missing a mobile footer padding, which is causing footers to become out-of-line with the rest of
			   the panel content on mobile displays. This padding should be applied globally to .panel-footer instead of defined for this panel
			   specifically. */
			padding: @panel-body-padding-mobile;
		}
	}

	@media print {
		box-shadow: none;
		border: 1px solid @border-color;
	}
}

.panel-plain {
	border: 1px solid @panel-default-border;
	.box-shadow-small;
	background: white;

	.panel-heading {
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
	}

	.panel-footer {
		border-top: none;
	}

	@media @small {
		border-left: none;
		border-right: none;
	}

	@media @mobile {
		.panel-footer {
			/* TODO: the default panel style is missing a mobile footer padding, which is causing footers to become out-of-line with the rest of
			   the panel content on mobile displays. This padding should be applied globally to .panel-footer instead of defined for this panel
			   specifically. */
			padding: @panel-body-padding-mobile;
		}
	}
}
