
.arrow {
	cursor: pointer;
}

.btn-delete {
	float: right;
	padding: 0;
}

