@import "../../bootstrap-variables";

.giving-statements {
	.edit-email {

		@media @mobile {
			> h1, > p {
				padding: 0 @grid-gutter-width;
			}
		}

		.table {
			margin-bottom: 16px;
			td, th {
				font-size: @font-size-base;
				padding: 7px;
				border-bottom: 1px solid @gray-lighter;
			}

			th {
				font-weight: 700;
				padding-left: 0;
				min-width: 135px;

				@media @mobile {
					padding-left: @grid-gutter-width;
				}
			}

			td {
				max-width: ~"calc(100vw - 135px)";

				.editable-label-2 {
					max-width: 100%;
				}

				@media @mobile {
					padding-right: @grid-gutter-width;
				}
			}
		}

		.hint-start-here {
			margin-top: 24px;
			.text-center;
		}

		.last-updated {
			.pull-right;
			margin-top: 8px;
			margin-bottom: 4px;
			font-size: @font-size-base-minus;
			font-style: italic;
			color: white;
			position: relative;
			z-index: 1010;
			text-shadow: 1px 1px 1px @gray;
			&.saved {
				text-shadow: none;
			}
		}

		.edit-email-hint,
		.editor {
			z-index: 1; // make it hover above the overlay
		}

		.edit-email-hint {
			.pull-right;
			margin-top: -6px;
		}

		.editor {
			position: relative;
			text-align: center;
			width: 100%;
			padding-bottom: @padding-base-vertical*2;

			textarea {
				padding: 16px;
				margin-left: auto;
				margin-right: auto;
				width: 550px;
				max-width: 100%;
				height: 160px;
				border: 1px dashed @color-complementary-red;
				opacity: 0.8;
				display: block;
				border-radius: @border-radius-base;
			}

			.tick {
				display: none;
			}

			svg {
				fill: white;
				margin-bottom: -2px;
				margin-right: 8px;
			}

			button {
				border-radius: 0 0 @border-radius-base @border-radius-base;
				background-color: @color-red;
				border-color: @color-red;

				&[disabled], &.btn-primary[disabled] {
					color: white;
				}
			}

			&.editing {
				textarea {
					border: 1px solid @color-complementary-red;

				}

				svg {
					display: none;
				}

				.pen {
					display: none;
				}
			}

			&.saved {
				textarea {
					border: 1px solid @color-complementary-green;
				}

				button {
					background-color: @color-complementary-green;
					border-color: @color-complementary-green;
				}

				.pen {
					display: none;
				}

				.tick {
					display: inline-block;
					margin-bottom: -3px;
				}
			}
		}

		.char-count {
			color: white;
			font-size: @font-size-base-minus;
			font-style: italic;
			position: absolute;
			right: 4px;
			margin-top: 4px;
			line-height: 14px;
			.text-right;

			&.length-exceeded {
				color: @color-red;
			}

			@media @mobile {
				display: block;
				position: static;
				text-align: center;
			}
		}

		.email-template {
			border: 1px solid @gray-light;
			margin-bottom: @padding-large-vertical*4;
			position: relative;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

			.logo {
				.square(48px);
				margin-right: 12px;
			}

			.date-range {
				font-size: @font-size-base-plus;
			}

			.overlay {
				position: absolute;
				background-color: rgba(77, 77, 77, 0.35);
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.panel {
				text-align: center;

				@media @mobile {
					margin-left: 0;
					margin-right: 0;
				}
			}

			.blurb {
				margin-left: @padding-small-horizontal*2;
				margin-right: @padding-small-horizontal*2;
				font-size: @font-size-small;
			}
		}

		.note {
			margin-left: auto;
			margin-right: auto;
			width: 500px;
			font-size: @font-size-base-minus;
			background-color: white;
			padding: @padding-large-vertical+@padding-small-vertical @padding-small-horizontal*2;
			border: 1px solid @gray-lighter;
			.round(3px);

			@media @mobile {
				margin-left: 7px;
				margin-right: 7px;
				max-width: ~"calc(100% - 14px)";
			}
		}

		.subject-label {
			vertical-align: top;
		}
	}

	.email-template .body .content {
		max-width: 100%;
	}

	.editable-label {
		width: 100%;

		input {
			width: 80%;
		}

		button {
			float: right;
		}
	}
}
