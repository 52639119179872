@import "../../bootstrap-variables";
@import "../../bootstrap/type";

.transaction-import-steps {
	@transaction-import-steps-list-item-icon-size: 160px;

	padding-top: @padding-base-vertical;

	@media @small {
		padding-bottom: @grid-gutter-width;
	}

	&-item {
		.text-center;
		padding: @grid-gutter-width;

		@media @small {
			.text-left;
			padding-left: @grid-gutter-width / 2;
			padding-right: @grid-gutter-width / 2;
		}

		&-image-wrapper {
			position: relative;
		}

		&-arrow {
			position: absolute;
			top: 50%;
			margin-top: -7px;
			right: -21px;
			fill: @gray-light;
			stroke: @gray-light;
			width: 22px;
			height: 15px;
		}

		&-image {
			margin: 0 auto;
			max-width: @transaction-import-steps-list-item-icon-size;
		}

		&-text {
			margin-top: @grid-gutter-width * 1.5;
			color: @gray-dark;

			@media @small {
				margin-top: 0;
				font-weight: 700;
			}
		}
	}

	&-item-separator {
		margin: 0 @grid-gutter-width / 2;
		border-bottom: 1px solid @gray-lightest;

		@media @small {
			margin-left: -(@grid-gutter-width / 2);
			margin-right: -(@grid-gutter-width / 2);
		}
	}
}

.transaction-import-steps-button {
	.text-center;

	.btn {
		margin-top: @grid-gutter-width;
		margin-bottom: @grid-gutter-width * 2;
	}
}
