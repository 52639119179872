@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.progress {
	overflow: hidden;
	height: 15px;
	margin-bottom: 10px;
	background-color: @progress-bg;
	border-radius: 15px;
}

.progress-bar {
	float: left;
	width: 0%;
	height: 100%;
	background-color: @color-green;
	border-radius: 15px;
	transition: width 1s ease;
}
