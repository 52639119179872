/*
	This module has been copied from PushpayWeb, for the purpose of supporting the MobilePhoneInput on the new login screen.
	This file should be removed when a new React-based mobile input component is developed as part of web giving, which should
	also replace the component on the login page.
*/

@import "../../bootstrap-variables.less";
@import "../../mixins/mixins.less";
@import "../../bootstrap/mixins.less";
@import "../arrows.less";

.phone-group-control {
	width: 40%;
	vertical-align: top;
	position: relative;
    white-space: nowrap;
    display: table-cell;
	padding: 8px 0;
	font-size: @font-size-base-plus;

	@media @mobile {
		padding: 0;
	}
}

.email-phone-input-control {
	@gap: 20px;
	@col-gray: #c8c8c8;

	border-radius: 5px;
	background-color: white;
	border: 1px solid @border-color;
	display: table;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;

	&.readonly {
		background: transparent;
	}

	.country-selector {
		display: table-cell;
		width: 64px;
		position: relative;
		border-right: 1px solid @border-color;

		&:after {
			content: '\f123';
			.arr;
			position: absolute;
			top: ~"calc(50% - 8px)"; // using escaped string to stop less calculating expression as 35%
			right: @gap / 1.5 - 5;
			font-size: 12px;
			line-height: 1.5;
			pointer-events: none;
			background: transparent;
			-webkit-transform: translateX(-1px);
		}

		.mobile-icon {
			vertical-align: middle;
			margin-left: @gap / 5;
			fill: @gray-mid;
		}
	}

	select {
		padding-right: 0;
		padding-left: 0;

		&:focus {
			outline: none;
		}
	}

	select, input {
		border: 0;
		height: 42px;
		-moz-appearance: none;
		-webkit-appearance: none;
		text-indent: 0.01px;
		text-overflow: '';
		background-color: white;
	}

	input {
		.placeholder(@col-gray);
		padding-left: 10px;
		padding-right: 0;
		background: transparent;
		width: 100%;

		&.disabled,
		&:disabled {
			background-color: @col-gray;
		}
	}

	.mobile {
		input {
			width: 113px !important;
		}
	}

	.mobile-number {
		padding-left: @gap / 4;
	}

	select {
		background-size: 46px 46px;
		width: 60px;

		&::-ms-expand {
			display: none;
		}
	}

	span.icon {
		display: inline-block !important;
		margin-left: 8px;
		z-index: 100;
		vertical-align: sub; /* aligns the icon in the center of the nested span */
	}

	span.icon-country-code {
		border-right: 1px solid #d0d0d0;
		padding-right: @gap / 2;
		line-height: 38px;
		display: inline-block;
	}

	.icon-check {
		display: none;
	}

	@media @mobile {
		width: 100%;
	}
}

@media @mobile {
	.phone-group-control+.field-validation-error {
		display: block;
	}
}
@media all and (-ms-high-contrast:none)
{
     .email-phone-input-control {
     	input {
     		width:100%;
     		transition:none!important;
     		&:focus {
				width:185px
     		}
     	}
    }
     *::-ms-backdrop, .email-phone-input-control input { width: 100%; overflow: visible; transition:none!important; } /* IE11 */
     *::-ms-backdrop, .email-phone-input-control input:focus { width:185px; }
}
