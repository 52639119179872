@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@status-icon-size: 80px;

.status-icon {
	width: @status-icon-size;
}

.status-svg {
	fill: @color-green;
	width: @status-icon-size;
	height: @status-icon-size;
}

.status-text {
	margin-top: 20px;
	color: @color-green;
}
