.settlements-alert-icon {
	color: @gray-mid;
	margin-right: 15px;
}

.settlements-heading-help {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.settlements-heading-help .batch-schedule-info {
	display: flex;
}

.schedule-info-icon {
	margin-right: 10px;
}

.settlements-heading-help .timezone {
	font-weight: 100;
	color: @gray-light;
}

.settlements-panel-wrap {
	padding: 0;
}

.settlements-form-group {
	display: flex;
	justify-content: space-between;
}

.settlements-control {
	display: flex;
	align-items: center;
}
.settlements-control .date-control-svg {
	margin: 0 8px 0 8px;
}
.settlements-control label {
	font-size: @font-size-base-plus;
	font-weight: normal;
}
.settlements-control .btn {
	min-width: 135px;
}
.settlements-control .view-btn {
	margin-right: 10px;
}
.control-export-label {
	margin-right: 20px;
}
.settlements-table {
   width: 100%;
}

.settlement-header-row {
	font-size: @font-size-base;
}

.settlements-table tbody tr:nth-child(odd) {
   background: @color-form;
}

.settlements-table tr td {
   font-size: @font-size-small;
}

.settlements-table tr {
   border-top: solid;
   border-bottom: solid;
   border-color: @gray-tr-hover;
   border-width: 1px;
   height: 45px;
}

.settlements-table .reconciled {
	background-color: @color-green-lighter;
}

.settlements-table .settlement-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: normal;
}

.settlements-table .date-col{
	width: 10%;
}

.settlements-table .settlement-col{
   width: 30%;
}

@media @desktop {
	.mobile-only { display: none !important; }
}

@media @mobile {
	.desktop-only { display: none !important; }
}

article.settlements-table i {
   vertical-align: sub;
   display: inline-block;
}

.settlement-payment-method-icon {
   margin-right: 15px;
}

.schedule-info-icon {
	width: 22px;
	height: 22px;
	margin-right: 10px;
}

.settlement-header-text-wrap {
	display: flex;
	align-items: center;
}

.settlement-cell {
	display: table-cell;
	vertical-align: inherit;
	border-left: solid;
	border-color: @gray-tr-hover;
	border-width: @panel-border-width;
	padding: 0;
}

.settlement-cell .content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
}

.settlements-pagination {
	position: relative;
	display: flex;
	justify-content: space-between;
	background: @color-form;
	padding: 20px 0;
	border-top: @panel-border-width solid #cccccc;
	margin-top: 20px;
}

.settlements-pagination .prv-nxt {
	display: flex;
	align-items: center;
	color: @btn-link-color;
	font-weight: bold;
	font-size: @font-size-base-minus;
	line-height: 1;
	top: 2px;
}

.settlements-pagination .nav-svg {
	margin: 0 15px 0 15px;
}

.settlements-export-button {
	background: none;
	border: none;
	padding: 0;
	color: @btn-link-color;
	white-space: nowrap;
}

.view-settlement-header {
	justify-content: space-between;
}

.settlement-status-label.success {
	color: @color-green;
}
.settlement-status-label.failed {
	color: @color-red;
}
.settlement-status-label.processing {
	color: @gray;
}

.settlement-status-icon.success {
	color: @color-green;
}
.settlement-status-icon.failed {
	color: @color-red;
}
.settlement-status-icon.processing {
	color: @gray;
}
