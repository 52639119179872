@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@popup-gutter-width: 10px;
@button-height: 30px;

.close-action-content {
	width: 390px;
	min-height: 140px;
	padding: @popup-gutter-width @popup-gutter-width * 3;
}

.delete-action-content {
	width: 450px;
	min-height: 185px;
	padding: @popup-gutter-width @popup-gutter-width * 3;
}

.action-checkbox {
	label {
		color: @gray-dark;
		font-weight: bold;
	}
}

.share-action-content {
	min-width: 150px;
	padding: @popup-gutter-width 0;
	margin: 0;
}

.share-item {
	font-size: @font-size-base-minus;
	list-style-type: none;
	white-space: nowrap;
	display: flex;
	align-items: center;
	height: @button-height;
	padding: 0 @popup-gutter-width;

	&:hover {
		cursor: pointer;
		background-color: #eeeeee;
	}

	&.share-heading {
		background-color: @gray-lightest;
		&:hover {
			cursor: default;
			background-color: @gray-lightest;
		}
	}
}

.share-icon {
	margin-right: @popup-gutter-width / 2;
	height: 24px;
	width: 24px;
}

.invalid {
	color: @color-red;
}

.warning {
	margin: @popup-gutter-width 0;
}

.button,
.text {
	vertical-align: middle;
	line-height: @button-height;
}
