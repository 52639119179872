@import "../../mixins/helpers";
@import "../../mixins/svgs";
@import "../../bootstrap_theme/labels";
@import "../../bootstrap-variables";

.integration-tile-container {
	.equal-height-columns;
	margin-left: -@grid-gutter-width/2;
	margin-right: -@grid-gutter-width/2;
}

.integration-tile {
	display: flex;
	flex-direction: column;
	border: 1px solid @gray-lighter;
	background-color: @color-form;
	width: 100%;
	min-height: 325px;
	margin-bottom: @grid-gutter-width;
}

.integration-tile-header {
	height: 180px;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.integration-tile-content {
	margin: 0 30px 22px;
	flex: 1 1 auto;

	h3 {
		margin-top: 20px;
	}

	p {
		color: @gray-mid;
		font-size: @font-size-base-minus;
	}

	.actions {
		margin-top: 20px;
	}

	.btn-link {
		font-size: @font-size-base;
		display: inline;
		margin: 0;
		line-height: inherit;
	}

	.label {
		margin: 0 8px 10px 0;
	}

	.integration-labels-section {
		margin-bottom: 10px;
	}

	.integration-tile-sub-title {
		font-weight: 500;
		font-size: 16px;
	}

	.integration-tile-sub-title + .label {
		margin: 0 0 0 8px;
	}

	.pco-integration-labels-section {
		.label + .label {
			margin: 0 0 0 8px;
		}
	}
}

.integration-tile-footer {
	margin: -22px 30px 22px;
	flex: 0 0 auto;
	font-size: @font-size-small;
	color: @gray-light;
	font-weight: @font-weight-semibold;
}
