#insights {

	.line-chart {

		.linearea {
			stroke: @color-complementary-green;
		}
		.dot {
			stroke: @color-complementary-green;
		}
	}

	.linebar-chart {
		.horizontal-lines {
			&.bottom {
				stroke: @color-complementary-green;
			}
		}
	}
}
