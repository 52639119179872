.giving-statements{
	.donor-emails {
		margin-bottom: 2 * @padding-base-vertical;
		.no-margin{
			margin-left: 0;
			margin-right: 0;
		}

		.bottom-border{
			border-color: @gray-lightest;
		}

		.donors-container{

			h2{
				margin: 0;
			}

			strong {
				color: @gray-darker;
			}

			.col-sm-2 {
				text-align: right;
				color: @gray-darker;

				@media @mobile {
					text-align: center;
				}
			}

			.highlight-red {
				color: @color-complementary-red
			}

		}

		hr {
			margin-top: @padding-base-vertical;
		}

		@media @mobile {
			text-align: center;
		}
	}
}
