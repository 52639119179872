@import "../../../PushpayWeb/Css/flexbox.less";

// mixins
@col-grey-l			: @gray-light;
@col-grey-rollover	: darken(@col-grey-l, 5%);
@col-grey-base		: @gray-base;
@col-grey			: @gray;
@col-grey-h			: darken( @col-grey, 20% );
@col-grey-darker	: @gray-darker;
@col-grey-dark		: @gray-dark;
@col-red			: @color-red;
@col-red-h			: @color-red-dark;
@black				: #272727;

@gap				: 20px;
@r					: 4px;
@border				: 1px solid @border-color;

#community-profile-v2 {

	// == Text/Typography
	.bold											{ font-weight: bold; }
	.upper											{ text-transform: uppercase; }
	.lower											{ text-transform: none; }
	.cen											{ text-align: center; }
	.weight( @weight: 400 )							{ font-weight: @weight; }
	.txtEmboss( @strength: 0.2 )					{ text-shadow: rgba(255,255,255,@strength) 0px 1px 0px; }
	.txtDeboss( @strength: 0.2 )					{ text-shadow: rgba(0,0,0,@strength) 0px -1px 0px; }

	.inline-form {
		form {
			display: block;
			margin-bottom: -@gap / 2;
		}
	
		.readonly {
			.value {
				display: inline-block;
				width: 285px;
			}
		}
	
		.editable {
			.btns {
				display: inline-block;
				white-space: nowrap;
				margin-bottom: @gap / 2;
			}
	
			.btn {
				visibility: visible;
			}
	
			input[type=text] {
				width: 205px;
				display: inline;
				font-size: 16px;
				height: auto;
				padding: 0 @gap / 1.5;
				position: relative;
				top: 2px;
				margin-bottom: @gap / 2;
				margin-right: @gap / 2;
			}
		}
	}
	
	.payment-details dd.inline-form .btn {
		margin-left: 0;
		margin-right: @gap / 2;
	}
	
	@media @mobile {
		.inline-form {
			.editable {
				.btn {
					visibility: visible;
					display: inline-block;
				}
			}
		}
	}

	.btn-link {
		font-size: 15px;
	}

	.pretty-arrow {
		font-family: ArrrowsRegular;
	}

	#edit-community-yourid {
		.btn {
			margin-left: 0;
			margin-right: 10px;
			min-width: 120px;
		}

		.sml-btn { 
			padding-top: 3px; 
			padding-bottom: 4px; 
			font-size: 14px; 
			.weight(400); 
		}

		.sml-btns {
			.btn { 
				.sml-btn; 
			}
		}

		input {
			width: 130px;
			height: 44px;
			top: 0;
		}
	}

	@media @desktop {
		.profile-details {
			float: left;
			width: 70%;
			&.anonymous {
				width: 100%;
				.action-link {
					width: 70%;
					padding-top: @gap;
					padding-bottom: @gap;
				}
			}
			.action-link {
				width: 70%;
				padding-top: @gap;
				padding-bottom: @gap;
			}
		}
	}

	@media @mobile {
		.profile-details {
			.action-link {
				top: @gap / 4;
				left: @gap / 4;
			}
		}
	}

	#community-member-transaction-list {
		clear: both;
		padding-top: 1px;
	}

	.page-head {
		header {
			a.btn-ghost {
				display: none;
			}
		}
	}

	&.vt-linked {
		.page-head {
			@media @desktop {
				width: 70%;
				&.anonymous {
					width:100%;
				}
			}

			header {
				padding-right: 0;
				h1 {
					float: left;
				}
				a.btn-ghost {
					float: right;
					display: block;
				}
			}
		}

		@media @mobile {
			padding-top: @gap;
		}
	}

	.transaction-pledge-container {
		.display-flex();
		border-bottom: 1px solid @border-color;
		padding-top: 40px;
		

		@media not @mobile {
			width: 100%;
			margin-bottom: 20px;
		}

		@media @mobile {
			padding-left: 20px;
			padding-right: 20px;
		}			
	}

	.custom-identifier-readonly {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	#transaction-tab,
	#pledge-tab {
		font-size: 20px;
		color: @gray-mid;
		font-weight: 300;

		&:hover {
			cursor: pointer;
		}

		&.transaction-pledge-tab-selected {
			color: @color-red;
			font-weight: normal;
			border-bottom: 3px solid @color-red;
		}
	}

	#community-member-pledge-list {
		padding-top: 1px;

		@media not @mobile {
			width: 50%;
		}

		.pledge-grid-table {
			@media @mobile {
				width: 100%;
				margin-bottom: 30px;
			}
		}

		.pledge-grid-row {
			.display-flex();
			cursor: default;

			&:nth-child(odd) td {
				@media @mobile {
					background-color: #f7f7f8;
				}
			}
			
			.pledge-grid-cell-campaign-name {
				.flex(1);
				text-align: left;
			}

			.pledge-grid-cell-delete {
				.display-flex();
				.flex-basis(60px);
				.justify-content-end();
				position: relative;
			}

			th {
				.display-flex();

				@media @mobile {
					display: none;
				}
			}

			td {
				.display-flex();

				@media @mobile {
					padding: 10px 20px;
					border-bottom: 1px solid #ccc;
				}
			}
		}

		.pledge-grid-delete {
			@tail-size: 8px;
			@tail-shadow-offset: 3px;

			&-btn {
				color: #666;
				border: none;
				background: transparent;

				&:hover {
					color: #860e06;
				}
			}

			&-icon {
				width: 16px;
				height: 16px;
				fill: currentColor;
			}

			&-bubble {
				position: absolute;
				top: -114px;
				background: white;
				color: @gray-darker;
				cursor: default;
				font-size: 12px;
				color: @gray-darker;
				border-radius: 8px;
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

				@media not @mobile {
					box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

					&:after {
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						box-sizing: border-box;
						border: @tail-size solid white;
						border-color: white;
						transform-origin: 0 0;
						transform: rotate(-45deg);
						transform-origin: -@tail-shadow-offset 50%;
						box-shadow: -@tail-shadow-offset @tail-shadow-offset 3px 0 rgba(0, 0, 0, 0.3);
					}

					&.left {
						right: -10px;

						&:after {
							right: 23px;
						}
					}
				}

				@media @mobile {
					position: fixed;
					top: ~"calc(50% - 107px/2)";
					left: ~"calc(50% - 357px/2)";
					box-shadow: 0 0 0 100vh rgba(0,0,0,0.3);
					z-index: 5;
				}
			}

			&-content {
				padding: 10px 30px 20px;
				text-align: left;
				min-width: 357px;
			}

			&-title {
				font-size: 15px;
				margin: 12px 0;
			}

			&-actions {
				.display-flex();
			}

			&-or {
				margin: 0 10px;
				line-height: 29px;
			}

			&-cancel {
				padding: 11px 0;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	
		.pledge-grid-delete-bubble {
			.btn {
				&.btn-red {
					background: @col-red;
					color: white;
					&:hover { background: @col-red-h; }
					& a {
						color: white;
					}
				}
			}

			.pledge-grid-delete-content {
				.pledge-grid-delete-title {
					font-weight: @headings-font-weight;
					white-space: nowrap;
				}
		
				.pledge-grid-delete-actions {
					.btn {
						padding: 0 45px;
					}
			
					.pledge-grid-delete-or {
						padding: 6px 0;
					}
				}
			}			
		}
	}

	.transaction-pledge-list-hidden {
		display: none;
	}

	@media @tablet {
		.profile-details {
			float:none;
			width:100%;
		}
		.recurring-history {
			padding:0;
			float:none;
			width:100%;
		}
	}
}
