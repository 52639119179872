@import "../../bootstrap-variables";

@txt-sent-banner-vertical-animation: -50px;
@txt-sent-banner-animation-length: 0.8s;

.txt-sent-banner {
	background-color: @color-complementary-green;
	color: white;
	position: fixed;
	top: @txt-sent-banner-vertical-animation;
	left: 0;
	width: 100%;
	padding-left: @alert-padding-sm;
	padding-right: @alert-padding-sm;
	font-size: @font-size-base-minus;
	display: flex;
	opacity: 0;

	transition: top @txt-sent-banner-animation-length, opacity @txt-sent-banner-animation-length;

	&.show-txt-sent-banner {
		top: 0;
		opacity: 1;
	}

	&-title {
		flex: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-icon {
		stroke: none;
		fill: white;
		flex: none;
	}

	&-text {
		padding: @alert-padding-sm;
	}

	.txt-sent-banner-dismiss {
		background-color: transparent;
		padding: 0;
		border: 0;
		line-height: 0;
		height: auto;
		padding-right: @alert-padding-sm;
		min-width: 0;
	}
}
