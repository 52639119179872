@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.message {
	margin-bottom: @grid-gutter-width;
}

.container {
	padding-top: @grid-gutter-width;
	padding-bottom: @grid-gutter-width;
	border: 1px solid @border-color;
	border-left: 0;
	border-right: 0;
	display: flex;
	font-size: @font-size-base;
}

.left {
	flex: auto;
	text-align: left;
}

.right {
	flex: none;
	text-align: right;
	margin-left: @grid-gutter-width;
}

.large {
	font-size: @font-size-h2;
	line-height: 30px;
}

.dollar {
	margin-right: 3px;
	font-size: @font-size-base-minus;
}
