@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@close-button-size: 12px;

.close {
	position: absolute;
	top: 5px;
	right: 5px;
	height: @close-button-size * 2;
	width: @close-button-size * 2;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
	cursor: pointer;
	fill: @gray-light;
	background-color: white;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.28);
	border: none;
	padding: 0;

	&:hover {
		fill: @gray-dark;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.37);
	}
}

.close-icon {
	height: @close-button-size;
	width: @close-button-size;
}

.upload-area {
	max-width: 100%;
	height: 100%;
	border-radius: @border-radius-base;
	background-color: @color-form;
	color: @gray-mid;
	font-weight: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	box-shadow: 0 0 0 4px @color-form;
	transition: all 0.2s linear;
	margin: 0;
	align-content: center;
	position: relative;
	cursor: pointer;

	.input {
		display: none;
	}

	@media @mobile {
		flex-direction: column;
	}

	&.hidden {
		display: none;
	}
}

.content {
	cursor: pointer;
	line-height: normal;
	text-align: center;
	max-width: 100%;

	@media @mobile {
		display: none;
	}
}

.error:not(.active) {
	border: 1px solid @color-red-validation;

	.border {
		visibility: hidden;
	}
}

.active {
	background-color: white;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.link {
	text-decoration: underline;
	cursor: pointer;
	line-height: normal;
	font-size: inherit;
	font-weight: 500;
	margin: 0;

	&:hover {
		color: @gray-darker;
	}
}

.error-text {
	position: absolute;
	line-height: normal;
	margin: 5px 0 0 0;
	color: @color-red;
	white-space: pre-wrap;
	bottom: -5px;
	transform: translateY(100%);
}

.preview-container {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	height: 100%;
	border-radius: @border-radius-base;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
	position: relative;
}

.border {
	transition: all 0.2s linear;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: transparent;
	stroke-width: 2;
	stroke-dasharray: 8 4;
	fill: none;
	stroke: darkgrey;
	pointer-events: none;

	@media @mobile {
		display: none;
	}
}
