@import "../../bootstrap-variables";

.configure-integration {
	max-width: @container-large-desktop;

	@listing-name-width: 220px;
	@enable-sync-width: @slider-toggle-width;
	@control-width: 300px;
	@spacing-row: 20px;

	.connection-section
	{
		margin-bottom: @line-height-computed;

		.integration-section-headers {
			width: 100%;
		}

		.integration-labels {
			display: inline-block;

			.label:not(:first-child) {
				margin-left: @spacing-row/2;
			}
		}

		h2 > div > span:first-child,
		.panel-heading > span:first-child {
			margin-right: @spacing-row/2;
		}

		@media @desktop {
			h2 {
				display: flex;
				flex-direction: row;

				.header-buttons {
					flex-grow: 1;
					text-align: right;
				}
			}
		}

		@media (max-width: @screen-xs-max) {
			h2 {
				display: block;

			}

			.btn-link {
				padding: 0;
			}

			.panel {
				border-top:0;
			}
		}
	}

	.list-group {
		.list-group-item {
			padding: @spacing-row;

			@media @desktop {
				flex-direction: row;
				align-items: center;
			}

			&.is-header {
				label {
					margin-bottom: 0;

					.icon {
						margin-bottom: -7px;
					}
				}
			}

			& > :first-child { // first column
				width: @listing-name-width + @enable-sync-width;
				margin-right: @spacing-row;
				flex-shrink: 0;
			}

			&:not(.is-header) {
				& > :first-child {// first column
					display: flex;
					flex-direction: row;
					align-items: center;

					label {
						font-size: @font-size-base-plus;
					}

					& > :nth-child(1) {
						width: @listing-name-width;
						flex-grow: 1;
					}

					& > :nth-child(2) {
						width: @enable-sync-width;
						text-align: right;
					}
				}

				&.selected {
					background-color: @color-green-lighter;
				}

				& + .selected {
					border-top: 1px solid #fff;
				}

				&.has-custom-validation-error {
					background-color: @state-danger-bg;
				}

				.field-validation-valid,
				.field-validation-error {
					min-height: @floating-label-height;
				}

				.form-group {
					.custom-validation-error {
						display: none;
					}

					&.has-error {
						.custom-validation-error {
							display: block;
						}

						.field-validation-error:not(.custom-validation-error),
						.field-validation-valid {
							display: none;
						}
					}
				}
			}
		}

		@media @mobile {
			.list-group-item {
				display: block;
				&.is-header {
					label:first-child {
						display: none;
					}

					label:nth-child(2) {
						display: block;

						& > span {
							display: flex;

							& > span:first-child {
								flex-grow: 1;
							}
						}
					}
				}

				&:not(.is-header) {
					& > :first-child {
						width: 100%;
						margin: 0;

						label {
							font-size: @font-size-large;
							font-weight: normal;
						}
					}
				}
			}
		}
	}

	.listing-settings-editor {
		@media @desktop {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		margin-top: @spacing-row / -2;
		margin-bottom: @spacing-row / -2;

		@media @mobile {
			margin-top: @spacing-row;
		}

		.form-group {
			width: @control-width;
			display: inline-block;
			margin-bottom: 0;
			vertical-align: top;

			&:not(:last-child) {
				margin-right: @spacing-row;
			}

			@media @mobile {
				width: 100%;
				max-width: 100%;
				margin: 0;
			}
		}

	}

	.organization-settings {
		.form-group {
			@media @desktop {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.control-label {
				width: @listing-name-width + @enable-sync-width - (@panel-body-padding - @spacing-row);
				margin-right: @spacing-row;
				padding-right: @spacing-row;
				flex-shrink: 0;

				@media @mobile {
					width: @listing-name-width + @enable-sync-width;
				}
			}

			.control {
				width: @control-width;
				display: inline-block;
				margin-bottom: 0;

				&:not(:last-child) {
					margin-right: @spacing-row;
				}
			}

			@media @mobile {
				.control-label,
				.control {
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}

	.control-label .info-tooltip {
		margin-left: 10px;
	}

	.field-validation-valid,
	.field-validation-error {
		margin: 0;
		display: block;
	}
}
