@import "../../bootstrap-variables";

.pill-box {
	@item-line-height: 18px;
	.round(3);
	border: 1px solid @gray-lighter;
	background-color: white;
	padding: @grid-gutter-width / 4;
	max-width: 480px;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: inline;

		@padding-left: 18px;
		@padding-right: 18px;
		@padding-top: 12px;
		@padding-bottom: 13px;
		@item-padding: @padding-top @padding-right @padding-bottom @padding-left;

		li {
			display: inline-block;
			font-size: @font-size-base-plus;
			line-height: @item-line-height;
			margin: 3px;
			padding: @item-padding;
			.round(3);

			&.pill {

				background-color: #eee;

				.close-btn {
					margin-left:10px;
					display: inline-block;
					color: @gray;
					.svg(10px, 10px);
				}
			}

			&.email-input {
				position: relative;
				min-width: @padding-left + @padding-right + 5px;
				min-height: 30px;
				padding-left: @padding-left + 2px; // leave room for cursor

				&.invalid {
					border: 1px dotted @color-red;
				}

				input[type="text"] {
					border: none;
					font-size:  @font-size-base-plus;
					line-height: @item-line-height;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					padding: @item-padding;


					&:focus {
						outline: none;
					}
				}
			}
		}
	}

}
