@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';
@pagination-control-padding: 15px;

.pagination-container {
	border-top: 1px solid @border-color;
	border-bottom: 1px solid @border-color;
	margin: 5px 0;
	text-align: center;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	list-style-type: none;
	padding: 0;
}

.pagination-control {
	padding: @pagination-control-padding;
	margin: 0;
	font-size: @font-size-base;
	line-height: 1.25;
	position: relative;
	color: @gray-dark;
	cursor: pointer;

	&:hover {
		color: @btn-link-hover-color;
	}
}

.current-page {
	color: @btn-link-color;
	cursor: default;
	position: relative;
	font-weight: bold;

	&:before {
		content: '';
		width: 10px;
		height: 10px;
		border-left: 1px solid @border-color;
		border-bottom: 1px solid @border-color;
		background-color: white;
		position: absolute;
		left: ~'calc(50% - 5px)';
		top: -5px;
		transform: rotate(-45deg);
	}

	&:hover {
		color: @btn-link-hover-color;
	}
}

.direction-icon {
	width: 12px;
	height: 12px;
	margin: 0 3px;
	vertical-align: baseline;
	fill: currentColor;
}

.omit-symbol {
	cursor: default;
	padding: @pagination-control-padding 0;

	&:hover {
		color: @gray-dark;
	}
}

.pagination-control-disabled {
	color: @gray-lighter;
	cursor: default;

	&:hover {
		color: @gray-lighter;
	}
}

@media @small {
	.direction-text {
		display: none;
	}
}
