@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.explanation {
	padding-left: @grid-gutter-width * 2;
	margin: @grid-gutter-width / 2 0 @grid-gutter-width;
}

.mapping-row {
	display: flex;
	padding: 25px 0;
	border-top: 1px solid @gray-lighter;
	align-items: center;
}
.mapping-merchant {
	flex: 0 0 auto;
	width: 305px;
	font-size: @font-size-large;
	font-weight: 500;
	color: @gray-dark;
}

.mapping-location {
	flex: 1 0 auto;
	margin-left: @grid-gutter-width;
}

.location-label {
	display: block;
	font-size: @font-size-base-minus;
}

.checkbox-container {
	.checkbox-label {
		font-size: @font-size-base;
		font-weight: 500;

		& span[class~='fake-checkbox'] {
			margin-right: 21px;
		}
	}
}

.location-select {
	width: 400px;
}

.optional {
	font-weight: normal;
}
