@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.date {
	font-size: @font-size-base-plus;
	padding-bottom: @panel-body-padding;

	strong {
		font-weight: 500;
	}

	@media @small {
		padding-bottom: 0;
	}
}

.number {
	font-size: 24px;
	font-weight: 300;
}

.split-panel {
	display: flex;

	@media @small {
		flex-direction: column;
		padding: @panel-body-padding-mobile;
	}

	& > .sub-panel {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: @font-size-base-minus;

		svg {
			width: 56px;
			height: 56px;
			margin-bottom: 10px;
		}

		&:not(:first-of-type) {
			border-left: 1px solid @panel-inner-border;
		}

		@media @small {
			padding: @panel-body-padding-mobile 0;
			border-bottom: 1px solid @panel-inner-border;

			&:not(:first-of-type) {
				border-left: none;
			}
		}
	}
}
