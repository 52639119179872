@import '../../../Content/LoggedInWeb/bootstrap-variables.less';

.page-container {
	font-size: @font-size-base-plus;

	.page-title {
		margin-bottom: 30px;
		line-height: 1.5;
		position: relative;

		@media @small {
			margin-bottom: 24px;
		}
	}

	.page-description {
		margin-bottom: 30px;
		line-height: 1.5;

		@media @small {
			margin-bottom: 24px;
		}
	}
}
