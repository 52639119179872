@import "../../bootstrap-variables";

.split-payment-info-tooltip {
	color: @link-color;
	border-bottom: 1px dotted @link-color;
}

.split-payment-info-tooltip-content {
	text-align: left;
	font-weight: 400;
	margin: @padding-small-vertical;
	line-height: @font-size-large;

	p {
		margin-bottom: @padding-large-vertical;
	}

	table {
		width: 100%;
		color: #35373B;
		
		tr {
			th, td {
				font-weight: normal;
				font-size: inherit;
				padding: 0;
			}

			td {
				text-align: right;
			}
		}
	}

	hr {
		margin: @padding-small-vertical 0;
	}

	.total-amount {
		text-align: right;
	}
}
