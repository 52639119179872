#insights {
	.insights-vars;

	@media print {

		background-color: white;

		header {

			.top-bar {
				display: none;
			}
		}

		footer {
			display: none;
		}

		section.insights-bg {
			background-color: white;
		}

		.panel {
			.panel-body {
				background-color: white;
			}
		}
	}
}
