#insights {
	.insights-vars;

	.loading {
		text-align: center;
		padding: 40px;
		background-color: white;
		box-shadow: none !important; // override base panel
		border: 1px solid @gray-lighter;

		h4 {
			color: @gray-dark;
		}
	}

	.enable-animation {
		.loading {

			img {
				animation-name: rotate;
				animation-duration: 1000ms;
				transform-origin: 50% 50%;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}

			@keyframes rotate {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}
