@import '../bootstrap-variables.less';

@list-group-padding-horizontal: @grid-gutter-width;
@list-group-heading-padding-vertical: 16px;
@list-group-item-padding-vertical: 24px;
@list-group-item-mobile-padding-vertical: 15px;
@list-group-load-more-padding-vertical: 30px - @list-group-item-padding-vertical;

.list-group-wrapper {
	@media @mobile {
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}
}

.list-group-item {
	padding: @list-group-item-padding-vertical @list-group-padding-horizontal;
	border-left: 0;
	border-right: 0;
	font-size: @font-size-base-minus;
	display: flex;

	@media @small {
		flex-direction: column;
		padding-top: @list-group-item-mobile-padding-vertical;
		padding-bottom: @list-group-item-mobile-padding-vertical;
	}

	&:first-child {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}

	&:last-child {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.list-group-item-large {
	font-size: @font-size-h2;
	font-weight: 300;
	margin-bottom: 8px;

	sup {
		font-size: @font-size-base-minus;
		margin-right: 3px;
	}

	@media @small {
		font-size: @font-size-large;
	}
}

.list-group-item-title {
	margin-right: 22px;
	font-weight: 300;
	&.btn-link {
		&:hover {
			background-color: @theme_colors_common-transparent;
		}
	}
}

.list-group-item-label {
	margin-top: -4px;
}

.list-group-item-light {
	color: @gray-mid;
}

.list-group-item-lighter {
	color: @gray-lighter;
}

.list-group-item-bullet {
	margin: 0 @padding-base-horizontal;
	&:before {
		content: '\2022';
	}
}

.list-group-row {
	display: flex;
	width: 100%;

	@media @mobile{
		flex-direction: column;
	}
}

.list-group-left {
	flex-grow: 1;
}

.list-group-right {
	.text-right;
}

.list-group-filters {
	padding: 0 @list-group-padding-horizontal;
	display: flex;

	@media @small {
		flex-direction: column;
	}

	@media not @small {
		align-items: flex-end;
	}
}

.list-group-filter {
	min-width: 260px;
	display: flex;
	flex-direction: column;

	@media not @small {
		padding-right: @grid-gutter-width;
	}
}

.list-group-heading {
	padding: @list-group-heading-padding-vertical @list-group-padding-horizontal;
	font-size: @font-size-base-plus;
	display: flex;

	@media @small {
		font-size: @font-size-base;
	}
}

.list-group-load-more {
	padding: @list-group-load-more-padding-vertical @list-group-padding-horizontal;
	.text-center
}

.list-group-action-link {
	margin-left: auto;
	font-size: @font-size-base-minus;

	.icon {
		margin-right: 4px;
	}
}
