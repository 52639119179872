@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@media not @small {
	.text-field {
		width: ~'calc(100% - @{input-height-base})';
	}
}

.color-picker-text {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	order: 2;
}

:global(.input-group .form-control:first-child).color-picker-text {
	border-top-right-radius: @border-radius-base;
	border-bottom-right-radius: @border-radius-base;
}

.color-picker-button {
	order: 1;
}

.color-picker-text:global(.input-validation-error) + .color-picker-button > div > div {
	border-left-color: @color-red-validation;
	border-top-color: @color-red-validation;
	border-bottom-color: @color-red-validation;
}
.color-picker-text:focus,
.color-picker-text:global(.input-validation-error) {
	border-left-color: @input-border;
}

.color-picker-text:focus:not(:global(.input-validation-error)) + .color-picker-button > div > div {
	border-left-color: @input-border-focus;
	border-top-color: @input-border-focus;
	border-bottom-color: @input-border-focus;
}
