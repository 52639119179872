@import "../bootstrap-variables";

@checkbox-left-padding: 28px;

.checkbox {
	label {
		font-size: @font-size-base;
		padding-left: @checkbox-left-padding;
		position: relative;

		&:before {
			position: absolute;
			left: 0;
			top: 2px;
			border-radius: @input-border-radius;
			content: ' ';
			display: inline-block;
			border: 1px solid @input-border;
			.square(@checkbox-input-size);
			background-color: white;
			margin: 0;
			vertical-align: middle;

			&:active {
				box-shadow: @gray-lighter 0px 0px 5px 0px inset;
			}
		}
	}

	input[type="checkbox"] {
		display: none;

		&:checked ~ .svg-checkbox-ticked {
			display: inline-block;
		}

		&[data-indeterminate="true"] ~ .svg-checkbox-indeterminate {
			display: inline-block;
		}
	}

	.svg {
		display: none;
		position: absolute;
		left: 0;
		margin-top: 1px;
		width: 18px;
		height: 20px;
	}

	&.checkbox-large {
		label {
			font-size: @font-size-base-plus;

			&:before {
				top: 3px;
			}
		}

		.svg {
			margin-top: 2px;
		}
	}

	&.checkbox-green {
		&.checked {
			label:before {
				background-color: @color-green;
				border-color: @color-green;
			}

			.svg {
				fill: white;
			}
		}
	}

	&.disabled,
	fieldset[disabled] & {
		
		label {
			color: @input-color-disabled;
			&:before {
				background-color: @input-bg-disabled;
				border-color: @input-border-disabled;
				opacity: @input-opacity-disabled;
			}
		}
	}
}

