@import '../../../Content/LoggedInWeb/bootstrap-variables.less';

@spinner-size: 60px;

.spinner-container {
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.load-more-spinner {
	border-color: lighten(@color-red, 50%);
	border-top-color: @color-red;
}

.load-spinner {
	.load-more-spinner();
	width: @spinner-size;
	height: @spinner-size;
	border-width: 5px;
}
