@vt-form-loading-zindex: 4;
@vt-form-omnibox-focused-zindex: 5;
@vt-form-recurring-radio-width: 100px;
@vt-bank-check-message-margin: 20px 40px;
@vt-check-message-margin: 20px 0px;
@vt-grid-gutter: @grid-gutter-width / 2;

.vt-form-recurring-caret-position(@size, @panel-padding) {
	left: @panel-padding + @vt-form-recurring-radio-width - (@size*sqrt(2) - @radio-inline-padding)/2;
}

.pay-vt-header-wrap {
	display: flex;
	justify-content: space-between;
}

.vt-form {
	> .payer-search-omnibox {
		margin: -1px;
		@media @mobile {
			margin-left: 0;
			margin-right: 0;
			border-left: 0;
			border-right: 0;
		}
	}
	> .panel-full-width-control {
		margin-bottom: 0;
	}

	&-expanded {
		> .payer-search-omnibox {
			margin-bottom: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			&-focused {
				z-index: @vt-form-omnibox-focused-zindex;
			}
		}
	}
}

.vt-form-payment-amount {
	display: flex;
	align-items: center;
	margin-top: 5px;

	> sup {
		font-weight: 400;
		font-size: @font-size-base-plus;
		top: (@font-size-base-plus - @font-size-h1)/4;
		padding-right: 5px;

		&.as-placeholder-validation-error {
			color: @color-red-validation;
		}
	}

	> .form-control {
		font-size: @font-size-h1;
		font-weight: 300;
	}

	+ .field-validation-error {
		font-size: @font-size-base-plus;
		font-weight: normal;
		margin-bottom: 0;
	}
}

.vt-form-payment-details {
	.overlay(disabled, 3);

	&-loading {
		// setting some height to accomodate the loading box with absolute position
		height: 200px;
	}

	&.disabled .loading-box {
		z-index: @vt-form-loading-zindex;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&-general {
		padding-bottom: 10px;
	}

	&-recurring-toggle .radio-inline {
		width: @vt-form-recurring-radio-width;
		margin-left: 0;
	}

	&-send-email {
		margin-top: 0;
		margin-bottom: @form-group-margin-bottom;
	}

	&-recurring {
		&:before {
			.vt-form-recurring-caret-position(@notch-size, @panel-body-padding);

			@media @mobile {
				.vt-form-recurring-caret-position(@notch-size-small, @panel-body-padding-mobile);
			}
		}

		.radio-grid {
			max-width: 100%;

			.radio-inline {
				width: ~'calc(25% + @{radio-grid-border-width})';

				&:last-child {
					width: 25%;
				}
			}
		}

		&-end-occurrences {
			.form-control {
				width: 50%;
			}

			&:after {
				content: 'payments';
				position: absolute;
				top: @line-height-computed + 5px;
				left: 50%;
				margin-left: 10px;
				height: @input-height-base;
				line-height: @input-height-base;
			}
		}
	}
}

.vt-edit-recurring-panel .disclaimer {
	margin-top: 25px;
	color: @gray-light;
	font-size: @font-size-small;
}

.vt-edit-recurring-panel .help-text {
	color: @gray-light;
	font-size: @font-size-small;
}
.vt-edit-recurring-panel .card-support {
	display: flex;
	justify-content: space-between;
}

.vt-edit-recurring-panel #MobileNumber_Country {
	padding-left: 5px;
}

.vt-edit-recurring-panel li {
	list-style: none;
}

.vt-edit-recurring-panel .recurring-radio-grid {
	display: flex;
	flex-basis: auto;
	justify-content: flex-start;
	align-items: stretch;
	flex-flow: row wrap;
	margin-bottom: 10px;
	padding-top: 10px;
}
.vt-edit-recurring-panel .recurring-radio-grid-item {
	background-color: #fff;
	justify-content: center;
	width: 25%;
	min-height: 68px;
	max-width: 25%;
	color: @gray;
	flex: 0 1 auto;
	display: flex;
}
.vt-edit-recurring-panel .recurring-radio-grid-radio {
	visibility: hidden;
	display: none;

	& ~ .recurring-radio-grid-label {
		display: flex;
		justify-content: center;
		align-content: center;
		padding: 13px 2px 13px 2px;
		border: 1px solid @gray-lighter;
		background-color: white;
		text-align: center;
		width: 100%;
		font-size: @font-size-small;
		margin-bottom: 0px;
	}

	&:checked ~ .recurring-radio-grid-label {
		color: white;
		background-color: @color-red;
	}
}

.vt-edit-recurring-panel .bank-check-message {
	padding: @tipbox-padding-vertical;
	margin: @vt-bank-check-message-margin;
	border: 1px solid @color-orange;
	border-radius: @border-radius-base;
	color: @color-orange;
	display: none;
	background-color: white;

	a {
		text-decoration: underline;
	}
}

.vt-edit-recurring-panel .duplicate-check-recorded {
	padding: @tipbox-padding-vertical;
	margin: @vt-check-message-margin;
	border: 1px solid @color-yellow;
	border-radius: @border-radius-base;
	color: @color-yellow;
	background-color: white;
	display: none;
}

.vt-edit-recurring-panel .duplicate-check-processed {
	padding: @tipbox-padding-vertical;
	margin: @vt-check-message-margin;
	border: 1px solid @color-red;
	border-radius: @border-radius-base;
	color: @color-red;
	background-color: white;
	display: none;
}

.vt-edit-recurring-panel .validating-check-msg {
	font-size: @font-size-small;
	color: @color-green;
	opacity: 0;
	max-height: 0;
	margin: 0;
	transition: opacity 0.5s, max-height 0.5s, margin 0.5s;
}

.vt-edit-recurring-panel .validating-check-msg.loading {
	opacity: 1;
	margin: @vt-check-message-margin;
	max-height: 100px;
}

.vt-edit-recurring-panel .new-fields-form-control {
	display: flex;

	label {
		width: 25%;
		padding: 0 10px 0 10px;
	}
	.control {
		width: 50%;
		padding: 0 10px 0 10px;
	}
}

.vt-edit-recurring-panel .panel-footer .btn {
	margin-left: 15px;
}

.vt-edit-recurring-panel {
	.orphaned-frequency-option-warning {
		position: relative;
		padding-left: @vt-grid-gutter;
		padding-right: @vt-grid-gutter;
		display: flex;
		margin-bottom: @form-group-margin-bottom-reduced;

		@media (min-width: @screen-md-min) {
			width: 50%;
			margin-left: 25%;
		}

		svg {
			width: 17px;
			height: 16px;
			margin-top: 4px;
			margin-right: 15px;
			color: @color-yellow;
			flex-shrink: 0;
		}

		p {
			font-size: 14px;
			margin: 0;
		}
	}
}
