@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@media @small {
	.batch-list-tabs {
		display: flex;

		.tab {
			flex-grow: 1;
			display: flex;
			align-items: center;

			.tab-content {
				padding: 1em;
				flex-grow: 1;
			}
		}
	}
}
