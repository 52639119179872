@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@tab-height: 30px;
@tab-transition-props: @btn-transition-duration ease;

.tabs {
	display: flex;
	padding: 0;
	margin: 0;
	border-bottom: solid 1px @text-muted;
}

.tabs-no-underline {
	display: flex;
	padding: 0;
	margin: 0;
}

.tab {
	padding: 0 30px 0 0;
	list-style: none;
	font-weight: normal;
}

.active {
	.button {
		color: @brand-primary;
		border-bottom: solid 2px @brand-primary;
		font-weight: bold;

		&:hover,
		&:focus {
			color: @brand-primary;
		}
	}
}

.button {
	background: none;
	border-style: none none solid;
	border-width: 0px;
	border-color: transparent;
	border-radius: 0;
	color: @text-muted;
	padding: 0 0 10px 0;
	line-height: @tab-height;
	transition: border-color @tab-transition-props, color @tab-transition-props;

	&:hover,
	&:focus {
		color: @text-color;
		outline: none;
	}
}

.label {
	display: inline-block;
	vertical-align: middle;
}

.content-pt {
	padding-top: 2em;
}

.content {
	padding-top: 0px;
}

.remove-top-border {
	border-top: none;
	padding-top: 0;
}
