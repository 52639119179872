@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.header {
	margin: 0 0 25px;
	display: flex;
	align-items: baseline;
}

.title {
	flex: 1 1 auto;
}

.link {
	width: 210px;
	text-align: right;
	font-size: @font-size-base-plus;
	padding-right: 5px;
}

.link-svg {
	width: 8px;
	height: 14px;
	fill: currentColor;
	margin-left: 5px;
	position: relative;
	top: 2px;
}
