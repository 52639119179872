@cd-batch-list-mobile-filter-notch-size: 16px;
@cd-batch-list-mobile-filter-notch-size-half: @cd-batch-list-mobile-filter-notch-size / 2;

.cd-batch-list-tabs > li {
	@media @small {
		width: 50%;
	}
}

.cd-batch-list-mobile-filters {
	.text-center;
	padding-bottom: 20px;
}

.cd-batch-list-mobile-filter-item {
	font-size: @font-size-small;
	&:not(:first-child) {
		border-left: 1px solid @border-color;
	}
}

.cd-batch-list-mobile-filter-btn {
	font-size: @font-size-small;
	padding: 0 8px;
	color: @text-color;

	&:hover,
	&:focus {
		color: @text-color;
		text-decoration: none;
	}

	&.active {
		color: @color-red;
		text-decoration: none;

		.icon {
			fill: currentColor;
		}
	}

	.icon {
		fill: @gray;
	}
}

.cd-batch-list-mobile-filter-sort {
	position: relative;
}

.cd-batch-list-mobile-filter-sort-options {
	position: absolute;
	opacity: 0;
	//The font size needs to be >= 16px so iPhone won't rescale the screen when it gains focus
	font-size: @font-size-base-plus;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.cd-batch-list-mobile-filters-expanded {
	padding: 20px 30px;
	margin-bottom: 0;
	border-color: @panel-default-inner-border;

	&:before {
		border-color: @panel-default-inner-border;
		left: ~"calc(100vw / 6 + @{cd-batch-list-mobile-filter-notch-size-half})";
	}
}

.cd-batch-list-item-closed-icon {
	.square(22px);
	fill: @color-complementary-green;
	margin-bottom: -2px;
}

.cd-batch-list-item-info {
	margin-right: @grid-gutter-width;
}

.cd-batch-list-item-rejected {
	opacity: 0.5;
}

@media @small {
	.cd-batch-list-item-total-wrapper {
		display: flex;
	}

	.cd-batch-list-item-total {
		order: 1;
		font-weight: 700;
		font-size: @font-size-base-minus;

		sup {
			margin-right: 1px;
			top: 0;
		}
	}

	.cd-batch-list-item-checks {
		flex-grow: 1;
		.text-left;
	}

	.cd-batch-list-item-closed-icon {
		margin-bottom: -4px;
	}
}
