@import "../bootstrap-variables";
@import "../mixins/helpers";

.legacy {
	@gap			: @grid-gutter-width;
	@r				: @border-radius-base;
	@col-grey-l		: @color-form;
	@border-color 	: lighten(@gray-light, 12%);	// ish.
	@border 		: 1px solid @border-color;
	@txt-base		: lighten(@gray-base, 40);

	fieldset {
		background: @col-grey-l;
		border: @border;
		.round(@r);
		.clearfix();
		.relative;

		h2 {
			.relative;
			background: #FFF;
			margin: 0;
			font-size: 21px;
			padding: @gap @gap @gap @gap * 2;
			border-bottom: 1px solid fade(@border-color, 40%);
			border-top: @border;

			&:first-child {
				border-top: none;
				.roundSome(@r,@r,0,0);
			}
		}

		ul {
			margin: @gap 0;
			padding: 0 @gap;
			width: 100%;
			display: table;

			&.no-pad {
				padding: 0;
			}

			li {
				display: table-row;

				.control {
					padding: 10px 0;
				}
			}
		}

		.overview {
			margin: @gap * 2;
			color: lighten( @txt-base, 20% );
		}
	}

	.form-group {
		display: flex;
		flex-wrap: nowrap;

		@media @mobile {
			flex-wrap: wrap;
		}
	}

	label {
		width: 40%;
		flex: 0 0 auto;

		span {
			font-weight: 200;
			color: @gray;
		}

		@media @mobile {
			width: 100%;
			margin: 0;
		}
	}

	.control {
		display: table-cell;
		width: 40%;
		flex: 1 1 auto;

		@media @mobile {
			width: 100%;
		}
	}

	.field-validation-valid {
		flex: 1 1 auto;
		width: 30%;

		@media @mobile {
			width: 100%;
		}
	}

	dl {
		display: flex;
		flex-wrap: wrap;
		margin: 0;

		dt {
			flex: 1 0 auto;
			width: 40%;
			margin: 0 0 10px;
		}

		dd {
			flex: 1 0 auto;
			width: 60%;
			margin: 0 0 10px;
		}

		@media @mobile {
			dt {
				width: 100%;
				margin: 0;
			}

			dd {
				width: 100%;
			}
		}

		&.split dd {
			border-left: 1px solid #CCC;
			padding: 0 0 10px 20px;
			margin: 0;
		}
	}


	.amount {
		.currency {
			color: @gray;
			font-weight: normal;
			vertical-align: 2px;
			font-size: @font-size-base;
		}

		.value {
			font-weight: normal;
			font-style: normal;
			font-size: @font-size-large;

			span {
				font-size: 0.7em;
				font-weight: normal;
				vertical-align: 4px;
				padding: 0 3px;
			}
		}
	}
}
