@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.export-dropdown {
	position: relative;

	@media @small {
		width: 100%;

		.export-button,
		.export-menu {
			width: 100%;
		}
	}
}
