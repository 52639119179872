@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@ribbon-height: 30px;
@ribbon-gutter: 15px;
@ribbon-border-radius: 8px;

.old-tile {
	display: flex;
	width: 100%;
	flex-direction: column;
	color: @gray-dark;
	text-align: center;
	border: 1px solid @gray-lighter;
	border-radius: @border-radius-base;
	padding: @grid-gutter-width * 2 @grid-gutter-width;

	&:hover {
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
		.tile-button {
			color: @btn-ghost-active-color;
			background-color: darken(@btn-ghost-active-bg, 10%);
			border-color: darken(@btn-ghost-active-border, 12%);
		}
	}

	&:hover,
	&:active,
	&:focus {
		color: @gray-dark;
		text-decoration: none;
	}
}

.tile {
	width: 100%;
	text-align: center;
}

.tile-link {
	flex-direction: column;
	display: flex;
	height: 100%;
	border: 1px solid @gray-lighter;
	border-radius: @border-radius-base;
	padding: @ribbon-gutter * 2 + @ribbon-height @grid-gutter-width;
	color: @gray-dark;
	&:hover {
		.tile-button {
			color: @btn-ghost-active-color;
			background-color: darken(@btn-ghost-active-bg, 10%);
			border-color: darken(@btn-ghost-active-border, 12%);
		}
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
	}

	&:hover,
	&:active,
	&:focus {
		color: @gray-dark;
		text-decoration: none;
	}
}

.tile-icon {
	margin-left: auto;
	margin-right: auto;
	.square(@tile-icon-size);
	border-radius: 50%;
	background-color: @color-green;
	padding-top: @tile-icon-padding;

	> svg {
		fill: white;
		.square(@tile-icon-svg-size);
	}
}

.tile-content {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	display: flex;
	flex-grow: 1;
}

.tile-button {
	align-self: center;
	max-width: 100%;

	@media screen and (max-width: @screen-lg) {
		min-width: 0;
	}

	@media @small {
		width: 100%;
	}
}

.ribbon {
	float: left;
	position: absolute;
	top: @ribbon-gutter;
	height: @ribbon-height;
	background-color: @gray-mid;
	font-size: @font-size-base-minus;
	color: #fff;
	padding: 0 @grid-gutter-width;
	border-radius: 0 @border-radius-base @border-radius-base 0;
	box-shadow: 0 3px 4px @gray-mid;

	&-content {
		line-height: @ribbon-height;
	}

	&::before {
		content: '';
		position: absolute;
		left: -7px;
		width: 8px;
		height: 40px;
		background-color: @gray-mid;
		border-radius: @ribbon-border-radius 0 0 @ribbon-border-radius;
	}

	&::after {
		content: '';
		position: absolute;
		width: 8px;
		height: 12px;
		left: -7px;
		top: 30px;
		background-color: @gray-dark;
		border-radius: @ribbon-border-radius 0 0 @ribbon-border-radius;
	}
}

.square(@size) {
	width: @size;
	height: @size;
}
