@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.tiles {
	margin-bottom: @grid-gutter-width * 2;
	flex-wrap: nowrap;

	@media @small {
		flex-wrap: wrap;
	}
}

.tile {
	float: none;

	@media @small {
		width: 100%;
		&:not(:last-child) {
			margin-bottom: @grid-gutter-width;
		}
	}
}

.help-center {
	border-top: 1px solid @gray-lighter;
	border-bottom: 1px solid @gray-lighter;
	padding: @grid-gutter-width 0;
	text-align: center;
}

.tile-count {
	height: @tile-icon-size;

	&-total {
		font-size: 55px;
		line-height: 1;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
