@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@logo-height: 200px;
@logo-animation: show-logo 0.5s ease 1.5s 1 normal forwards;

.wrapper {
	display: flex;
	justify-content: center;
}

.container {
	position: relative;
	margin-bottom: 80px;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 500px;
}

.header {
	opacity: 0;
	animation: show-header 1s ease 1s 1 normal forwards;
}

.content {
	opacity: 0;
	animation: show-content 0.5s ease 1.5s 1 normal forwards;
}

.intro {
	margin-bottom: 24px;
	max-width: 400px;
}

.logo {
	opacity: 0;
	animation: @logo-animation;
	height: @logo-height;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: '';
		height: @logo-height;
		width: @logo-height;
		border-radius: 50%;
		background-color: #f7f7f7;
		position: absolute;
		display: block;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
		opacity: 0;
		animation: @logo-animation;
	}
}

.icon {
	height: @logo-height * 0.4;
	width: @logo-height * 0.6;
}

@keyframes show-header {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}

	30% {
		opacity: 1;
		transform: translateY(100%);
	}

	50% {
		opacity: 1;
		transform: translateY(100%);
	}

	80% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes show-content {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes show-logo {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.live-campaign-required {
	&-buttons {
		display: flex;
		margin-top: 12px;

		@media @small {
			flex-direction: column;
		}
	}

	&-goto {
		@media @small {
			margin-right: 0;
			margin-bottom: @grid-gutter-width;
		}
	}
}
