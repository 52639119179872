@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@sort-by-select-arrow-width: 35px;

.sort-by {
	display: flex;
	align-items: center;
	position: relative;

	&-label {
		margin-bottom: 0;

		@media @mobile {
			cursor: pointer;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: @font-size-base;
			font-weight: 500;
			color: @link-color;

			&:hover,
			&:active,
			&:focus,
			&-focus {
				color: @link-hover-color;
				text-decoration: @link-hover-decoration;
			}
		}
	}

	&-select-wrapper {
		position: relative;
		margin-left: @padding-base-horizontal;

		@media @mobile {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: ~'calc(100% - @{sort-by-select-arrow-width})';
			border-bottom: 1px dashed;

			@media @mobile {
				display: none;
			}
		}

		&:after {
			padding-top: 0;
			padding-bottom: 0;
			top: 6px;

			@media @mobile {
				display: none;
			}
		}
	}

	// this needs to be nested to override bootstrap styles
	.sort-by-select {
		padding: 0 @sort-by-select-arrow-width 0 0;
		border: none;
		height: auto;

		@media @mobile {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			padding-right: 0;
		}
	}
}

.icon-sort {
	margin-right: @padding-base-horizontal;
	margin-top: -1px;
	fill: currentColor;
}
