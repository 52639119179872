@import "../../../../Content/LoggedInWeb/bootstrap-variables.less";
@import "../../../../Content/PushpayWeb/Css/variables.less";

.wrapper {
	display: inline-flex;
	flex-wrap: nowrap;
}

.label {
	display: inline-flex;
	flex: 0;
	align-items: center;
	margin-right: 5px;
}

.icon {
	width: 10px;
	height: 10px;
	margin-right: 5px;
}
