@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@total-margin: 0.5em;
@secondary-font-size: 15px;

.container {
	padding: 20px 0;
}

.priority-totals {
	font-size: 24px;
	font-weight: 300;
	margin-bottom: @total-margin;

	.total-value {
		font-weight: bold;
		@media @mobile {
			font-size: 20px;
		}
	}
}

.secondary-totals {
	font-size: @secondary-font-size;
	padding-bottom: 10px;
}

.total-label {
	margin-right: 5px;
	&:after {
		content: ':';
	}

	@media @mobile {
		font-size: @secondary-font-size;
		&:after {
			display: none;
		}
	}
}

.total {
	@media not @mobile {
		display: inline-block;
		padding-right: 26px;
	}

	@media @mobile {
		line-height: 1.4;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}
}

.goal-total {
	@media @mobile {
		border-top: 1px solid @color-form-alt;
		border-bottom: 1px solid @color-form-alt;
		line-height: 2.2;
		margin-top: @total-margin;

		.total-value {
			font-size: @secondary-font-size;
		}
	}
}

.pledge-table-wrapper {
	margin: 0 0 15px;
}

.search-box {
	display: flex;
	width: 100%;
	height: 44px;
	max-width: 475px;
	border: 1px solid @border-color;
	border-radius: @border-radius-base;
	flex-wrap: nowrap;
	align-items: stretch;
}

.search-input {
	font-size: @font-size-h4;
	border: none;
	flex: 1 0 auto;
	border-radius: @border-radius-base 0 0 @border-radius-base;
	padding: 0 18px;

	&:active,
	&:focus {
		outline: none;
	}
}

.search-button {
	border: none;
	background-color: @gray-lightest;
	color: @gray-dark;
	border-left: 1px solid @border-color;
	flex: 0 0 auto;
	border-radius: 0 @border-radius-base @border-radius-base 0;
	transition: color, background-color 0.5s;
	padding: 9px 18px;

	&:active,
	&:focus,
	&:hover {
		background-color: @gray-light;
		color: @gray-darker;
		outline: none;
	}
}

.export-button {
	border: none;
	color: @link-color;
	text-decoration: none;
	font-size: @font-size-base-minus;
	flex: 0 0 auto;
	background-color: transparent;
	border-radius: 0;
	padding: 10px 0;
	transition: color, background-color 0.5s;
	font-weight: @btn-font-weight;
	outline: none;

	&:active,
	&:focus,
	&:hover {
		color: @link-hover-color;
		text-decoration: @link-hover-decoration;
	}

	&:disabled,
	&:disabled:hover {
		color: @btn-disabled-color;
	}
}

.search-icon {
	width: 24px;
	height: 24px;
}

.reset-link {
	padding: 0;
	font-size: @font-size-small;
}

.search-export-container {
	display: grid;
	align-items: end;
	grid-template-areas: 'search-block export-block';
}

.search-block {
	grid-area: search-block;
}

.export-block {
	grid-area: export-block;
	display: flex;
	align-content: end;
	justify-content: end;
	align-items: end;
	justify-content: flex-end;
}
