@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.title {
	margin-bottom: 0;

	& + .listing-selector {
		margin-top: 30px;
		margin-bottom: 0;
	}
}

.intro {
	display: flex;
	margin-bottom: 30px;
	align-items: baseline;

	@media @small {
		flex-direction: column;
		align-items: flex-start;
	}

	&-text {
		flex: auto;
		padding-right: @grid-gutter-width;

		@media @mobile {
			width: 100%;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}
}

.lead {
	@media @mobile {
		margin-bottom: @line-height-computed / 2;
	}
}

.btn-anon {
	font-size: @font-size-large;
	vertical-align: baseline;
}

.btn-new-member {
	flex: none;

	@media @small {
		padding-left: 0;
		margin-top: 20px;
	}
}

.warning {
	@media @mobile {
		margin-top: @grid-gutter-width;
	}
}

.icon-info {
	height: 25px;
	width: 25px;
}
