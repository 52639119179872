@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.timeline-countdown {
	font-size: @font-size-h2;
	font-weight: 400;
	padding: 0 0 20px;
	border-bottom: 2px solid @border-color;
	margin: 0 0 20px;
}

.date {
	font-size: @font-size-base-plus;
	margin: 0 0 20px;
}

.details-link {
	display: block;
	margin-top: 25px;
}

.pencil-icon {
	position: relative;
	top: 3px;
	display: inline-block;
	margin-right: 5px;
	fill: currentColor;
}
