@import "../../bootstrap-variables";

.editable-label {
	display: flex;

	.value-edit, .value-display {
		flex: 1 1 auto;
	}

	.value-edit {
		background-color: transparent;
		border: 1px solid @border-color;
		padding: 3px;
		border-radius: @border-radius-small;
		min-width: 100px;
	}

	.value-display {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	button {
		flex: 0 0 auto;
		text-align: left;
		border: none;
		background-color: transparent;
		color: @btn-link-color;

		svg {
			fill: currentColor;
			stroke:currentColor;
			position: relative;
			top: 2px;
		}

		&.saved {
			color: @color-complementary-green;
		}

		&[disabled], &.saved[disabled] {
			color: @gray-light;
		}
	}
}
