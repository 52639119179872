#insights {

	.donut-chart {
		margin-top: 11px;
		display: inline-block;

		text {
			&.value {
				font-size: 30px;
				font-weight: 300;
				line-height: 36px;
			}
		}
	}
}

