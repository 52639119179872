@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.split-panel {
	display: flex;
	flex: 1 1 auto;
	padding-top: 10px;

	.copy {
		min-height: 50px;
	}

	.header {
		padding-bottom: 10px;
	}

	.download-buttons {
		display: flex;
	}

	.panel {
	}

	.download-section-left {
		flex: 1 1 auto;
	}

	.download-section-right {
		flex: 1 1 auto;
	}

	& > .sub-panel {
		padding-right: @panel-body-padding;
		display: flex;
		flex-grow: 1;
		flex-direction: column;

		&:last-of-type {
			border-left: 1px solid @panel-inner-border;
			padding-left: @panel-body-padding;
		}
	}

	.button-group {
		margin-bottom: 20px;
	}

	@media @mobile {
		display: block;

		& > .sub-panel {
			margin: auto -@panel-body-padding-mobile;
			width: auto;
			padding: @panel-body-padding;

			&:last-of-type {
				border-left: none;
				border-top: 1px solid @panel-inner-border;
			}
		}
	}

	.tooltip-icon {
		fill: @gray-light;
		vertical-align: middle;
	}
}

.panel-row {
	padding-top: 0 !important;
}
