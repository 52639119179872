@import "../../bootstrap-variables";

.transaction-import-summary-title {
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	@media @small {
		display: block;
	}

	.delete-import-btn {
		@media @small {
			margin-bottom: @line-height-computed / 2;
		}
	}

	& > h1 {
		@media @small {
			margin-top: @line-height-computed / 2;
		}
	}
}

.transaction-import-summary-subtitle {
	display: flex;

	@media @small {
		display: block;
	}

	& > li:not(:last-child):after {
		content: '|';
		margin-left: @padding-base-horizontal;
		margin-right: @padding-base-horizontal;

		@media @small {
			display: none;
		}
	}
}

.transaction-import-summary-panel {
	@summary-panel-margin-mobile: -@grid-gutter-width + 8px;
	@summary-panel-row-padding-vertical: 15px;


	@media @small {
		margin-left: @summary-panel-margin-mobile;
		margin-right: @summary-panel-margin-mobile;
		overflow: hidden;
	}

	&.panel-color {
		.panel-footer {
			background-color: white;
		}

		.panel-heading {
			padding: @panel-heading-padding;
			text-align: left;

			@media @small {
				padding: @panel-heading-padding-mobile;
			}
		}
	}

	.summary-heading {
		font-size: @font-size-base-minus;

		.summary-title {
			font-size: @font-size-h2;

			@media @small {
				font-size: @font-size-base * 1.5;
			}
		}

		.summary-description {
			margin-top: @padding-large-vertical;
		}
	}

	.summary-panel-body {
		padding: @grid-gutter-width/4 @grid-gutter-width*2;
		font-size: @font-size-base-minus;

		@media @small {
			padding-left: @grid-gutter-width;
			padding-right: @grid-gutter-width;
		}

		.summary-panel-row {
			padding-top: @summary-panel-row-padding-vertical;
			padding-bottom: @summary-panel-row-padding-vertical;
			border-bottom: 1px solid @gray-lighter;

			&:last-child {
				border-bottom: none;
			}

			.summary-panel-row-label {
				font-size: @font-size-base * 1.3;
				margin-bottom: @padding-small-vertical;

				@media @small {
					font-weight: bold;
					font-size: @font-size-base-plus;
				}
			}

			.summary-panel-row-value {
				font-size: @font-size-base * 1.5;
				text-align: right;

				@media @small {
					font-size: @font-size-base-plus;
				}

				.dollar {
					font-size: 0.7em;
					vertical-align: super;
				}
			}

			.summary-panel-row-value-small {
				font-size: @font-size-base-plus;
				text-align: right;

				.dollar {
					font-size: 0.7em;
					vertical-align: super;
				}
			}
		}

		.summary-panel-row-send-email {
			display: flex;
			align-items: center;
			padding-top: @summary-panel-row-padding-vertical;
			padding-bottom: @summary-panel-row-padding-vertical;

			.info-link {
				margin-left: @padding-xs-horizontal;
			}
		}
	}
}
