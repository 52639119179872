@import "../../bootstrap-variables";

@vt-payments-grid-num-columns: 8;
@vt-payments-grid-cell-left-mobile-padding: @table-cell-padding-horizontal;
@vt-payments-paymentstatus-width: 115px;
@vt-payments-paymentstatus-width-mobile: 32px;
@vt-payments-amount-width: 110px;
@vt-payments-actions-width: 75px;
@vt-payments-paymentstatus-icon-size: 22px;
@vt-payments-payment-details-body-padding: 25px;

.vt-payments {
	margin-top: 30px;

	&-grid {
		margin-top: @grid-gutter-width;

		@media @mobile {
			width: 100vw;
			overflow: hidden;
			margin-left: -@grid-gutter-width;
			margin-right: -@grid-gutter-width;
		}

		.data-grid {
			margin-bottom: @grid-gutter-width;
		}

		.data-grid-cell {
			flex: 1 1 unit(100/@vt-payments-grid-num-columns, %);

			&.data-grid-cell-empty {
				@media @mobile {
					visibility: hidden;
					padding: 0;
				}
			}
		}

		.data-grid-cell-paymentstatus {
			flex: 0 0 @vt-payments-paymentstatus-width;

			@media @mobile {
				flex-basis: @vt-payments-paymentstatus-width-mobile;
				.text-center;
			}

			@media print {
				flex-basis: @vt-payments-paymentstatus-width-mobile;
				.text-center;
			}
		}

		@media print {
			.data-grid-row-header .data-grid-cell-paymentstatus {
				color: transparent;
			}
		}

		.data-grid-cell-payerdisplayname {
			@media @mobile {
				flex-basis: ~"calc(40% - @{vt-payments-paymentstatus-width-mobile})";
				padding-left: @vt-payments-grid-cell-left-mobile-padding;
			}

			@media @small {
				flex-basis: ~"calc(100% - @{vt-payments-paymentstatus-width-mobile} - @{vt-payments-amount-width})";
			}
		}

		.data-grid-cell-paymentmethod {
			@media @mobile {
				order: 2;
			}

			@media @small {
				order: 6;
			}
		}

		.data-grid-cell-funddisplayvalue {
			@media @mobile {
				order: 3;
			}

			@media @small {
				flex-basis: ~"calc(40% - @{vt-payments-paymentstatus-width-mobile})";
				padding-left: @vt-payments-grid-cell-left-mobile-padding;
				margin-left: @vt-payments-paymentstatus-width-mobile;
			}
		}

		.data-grid-cell-amountasmoney {
			flex: 0 0 @vt-payments-amount-width;
			.text-right;

			@media @mobile {
				order: 4;
			}

			@media @small {
				order: 2;
			}
		}

		.data-grid-cell-editurl {
			flex: 0 0 @vt-payments-actions-width;
			color: @btn-link-color;
			.text-right;
			.hidden-print;

			@media @mobile {
				flex: auto;
				order: 5;
			}
		}

		&-action-icon {
			position: relative;
			top: 3px;
			.square(16px);
			stroke: currentColor;
			fill: currentColor;
		}
	}

	&-grid .data-grid-row-data .data-grid-cell-paymentstatus,
	&-payment-details-paymentstatus {
		font-size: @font-size-small;

		.icon {
			.square(@vt-payments-paymentstatus-icon-size);
			fill: currentColor;
			vertical-align: bottom;
			margin-right: 6px;

			@media @mobile {
				margin-right: 0;
			}
		}
	}

	.data-grid-row-new &-payment-details .data-grid-row-data {
		background: none;
		border-color: @border-color;
	}

	&-payment-details {
		.panel-heading {
			position: relative;
			padding: 0;
			font-size: @font-size-base;
			font-weight: normal;
			border: none;
		}

		&-title {
			margin-top: 0;

			@media @mobile {
				.text-center;
				border-bottom: 1px solid @panel-inner-border;
				margin-bottom: 0;
				line-height: 49px;
			}
		}

		.data-grid-row-data {
			padding-left: 15px;
			padding-right: 15px - @table-cell-padding-horizontal;
			border-top: none;

			@media @mobile {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.data-grid-row:nth-child(even) & {
			.data-grid-row-data {
				background: none;
			}
		}

		.data-grid-cell-closegift {
			flex: 0 0 @vt-payments-actions-width + @table-cell-padding-horizontal;
			.text-right;
			padding: 0;
			.hidden-print;

			@media @mobile {
				flex-basis: 0;
				order: 5;
			}
		}

		&-close-btn {
			padding: @table-cell-padding @table-cell-padding-horizontal;
			line-height: @line-height-base;
			height: auto;
			color: @gray-mid;

			@media @mobile {
				position: absolute;
				top: 0;
				right: @grid-gutter-width - @table-cell-padding-horizontal;
			}
		}

		&-close-icon {
			fill: currentColor;
			.square(18px);
			vertical-align: text-top;
		}

		.panel-body {
			position: relative;
			padding: @vt-payments-payment-details-body-padding;

			@media @mobile {
				padding: @grid-gutter-width;
			}

			.loading-box {
				top: 50%;
				margin-top: -((100px + @padding-base-vertical + @line-height-computed)/2);
			}
		}

		&-details-loading {
			min-height: 220px;
		}

		&-column-payment {
			@media @desktop {
				border-right: 1px solid @panel-inner-border;
			}

			@media @mobile {
				border-bottom: 1px solid @panel-inner-border;
				margin-bottom: @grid-gutter-width;
			}
		}

		&-column-payer {
			@media @desktop {
				border-left: 1px solid @panel-inner-border;
				margin-left: -1px;
				padding-left: @vt-payments-payment-details-body-padding + 1;
			}
		}

		&-item {
			margin-bottom: 15px;
			word-wrap: break-word;
		}

		&-amount, &-asset-type {
			@media @desktop {
				font-size: @font-size-large-plus;
				line-height: 1;

				&-number {
					font-weight: 300;
				}
			}
		}

		&-description-for-merchant {
			white-space: pre-wrap;
		}
	}

	/** Grid Override --------------------------------------------------------------
	* This block overrides flexbox with grid if available
	*/

	&-grid {
		.data-grid-row {
			&-header,
			&-data {
				grid-template-columns:
					@vt-payments-paymentstatus-width
					minmax(0, 2fr)
					minmax(0, 3fr)
					repeat(2, minmax(0, 2fr))
					@vt-payments-amount-width
					@vt-payments-actions-width;

				@media @mobile {
					grid-template-columns:
						@vt-payments-paymentstatus-width-mobile
						minmax(0, 2fr)
						repeat(2, minmax(0, 1fr))
						@vt-payments-amount-width
						@vt-payments-actions-width;
				}

				@media @small {
					grid-template-columns:
						@vt-payments-paymentstatus-width-mobile
						minmax(0, 1fr)
						min-content;
					grid-template-rows: repeat(2, auto);
				}
			}
		}

		.data-grid-cell-payerdisplayname {
			grid-column: 2;
		}

		.data-grid-cell-payerdisplayemail {
			grid-column: 3;
		}

		.data-grid-cell-paymentmethod {
			grid-column: 4;

			@media @mobile {
				grid-column: 3;
			}
		}

		.data-grid-cell-funddisplayvalue {
			grid-column: 5;

			@media @mobile {
				grid-column: 4;
			}

			@media @small {
				grid-column: 2;
				grid-row: 2;
				margin-left: 0;
			}
		}

		.data-grid-cell-amountasmoney {
			grid-column: 6;

			@media @mobile {
				grid-column: 5;
			}

			@media @small {
				grid-column: 3;
			}

			@media print {
				grid-column: 7;
			}
		}

		.data-grid-cell-editurl {
			grid-column: 7;

			@media @mobile {
				grid-column: 6;
			}

			@media @small {
				grid-column: 3;
				grid-row: 2;
			}
		}
	}

	&-payment-details {
		.data-grid-row-data {
			grid-template-columns:
					@vt-payments-paymentstatus-width
					minmax(0, 2fr)
					minmax(0, 3fr)
					repeat(2, minmax(0, 2fr))
					@vt-payments-amount-width
					@vt-payments-actions-width + @table-cell-padding-horizontal;

			@media @mobile {
				grid-template-columns:
					@vt-payments-paymentstatus-width-mobile
					minmax(0, 1fr)
					@vt-payments-amount-width
					0;
			}
		}

		.data-grid-cell-amountasmoney {
			grid-row: 1;

			@media @mobile {
				grid-column: 3;
			}
		}

		.data-grid-cell-closegift {
			grid-column: 7;

			@media @mobile {
				grid-column: 4;
			}
		}
	}
}
