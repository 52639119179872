@import "../bootstrap-variables";
@import "../mixins/helpers.less";

.tipbox {
	position: relative;
	clear: both;
	margin-bottom: @grid-gutter-width * 2;
	@action-button-lineheight: 16px;
	min-width: @tipbox-min-width;

	@media @mobile {
		margin-bottom: @grid-gutter-width;
	}

	&.dismissed {
		margin-bottom: 0;

		.btn-dismiss,
		.tipbox-content,
		.tipbox-content.with-icon {
			display: none;
		}
	}

	&:not(.dismissed) {
		.btn-show-help {
			display: none;
		}
	}

	.tipbox-content {
		border: 1px solid @panel-default-border;
		border-radius: @border-radius-base;
		background-color: @tipbox-bg;
		font-weight: 300;
		color: @tipbox-default-text;
		padding: @tipbox-padding-vertical @tipbox-padding-horizontal;
		position: relative;
		box-shadow: 0 1px 1px rgba(0,0,0,.05);
		&:extend(.clearfix all);

		@media (max-width: @screen-xs-max) {
			padding: @tipbox-padding-mobile-vertical @tipbox-padding-mobile-horizontal;
		}

		&.no-header {
			padding-top: @tipbox-padding-vertical + @action-button-lineheight;
		}

		&.with-icon {
			display: flex;

			@media (max-width: @screen-xs-max) {
				display: block;
			}
		}

		&.centered {
			text-align: center;
		}

		.tipbox-icon {
			margin-right: @tipbox-padding-horizontal;
			flex-shrink: 0;
			@media (max-width: @screen-xs-max) {
				margin-right: 0;
				text-align: center;
				margin-top: 30px;
			}

		}


		.tipbox-ags-launch-banner-icon {
			.square(100px);
			.round(50%);
			background:@color-complementary-green;
			display: inline-block;

			.svg(64px, 64px);

			.svg{
				color: @color-complementary-green;
				margin: 18px;
			}
		}

		.tipbox-header {
			.h3;
			color: @text-color;
			margin-top: 0;

			@media (max-width: @screen-xs-max) {
				text-align: center;
				margin-top: 30px
			}
		}

		h1,h2,h3,h4,h5,h6 {
			color: @text-color;
		}

		&.extreme-edge {
			padding: 0;
			overflow: hidden;
		}

	}

	.btn-dismiss {
		position: absolute;
		top: 12px;
		right: 12px;
		line-height: @action-button-lineheight;
	}

	.btn-show-help {
		.btn-sm;
		.btn-gray-link;
		padding: 0;
		height: auto;
		line-height: @action-button-lineheight;
		font-size: @font-size-base;
		font-weight: 300;
		margin-bottom: @line-height-computed;
	}
}

.tipbox-prep {
	.prep-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin: @padding-base-horizontal 0 @padding-base-horizontal 0;

		@media @small {
			flex-direction: column;
		}

		.circled-svg-md {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: none;
			.colored-circle(@icon-bg, 50px);
			padding: @grid-gutter-width / 2;

			.svg {
				color: white;
				fill: white;
				stroke: none;
				height: @line-height-computed;
				width: @line-height-computed;
			}

			.svg-recurring {
				width: 32px;
				height: 32px;
			}
		}

		.prep-text {
			margin-left: @padding-base-horizontal;

			@media @small {
				margin-left: 0;
				margin-top: @padding-base-vertical;
			}
		}
	}
}
