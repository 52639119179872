@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

:global(.community-member-type-transition) {
	&-enter {
		opacity: 0.01;
	}

	&-enter&-enter-active {
		opacity: 1;
		transition: opacity 200ms linear;
	}

	&-leave {
		position: absolute;
		opacity: 1;
	}

	&-leave&-leave-active {
		position: absolute;
		opacity: 0.01;
		transition: opacity 200ms linear;
	}
}

.community-member-type-transition-wrapper {
	display: flex;
	flex-direction: row;
	position: relative;
	overflow: hidden;
}
