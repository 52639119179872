@import "../../bootstrap-variables";

.transaction-import-header {
	@header-line-horizontal-offset: 40px;
	@step-circle-size: 30px;

	display: flex;
	justify-content: space-between;
	margin-bottom: @panel-body-padding;
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		border-bottom: 4px solid @gray-light;
		width: ~'calc(100% - @{header-line-horizontal-offset} * 2)';
		left: @header-line-horizontal-offset;
		top: @step-circle-size / 2;
		z-index: -1;
	}

	.import-step {
		color: @gray-light;

		.step-circle-wrapper {
			background-color: @body-bg;
			width: @step-circle-size + @padding-small-horizontal * 2;
			margin: auto;
		}

		.step-circle {
			border: 2px solid @gray-light;
			border-radius: 50%;
			width: @step-circle-size;
			height: @step-circle-size;
			margin: auto;
			text-align: center;
		}

		&.selected {
			font-weight: bold;
			color: @gray-mid;

			.step-circle {
				color: white;
				background-color: @gray-mid;
				border-color: @gray-mid;
			}
		}
	}

	@media @small {
		.step-text {
			display: none;
		}
	}
}
