@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.merchants-title {
	font-size: @font-size-base-minus;
	font-weight: bold;
}

.payment-batches-title {
	font-size: @font-size-base-minus;
	font-weight: bold;
}

.batch-listing-text {
	font-size: @font-size-base;
}
