@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@column-gap: @grid-gutter-width / 2;

.container {
	max-width: 1200px;
}

.header {
	margin-bottom: 35px;
}

.add-a-pledge-form {
	margin-bottom: 40px;
}

.grid-heading {
	margin-bottom: 20px;
}

.amount-container {
	&:before {
		position: absolute;
		top: 42px;
		font-size: @font-size-base-plus;
		z-index: 5;
		content: '$';

		[data-field-invalid]& {
			color: @color-red-validation;
		}
	}
}

.amount-input {
	font-size: @font-size-h1;
	border: 0;
	padding: 0;
	margin-left: 14px;
}

.add-member-button-wrapper {
	margin-top: 45px;
	text-align: right;

	@media @small {
		margin-top: 0;
		text-align: left;
		margin-bottom: 10px;
	}
}

.add-member-button {
	padding-left: 0;
	padding-right: 0;
}

.add-member-icon {
	width: 24px;
	height: 24px;
	margin-right: 5px;
	margin-bottom: 2px;
	fill: currentColor;
}

.form-buttons {
	display: flex;
	align-items: flex-start;
	@media @small {
		align-items: stretch;
		flex-direction: column-reverse;
	}
}

.reset {
	@media not @small {
		margin-right: @column-gap;
	}
}

.add {
	margin-bottom: @grid-gutter-width;
}
