@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@tipbox-icon-size: 50px;

.tip {
	display: flex;
	margin-top: @grid-gutter-width;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: none;
	border-radius: 50%;
	width: @tipbox-icon-size;
	height: @tipbox-icon-size;
	background-color: @icon-bg;
	margin-right: @grid-gutter-width;

	&-svg {
		width: 24px;
		height: 24px;
		fill: #fff;
	}
}

.text {
	flex: auto;
}
