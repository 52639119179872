@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@column-padding: 50px;
@column-width: 160px;

.row-details {
	padding-left: 15px;
	padding-right: 15px;
	background-color: white;

	&:global(.data-grid-row-data) {
		border-top-color: @border-color;
		border-radius: @border-radius-base;
	}
}

// fix bg color of even-numbered rows
:global {
	.data-grid-row:nth-child(even) {
		:local {
			.row-details {
				background-color: white;
			}
		}
	}
}

.wrapper {
	padding: 35px;
	display: flex;
	background-color: @color-form;
	position: relative;
	min-height: 170px;
	border-color: @border-color;
	border-style: solid;
	border-width: 1px 0;

	@media @small {
		flex-wrap: wrap;
	}
}

.column {
	padding: 0 @column-padding;
	width: @column-width + 2 * @column-padding;

	&:first-child {
		padding-left: 0;
		border-right: 1px solid @border-color;
		width: @column-width + @column-padding;

		@media @small {
			border-right: none;
			border-bottom: 1px solid @border-color;
			margin-bottom: 20px;
		}
	}

	&:last-child {
		padding-right: 0;
		width: @column-width + @column-padding;
	}

	@media @small {
		flex-basis: 100%;
		padding: 0;
	}
}

.reference-item {
	margin: 0 0 20px;
}

.reference-item-title {
	margin: 0 0 6px;
	font-weight: 700;
	font-size: @font-size-base;
}

.reference-item-value {
	font-size: @font-size-base-plus;
}

.footer-btn-group {
	padding: 30px;
	text-align: center;

	& > :global(.btn) {
		margin: 0 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@media @small {
		display: flex;
		flex-wrap: wrap;

		& > :global(.btn) {
			flex-basis: 100%;
			margin: 0;
		}

		& > :global(.btn-ghost) {
			order: 1;
			margin-top: 20px;
		}
	}
}

.btn-delete {
	margin-left: auto;

	@media @small {
		position: absolute;
		right: 0;
	}
}
