#insights {
	.insights-vars;

	.decorated {
		sup {
			font-weight: 300;
			font-size: 50%;
			vertical-align: middle;
			line-height: 0;
			color: inherit;
		}
	}
}
