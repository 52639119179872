@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.conversion-link {
	vertical-align: middle;
}

.conversion-link-tooltip-icon {
	fill: @gray-light;
	vertical-align: middle;
}
