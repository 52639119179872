.navbar-collapse a {
	font-size:	@font-size-large;
}


.navbar-brand {
	.center-block;
	.sliding-hover(darken(@brand-primary, 10%));
	float:none;
}

.navbar-default {

	border: 0;

	.navbar-nav {
		@active-item-border: 4px;

		margin: 0;

		// when menu is open
		> .open > a {

			&,
			&:hover,
			&:focus {
				color: white;
				}
		}
		> li {
			> a {
				.sliding-hover(darken(@brand-primary, 10%));
				height: 60px;
				@media @desktop {
					padding-bottom: 12px;
				}
			}
			> .dropdown-menu {
				position: fixed;
				left: 0;
				right: 0;
				top: @navbar-height;
				border: 0;
				border-radius: 0;
				background-color: @color-form-alt;
				padding: 17px 0px;
				overflow-y: auto;
				.box-shadow-small;
				max-height: 194px;

				@media @desktop {
					top: @navbar-height - @active-item-border;
					max-height: 218px;
					max-width: 350px;
					right: auto;
				}

				li{
					a{
						color: @gray-dark !important; // forced override from BS
						.truncate;
						.no-wrap;
						line-height: 2.0;
						padding-top: 0px; // height is controlled by linehight
						padding-bottom: 0;
						padding-left: 16px;
						border-left: @active-item-border solid transparent;
						font-size: 16px;

						@media	@desktop{
							padding-top: 4px; // height is controlled by linehight
						}
					}
					h4{
						max-width: 100vw;
						.truncate;
						.no-wrap;

					}

					&.selected,
					&:active,
					&:hover {
						a {
							color: @brand-primary !important; // forced override from BS
							border-color: @brand-primary;
						}
					}
				}
			}
		}

		// item in navbar that is currently active / selected
		.active {
			color:  white;

			@media @desktop {
				.box-shadow(inset 0px -(@active-item-border) 0px 0px @color-form);
			}
		}
	}
}
