.cd-batch-open-portal-dialog-heading {
	margin-bottom: 26px;
}

.cd-batch-open-portal-dialog-list {
	list-style: none;
	padding-left: 0;
	margin-bottom: 24px;
}

.cd-batch-open-portal-dialog-list-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 18px;
	font-size: @font-size-base-minus;
}

.cd-batch-open-portal-dialog-list-circle {
	.colored-circle(@color-complementary-green, 50px);
	display: flex;
	flex: 0 0 auto;
	margin-right: 15px;
	align-items: center;
	justify-content: center;
}

.cd-batch-open-portal-dialog-list-icon {
	.square(24px);
	fill: white;
	stroke: none;

	&.icon-report {
		margin-left: 5px;
	}
}
