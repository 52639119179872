@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@amount-width: 170px;
@actions-width: 75px;
@delete-spinner-size: 18px;
@delete-icon-size: 16px;

.grid-row-with-totals {
	display: grid;
	grid-template-areas: 'payername payeremail datepledged campaignname amount totalgiven campaignprogress campaignremaining actions';
	grid-template-columns: 2fr 3fr 2fr 0 @amount-width 2fr 2fr 2fr @actions-width;

	@media @mobile {
		grid-template-areas: 'payername campaignname amount actions';
		grid-template-columns: 3fr 0 @amount-width @actions-width;
	}

	@media @small {
		grid-template-areas: 'payername amount';
		grid-template-columns: 2fr min-content;
	}
}

.grid-row {
	display: grid;
	grid-template-areas: 'payername payeremail datepledged campaignname amount actions';
	grid-template-columns: 2fr 3fr 2fr 0 @amount-width @actions-width;

	@media @mobile {
		grid-template-areas: 'payername campaignname datepledged amount actions';
		grid-template-columns: 2fr 2fr 0 @amount-width @actions-width;
	}

	@media @small {
		grid-template-areas: 'payername amount';
		grid-template-columns: 1fr min-content;
	}
}

.grid-row-with-campaign-name {
	grid-template-columns: 2fr 3fr 2fr 2fr @amount-width @actions-width;

	@media @mobile {
		grid-template-columns: 2fr 1fr 1fr @amount-width @actions-width;
	}

	@media @small {
		grid-template-columns: 1fr min-content;
	}
}

.column-campaignremaining {
	grid-area: campaignremaining;
}

.column-campaignprogress {
	grid-area: campaignprogress;
}

.column-campaigntotalgiven {
	grid-area: totalgiven;

	@media @mobile {
		text-align: right;
	}
}

.column-payerdisplaydatepledged {
	grid-area: datepledged;
}

.column-payerdisplayname {
	grid-area: payername;
}

.column-payerdisplayemail {
	grid-area: payeremail;
}

.column-campaignname {
	grid-area: campaignname;
}

.column-actions {
	grid-area: actions;
}

.column-amount {
	grid-area: amount;
	text-align: left;

	@media @mobile {
		text-align: right;
	}
}

.column-actions {
	position: relative;
	overflow: visible;
	text-align: center;
}

.delete-container {
	position: relative;
}

.delete-btn {
	color: @text-color;
	padding: 0;
	margin-top: -3px;
	height: @delete-icon-size;
	line-height: @delete-icon-size;
}

.delete-icon {
	width: @delete-icon-size;
	height: @delete-icon-size;
	fill: currentColor;
}

.delete-popup-content {
	padding: 10px 30px 20px;
	text-align: left;
	min-width: 280px;
}

.delete-popup-button {
	min-width: auto;
}

.delete-popup-loading {
	padding: 0;
}

.delete-popup-spinner {
	width: @delete-spinner-size;
	height: @delete-spinner-size;
}

.delete-popup-button,
.delete-popup-text {
	vertical-align: middle;
	line-height: 30px;
}
