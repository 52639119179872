.btn {
	&.down-arrow,
	&.right-arrow {

		&::after {
			font-family: @ionicons-font-family;
			font-style: normal;
			font-size: 25px;
			line-height: 20px;
			text-decoration: none;
			vertical-align: middle;
			display: inline-block;
			margin-top: -2px;
			padding-left: 5px;
		}
	}

	&.down-arrow {
		&::after {
			content: @ionicon-var-android-arrow-dropdown;
		}
	}

	&.right-arrow {

		&::after {
			content: @ionicon-var-android-arrow-dropright;
		}
	}
}
