@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';
@import '../../../../../Content/LoggedInWeb/bootstrap/mixins/labels.less';
@import '../shared-styles/styles.less';

.header {
	padding: 0;
}

.item-container {
	flex: 1;
	display: flex;
	align-items: center;
	cursor: pointer;

	@media @small {
		flex-direction: column;
		align-items: flex-start;
	}
}

.name {
	flex: 0 0 auto;
	font-size: 24px;
	line-height: 1.2;
	color: @brand-primary;
	font-weight: 300;
	margin: 0 20px 0 0;

	@media @small {
		margin: 0 0 10px 0;
	}
}

.status {
	.status-colors();
}

.count-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@media @small {
		display: none;
	}
}

.count {
	font-size: 24px;
	font-weight: 300;
}

.count-text {
	font-size: 16px;
	font-weight: 300;
}
