@import "../bootstrap-variables.less";
@import "../bootstrap/mixins/responsive-visibility";

.hidden-desktop {
	@media @desktop {
		display: none !important;
		visibility: hidden;
	}
}

.hidden-mobile {
	@media @mobile {
		display: none !important;
		visibility: hidden;
	}
}

.hidden-md-all {
	@media (min-width: @screen-md-min) {
		.responsive-invisibility();
	}
}


.hidden-lg-all {
	@media (max-width: @screen-lg) {
		.responsive-invisibility();
	}
}
