@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';
.header {
	display: flex;
	align-items: center;
}

.title {
	flex: auto;
	margin: 0;
}

.button-create {
	&-desktop {
		flex: none;
	}

	&-mobile {
		margin-bottom: @grid-gutter-width;
		margin-top: -@grid-gutter-width / 2;
		padding-left: 0;
	}
}

.footer {
	margin-top: @grid-gutter-width*2;
	border-top: 1px solid @border-color;
	border-bottom: 1px solid @border-color;
	color: @gray-mid;
	text-align: center;
	padding: @grid-gutter-width;

	@media @mobile {
		margin: @grid-gutter-width*2 -@grid-gutter-width 0;
	}
}

.link-help {
	padding: 0;
	line-height: inherit;
}
