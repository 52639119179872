@import "../../bootstrap-variables.less";

@radio-grid-border-radius: 4px;
@radio-grid-border-width: 1px;
@radio-grid-button-min-height: @btn-static-height;

.radio-grid {
	display: flex;
	flex-flow: wrap;
	margin-bottom: 5px;
	width: 100%;
	padding-top: 10px;
	margin-left: @radio-grid-border-width;

	label {
		.radio-grid-item;

		&:first-child > span {
			.radio-grid-item-first;
		}

		&:last-child > span {
			.radio-grid-item-last;
		}

		input[type=radio] {
			.radio-grid-input;

			& ~ span {
				.radio-grid-button;
			}

			&:checked {
				& ~ span {
					.radio-grid-button-checked;
				}

				&:focus {
					& ~ span {
						.radio-grid-button-focused;
					}
				}
			}
		}
	}
}

.radio-grid-item {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	margin: -@radio-grid-border-width; // to overlap borders
	padding: 0;
	font-weight: normal;
	cursor: pointer;
}

.radio-grid-item-first {
	border-top-left-radius: @radio-grid-border-radius;
	border-bottom-left-radius: @radio-grid-border-radius;
}

.radio-grid-item-last {
	border-top-right-radius: @radio-grid-border-radius;
	border-bottom-right-radius: @radio-grid-border-radius;
}

.radio-grid-input {
	position: absolute;
	margin-left: -20px;
}

.radio-grid-button {
	flex: auto;
	background-color: white;
	color: @brand-primary;
	padding: 10px 13px;
	min-height: @radio-grid-button-min-height;
	border: @radio-grid-border-width solid @input-border;
	text-align: center;
	line-height: 1.3;
	.transition(0.2s); // matches button

	&:hover {
		background-color: @brand-primary;
		color: white;
		border-color: @brand-primary;
	}
}

.radio-grid-button-checked {
	color: white;
	background-color: @gray-dark;
}

.radio-grid-button-focused {
	background-color: darken(@gray-dark, 10%);
	color: white;
}
