@import "../../mixins/round";
@import "../../bootstrap-variables";
@import "../../mixins/responsive";

.fund-list {
	@padding-list-horizontal-mobile: 20px;
	@padding-list-horizontal: 10px;
	@padding-list-item-vertical: 20px;

	@media @mobile {
		.make-row(@grid-gutter-width * 2);
	}

	.fund-list-tabs > li {
		@media @small {
			width: 50%;
		}
	}

	.filter-form, .form-group {
		margin: 0;
	}

	.sort-bar {
		border-bottom: 1px solid #ddd;
	}

	.select-wrapper:after {
	    border-left: 1px solid #ccc;
  	}

	.list-group {
		.list-group-item {
			.round(0);
			display: block;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			margin-bottom: 0;
			padding: @padding-list-item-vertical @padding-list-horizontal;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			.list-group-item-heading {
				display: flex;
				align-items: baseline;
				font-size: @font-size-h3;

				> div:first-child {
					flex: 1 1 auto;
					white-space: normal;
					word-break: break-word;
					margin-right: 5px;
					margin-left: -5px;

					> * {
						display: inline-block;
						margin-left: 5px;

					}

					@media (max-width: @screen-xs-max) {
						.fund-name {
							display: block;
						}
					}

				}

				.fund-name {
					&:hover {
						background-color: @theme_colors_common-transparent;
					}
				}

				.label {
					svg {
						width: 24px;
						height: 16px;
						margin-right: 10px;
						stroke: none;
						fill: white;
						vertical-align: middle;
					}

					& > span{
						vertical-align: middle;
					}
				}

				sup {
					font-size: 55%;
				}

				small {
					font-size: @font-size-base-plus;
					color: @gray-light;
				}

				a {
					font-weight: @btn-font-weight;
				}
			}

			@media (max-width: @screen-xs-max) {
				padding-left: @padding-list-horizontal-mobile;
				padding-right: @padding-list-horizontal-mobile;

				.list-group-item-heading,
				.list-group-item-text {
					display: block;
				}
			}

			.list-group-item-text {
				white-space: normal;
				word-break: break-word;
				line-height: @line-height-base;
			}
		}
		.list-group-item-unknown {
			background-color: @color-form;
		}
	}

	.btn {
		.hidden-xs;
		height: auto;
		line-height: normal;
		.btn-xs;
		.pull-right;
		padding: @padding-xs-vertical 0;
		font-size: @font-size-base;
		margin-top: 9px;

		@media (max-width: @screen-xs-max) {
			padding-left: @padding-list-horizontal-mobile;
			padding-right: @padding-list-horizontal-mobile;
		}
	}


	.fund-total-container {

		@media (max-width: @screen-xs-max) {
			margin-top: 10px;

			display: flex;
			flex-wrap: wrap;
			line-height: @line-height-computed;

			.fund-total {
				word-break: break-all;
				margin-right: 10px;
			}
		}
	}
	.fund-total-message {
		position: relative;
		height: 46px;
		padding-top: 10px;

		@media(max-width: @screen-xs-max) {
			text-align: center;
			padding-top: 20px;
		}

		@media(min-width: @screen-sm-min) {
			.pull-right;
		}

		> div {
			white-space: nowrap;
		}

		.fund-total-message-enter {
			opacity: 0.01;
		}
		.fund-total-message-enter-active {
			opacity: 1;
			transition: opacity 400ms ease-in 2000ms;
		}
		.fund-total-message-leave {
			opacity: 1;
			width: 220px;
			height: 50px;
			background: url(/Content/LoggedInWeb/assets/images/loading_details.gif);
			position: absolute;
			right: 0;
			top: -3px;

			@media(max-width: @screen-xs-max) {
				top: 8px;
				right: 50%;
				margin-right: -110px;
			}

			.fund-total-message-inner {
				display:none;
			}
		}
		.fund-total-message-leave-active {
			opacity: 0.01;
			transition: opacity 500ms ease-in 1500ms;
		}
		.fund-total-updated {
			color: @color-complementary-green;
		}
	}

	.fund-total {
		position: relative;
		font-size: @font-size-h2;
		font-weight: normal;
		line-height: 1;

		div + div {
			position: absolute;
			top: 0;
			right: 0;

			@media(max-width: @screen-xs-max) {
				right: auto;
				left: 0;
			}
		}

		.fund-total-enter {
			opacity: 0.01;
			color: @color-complementary-green;
		}
		.fund-total-enter-active {
			opacity: 1;
			color: inherit;
			transition: opacity 500ms ease-in 2000ms,
				color 1300ms ease-in 6500ms;
		}
		.fund-total-leave {
			opacity: 1;
		}

		.fund-total-leave-active {
			opacity: 0.01;
			transition: opacity 500ms ease-in 1500ms;
		}
	}
}
