@import "../../bootstrap-variables";
@import "../../mixins/mixins";

/*The Mixins generated for this page*/

.btn1-styles {
	background-color: @color-complementary-green;
	color: @btn-default-color;
}

.btn2-styles {
	background-color: @color-orange;
	color: @btn-default-color;
}

.btn3-styles {
	background-color: @color-complementary-red;
	color: @btn-default-color;
}

.btn4-styles {
	background-color: @color-yellow;
	color: @btn-default-color;
}

.btn5-styles {
	background-color: @color-blue;
	color: @btn-default-color;
}

.margin-header {
	margin-top: 24px;
}

.page-head.pillars {

	@media @mobile  {
		h1 {
			font-size: 28px;
			font-weight: 200;
			margin: 0;
		}
	}
}

.success-steps {

	.mobile-padding {

		@media @mobile {
		padding: 0 15px;
		}
	}

	.padding-bottom-section {

		@media @mobile  {
			padding-bottom: 45px;
		}
	}

	.btn-grey {
		background-color: @gray-lighter;
		margin: 0 auto;
		font-weight: 600;
		color: @btn-default-color;
		min-width: 0;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		a {
			color: @btn-default-color;
		}

		&:hover {
			background-color: @gray;
			cursor: pointer;
		}

		&.btn-1-complete {
			.btn1-styles;
		}

		&.btn-2-complete {
			.btn1-styles;

			&:hover {
				.btn2-styles;
			}
		}

		&.btn-3-complete {
			.btn1-styles;

			&:hover {
				.btn3-styles;
			}
		}

		&.btn-4-complete {
			.btn1-styles;

			&:hover {
				.btn4-styles;
			}
		}

		&.btn-5-complete {
			.btn1-styles;

			&:hover {
				.btn5-styles;
			}
		}

		@media @mobile {
			display: none;
		}
	}

	.pillar-container {
		display: flex;
		width: 100%;
		border-top: 1px solid @border-color;

		@media @mobile {
			.make-row(@grid-gutter-width);
			min-width: 100%;
			display: block;
			width: initial;
		}
	}

	.pillar-column {
		display: flex;
		padding: 60px 0;
		width: 20%;

		> a {
			display: flex;
			flex-direction: column;
			color: @text-color;
			width: 100%;
			padding: 0 10px;

			&:hover {
				color: @text-color;
			}

			&:hover, &:active, &:focus {
				text-decoration: none;
			}

			@media @mobile {
				display: inline-block;
				width: 100%;
				padding: 0;
			}
		}

		.pillar-image {
			@full-size: 160px;
			@mobile-size: 85px;

			position: relative;
			width: 100%;
			max-width: @full-size;
			margin: 0 auto;

			@media @mobile {
				width: @mobile-size;
				height: @mobile-size;
				float: left;
				margin: 10px 25px 10px 0px;
			}

			&.completed {
				.svg {
					color: white;
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;

					width: 100%;
					height: 100%;
					.round(@full-size / 2);
					background-color: rgba(14, 157, 75, .7);
					@media @mobile {
						width: @mobile-size;
						height: @mobile-size;
						.round(@mobile-size / 2);
					}
				}
			}

			img {
				display: block;
				width: 100%;

				@media @mobile {
					width: 85px;
					height: 85px;
				}
			}
		}
		p.heading {
			font-weight: normal;
			font-size: 18px;
			text-align: center;
			padding-top: 30px;

			@media @mobile {
				display: none;
			}
		}

		p.bold {
			font-weight: bold;
			font-size: 16px;
			text-align: center;
			padding-bottom: 18px;

			@media @mobile {
				text-align: left;
				margin: 0;
			}
		}

		p.hidden-mobile {
			flex: auto;

			@media @mobile {
				display: none;
			}
		}

		p.mobile-visible-only {
			display: none;

			@media @mobile {
				display: block;
				width: 100%;
			}
		}

		span.list {
			display: block;
			padding-bottom: 5px;
			padding-top: 10px;
			font-weight: 400;
		}

		&:hover {

			.btn-grey {

				&.btn-hover-1 {
					.btn1-styles;
				}

				&.btn-hover-2 {
					.btn2-styles;
				}

				&.btn-hover-3 {
					.btn3-styles;
				}

				&.btn-hover-4 {
					.btn4-styles;
				}


				&.btn-hover-5 {
					.btn5-styles;
				}

				&.completed {
					background-color: @color-green;
				}

				@media @mobile {
					display: none;
				}
			}
		}

		.btn-grey.completed {
			background-color: @color-green;
			color: @btn-default-color;
		}

		@media @mobile {
			padding: 10px 15px;
			border-bottom: 1px solid @border-color;
			width: 100%;
			display: inline-block;
		}
	}

	.open-msg {
		padding: 20px 0px 60px;
		margin: 0;
		line-height: 34px;
		text-align: left;

		@media @mobile {
			width: 100%;
			text-align: center;
			padding: 30px 0px 40px 0px;
			font-size: 21px;
			text-align: left;
		}
	}

	.pushpay-red-text {
		color: @color-red;
	}

	.single-section {

		.pad-bottom {
			padding-bottom: 7px;
		}

		@media @mobile {
			background: @color-form;
			border: 1px solid @border-color;
			margin-bottom: 45px;
			border-radius: 4px;
		}
	}

	.pillar-3-app-store-section {
		padding-left: 10px;

		@media @mobile {
			display: block;
			padding-left: 0;

			img {
				margin: 0 auto;
				width: 130px;
			}
		}
	}

	.btn-pad-bottom {
		padding-bottom: 30px;
		margin: 0;
	}

	.steps-border-bottom{
		border-bottom: 1px solid @border-color;

		@media @mobile {
			border-bottom: none;
		}
	}
}

.call-outs {
	padding: 55px 0;
	border-bottom: 1px solid @border-color;
	display: flex;

	h2 {
		display: none;

		@media @mobile {
			display: block;
			margin-top: 60px;
			margin-left: @grid-gutter-width;
			margin-right: @grid-gutter-width;
		}
	}

	@media @mobile {
		padding: 0;
		flex-wrap: wrap;
		border-bottom: none;
		margin: 0 -@grid-gutter-width;

	}

	.call-out-item {
		color: @text-color;
		text-decoration: none;
		display: flex;
		flex-direction: column;
		flex: 0 1 auto;
		text-align: center;
		padding: 0 @grid-gutter-width / 2;
		width: 100%;

		&:hover {
			text-decoration: none;
			.btn {
				color: @btn-ghost-hover-color;
				background-color: @btn-ghost-hover-bg;
				border-color: @btn-ghost-hover-border;
			}
		}

		@media @mobile {
			padding: 15px 0;
			border-bottom: 1px solid @border-color;
			flex-direction: row;
			text-align: left;
		}

		h2 {
			display: block;
			margin-left: 0;
		}

		.panel-icon {
			width: 80px;
			height: 80px;
			border-radius: 40px;
			background-color: @icon-bg;
			padding: 20px;
			margin: 0 auto;
			flex: 0 1 auto;
			align-self: flex-start;

			.svg {
				color: white;
			}

			&.ags .svg {
				color: @icon-bg;
			}

			&.vt {
				padding: 18px;
			}

			&.insights {
				padding: 22px;
			}

			@media @mobile {
				margin: 0 25px 0 15px;
			}
		}

		.summary {
			flex: 1 1 auto;
			padding: 0 0 15px;

			@media @mobile {
				padding: 0;

				h2 {
					margin-top: 0;
				}
			}
		}

		.call-out-action {
			flex: 0 1 auto;

			@media @mobile {
				display: none;
			}
		}

		&.full-width {
			flex-flow: row nowrap;
			text-align: left;
			max-width: 960px;

			.panel-icon {
				align-self: center;
			}

			.summary {
				align-self: center;
				margin: 0 @grid-gutter-width;

				@media @mobile {
					margin: 0;
				}
			}

			.call-out-action {
				align-self: center;
				margin: 0 @grid-gutter-width 0 0;
			}
		}
	}
}

.vt-grey-bg-mobile {
    padding: 45px 0 35px;
    border-bottom: 1px solid @gray-lighter;

	@media @mobile {
		background-color: @panel-bg;
		padding: 40px 0px 0px;
		.make-row(@grid-gutter-width);
	}

	p{
		&.vt-intro{
			padding-bottom: 30px;
			margin: 0;

			@media @mobile {
				padding: 0px 35px 30px;
			}
		}
	}

	.btn-vt {
		margin: 0;

		@media @mobile {
			padding: 0px 0px 30px;
		}
	}
}

.total-recurring-container {
	h2{
		padding-bottom: 30px;

		@media @mobile {
			font-size: 20px;
		}
	}

	p{
		padding-bottom: 18px;
	}

	.panel-max-width {
		max-width: 100%;
		margin-bottom: 36px;
	}
}

.individual-recurring-container {
	h2 {
		margin-bottom: 18px;
	}

	p{
		padding-bottom: 18px;
	}
}

.dashboard-tutorial-image {
	min-width: 450px;
	text-align: center;

	@media @mobile {
		min-width: 0;
	}
}

.dashboard-carousel-container .tutorial-description {
	flex-grow: 1;
}
