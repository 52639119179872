@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.inputs {
	display: flex;
}

.filter {
	flex: 1 1 auto;
	margin-left: 20px;

	&:first-child {
		margin-left: 0;
	}
}

.filter-title {
	font-size: @font-size-h4;
	font-weight: 500;
	border-bottom: 1px solid @gray-mid;
	padding-bottom: 15px;
	margin-top: 0;
	display: flex;
}

.filter-heading {
	margin: 0;
	padding: 0;
	font-size: @font-size-h4;
	font-weight: 500;
	flex: 1 1 auto;
}

.reset-button {
	font-size: @font-size-small;
	line-height: @font-size-h4;
	margin-left: auto;
	margin-right: 5px;
	flex: 0 0 auto;
	align-items: baseline;
	padding: 0;
	border: 0;
	background-color: transparent;
	outline: none;
}

.reset-button-text {
	display: inline-block;
	color: @gray-dark;
	border-bottom: 1px dashed currentColor;
	padding-bottom: 1px;
}

.reset-svg {
	width: 14px;
	height: 14px;
	margin-right: 9px;
	position: relative;
	top: 3px;
	fill: @gray;
}

.selected-filters {
	display: flex;
	flex-wrap: nowrap;
}

.selected-filter-item {
	margin-left: 25px;
	flex: 1 1 auto;
	width: 100%;

	&:first-child {
		margin-left: 0;
	}
}

.button-group {
	padding: 40px 0 0;

	& > * {
		display: inline-block;

		margin: 0 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@media @small {
		display: flex;
		flex-wrap: wrap;

		& > * {
			flex-basis: 100%;
			margin: 0;
		}

		& > *:last-child {
			margin-top: 20px;
		}
	}
}

@media @small {
	.inputs {
		flex-direction: column;
	}

	.filter {
		margin-left: 0;
	}

	.selected-filters {
		flex-direction: column;
		margin-left: -@panel-body-padding-mobile;
		margin-right: -@panel-body-padding-mobile;
	}

	.selected-filter-item {
		margin: 0;
	}
}
