
.footer {
    position: relative;
}

.back-link {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.icon {
	width: 8px;
	height: 14px;
	fill: currentColor;
	margin-right: 8px;
}

.delete-button {
	position: absolute;
	right: 0;
	bottom: 0;
}
