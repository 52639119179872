// Insights namespace is expected to be put on the body
// however this is not the case for the panels / cards
#insights {
	.insights-vars;

	header {

		.navbar {
			color: white;
			text-align: center;
			border: none;
			border-bottom: 1px solid @gray-lighter;
			margin-bottom: 0;
		}

		.row {
			.col-md-6 {
				text-align: center;

				&:first-child {
					line-height: @padding-base-vertical*8; 	// ~80px
				}

				&:last-child {
					line-height: @padding-base-vertical*6; 	// ~60px
				}
			}
		}



		.top-bar {
			background-color: @brand-primary;
			padding-top: @padding-base-vertical*2;
			padding-bottom: @padding-base-vertical*2;

			h1 {
				margin-top: @padding-base-vertical/2;
				margin-bottom: 0;
			}
		}

		.details {
			color: @gray-dark;
			//height: @padding-base-vertical*8;
			overflow: hidden;
			font-size: @font-size-base-plus;
			font-weight: 400;

			i {
				display: block;
				border-top: 1px solid @gray-lighter;
				height: 1px;
				margin-right: @padding-base-horizontal*2;
				margin-left: @padding-base-horizontal*2;

			}

			img {
				width: @padding-base-horizontal*6;
				height: @padding-base-vertical*6;
			}



		}

		.svg {
			color: white;
		}

		@media @desktop {
			.row {
				.col-md-6 {

					&:first-child {
						text-align: right;
						padding-right: @padding-base-horizontal*4;
					}

					&:last-child {
						text-align: left;
						padding-left: @padding-base-horizontal*4;
						line-height: @padding-base-vertical*8; 	// ~80px
					}
				}
			}

			.details {
				height: @padding-base-vertical*8;		// ~80px

				i {
					display: inline-block;
					position: relative;
					left: 40px;
					top: 15px;
					height: @padding-base-vertical*4;
					border-left: 1px solid @gray;
					width: 1px;
					margin: 0;
				}
			}
		}
	}

	main {
		margin-top: 40px;

		.heading {
			text-align: center;
			padding-bottom: 30px;

			* {
				font-weight: 300;
			}
		}
	}

	footer {
		padding-top: @padding-base-vertical*4;

		text-align: center;

		hr {
			max-width: @panel-max-width;
		}

		i {
			display: block;
		}

		.svg {
			color: @gray-light;
		}

		.address {
			color: @gray-light;
			font-weight: 300;
		}
	}

	&.liw {
		@media @mobile {
			margin-left: auto;
			margin-right: auto;
			max-width: @panel-max-width;
		}
	}
}

body#insights {
	padding-top: 0;
}
