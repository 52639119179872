@import 'donor-emails';
@import 'edit-email';
@import 'preview-statement';
@import 'send-and-download';
@import 'giving-statements-prep';
@import 'help-block';

.giving-statements {

	.summary-with-tooltip {
		display: flex;

		@media @mobile {
			display: block;
		}
	}

	.summary-tooltip {
		padding: 29px 0 0 7px;

		@media @mobile {
			display: none;
		}
	}

	.summary {
		display: inline;
	}

	.svg-information {
		fill: @gray-light;
		stroke: @gray-light;
	}

	.steps {
		display: flex;

		@media @mobile {
			flex-wrap: wrap;
		}


		a.step {
			&:hover, &:active, &:focus {
				.btn, .btn:focus {
					background-color: darken(@color-red, 10%);
					border-color: darken(@color-red, 10%);
					color: white;
				}
			}
		}

		.step {
			color: @text-color;
			text-decoration: none;
			display: flex;
			flex-direction: column;
			flex: 0 1 auto;
			text-align: center;
			padding: 0 @grid-gutter-width;
			width: 100%;
			min-width: 160px;
			position: relative;
			outline: none;

			&:before {
				content: '';
				display: block;
				width: 22px;
				height: 15px;
				position: absolute;
				top: 73px;
				left: -10px;
				background: url("/Content/LoggedInWeb/assets/images/ags/arrow-short.png") top left no-repeat;
			}

			&.step1:before {
				content: none;
			}

			@media @mobile {
				padding: 15px 0 15px @grid-gutter-width;
				border-bottom: 1px solid @border-color;
				flex-direction: row;
				text-align: left;

				&:before {
					content: none;
				}

				&:hover:before {
					color: @gray-mid;
				}

				&.disabled:before, &.disabled:hover:before {
					color: @gray-lighter;
				}

				&.step1 {
					border-top: 1px solid @border-color;
				}
			}


			@circleSize: 160px;
			@circleSizeSmall: 80px;

			.circled {
				flex: 0 0 auto;
				align-self: flex-start;
				width: @circleSize;
				height: @circleSize;
				margin-bottom: @circleSize/4;
				margin-left: auto;
				margin-right: auto;

				background-position: 50% 50%;
				background-size: contain;

				@media @mobile {
					width: @circleSizeSmall;
					height: @circleSizeSmall;
					margin: 0 @grid-gutter-width  0 0;
				}


				&.completed {

					:first-child {
						opacity: 0.9;
						position: absolute;
					}

					.tick {
						background-position: 50% 50%;
						background-repeat: no-repeat;
						background-image: url(/Content/LoggedInWeb/assets/images/ags/tick.png);
						background-size: initial;
						position: absolute;
					}
				}
			}

			&.step1 .circled {
				background-image: url(/Content/LoggedInWeb/assets/images/ags/step1.png);
			}

			&.step2 .circled {
				background-image: url(/Content/LoggedInWeb/assets/images/ags/step2.png);
			}

			&.step3 .circled {
				background-image: url(/Content/LoggedInWeb/assets/images/ags/step3.png);
			}

			&.step4 {
				.circled {
					background-image: url(/Content/LoggedInWeb/assets/images/ags/step4.png);
				}

				.mobile-message {
					display: none;
					color: @color-green;
				}

				@media @mobile {
					.desktop-message {
						display: none;
					}

					.mobile-message {
						display: block;
					}
				}
			}

			&.step5 .circled {
				background-image: url(/Content/LoggedInWeb/assets/images/ags/step5.png);
			}

			&.disabled  {
				cursor: not-allowed;

				.circled {
					filter: grayscale(1);
					background-color: @gray-lightest;
				}

				.btn {
					background-color: @gray-lightest;
					border-color: @gray-lightest;
					color: white;
				}
			}

			.summary {
				flex: 1 1 auto;
				padding: 0 0 15px;

				@media @mobile {
					padding: 0;
				}
			}

			.actions {
				position: relative;

				.progress-label {
					position: absolute;
					width: 100%;
					bottom: -3em;
				}

				label.ghost-label {
					flex-direction: column;
					line-height: 1.3em;
					margin: 0;

					@media (max-width: @screen-lg) {
						font-size: 16px;
					}
				}

				@media @mobile {
					display: none;
				}
			}

			.btn {
				width: 100%;
				min-width: 0;
				padding: 0;

				@media @mobile {
					display: none;
					visibility: hidden;
				}
			}
		}
	}
}
