@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.button {
	// This needs to be nested in a `.button` class due to specificity wars, and it losing it's dark red background
	// colour.
	&.button-loading {
		background-color: @btn-default-bg;
		color: @btn-default-color;
		pointer-events: none;

		fieldset[disabled] & {
			opacity: 1;
			background-color: @btn-default-bg;
			color: @btn-default-color;
		}
	}
}

.button-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
