.circled {
	border-radius: 50%;

	&.red {
		background-color: @color-red;
	}

	&.yellow {
		background-color: @color-yellow;
	}

	&.green {
		background-color: @color-complementary-green;
	}

	&.blue {
		background-color: @color-blue;
	}

	&.orange-darker {
		background-color: @color-orange-darker;
	}

	&.pink {
		background-color: @color-purple-lighter;
	}

	svg {
		margin: 0 auto;
		display: block;
	}
}

&.circled-lg {
	width: 80px;
	height: 80px;
	padding: 18px;

	.circled;
}

&.circled-md {
	width: 50px;
	height: 50px;
	padding: 15px;

	.circled;
}
