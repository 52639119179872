@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@dqs-border-width: 1px;
@default-bg-color: white;
@default-color: @brand-primary;
@active-bg-color: @gray-dark;
@active-color: white;
@hover-bg-color: @brand-primary;
@hover-color: white;
@disabled-bg-color: @btn-ghost-disabled-bg;
@disabled-color: @btn-ghost-disabled-border;

.date-quarter-selector {
	display: flex;
	box-sizing: border-box;
	position: relative;
	align-items: center;

	border-radius: @border-radius-base;

	@button-line-height: 1.2;

	&-label {
		line-height: @button-line-height;
		flex: 2 1 25%;
		display: flex;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		border: @dqs-border-width solid @btn-default-border;
		background-color: @default-bg-color;
		color: @default-color;
		margin: 0;
		padding: 13px 10px;
		text-align: center;
		z-index: 1;
		cursor: pointer;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

		&:nth-of-type(1) {
			border-radius: @border-radius-base 0 0 @border-radius-base;
		}
		&:nth-of-type(2) {
			margin-left: -@dqs-border-width;
			&.is-active {
				border-left-color: white;
			}
		}
		&:nth-of-type(3) {
			margin-left: -@dqs-border-width;
			&.is-active {
				border-left-color: white;
			}
		}
		&:nth-of-type(4) {
			border-radius: 0 @border-radius-base @border-radius-base 0;
			margin-left: -@dqs-border-width;
			&.is-active {
				border-left-color: white;
			}
		}

		&:hover {
			z-index: 2;
			color: @hover-color;
			background-color: @hover-bg-color;
		}

		&.is-active {
			z-index: 3;
			border-color: @active-bg-color;
			color: @active-color;
			background-color: @active-bg-color;
			border-color: @active-bg-color;
		}

		&.is-disabled {
			z-index: 0;
			border-color: @disabled-color;
			color: @disabled-color;
			background-color: @disabled-bg-color;
			cursor: not-allowed;
		}

		&.is-active.is-disabled {
			color: @active-color;
			background-color: @gray-lighter;
		}
	}

	&-checkbox {
		position: absolute;
		left: -9000px;
		opacity: 0;
	}
}

@media @small {
	.date-quarter-selector {
		flex-wrap: wrap;
		&-label {
			flex: 1 50%;
			&:nth-of-type(1) {
				border-radius: 0;
				border-top-left-radius: @border-radius-base;
				margin-left: 0;
				&.is-active {
					border-color: @active-bg-color;
				}
			}
			&:nth-of-type(2) {
				border-radius: 0;
				border-top-right-radius: @border-radius-base;
				margin-left: -@dqs-border-width;
				&.is-active {
					border-color: @active-bg-color;
					border-left-color: white;
				}
			}
			&:nth-of-type(3) {
				border-radius: 0;
				border-bottom-left-radius: @border-radius-base;
				margin-top: -@dqs-border-width;
				margin-left: 0;
				&.is-active {
					border-color: @active-bg-color;
					border-top-color: white;
				}
			}
			&:nth-of-type(4) {
				border-radius: 0;
				border-bottom-right-radius: @border-radius-base;
				margin-left: -@dqs-border-width;
				margin-top: -@dqs-border-width;
				&.is-active {
					border-color: @active-bg-color;
					border-top-color: white;
					border-left-color: white;
				}
			}
		}
	}
}
