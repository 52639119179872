.pushpay-accounts {
	.lacking-id-filter {
		@media @desktop {
			margin-top: 28px;
		}
	}

	.col-total-transactions,
	.col-total-amount,
	.col-pay {
		text-align: right;
	}

	.col-edit,
	.col-pay {
		@media not @small {
			width: 1%;
			white-space: nowrap;
		}
	}

	.edit-link {
		display: flex;
		margin-top: -5px;
	}
}

.edit-pushpay-account-action-buttons-wrap {
	flex-wrap: wrap;
	align-items: center;
}