@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@icon-size: 16px;
@icon-border-size: 3px;
@icon-color: @gray-dark;
@action-gutter-width: 4px;
@action-animation: 0.8s ease-out;
@action-height-mobile: 45px;
@action-margin-left: 25px;

.action {
	position: relative;
	color: @gray-mid;
	font-size: @font-size-base-minus;

	&:hover,
	&:focus {
		text-decoration: none;
		color: @btn-link-hover-color;
		cursor: pointer;
	}

	& + .action {
		margin-left: @action-margin-left;

		&.major-action,
		&.share-link {
			margin-left: auto;
		}
	}

	&.add-pledge-link + &.share-link {
		margin-left: @action-margin-left;
	}
}

.icon {
	margin-left: 5px;
	height: @icon-size;
	width: @icon-size;
	vertical-align: text-bottom;
	fill: currentColor;
}

.major-action {
	color: @btn-link-color;
	font-weight: bold;

	&:hover {
		color: @btn-link-hover-color;
	}
}

.shared-indicator {
	position: fixed;
	transform: translate(10px, 10px);
	padding: 3px 6px;
	border-radius: @border-radius-base;
	background-color: @color-form;
	color: @gray-mid;
	z-index: 5;
	box-shadow: 0 0 2px 0 @gray-dark;
}

@media @small {
	.action {
		flex-basis: ~'calc(50% - @{action-gutter-width})';
		height: @action-height-mobile;
		border-radius: @border-radius-base;
		margin-top: @action-gutter-width * 2;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;

		&:not(.major-action) {
			background-color: @color-form;

			&:hover {
				background-color: darken(@color-form, 10%);
			}
		}

		&.major-action {
			border: 1px solid @btn-link-border;
		}

		&.add-pledge-link {
			order: 5;
			flex-basis: 100%;
		}

		& + .action {
			margin-left: auto;
		}

		& + .action,
		&.add-pledge-link + &.share-link {
			margin-left: 0;
		}
	}
}
