@waiting-indicator-width: 32px;

.waiting-indicator() {
	position: relative;
	padding-right: 1.5em;

	&::after {
		position: absolute;
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
		animation: waiting steps(4,end) 1s infinite;
		content: " ...";
		white-space: pre;
		width: 0.3em;
	}
}

@keyframes waiting {
	to {
	  width: 1.5em;
	}
}