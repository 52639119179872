@import "../bootstrap-variables";

@label-padding-vertical: 5px;
@label-padding-horizontal: 9px;
@label-font-size: @font-size-base * 2/3;
@label-line-height-computed: 1.2 * @label-font-size;
@label-large-height: 44px;

.label {
	font-size: @font-size-base * 2/3;
	font-weight: normal;
	line-height: @label-line-height-computed;
	padding: @label-padding-vertical @label-padding-horizontal;
	border-radius: @border-radius-base;
	text-transform: uppercase;
	letter-spacing: 1px;
	vertical-align: middle;
	display: inline-block;
}

.label-with-border(@color, @background-color, @border-width: 1px) {
	border: @border-width solid @color;
	padding: @label-padding-vertical - @border-width @label-padding-horizontal - @border-width;
	background-color: @background-color;
	color: @color;
}

.label-content-before(@content) {
	@before-size: 10px;

	&:before {
		content: @content;
		text-align: center;
		font-weight: bold;
		margin-right: @label-padding-horizontal;
		font-size: @font-size-small;
		width: @before-size;
		height: @before-size;
		float: left;
		line-height: @label-line-height-computed;
	}
}

.label-inprogress {
	.label-variant(@label-inprogress-bg);
}

.label-danger-secondary {
	.label-with-border(@brand-primary, white);
}

.label-success {
	> .icon {
		color: @label-success-bg;
	}
}

.label-success-secondary {
	.label-with-border(@color-complementary-green, white);
}

.label-default-secondary {
	.label-with-border(@gray-mid, white);
}

.label-closed {
	.label-with-border(white, @gray-mid);
}

.label-inverse {
	.label-with-border(@gray-mid, white);
}

.label-large {
	height: @label-large-height;
	border-radius: @label-large-height/2;
	padding: 0 @label-large-height/2;
	font-size: @font-size-base-plus;
	line-height: @label-large-height;
	font-weight: 700;
	text-transform: none;
	letter-spacing: initial;

	> .icon {
		position: relative;
		.square(22px);
		margin-right: 5px;
		margin-top: -2px;
		fill: currentColor;
		vertical-align: middle;
	}
}

.ghost-label {
	background-color: transparent;
	border-radius: 4px;
	font-size: @font-size-small;
	font-style: italic;
	height: @btn-static-height;
	line-height: @btn-static-height;
	display: flex;
	flex-grow: 1;
	justify-content: center;

	&.green {
		color: @color-complementary-green;
		border: 1px solid @color-complementary-green;
	}
}

.loading-label {
	@loading-label-color: #29b574; /*Our loading gif doesnt currently match our color-complementary-green*/
	background-color: @loading-label-color;
	border: 1px solid @loading-label-color;
	border-radius: 4px;
	font-size: @font-size-base-plus;
	font-weight: bold;
	height: @btn-static-height;
	line-height: @btn-static-height;
	width: 100%;
	display: block;
	color: white;

	.loading-icon {
		margin-top: -7px;
		margin-right: 8px;
	}
}
