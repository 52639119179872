
.payer-search-omnibox {
	@omnibox-padding-horizontal: 40px;
	@omnibox-padding: 15px @omnibox-padding-horizontal;
	@omnibox-padding-mobile: 15px 20px;

	@btn-clear-size: 18px;
	@btn-clear-size-mobile: 44px;
	@btn-clear-size-padding-mobile: (@btn-clear-size-mobile - @btn-clear-size)/2;
	@btn-clear-spacing: @grid-gutter-width;
	@input-vertical-margin: 5px;
	@btn-clear-margin-top: -(@input-height-base/2 + @btn-clear-size/2 + @input-vertical-margin);

	position: relative;
	padding: @omnibox-padding;
	border: 1px solid @input-border;
	border-radius: @input-border-radius;
	background: #fff;
	//same border transition as on the form-control
  	.transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

	@media @mobile {
		padding: @omnibox-padding-mobile;
	}

	@media @small {
		border-radius: 0;
	}

	&.payer-search-omnibox-focused {
		border-color: @input-border-focus;
	}

	&.input-validation-error {
		border-color: @color-red-validation;
		&.payer-search-omnibox-focused{
     		border-color: darken(@color-red-validation, 10%);
		}
	}

	.input-substitution-text {
		position: absolute;
		display: block;
	}

	.form-control,
	.input-text-width-spacing,
	.input-substitution-text
	 {
		padding: 0;
		margin: @input-vertical-margin 0;
		border: 0;
		font-size: @font-size-large-plus;
		font-weight: 300;
		padding-right: @btn-clear-spacing + @btn-clear-size;

		&::-ms-clear {
			display: none;
		}
	}

	.input-text-width-spacing {
		display: inline-block;
		visibility: hidden;
		height: 0;
		max-width: 100%;
	}

	> .dropdown-menu {
		margin-top: -1px;
		margin-left: -1px;
		width: 390px;
		background: @color-form;
		padding-top: 0;
		display: flex;
		flex-flow: column nowrap;

		@media @small {
			width: 100%;
			margin-top: 0;
			margin-left: 0;
		}

		> .dropdown-menu-nested {
			border: 0;
			position: relative;
			overflow-y: auto;
			width: 100%;
			margin: 0;
			padding: 0;
			border-radius: 0;
			box-shadow: none;
			border-bottom: 1px solid @border-color;
			max-height: 335px;
			flex: 1 0 auto;

			> li:not(:last-child) {
				border-bottom: 1px solid @border-color;
			}

			.btn {
				border-left-width: 4px;
				padding: @omnibox-padding;
				height: auto;

				@media @mobile {
					padding: @omnibox-padding-mobile;
				}
			}
		}

		.dropdown-header {
			white-space: normal;
			padding: @omnibox-padding;

			> .field-validation-error {
				margin: 0;
			}
		}
	}

	.payer-search-add-new-payer-button {
		padding: @omnibox-padding 10px;
		width: 100%;
		position: relative;
		.text-left;
		flex: 0 0 auto;

		&:after {
			position: absolute;
			content: "";
			top: -1px;
			left: 20px;
			height: 1px;
			right: 20px;
			box-shadow: 0 -3px 10px 5px @gray-lighter;
			border-radius: 50%;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: @theme_colors_common-transparent;
		}
	}

	.payer-search-dropdown-item {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}

	.payer-search-dropdown-item-icon {
		display: flex;
		margin: 4px 20px 0 -20px;
		svg {
			width: 16px;
			height: 16px;
		}
	}

	.payer-search-dropdown-item-info {
		display: block;
		line-height: @line-height-base;
		overflow: hidden;
		text-overflow: ellipsis;

		.payer-search-dropdown-item-info-zipcode:not(:only-child) {
			margin-left: 15px;
		}
	}

	.payer-search-label {
		width: 100%;
		margin: 0;
	}

	.payer-search-info {
		font-size: @font-size-base-plus;
		font-weight: normal;
		color: @gray-mid;
		margin: 0 -@padding-base-horizontal;

		display: flex;

		> * {
			padding: 0 @padding-base-horizontal;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.payer-search-info-address {
			flex-shrink: 2;
			padding-right: 0;
		}

		.payer-search-info-postcode {
			flex-shrink: 0;
		}

		.payer-search-info-address + .payer-search-info-postcode {
			padding-left: 0;
		}
	}

	.payer-search-info-error {
		color: @color-red-validation;
	}

	&.check-reading {
		//hide input and show Reading check label...
		input {
			opacity: 0;
		}
	}

	.payer-search-clear-container {
		display: block;
		height: 0;
	}

	.payer-search-clear {
		.square(@btn-clear-size);
		padding: 0;

		position: absolute;
		margin-top: @btn-clear-margin-top;
		margin-left: -@btn-clear-spacing;

		.icon {
			width: @btn-clear-size;
			height: @btn-clear-size;
		}

		@media @mobile {
			.square(@btn-clear-size-mobile);
			padding: @btn-clear-size-padding-mobile;
			margin-top: @btn-clear-margin-top - @btn-clear-size-padding-mobile;
			margin-left: -(@btn-clear-spacing + @btn-clear-size-padding-mobile);
		}

		@media @small {
			right: @btn-clear-spacing - @btn-clear-size-padding-mobile;
		}
	}

	.payer-search-edit-btn {
		@media @small {
			float: right;
		}
	}

	.payer-search-edit-icon {
		.square(16px);
		margin-right: 4px;
	}
}
