@import "../../bootstrap-variables.less";
@import "../../bootstrap";

.reallocate {

	.reallocation-control {
		.form-horizontal {
			@media (max-width: @screen-xs-max) {
				.form-control,
				.control-label {
					margin-bottom: @filter-gutter-width;
				}
			}

			.form-group-fund {
				display: flex;

				> .select-wrapper, .btn {
					flex-grow: 1;
				}

				> .new-design-select-wrapper {
					&:after {
						padding-right: 25px;
					}
				}


				@media (max-width: @screen-xs-max) {
					flex-wrap: wrap;
				}

				@media (min-width: @screen-sm-min) {
					.btn {
						margin-left: 30px;
					}
				}
			}
		}
	}

	.advanced-filters-section:not(.is-expanded) {
		.filter-control {
			margin-right: 0;
		}
	}

	.step-header,
	.reallocation-control .control-label {
		.h3;
	}

	.reallocation-control .control-label {
		margin-top: 0;
		display: inline-block;
	}

	.grid-counter {
		color: @gray-mid;
		font-weight: normal;
	}

	.reference-field-row {
		&:not(:first-child) {
			padding-top: 10px;
		}
	}

	.see-detail {
		display: none;
	}

	.reset-btn {
		height: 34px; /*this just aim to make the dashed line to have a small pading*/
	}


	.filter-btn {
		font-weight: bold;
	}

	.active {
		border-color: @gray;
	}

	.reallocation-label {
		margin-left: 0px;
	}

	.search-input {
		.input-group-addon {
			border-right: none;
			background-color: white;
		}

		input {
			border-left: none;
			border-bottom-right-radius: @border-radius-base !important; //default radius here is a lot too strict
			border-top-right-radius: @border-radius-base !important;
		}
	}

	.advanced-filters-section-toogle {
		display: none;
	}
}
