@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.list-wrapper {
	padding: @padding-large-vertical * 1.5 0;
	border-bottom: 1px solid @border-color;
}

.item-list-heading {
	margin: 0 0 1em;
	line-height: 1;
	font-size: @font-size-base-plus;
	color: @gray-dark;
	font-weight: 400;
}

.item-list {
	display: flex;
	list-style-type: none;
	padding: 0;
	flex-wrap: wrap;
}

.item {
	cursor: pointer;
	border-radius: @border-radius-base;
	background-color: @gray-dark;
	padding: 5px;
	font-size: @font-size-small;
	line-height: 1;
	margin-right: 6px;
	margin-bottom: 5px;
	color: white;
	white-space: nowrap;
	transition: background-color 0.2s ease-in;
	display: flex;
	align-items: center;

	&:hover {
		background-color: @gray-mid;
	}
}

.item-content {
	max-width: 280px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.close-icon {
	width: 10px;
	height: 10px;
	fill: currentColor;
	margin-left: 10px;
}

@media @small {
	.list-wrapper {
		padding-left: @panel-body-padding-mobile;
		padding-right: @panel-body-padding-mobile;
	}
}
