//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  .button-variant(
	  @btn-default-color;
	  @btn-default-bg;
	  @btn-default-border;
	  @btn-default-hover-color;
	  @btn-default-hover-bg;
	  @btn-default-hover-border;
	  @btn-default-disabled-color;
	  @btn-default-disabled-bg;
	  @btn-default-disabled-border;
	);
}
.btn-primary {
  .button-variant(
	@btn-primary-color;
	@btn-primary-bg;
	@btn-primary-border;
	@btn-primary-hover-color;
	@btn-primary-hover-bg;
	@btn-primary-hover-border;
	@btn-primary-disabled-color;
	@btn-primary-disabled-bg;
	@btn-primary-disabled-border;
	);
}
// Success appears as green
.btn-success {
  .button-variant(
		@btn-success-color;
		@btn-success-bg;
		@btn-success-border;
		@btn-success-hover-color;
		@btn-success-hover-bg;
		@btn-success-hover-border;
		@btn-success-disabled-color;
		@btn-success-disabled-bg;
		@btn-success-disabled-border;
	);
}
// Info appears as blue-green
.btn-info {
  .button-variant(
	  @btn-info-color;
	  @btn-info-bg;
	  @btn-info-border;
	  @btn-info-hover-color;
	  @btn-info-hover-bg;
	  @btn-info-hover-border;
	  @btn-info-disabled-color;
	  @btn-info-disabled-bg;
	  @btn-info-disabled-border;
	);
}
// Warning appears as orange
.btn-warning {
  .button-variant(
	  @btn-warning-color;
	  @btn-warning-bg;
	  @btn-warning-border;
	  @btn-warning-hover-color;
	  @btn-warning-hover-bg;
	  @btn-warning-hover-border;
	  @btn-warning-disabled-color;
	  @btn-warning-disabled-bg;
	  @btn-warning-disabled-border;
	);
}
// Danger and error appear as red
.btn-danger {
  .button-variant(
	  @btn-danger-color;
	  @btn-danger-bg;
	  @btn-danger-border;
	  @btn-danger-hover-color;
	  @btn-danger-hover-bg;
	  @btn-danger-hover-border;
	  @btn-danger-disabled-color;
	  @btn-danger-disabled-bg;
	  @btn-danger-disabled-border;
	);
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  font-weight: @font-weight-regular;
  color: @btn-link-color;
  background-color: @btn-link-bg;
  border-color: @btn-link-border;
  text-decoration: none;
	padding: @btn-padding-sm;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    .box-shadow(none);
  }

	&:active,
	&:hover,
	&:focus {
		text-decoration: none;
		color: @btn-link-hover-color;
		background-color: @btn-link-hover-bg;
		border-color: @btn-link-hover-border;
	}

	&[disabled],
	fieldset[disabled] & {
		color: @btn-link-disabled-color;
		background-color: @btn-link-disabled-bg;
		border-color: @btn-link-disabled-border;

		&:hover,
		&:focus {
		text-decoration: none;
		color: @btn-link-disabled-color;
		background-color: @btn-link-disabled-bg;
		border-color: @btn-link-disabled-border;

		}
	}
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
