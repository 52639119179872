@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@left-radius: @border-radius-base;

.button {
	height: @input-height-base;
	width: @input-height-base;
	vertical-align: top;
	border-top-left-radius: @left-radius;
	border-bottom-left-radius: @left-radius;
	border: 1px solid @border-color;
	border-right: none;
	padding: 0;
	overflow: hidden;
}

.color-box {
	width: 100%;
	height: 100%;
}

.color-field {
	display: none;
}

.popover {
	position: absolute;
	z-index: @zindex-dropdown;
}

.cover {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.error-message {
	background: rgb(255, 255, 255);
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 5px 8px;
	box-sizing: initial;
	width: 225px;
	color: @color-red-validation;
	text-align: center;
	margin-top: -5px;
	padding-bottom: 4px;
}
