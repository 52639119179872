@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.giver-panel {
	display: flex;
}

.giver-column {
	flex: 2 0 auto;
	padding: 0 @padding-base-horizontal * 5;
	border-left: 1px solid @border-color;
	display: flex;
	justify-content: center;
}

.giver-icon {
	background-color: @color-green;
	color: white;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	& svg {
		fill: currentColor;
		position: relative;
	}
}

.migrated-icon {
	left: 2px;
}

.error-icon {
	bottom: 2px;
}

.giver-column:first-child {
	border-left: none;
	padding-left: 0;
	justify-content: left;
	flex-grow: 1;
}

.giver-details {
	padding-left: @padding-base-horizontal;
}

.giver-heading {
	font-size: @font-size-base-minus;
	font-weight: 700;
	margin: 0;
	line-height: 1.5;
}

.count {
	font-size: @font-size-large-plus;
}

@media @small {
	.giver-panel {
		flex-direction: column;
	}

	.giver-column {
		justify-content: left;
		border-left: none;
		border-bottom: 1px solid @border-color;
		padding: @padding-small-vertical * 5 0;

		&:first-child {
			padding-top: @padding-small-vertical;
		}

		&:last-child {
			border-bottom: 0;
			padding-bottom: @padding-small-vertical;
		}
	}
}
