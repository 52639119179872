.arrow(@width, @color) {
	border-left: @width solid transparent;
	border-right: @width solid transparent;
	border-top: @width solid @color;
	border-bottom: @width solid @color;
	border-top-color: @color;
	border-bottom-color: @color;
}

.arrow-up() {
	border-top: none !important;
}

.arrow-down() {
	border-bottom: none !important;
}

.arrow {
	&.arrow-up {
		.arrow-up();
	}

	&.arrow-down {
		.arrow-down();
	}
}
