@import "radio-grid";

.form-control-radio {
	.radio-with-tooltip {
		margin-right: 45px;

		& + .radio-with-tooltip {
			margin-left: 0;
		}
	}

	&.radio-grid {
		.radio-inline {
			.radio-grid-item;

			&:first-child .form-control-radio-button {
				.radio-grid-item-first;
			}

			&:last-child .form-control-radio-button {
				.radio-grid-item-last;
			}

			& + .radio-inline {
				margin-left: 0;
			}
		}

		.form-control-radio-input {
			.radio-grid-input;

			&:checked {
				& ~ .form-control-radio-button {
					.radio-grid-button-checked;
				}
			}

			&:focus ~ .form-control-radio-button {
				.radio-grid-button-focused;
			}
		}

		.form-control-radio-button {
			.radio-grid-button;
			display: flex;
			align-items: center;
		}

		.form-control-radio-button-content {
			flex: auto;
		}
	}
}
