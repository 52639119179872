/*This LESS/CSS stylesheet can only be used for the Pushpay styleguide*/
.styleguide {

	.content-guidelines{
		h3{
			margin: 0 0 15px;
		}
	}

	.border-gray{
		border: 1px solid @gray-lighter;
	}

	.circled-lg {

		.svg-hourglass {
			width: 30px;
			height: 45px;
			fill: white;
		}
	}

	.circled-md {

		.svg-cal-white{
			fill: white;
			stroke: none;
			width: 20px;
			height: 20px;
		}
	}

	.complementary-colors-container{
		color: @gray-dark;
	}

	.color-container{
		padding: 20px;

		.color-sample {
			max-width: 160px;
			height: 160px;

			&.color-red{
				background-color: @color-red;
			}

			&.color-green {
				background-color:@color-green;
			}

			&.color-gray-darker {
				background-color: @gray-darker;
			}

			&.color-gray-dark {
				background-color: @gray-dark;
			}

			&.color-gray-mid{
				background-color: @gray-mid;
			}

			&.color-gray {
				background-color: @gray;
			}

			&.color-gray-light {
				background-color: @gray-light;
			}

			&.color-gray-lighter {
				background-color: @gray-lighter;
			}

			&.color-grey-form {
				background-color: @color-form;
				border: 1px solid @gray-lighter;
			}

			&.color-grey-form-alt {
				background-color: @color-form-alt;
				border: 1px solid @gray-lighter;
			}

			&.color-complementary-red {
				background-color: @color-complementary-red;
			}

			&.color-complementary-green {
				background-color: @color-complementary-green;
			}

			&.color-blue {
				background-color: @color-blue;
			}

			&.color-yellow {
				background-color: @color-yellow;
			}

			&.color-orange {
				background-color:@color-orange;
			}

			&.color-orange-darker {
				background-color: @color-orange-darker;
			}

			&.color-purple {
				background-color: @color-purple;
			}
		}
	}
}
