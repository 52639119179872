@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';
@import '../../../../../Content/LoggedInWeb/modules/waiting-indicator.less';

@title-size: 20px;
@action-height: 30px;
@amount-font-size: 24px;

.container {
	position: relative;
	height: auto;
	display: grid;
	grid-template-areas:
		'title amount'
		'time-record amount'
		'progress-bar progress-bar'
		'actions actions';
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr auto @action-height;
	grid-row-gap: 5px;
	align-items: center;
	margin: @grid-gutter-width 0;
	transition: box-shadow 0.25s linear;

	@media not @small {
		background: linear-gradient(to top, @color-form @action-height, white -@action-height);
		padding: @grid-gutter-width @grid-gutter-width 0;
		border-radius: @border-radius-base;
		box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35);
		border: 1px solid rgba(0, 0, 0, 0.1);

		&:hover {
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
			z-index: 1;
		}

		&:first-of-type {
			&.tabs {
				margin-top: 30px;
			}
		}
	}

	@media @small {
		grid-template-areas:
			'title'
			'time-record'
			'progress-bar'
			'amount'
			'actions';
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto auto auto;
		border-top: 1px solid @border-color;
		padding: 40px 0;

		&:first-of-type {
			margin-top: 0;

			&.tabs {
				padding-top: 30px;
				border-top: 0px solid @border-color;
			}
		}
	}
}

.title {
	grid-area: title;
	font-size: @title-size;
	line-height: 1.2em;
	display: flex;
	align-items: flex-start;

	@media not @mobile {
		color: @gray-darker;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: @brand-primary;
	}
}

.status {
	grid-area: status;
	margin: 0 0 0 15px;
	cursor: pointer;
	position: relative;
	top: 2px;
}

.amount-record {
	grid-area: amount;
	align-self: flex-start;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin: 0;
	color: @gray-mid;
}

.separation-symbol() {
	content: '|';
	padding: 0 @padding-base-horizontal;
	position: relative;
	top: -1px;
}

.amount-record-item {
	font-size: @font-size-base-minus;
	white-space: nowrap;

	&:first-child {
		& > .amount:not(.amount-placeholder) {
			font-size: @amount-font-size;
			line-height: 1.2em;
		}
		& > .amount-label {
			&:after {
				content: ':';
			}
		}
	}
}

.collected-info {
	width: 100%;
	text-align: right;
}

.pledged-info {
	&:after {
		.separation-symbol();
	}
}

.amount {
	margin-left: 5px;
	font-weight: bold;
	color: @gray-dark;

	&:before {
		content: '$';
		position: relative;
	}
}

.amount-placeholder {
	.waiting-indicator();

	&:before {
		content: '';
	}
}

.time-record {
	grid-area: time-record;
	align-self: baseline;
	font-size: @font-size-base-minus;
	color: @gray-mid;

	> .time-record-item + .time-record-item {
		&:before {
			.separation-symbol();
		}
	}

	.time-record-value {
		font-weight: bold;
	}
}

.actions {
	grid-area: actions;
	display: flex;
	align-content: center;
	line-height: @action-height;

	:global {
		.checkbox label {
			line-height: 1.6;
		}
	}
}

.progress-bar {
	grid-area: progress-bar;
	padding: 10px 20px;
	min-height: 30px;

	@media @mobile {
		min-height: 0;
	}
}

.reached {
	color: @color-green;
}

@media @small {
	.title {
		font-size: 24px;
		justify-content: space-between;
	}

	.status {
		top: 4px;
	}

	.amount-record-item {
		font-size: @font-size-base-plus;
		line-height: 2em;
		flex-basis: 100%;
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		&:not(:last-child) {
			border-bottom: 1px solid @color-form-alt;
		}

		.amount-label:after {
			display: none;
		}
	}

	.pledged-info {
		&:after {
			display: none;
		}
	}

	.time-record {
		display: flex;
		flex-wrap: wrap;

		> .time-record-item {
			flex-basis: 100%;
			display: flex;
			justify-content: space-between;

			&:before {
				display: none;
			}
			> .time-record-label {
				&:after {
					content: ':';
				}
			}
		}
	}

	.actions {
		flex-wrap: wrap;
		justify-content: space-between;
		-ms-grid-row: 7;
	}

	.progress-bar {
		padding: 10px 0 0;
	}
}

.cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: @white-50;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading {
	border-color: lighten(@brand-primary, 50%);
	border-top-color: @brand-primary;
}
