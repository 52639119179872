@import "../../bootstrap-variables";
@import "../../mixins/mixins";
@import "../ionicons/ionicons";

.community-members {
	@fake-eee: @color-form-alt;
	@icon-width: 24px;
	@icon-margin: 5px;
	@sorting-icon-height: 20px;
	@sorting-icon-width: 10px;
	@inner-row-border: 1px;
	@circle-radius: 5px;
	@circle-diameter: @circle-radius*2;
	@profile-icon-width: 18px;
	@profile-icon-height: 18px;
	@count-circle-size: 14px;
	@account-row-vertical-padding: 8px;
	@account-icon-height: 20px;
	@drag-handle-width: 23px;
	@head-row-height: 40px;

	.absolute() {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	.name {
		font-weight: bold;
	}

	.grid {
		.grid-row {
			display: flex;
			align-items: center;
			position: relative;

			&.head .last-activity {
				text-align: left;
			}

			> * {
				padding: @table-cell-padding @table-cell-padding-horizontal;

				text-align: left;
				flex: 1 1 10%;
				overflow: hidden;
				text-overflow: ellipsis;

				&.row-icon {
					display: flex;
					align-items: center;
					align-self: stretch;
					flex: 0 0 50px;
					padding-top: 0;
					padding-bottom: 0;
					overflow: visible;
					position: relative;
				}

				&.name {
					max-width: 150px;
				}

				&.email-address {
					max-width: 300px;
					word-break: break-word;
				}

				&.address {
					display: inline-block;
					align-self: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					flex: 1 1 0%;
				}

				&.mobile-number {
					max-width: 130px;
				}

				&.last-activity {
					max-width: 110px;
					text-align: right;
				}

				&.commands {
					flex: 0 0 (@icon-width*3 + @icon-margin*2);
					padding-right: 0;
					padding-left: 0;
					display: flex;
				}
			}


		}

		.head {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 @inner-row-border;
			background-color: @gray-mid;
			color: white;
			.roundSome(@border-radius-base, @border-radius-base, 0 ,0);
			align-items: stretch;
			font-size: @table-header-font-size;
			font-weight: bold;
			height: @head-row-height;

			> * {
				line-height: 1;
			}

			.commands {
				justify-content: flex-end;

				button {
					margin-right: @icon-margin - @inner-row-border;
				}
			}

			button {
				background-color: transparent;
				border: 0;
				outline: 0;
				position: relative;

				&:hover {
					text-decoration: underline;
				}

				&:after {
					.ion;

					width: @sorting-icon-width;
					font-size: @sorting-icon-height;
					line-height: @sorting-icon-height;
					position: absolute;
					right: @table-cell-padding-horizontal;
					top:  50%;
					margin-top: -@sorting-icon-height/2;
				}

				&.active {
					background-color: @gray-dark;
					padding-right: @table-cell-padding-horizontal + @sorting-icon-width;

					&.asc:after {
						content: @ionicon-var-android-arrow-dropdown;
					}

					&.desc:after {
						content: @ionicon-var-android-arrow-dropup;
					}
				}
			}
		}
	}


	//arrow icon
	.arrow {
		font-size: 19px;

		&:before {
			.ion;
		}

		&.expand-arrow:before {
			content: @ionicon-var-chevron-down;
		}

		&.closed-arrow:before {
			content: @ionicon-var-chevron-up;
		}
	}

	.sobusy {
		position: relative;

		&:after {
			//needs to be an overlay to cover the whole table - so no clicks will be processed
			.absolute();
			top: @head-row-height;
			cursor: wait;
			background-color: white;
			opacity: 0.9;
			content: "";
		}
	}

	.ungroupDropTarget.not-dragging .member-container:before {
		transition: visibility 0.25s ease-out, outline 0.25s ease-out;
	}

	//borders and row dimensions
	.member-container {
		margin-top: -1px;
		position: relative;

		&.expanded {
			.member-details{
				.grid-row {
					border: 1px solid @gray-light;
				}
			}

			.accounts-container {
				display: block;
			}
		}

		&:last-child:not(.expanded){
			.grid-row {
				border-bottom: 1px solid @gray-light;
			}
		}

		&:not(.disableCommunityMemberDragAndDrop) .member-details {
			cursor: move;
		}

		.member-details {
			position: relative;

			.grid-row {
				border: 1px solid transparent;
				border-top: 1px solid @gray-light;
			}
		}

		&.expanded + .member-container {
			margin-top: 0;

			.member-details {
				.grid-row {
					border-top-color: transparent;
				}
			}
		}

		&:not(.is-dragging,.disableCommunityMemberDragAndDrop),
		&.expanded:not(.is-dragging,.disableCommunityMemberDragAndDrop) {
			.member-details:hover {
				.draghandle {
					display: flex;
					background-color: @color-complementary-green;
				}

				.grid-row {
					background-color: @color-green-lighter;
					border: 1px solid @color-complementary-green;
					border-left: 1px solid transparent;
					.roundSome(0, 3px, 0, 3px);
				}
			}
		}
		&.disableCommunityMemberDragAndDrop {
			.member-details:hover {
				.grid-row {
					background-color: @gray-lighter;
					border: 1px solid @gray-light;
					border-left: 1px solid transparent;
					.roundSome(0, 3px, 0, 3px);
				}
			}
		}

		&:hover:not(.is-dragging,.disableCommunityMemberDragAndDrop):not(.expanded) + .member-container {
			margin-top: 0;

			.member-details {
				.grid-row {
					border-top: 0;
				}
			}
		}
	}

	.accounts-container {
		display: none;

		border: 1px solid @gray-light;
		border-bottom-width: 3px;
		border-top: 0;

		background-color: @fake-eee;
		box-shadow: fade(@border-color, 65%) 0px 1px 0px inset;
		font-size: @font-size-small;

		.account {
			position: relative;

			.grid-row > * {
				padding-top: @account-row-vertical-padding;
				padding-bottom: @account-row-vertical-padding;
				min-height: @account-row-vertical-padding*2 + @account-icon-height;
			}

			.grid-row {
				border-bottom: 1px solid @gray-lightest;
			}

			&:last-child .grid-row {
				border-bottom-color: transparent;
			}

			&.dropped {
				transition: background-color .45s ease-out;
			}

			&.triggered {
				background-color: lighten(@color-complementary-green, 40%);
			}

			&:not(.is-dragging,.disableCommunityMemberDragAndDrop):hover {
				cursor: move;
				background-color: @color-green-lighter;

				.commands .icon {
					opacity: 1;
				}
			}
			&.disableCommunityMemberDragAndDrop:hover {
				background-color: @gray-lighter;

				.commands .icon {
					opacity: 1;
				}
			}

			.commands .icon {
				opacity: 0; //hidden but still has layout
				transition: none;

				&:not(:last-child) {
					margin-right: @icon-margin + 1px;
				}
			}
		}
	}

	//outline
	.member-container {
		background: white;

		&:before {
			outline: 3px solid transparent;
			//IE does not support outline-offset and pointer-events :( - that makes the element unclickable IE10 for 0.25s while running transition
			pointer-events: none;
			position: absolute;
			top: 3px;
			bottom: 3px;
			left: 3px;
			right: 3px;
			content: "";
			visibility: hidden;
		}

		&.highlight {
			:not(:first-child) {
				opacity: 0.5;
			}
		}

		&.highlight:before {
			visibility: visible;
			outline-color: @color-complementary-green;
		}

		&.non-droppable {
			&.highlight:before {
				outline-color: @color-complementary-red;
			}
		}

		//circle and paths
		&.expanded {

			.grid-row:before {
				content: "";
				border-left: 1px solid @gray-dark;
				position: absolute;
				left: @profile-icon-width + @inner-row-border;
				top: -1px;
				bottom: 0;
			}

			.member-details {
				.grid-row:before {
					top: ~"calc(50% + 9px)";
				}
			}

			.account {
				.grid-row:before  {
					left: @profile-icon-width + @inner-row-border;
					border-color: @gray;
				}

				.grid-row:after {
					content: "";
					border-left: 1px solid @gray;
					border-bottom: 1px solid @gray;
					position: absolute;
					left: @profile-icon-width + @inner-row-border;
					width: @circle-diameter;
					height: @circle-diameter;
					top: ~"calc(50% - @{circle-diameter})";
				}

				.row-icon .circle {
					left: @table-cell-padding-horizontal + @profile-icon-width + @inner-row-border;
					top: ~"calc(50% - @{circle-radius})";
					position: absolute;
				}

				&:last-child {
					.grid-row:before  {
						bottom: ~"calc(50% + @{circle-diameter})";
					}

					.grid-row:after {
						border-bottom-left-radius: @border-radius-small;
					}
				}
			}
		}
	}

	.circle {
		.round(@circle-radius);
		width: @circle-diameter;
		height: @circle-diameter;
		display: inline-block;
		border: 2px solid @gray-mid;

		&.verified {
			background-color: @gray-mid;
		}
	}

	.member-icon {
		position: relative;
		display: block;
		height: @profile-icon-width;

		.svg {
			width: @profile-icon-width;
			height: @profile-icon-height;
			fill: @gray-dark;
			stroke: none;
		}

		.count {
			box-sizing: content-box;
			display: inline-block;
			position: absolute;
			width: @count-circle-size;
			height: @count-circle-size;
			color: white;
			background-color: @gray-dark;
			border: 1px solid white;
			border-radius: 100%;
			font-size: 11px;
			line-height: @count-circle-size;
			text-align: center;
			top: 8px;
			left: 14px;
		}
	}

	//fade out a row when it's being dragged
	.is-dragging {
		background-color: @gray-lighter;

		&:after{
			position: absolute;
			left: 0px;
			right: 0px;
			top: 1px;
			bottom: 0px;
			content:"";
			opacity: 0.85;
			background-color: white;
		}
	}

	.icon {
		display: inline-block;
		border: none;
		background: none;
		outline: none;
		padding: 0;
		line-height: 1;
		.transition(0.2s);
		margin-right: @icon-margin;
		width: @icon-width;

		&:last-child {
			margin-right: 0;
		}

		svg {
			.transition(0.2s);
			width: @icon-width;
			max-height: 26px;
		}
	}

	.account .icon {
		height: @account-icon-height;
		svg {
			max-height: @account-icon-height;
		}
	}

	.member-details .icon {
		color: @icon-color;

		.giving-statement-icon,
		.giving-statement-icon svg /*Firefox :playthesad:*/ {
			fill: @icon-color;
			stroke: @icon-color;
			color: @icon-color;
		}

		&:hover .giving-statement-icon,
		&:hover .giving-statement-icon svg /*Firefox :playthesad:*/ {
			color: @icon-hover-color;
			fill: @icon-hover-color;
			stroke: @icon-hover-color;
		}

		svg {
			stroke: @icon-color;
			fill: @icon-color;
		}

		&:hover {
			color: @icon-hover-color;

			svg {
				stroke: @icon-hover-color;
				fill: @icon-hover-color;
			}
		}
	}

	.member-details {
		margin-left: -@drag-handle-width;
		padding-left: @drag-handle-width;

		.draghandle {
			display: none;
			position: absolute;
			color: white;
			width: @drag-handle-width;
			top: 0;
			left: 0;
			bottom: 0;
			align-items: center;
			justify-content: center;
			.roundSome(3px, 0, 3px, 0);

			svg {
				width: 11px;
				height: 14px;
			}
		}
	}

	.draglayer {
		position: fixed;
		pointer-events: none;
		z-index: 100;
		left: 0;
		top: 0;
		background: @color-complementary-green;
		color: white;
		box-shadow: 1px 1px 7px rgba(0,0,0,0.27);
		border-radius: 3px;
		padding: 8px;

		&.non-draggable {
			background: @color-complementary-red;
		}

		table {
			color: white;

			td {
				padding: 0;
				padding-right: 15px;

				&:first-child {
					padding-left: 15px;
					padding-right: 10px
				}
			}
		}

		i, div.description {
			margin: 3px 7px;
		}

		.member-icon {
			.svg {
				fill: white;
			}
		}
	}

	.summary {
		@spacing: @line-height-computed;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: @spacing 0;

		h3,
		.legend {
			margin: 0;
			margin-top: @spacing;
		}

		h3 {
			margin-right: @spacing;
			flex-shrink: 0;
			flex-grow: 1;
		}

		.conversion-link {
			margin-top: @spacing;
			flex-shrink: 0;
			flex-grow: 1;
		}

		.legend {
			font-size: @font-size-small;
			flex-shrink: 0;
			display: flex;
		}

		.legend-item {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-right: @spacing;
			}

			&:last-child {
				margin-right: 2px;
			}

			.key {
				margin-right: 8px;
			}

			svg {
				fill: @gray-dark;
				width: 16px;
				height: 16px;
			}
		}
	}

	.load-more-container {
		text-align: center;
		margin: @line-height-computed 0;

		.btn {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.filter-form {
		.row {
			& > * {
				margin-bottom: 20px;
			}
		}
	}

	.tipbox {
		min-width: 470px;

		.carousel {
			min-height: 326px;

			.carousel-slide {
				min-height: 282px;
			}
		}
	}
}

.tutorial-slide {
	.tutorial-description {
		svg {
			position: relative;
			top: 4px;

			&.unlink,
			&.unlink svg {
				fill: @gray-dark;
			}

			&.member-fill,
			&.member-fill svg {
				fill: @gray-dark;
			}

			&.member-outline,
			&.member-outline svg {
				fill: @gray-dark;
			}

			&.pencil,
			&.pencil svg{
				fill: @color-red;
				stroke: @color-red;
			}
		}
	}
}

.statements-table {
	.version-column {
		width: 15%;
	}
	.created-on-column {
		width: 25%;
	}
	.sent-on-column {
		width: 25%;
	}
	.created-by-column {
		width: 15%;
	}
	.links-column {
		width: 20%;
	}
}

.statement-list {
	margin: 0 0 30px;

	.separator {
		height: 5px;
		width: 5px;
		background-color: #777;
		border-radius: 50%;
		display: inline-block;
		margin: 0 0 2px 5px;
	}

	h3 {
		margin-top: 0;
		margin-bottom: 0;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}
