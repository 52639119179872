@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.status {
	display: flex;
	align-items: center;
	line-height: 1;
}

.status-active {
	color: @color-green;
}

.status-declined,
.status-unreached {
	color: @color-red;
}

.status-scheduled,
.status-awaiting {
	color: @gray-dark;
}

.status-icon {
	width: 20px;
	height: 20px;
	fill: currentColor;
	margin-right: 10px;
}
