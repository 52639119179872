@import "../bootstrap-variables";

/* Custom hamburger to satisfy design */
.burger-bar() {
	display: inline-block;
	width: 26px;
	height: 2px;
	transition: all @flyout-transition-timing @flyout-transition-easing;
	background-color: @color-form-alt;
	.round(1);
}

.burger {
	.sliding-hover(darken(@brand-primary, 10%));
	transition: @flyout-transform-transition;
	.square(@navbar-height);
	padding: 15px 17px 25px;
	display: none;
	background: 0;
	border: 0;
	outline: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: @zindex-popover;
	.burger-bars {
		.burger-bar();
		position: relative;
		&:before, &:after {
			.burger-bar();
			position: absolute;
			left: 0;
			content: '';
			transform-origin: 0 1px;
		}
		&:before {
			top: -9px;
		}
		&:after {
			top: 9px;
		}
	}
	&:after {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		content: '';
		top: 0;
		left: 0;
		background: transparent;
		transition: background @flyout-transition-timing @flyout-transition-easing;
	}
	&.open {
		.burger-bars {
			transform: scale3d(1.3, 1.3, 1.3);
			background: transparent;
			&:before, &:after{
			  top:0;
			  background-color: @gray;
			  transform-origin: center center;
			}
			&:before{
			  transform: rotate3d(0, 0, 1, 45deg);
			}
			&:after{
			  transform: rotate3d(0, 0, 1, -45deg);
			}
		}
		&:after {
			background-color: @color-form;
		}
	}
	@media @mobile {
		display: inline-block;
	}
}

.burger.profile-close {
	right: 0;
	left: auto;
	.flyout-fadein();
	.burger-bars {
		background: transparent;
	}
}
