@import "../../bootstrap-variables";

@icon-width: 20px;
@icon-height: 20px;

.email-recipient-list {
	.pill-box ul li.pill {

		&.verified, &.unverified {
			background-color: #eee;
		}

		&.invalid {
			border: 1px dotted @color-red;
			background-color: white;
		}

		&.new {
			background-color: white;
			border: 1px dotted @text-color;
		}

	}
	.status-icon {
		display: inline-block;
		position: relative;
		top: 5px;
		margin: -5px 9px -5px 0;

		&.verified-icon {
			color: @color-green;
			.svg(@icon-width, @icon-height);
		}

		&.invalid-icon {
			color: @color-red;
			.svg(@icon-width, @icon-height);
		}

		&.unverified-icon {
			color: @gray-dark;
			.svg(@icon-width, @icon-height);
		}
	}

	.deleted-emails {
		margin-top: 20px;
		font-size: @font-size-base;
	}

	.legend {
		margin-top: 20px;
		font-size: @font-size-base-minus;
		.item {

			display: inline-block;
			margin-right: 20px;

			.status-icon {
				margin: -5px 6px -5px 0;
			}
		}
	}
}
