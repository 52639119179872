@import "../../bootstrap-variables";

.navbar-top, .header-bar, .mobile-steps-banner {

	.banner {
		display: flex;
		flex: 0 1 auto;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: auto;
		justify-content: center;
		align-items: center;
		padding: 0 20px;
		margin: 0;

		.steps-banner {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			height: 36px;
			padding: 0 14px;
			color: white;
			font-size: @font-size-h6;
			line-height: 1;
			border-radius: 18px;
			border: 1px solid @white-50;
			max-width: 100%;
			transition: background-color .2s;

			i, .icon {
				position: relative;
				margin-right: 6px;
				margin-top: 3px;
			}

			.icon {
				top: -2px;
			}

		}

		a.steps-banner:hover {
			text-decoration: none;
			background-color: darken(@brand-primary, 10%);
		}
	}
}


.mobile-steps-banner {
	padding: 0 0 30px 0;

	.banner {
		border-bottom: none;
		padding: 0;

		.steps-banner {
			border-color: @brand-primary;
			color: @brand-primary;

		}
	}
}

@media (max-width: @grid-float-breakpoint-max) {

	.navbar-top, .header-bar .banner {
		display: none;
	}

}
