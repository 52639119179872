@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.close-btn {
	color: @color-red;
	position: absolute;
	z-index: 101;
	top: 10px;
	right: 0;
	background: none;
	border: none;
	:global(.svg) {
		width: 40px;
		height: 40px;
	}
}
