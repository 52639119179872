@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@icon-size: 25px;

.preview-toggle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.selected {
	color: @brand-primary;
}

.label {
	margin: 3px 8px 0;
	font-weight: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	cursor: pointer;
}

.svg {
	height: @icon-size;
	display: inline-block;
	vertical-align: middle;
	margin-right: 3px;
}

.svg-laptop {
	width: 36px;
}
.svg-mobile-rect {
	width: 14px;
}

input[type='radio'].radio-box {
	position: absolute;
	left: -9000px;
}
