@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@form-gutter-width: 35px;

.secondary-color {
	margin-top: @form-gutter-width;
}

@media not @small {
	.color-group {
		display: flex;
		justify-content: space-between;

		:global(.form-control) {
			width: 0;
		}

		.color-control-container {
			flex: 1;
		}
	}

	.secondary-color {
		margin: 0 0 0 20px;
	}

	.primary-color-container {
		margin-right: 10px;
	}
}

.form {
	:global(.form-group) {
		position: relative;

		:global(.field-validation-error) {
			position: absolute;
			line-height: normal;
			left: 0;
			right: 0;
			margin: 5px 0 0 0;
			color: @color-red;
			white-space: pre-wrap;
		}
	}
}

.item {
	margin-bottom: @form-gutter-width;
}

:global(.form-group label .info-tooltip) {
	&:hover,
	&:focus {
		:local(.info-icon) {
			color: @gray-dark;
		}
	}
}

.tooltip-inner {
	padding: 15px;
}

.image-uploader(@height) {
	position: relative;
	display: flex;
	flex-direction: column;
	height: @height;
	white-space: pre;
	border-radius: @border-radius-base;
}

.background {
	overflow: hidden;
	.image-uploader(120px);
	img {
		width: 420px;
		height: auto;
	}

	:global(.preview-image) {
		height: inherit;
		vertical-align: top;
	}
}

.background-contained {
	overflow: hidden;
	.image-uploader(120px);
	img {
		width: 420px;
		height: auto;

		@media @small {
			height: inherit;
		}
	}
}

.brand-logo {
	.image-uploader(70px);
}

.square-logo {
	.image-uploader(150px);
	white-space: pre-wrap;

	:global {
		.upload-area {
			width: 150px;
			flex-direction: column;
		}

		.waiting-container {
			width: 150px;
		}

		.image-container {
			width: 268px;
		}

		.preview-image {
			position: absolute;
			width: 40px;
			height: 40px;
			top: 35px;
			left: 45px;
		}
	}
}

.color-control-container {
	:global(.form-group) {
		margin-bottom: 0;

		@media @small {
			margin-bottom: 25px;
		}
	}
}
