
.overlay(@className: overlayed, @zIndex: auto, @transition-duration: 0.15s, @transition-delay: 0.15s) {
	position: relative;

	&:after {
		content: " ";
		display: block;
		background-color: rgba(255, 255, 255, 0.7);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		visibility: hidden;
		opacity: 0;
		transition-duration: @transition-duration;
		transition-delay: 0s;
		transition-timing-function: ease-in-out;
		transition-property: visibility, opacity;
	}

	&.@{className} {
		pointer-events: none;

		&:after {
			visibility: visible;
			opacity: 1;
 			transition-delay: @transition-delay;
			z-index: @zIndex;
		}
	}
}
