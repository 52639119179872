@import "../../bootstrap-variables";

//
// title header - this needs to be refactored to use
// bs3 .page-headers
// --------------------------------------------------
// DEPRECATED - DON'T USE!!!
// --------------------------------------------------
.page-head {

	> section{
		header {
			h1{
				margin-top: 0;
				margin-bottom: 20px;
			}
		}
	}
}

//
// override BS3 standard .page-header
// --------------------------------------------------
.page-header {
	margin-top: 0;
	border-bottom: none;

	h1 {
		margin-top: 0;
	}

	small {
		color: @gray;
		font-weight: 200;
	}
}

//
// graphic headers
// --------------------------------------------------
header.graphic-header {
	width: 100%;
	height: 196px;
	margin-top: -@navbar-height;
	padding-top: 37px;
	margin-bottom: @grid-gutter-width + 20;

	@media @mobile {
		margin-top: -30px;
		margin-bottom: @grid-gutter-width - 14;

		h1 {
			display: none;

			&.mobile-header{
				display: block;
				padding: 0  @padding-base-horizontal * 2  @padding-base-horizontal * 2;
				text-align: center;

				span{
					white-space: nowrap;
				}
			}
		}
	}


	&.green {
		background-repeat: repeat;
		background-image: url('/Content/LoggedInWeb/assets/images/tax-statements-banner-texture.png');
		background-color: @color-complementary-green;
		background-size: initial;
	}

	.title {
		margin: 19px 60px 0 40px;
		color: @btn-info-color;

		@media @mobile {
			display: none;
			margin: 0;
		}
	}

	// tax image
	.tax {
		padding-left: 110px;

		img {
			width: 281px;
			height: 159px;
		}

		@media @mobile {

			overflow-x: hidden;
			padding-left: 48px;

			img {
				margin: 0 auto;
				display: block;
			}
		}
	}

	.title, .graphic {
		display: inline-block;
		float: left;

		@media @mobile {
			display: block;
			float: none;
		}
	}
}

.heading-help {
	.make-xs-column(12, @grid-gutter-width);

	&:last-child {
		padding-bottom: 20px;
	}
}
