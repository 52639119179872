@import "../../bootstrap-variables";

.popup .preview-statement {
	max-width: 1060px;

	@media @mobile {
		h1, p {
			padding: 0 @grid-gutter-width;
		}
	}

	.preview-content {
		background-color: white;
		width: 100%;
		border: none;
		height: ~"calc(100vh - 280px)";
	}

	form {
		.form-control {
			input {
				height: 30px;
			}

			textarea {
				height: 100px !important;
			}
		}

		margin-bottom: @padding-base-horizontal*3;
	}
}
