// the d3-tip library will append the tip element to the end of the body
// this can then put it outside the bounds of the #insights namespace
.insights-tooltip {
	line-height: 1;
	font-weight: bold;
	padding: 12px;
	background-color: @gray-dark;
	color: white;
	border-radius: @border-radius-small;
	pointer-events: none;
	margin-top: -10px;

	&::after {
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-top-color: @gray-dark;
		border-width: 10px;
		margin-left: -10px;
	}
}
