#insights {
	.suggestion {
		p {
			font-size: @font-size-base-plus;
			margin: @line-height-computed;
		}

		.speech-bubble {
			width: 50px;
			height: 50px;
			border-radius: 25px;
			background-color: @brand-primary;
			text-align: center;
			margin: auto;

			.svg {
				color: white;
			}

			svg {
				padding-top: 13px;
			}
		}
	}
}
