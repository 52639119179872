@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.panel {
	text-align: center;
}

.regenerate-statements {
	> svg {
		fill: @btn-link-color;
		width: 12px;
		height: 12px;
	}

	@media @small {
		padding-left: 0;
		border-left: none;
	}
}

.delete-button {
	font-size: 15px;
}
