@import "../../bootstrap_theme/labels";
@import "../../bootstrap-variables";

.alert.alert-integration-warning {
	@padding-vertical: 20px;
	@padding-horizontal: 30px;

	display: flex;
	padding: @padding-vertical @padding-horizontal;
	background: white;

	.alert-icon {
		position: static;
		margin-right: @padding-horizontal;
		margin-top: 10px;
		flex-shrink: 0;
	}

	.alert-title {
		display: block;
		font-size: @font-size-h3;
		margin-bottom: 15px;
	}

	.btn-link {
		font-size: @font-size-base;
		display: inline;
		padding: 0;
		margin: 0;
		height: auto;
		line-height: inherit;
	}
}
