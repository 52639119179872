@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@label-spacing: 17px;

.container {
	display: flex;
	align-items: flex-end;

	@media @small {
		flex-direction: column;
		align-items: flex-start;
	}
}

.heading {
	white-space: nowrap;

	@media @small {
		white-space: normal;
	}
}

.label-group {
	padding: 0 0 0 @label-spacing;

	@media @small {
		padding: 0;
	}
}

.label {
	margin: 0 @label-spacing @label-spacing 0;
}
