.payment-request-listing-panel {
   display: flex;
   justify-content: space-between;
}
.payment-request-listing-panel .wide {
   padding-right: 10px;
   flex: 3 1 auto;
}
.payment-request-listing-panel .date-arrow-wrap {
   padding: 10px 10px 0 0;
}

.payment-request-listing-panel button {
   min-width: 0px;
}
.payment-request-listing-panel .view-btn {
   margin-right: 10px;
}

.payment-requests-listing-table {
   min-width: 1400px;
}

.payment-requests-listing-table tr {
   cursor: pointer;
   &:hover {
      background-color: @gray-lighter !important;
   }
}

.payment-requests-listing-table tr.completed {
   color: @color-green;
}

.payment-requests-listing-table span.failed {
   color: @color-red;
}

.payment-requests-listing-table .failed svg {
   color: @color-red;
}

.payment-requests-listing-table .status-icon-wrap {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 16px;
   height: 16px;
   margin-right: 2px;
}

.payment-requests-listing-table td .truncate {
   display: block;
   text-overflow: ellipsis;
   overflow: hidden;
   max-width: 200px;
   white-space: nowrap;
}

.pay-payment-edit-new-field-wrap {
   display: flex;
   margin-bottom: 15px;
}
.pay-payment-edit-new-field-wrap label {
   width: 25%;
   display: flex;
   align-items: center;
}
.pay-payment-edit-new-field-wrap .control {
   width: 50%;
}