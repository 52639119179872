// Alerts

.alert-variant(
	@background;
	@border;
	@text-color;
	@hr-color;
	@link-color;
	) {
  background-color: @background;
  border-color: @border;
  color: @text-color;

  hr {
	border-top-color: @hr-color;
  }
  .alert-link {
    color: @link-color;
  }
}
