@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@item-vertical-padding: 18px;
@name-font-size: 20px;

.container {
	margin-top: @grid-gutter-width + @padding-large-vertical;
}

.item {
	padding: @item-vertical-padding 0;
}

.name {
	font-size: @name-font-size;

	@media @small {
		line-height: 24px;
	}
}

.code {
	font-size: @font-size-base-plus;

	@media @small {
		display: block;
	}

	@media not @small {
		padding-left: @padding-base-horizontal;
	}
}

.notes {
	margin-top: @padding-small-vertical;
}

.total {
	margin-bottom: 0;

	@media @small {
		font-size: @font-size-h2;
		text-align: left;
	}
}
