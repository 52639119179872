@import '../bootstrap_theme/tables.less';
@import '../bootstrap_theme/checkboxes.less';

@checkbox-column-horizontal-padding: 20px;

//
// Table extension methods
// --------------------------------------------------
.col-min-width {
	width: 1%;
	white-space: nowrap;
}

.col-status {
	.col-min-width();
	th& {
		.text-center();
	}
	table tbody td& {
		padding-top: @padding-base-vertical;
		padding-bottom: @padding-base-vertical;
		@media (max-width: @screen-md-max) {
			.text-center();
		}
	}
	strong {
		vertical-align: inherit;
		.visible-lg-inline-block();
		margin-top: 3px;
	}
	.status-success {
		color: @brand-success;
	}

	.status-warning {
		color: @brand-warning;
	}

	.status-default,
	.status-info {
		color: @brand-info;
	}

	.status-danger {
		color: @brand-danger;
	}
}

.col-email {
	td& {
		.truncate();
		@media (max-width: @screen-md-max) {
			max-width: 100px;
		}
	}
}

.col-date {
	.text-center();
	.col-min-width();
}

.col-payment {
	div,
	strong {
		display: inline-block;
		min-width: 84px;
		.text-right();
		.no-wrap();
		&.large-amount {
			min-width: 100px;
		}
	}
}

.col-round-right {
	th& {
		.roundSome(0, @border-radius-base, 0, 0);
	}
}

.col-checkbox {
	.checkbox;
	.col-min-width();
	.table tr & {
		padding-left: @checkbox-column-horizontal-padding;
		white-space: nowrap;
	}
	label:not(.col-checkbox-header-label) {
		padding-left: @checkbox-column-horizontal-padding;
	}
	@media @mobile {
		margin-top: 0;
		margin-bottom: 0;
	}
}

//
// Mobile enabled tables
//
// This css is not pretty and too complex
// however atm it's what's required to re-use grid.mvc
// --------------------------------------------------
table.mobile-enabled {
	@status-column-width: 26px;

	@media (max-width: @screen-xs-max) {
		border-top: 2px solid @gray-lighter;

		&.table-striped {
			> tbody > tr {
				&:nth-of-type(odd) {
					background-color: transparent;
				}

				&:hover {
					background-color: inherit;
				}
			}
		}

		.col-status {
			width: @status-column-width + @table-cell-padding-horizontal; // fixed width of the status icon
			padding-right: 0;
			text-align: left;
		}

		.data-list {
			display: none;
			visibility: hidden;
		}

		// hide th when grid is empty in mobile view
		&.table-empty {
			thead {
				display: none;
				visibility: hidden;
			}
		}

		// hide footers and headers
		thead {
			tr {
				display: block;

				th:not(.col-checkbox) {
					display: none !important;
					visibility: hidden;
				}
			}
		}

		> tbody {
			> tr {
				display: block;
				border-bottom: 1px solid @gray-lighter;

				&.grid-empty-text {
					display: table-row;
				}

				&:last-child {
					border-bottom: 2px solid @gray-lighter;
				}

				&.has-status-column .secondary {
					// offset for status
					padding-left: @status-column-width + @table-cell-padding-horizontal*2;
				}

				&.has-checkbox-column .secondary {
					// offset for checkbox
					padding-left: @checkbox-input-size + @checkbox-column-horizontal-padding*2;
				}
			}

			td {
				display: none;
				visibility: hidden;
				width: auto;

				&.col-mobile {
					display: inline-block;
					visibility: visible;
					background-color: white;

					&.mobile-right {
						float: right;
					}
				}

				&.secondary {
					display: block;
					padding-top: 0px;

					span {
						color: @gray;
					}

					.arrow-hollow-down-after();

					&::after {
						position: absolute;
						right: 0;
						padding-right: @table-cell-padding-horizontal;
						margin-top: @table-cell-padding-horizontal * -1;
						color: @gray;
					}
				}

				&:last-child {
					width: 100%;
				}

				button.see-detail {
					width: 100%;
					margin-top: 5px;
				}

				&.data-list {
					> div {
						display: table;
						width: 100%;
						color: @gray;

						.mobile-data-row {
							display: table-row;

							> div {
								padding: 5px 0;
								border-bottom: 1px solid @gray-lighter;
								display: table-cell;

								&:first-child {
									font-weight: bold;
									width: 30%;
								}

								&:last-child {
									text-align: right;
									width: 70%;
								}
							}
						}

						div.mobile-data-row:last-of-type {
							> div {
								border-bottom: 0px;
							}
						}
					}
				}
			}

			//
			// Expanded state
			//

			// force override on background color
			> tr {
				&.expanded {
					background-color: @color-green-lighter;

					td,
					&:nth-of-type(odd),
					&:hover {
						background-color: @color-green-lighter;
					}

					.data-list {
						border-top: 1px solid @gray-lighter;
						visibility: visible;
						display: inline-block;
					}

					.secondary {
						.arrow-hollow-up-after();

						&::after {
							position: absolute;
							right: 0;
							padding-right: @table-cell-padding-horizontal;
							margin-top: @table-cell-padding-horizontal * -1;
							color: @gray;
						}
					}
				}
			}
		}
	}
}

//
// Summary listing table
// --------------------------------------------------
.summary-listing {
	@media @mobile {
		.make-row(@grid-gutter-width * 2);

		tbody tr td {
			font-size: 13px;
		}

		tfoot tr td {
			font-size: 16px;
		}

		.light {
			font-size: 13px;
		}

		.col-monthly-est {
			.col-min-width();
		}
	}

	@media (min-width: @screen-sm-min) {
		.col-numbering {
			.col-min-width();
		}

		.col-weekly-est {
			.col-min-width();
			.text-right();
		}

		.col-weekly-est {
			.col-min-width();
			.text-right();
		}

		.col-monthly-est {
			strong,
			div {
				display: inline-block;
				min-width: 156px;
				.text-right();
			}
		}

		.col-listing {
			padding-right: 40px;
			.col-min-width();
		}
	}
}

th,
td {
	padding-left: 15px;
	padding-right: 15px;
}

tbody tr td {
	font-size: 15px;
}

tfoot tr td {
	padding: 12px 8px;
	font-size: 18px;
	border-top: 1px solid @gray-lighter;
	border-bottom: 1px solid @gray-lighter;
}

.light {
	color: @gray;
}

.col-numbering {
	.light();
	.text-center();
}

.col-weekly-est {
	.text-right();
}

.col-monthly-est {
	strong,
	div {
		display: inline-block;
		min-width: 156px;
		.text-right();
	}
}
