@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@linkIconSize: 20px;

.container {
	display: grid;
	grid-template-areas:
		'title status edit add'
		'info info info info'
		'listings listings listings listings';
	grid-template-columns: auto auto 1fr auto;
	grid-template-rows: auto auto auto;
	grid-column-gap: 15px;
	align-items: baseline;
	margin-bottom: 20px;

	@media @mobile {
		grid-template-areas:
			'back edit'
			'status add'
			'title title'
			'description description'
			'info info'
			'listings listings';
		grid-template-columns: auto auto;
		grid-template-rows: auto auto auto auto auto auto auto;
		align-items: center;
	}
}

.back-link,
.edit-link {
	a {
		font-size: @font-size-base-minus;
		display: flex;
	}

	@media @mobile {
		padding-bottom: 30px;
	}
}

.back-link {
	grid-area: back;
	display: none;

	@media @mobile {
		display: initial;
	}
}

.link-icon {
	width: @linkIconSize;
	height: @linkIconSize;
	fill: currentColor;
	margin: 0 5px;
}

.title {
	grid-area: title;
	@media @mobile {
		font-size: 22px;
		font-weight: bold;
	}
}

.status {
	grid-area: status;
	height: 23px;
}

.edit-link {
	grid-area: edit;

	@media @mobile {
		justify-self: end;
	}
}

.add-link {
	grid-area: add;
	font-size: @font-size-h4;

	@media @mobile {
		justify-self: end;
	}
}

.add-icon {
	height: 1.3em;
	width: 1.3em;
	fill: currentColor;
	position: relative;
	top: 4px;
}

.info {
	grid-area: info;
	@media not @mobile {
		display: inline-flex;
	}
}

.info-item {
	line-height: 20px;
	margin-top: 5px;

	@media @mobile {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	@media not @mobile {
		& + .info-item {
			padding-left: 20px;
			margin-left: 20px;
			border-left: 1px solid @text-color;
		}
	}
}

.info-title {
	@media @mobile {
		flex-basis: 150px;
	}
}

.listings {
	grid-area: listings;
}

.description {
	grid-area: description;
}
