@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';
@import '../../../../../Content/LoggedInWeb/mixins/box-shadow.less';

@slider-toggle-width: 49px;
@slider-toggle-height: 24px;
@slider-toggle-track-offset: 3px;
@slider-toggle-track-width: 39px;
@slider-toggle-track-height: 15px;
@slider-toggle-ball-width: 20px;
@slider-toggle-ball-height: 21px;
@slider-toggle-ball-top: floor((@slider-toggle-height - @slider-toggle-ball-height)/2);

// colors
@track-unticked-color: #e2e2e2;
@ball-unticked-color: #f1f1f1;
@track-ticked-color: #7fcea5;
@ball-ticked-color: #009d4b;
@box-shadow-color-darker: rgba(0, 0, 0, 0.7);

.toggle {
	display: inline-block;
	position: relative;
	width: @slider-toggle-width;
	min-height: @slider-toggle-height;
	line-height: @slider-toggle-height;
	vertical-align: middle;
	transition: all 0.3s ease;
	user-select: none;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: @slider-toggle-ball-top + @slider-toggle-track-offset;
		left: @slider-toggle-track-offset;
		width: @slider-toggle-track-width;
		height: @slider-toggle-track-height;
		border-radius: @slider-toggle-track-height;
		background-color: @track-unticked-color;
		transition: all 0.3s ease;
		visibility: visible;
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: @slider-toggle-ball-top;
		display: block;
		width: @slider-toggle-ball-width;
		height: @slider-toggle-ball-height;
		border-radius: 100%;
		background: @ball-unticked-color;
		.box-shadow-small;
		transition: all 0.3s ease;
		visibility: visible;
	}

	&:active::after {
		transform: scale(1.15, 0.85);
	}
}

.checkbox {
	opacity: 0;
	position: absolute;
}

.checkbox:checked ~ .toggle {
	&::before {
		background: @track-ticked-color;
	}

	&::after {
		left: @slider-toggle-track-width - @slider-toggle-track-offset - ceil(@slider-toggle-ball-width / 2);
		background: @ball-ticked-color;
	}
}

.checkbox:disabled ~ .toggle {
	pointer-events: none;

	&::before {
		background-color: #bcbdbc;
	}

	&::after {
		background: #d5d5d5;
	}
}

.checkbox:focus ~ .toggle {
	&::before {
		.box-shadow-small;
	}

	&::after {
		.box-shadow(0 3px 9px @box-shadow-color-darker);
	}
}
