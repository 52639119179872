@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.flex-container {
	display: flex;
	justify-content: space-between;
}

.flex-container-vertical {
	display: flex;
	flex-direction: column;
}

.excluded-info {
	.bold {
		font-weight: bold;
	}
}

.contact-method-message {
	padding-top: 10px;
	padding-bottom: 10px;
}

.contact-methods-options-select {
	width: 300px;
}

@amount-wrapper-width: 40px;
.amount {
	&-container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	&-wrapper {
		position: relative;

		&:before {
			position: absolute;
			font-size: @font-size-large;
			text-align: center;
			height: @input-height-base;
			line-height: @input-height-base;
			width: @amount-wrapper-width;
			background-color: @gray-lighter;
			border-radius: 4px;
			content: '$';
		}
	}

	&-input {
		width: 200px;
		padding-left: @amount-wrapper-width + 5px;
	}
}
