@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';
@import '../../../../../Content/LoggedInWeb/bootstrap/mixins/labels.less';
@import '../shared-styles/styles.less';

@itemSpacing: 10px;
@itemBorder: 2px solid @gray-mid;

.title-container {
	display: flex;
	margin: 0 0 20px 0;

	@media @small {
		flex-direction: column;
	}
}

.statement-name-container {
	flex: 1;
	display: flex;
	align-items: center;

	@media @small {
		flex-direction: column;
		align-items: flex-start;
		order: 1;
	}
}

.statement-name {
	font-size: 32px;
	line-height: 1.2;
	font-weight: 300;
	max-width: fit-content;
	margin: 0 20px 0 0;

	@media @small {
		font-size: 30px;
		margin: 0 0 10px 0;
	}
}

.run-status {
	.status-colors();
}

.back-to-giving-statements {
	font-size: @font-size-base-plus;

	> svg {
		fill: @btn-link-color;
		width: 14px;
		height: 14px;
	}

	@media @small {
		align-self: flex-end;
		margin: 0 0 10px 0;
	}
}

.details-container {
	display: flex;
	font-size: @font-size-base-minus;
	line-height: 1.2;
	position: relative;

	@media @small {
		flex-direction: column;
	}
}

.generated-date {
	padding-right: @itemSpacing;

	> strong {
		font-weight: 700;
	}

	@media @small {
		padding-right: 0;
		margin-bottom: @itemSpacing;
	}
}

.statements-generated {
	padding: 0 @itemSpacing;
	border-left: @itemBorder;

	> strong {
		font-weight: 700;
	}

	@media @small {
		padding: 0;
		border-left: none;
		margin-bottom: @itemSpacing;
	}
}

.regenerate-statements {
	padding-left: @itemSpacing;
	border-left: @itemBorder;

	> svg {
		fill: @btn-link-color;
		width: 12px;
		height: 12px;
	}

	@media @small {
		padding-left: 0;
		border-left: none;
	}
}

.delete-button {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}
