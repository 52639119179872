@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@line-padding: 10px;
@space-left: 20px;

.item {
	display: flex;
	padding: @line-padding 0;
	border-bottom: 1px solid @color-form-alt;
	margin: 0 @space-left;
	font-weight: bold;
	font-size: 14px;

	&-label {
		width: 40%;
		min-width: 130px;
		font-weight: normal;
		margin-bottom: 0;
	}

	&:last-child {
		border-bottom: none;
	}
}

.title {
	padding: @line-padding 0;
	background-color: @color-form;
	padding-left: @space-left;
}

.back-button {
	margin: @space-left;
	color: @color-red;
	cursor: pointer;

	&:hover {
		color: @color-red-dark;
	}

	&-svg {
		width: 14px;
		height: 14px;
		margin-right: 3px;
		fill: currentColor;
	}
}

.delete-button-wrapper {
	margin: @space-left;
}

.delete-button {
	width: 100%;
}
