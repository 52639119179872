@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.frequency-grid {
	display: block;
	box-sizing: border-box;
	position: relative;
	align-items: center;

	@frequency-button-border-width: 1px;
	@svg-height: 30px;
	@button-line-height: 1.2;

	overflow: hidden;

	&-tester-row,
	&-row {
		flex: 1;
		display: flex;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		border: @frequency-button-border-width solid @border-color;
		border-radius: @border-radius-base;
	}

	&-row:first-of-type {
		margin-bottom: -@frequency-button-border-width;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	&-row:not(:first-of-type) {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}

	&-tester-row {
		margin-top: 10px;
	}

	&-tester-row &-label:first-of-type,
	&-row &-label:first-of-type {
		border: none;
	}

	&-tester-row &-label:last-of-type,
	&-row &-label:last-of-type {
		border-right: none;
	}

	&-label {
		line-height: @button-line-height;
		flex: 1;
		display: flex;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		background-color: white;
		border-left: @frequency-button-border-width solid @border-color;
		border-right: @frequency-button-border-width solid @border-color;
		margin: 0;
		padding: 13px 10px;
		text-align: center;
		z-index: 1;
		width: 100%;
		cursor: pointer;
		transition: background-color @btn-transition-duration ease-in-out, color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		&.is-active {
			z-index: 3;
		}

		&:not(:first-of-type) {
			margin-left: -@frequency-button-border-width;
		}

		&:hover {
			z-index: 2;
		}
	}

	&-active {
		color: @btn-ghost-active-color;
		background-color: darken(@btn-ghost-active-bg, 10%);
		border-color: darken(@btn-ghost-active-border, 12%);
	}

	&-title {
		font-size: 15px;
		font-weight: normal;
	}

	&-radio {
		position: absolute;
		left: -9000px;
		opacity: 0;
	}
}
