@cd-batch-list-loading-width: 150px;
@cd-batch-list-loading-height: 138px;
@cd-batch-list-loading-offset: 200px;

.cd-batch-list-loading-wrapper {
	min-height: @cd-batch-list-loading-height + @cd-batch-list-loading-offset + @padding-base-vertical * 2;
	position: relative;
	.overlay(loading);

	.loading-box {
		top: @cd-batch-list-loading-offset;
		left: 50%;
		margin-top: 0;
		margin-left: -@cd-batch-list-loading-width / 2;
		width: @cd-batch-list-loading-width;
		height: @cd-batch-list-loading-height;
		font-size: 20px;
		font-weight: 700;
	}
}

.cd-batch-list-refresh-loading {
	color: @color-complementary-green;
	opacity: 1;

	&:hover,
	&:focus {
		color: @color-complementary-green;
		text-decoration: none;
	}

	.icon {
		fill: currentColor;
	}
}
