@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@item-border: 1px solid rgb(204, 204, 204, 0.6);

.description {
	font-size: @font-size-base-plus;
	line-height: 1.5;
	border-bottom: @item-border;

	> p {
		margin-bottom: 20px;
	}
}

.list-container {
	font-size: @font-size-base-minus;
	line-height: 1.2;
}

.list-name {
	font-weight: 700;
	padding: 15px 0;
	border-bottom: @item-border;

	&:after {
		content: ':';
	}
}

.list-item-container {
	padding: 0;
	margin: 0;
	height: 235px;
	overflow-y: auto;
	position: relative;
}

.list-item {
	display: block;
	padding: 15px 0;
	border-bottom: @item-border;
}

.error {
	margin: 15px 0 0 0;

	:global(.alert-icon) {
		margin: 0 15px 0 0;
	}
}
