@import "../bootstrap-variables";

.input-group {
	display: flex;

	.select-wrapper,
	.form-control {
		display: flex;
		flex: auto;
	}

	.form-control:focus + &-addon {
		border-color: @gray;
	}

	.form-control[disabled] + &-addon {
		color: @input-color-disabled;
		border-color: @input-border-disabled;
		border-left-color: transparent;
		background-color: @input-bg-disabled;
		opacity: @input-opacity-disabled;
		pointer-events: none;
		cursor: not-allowed;
	}

	&-transparent &-addon {
		background-color: white;
	}

	&-left .form-control {
		border-left: none;
		order: 1;
	}

	&-left {
		.select-wrapper,
		.form-control {
			&:first-child {
				border-left: none;
				order: 2;
				.roundSome(0, @input-border-radius, 0, @input-border-radius);
			}
		}

		.input-group-addon {
			.roundSome(@input-border-radius, 0, @input-border-radius, 0);
			border-left: 1px solid @input-border;
		}
	}

	&-addon {
		font-size: @font-size-base-plus;
		display: flex;
		width: auto;
		align-items: center;
		margin: 0;
		// matches bootstrap/forms.less style for input
		.transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

		.input-validation-error + & {
			.validation-red-border;
		}

		.input-validation-error:focus + & {
			border-color: darken(@color-red-validation, 10%);
		}

		svg {
			fill: currentColor;
		}
	}

	&-right .form-control {
		border-right: none;
	}
}
