@import "../../bootstrap-variables";
@import "../../mixins/svgs";

.mobile-popup {
	display: none;

	@media @small, (hover: none) {
		z-index: @zindex-tooltip;

		.mobile-popup-background {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.6);
		}

		.mobile-popup-content {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			min-height: 20%;
			max-width: unset;
			border-radius: 0;
			background: white;
			color: black;
			padding: @panel-body-padding-mobile;
			text-align: left;

			.close {
				color: @gray-light;
				.svg(@icon-size, @icon-size);
			}
		}

		.mobile-popup-header {
			font-size: @font-size-h2;
			margin: @padding-large-vertical @icon-size @panel-body-padding-mobile 0;
			line-height: @headings-line-height;
		}

		.mobile-popup-text {
			color: @gray;
			margin-right: @icon-size;
		}
	}
}
