@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.page-intro {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 0 @padding-large-horizontal * 2;
}

.intro-heading {
	flex: 1 0 auto;
}

.status-button {
	flex: 0 0 auto;
}

.intro-text {
	width: 100%;
}
