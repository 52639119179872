@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@contentWidth: 90%;

.container {
	border: 1px solid @gray-light;
	border-top: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
}

.email-template {
	position: relative;
	display: flex;
	flex-direction: column;
	border-top: 12px solid @color-red;
	border-radius: 4px;

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		padding: 20px;

		.organization {
			display: flex;
			align-items: center;

			.logo {
				width: 60px;
				height: 60px;
				margin-right: 10px;
			}

			.name {
				font-size: @font-size-base-plus;
			}
		}

		.email-heading-input {
			width: @contentWidth;
		}

		.header-divider {
			width: 30%;
			margin: 0 0 20px 0;
		}

		.date {
			font-size: @font-size-base-plus;
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: @color-form-alt;
		padding: 64px 20px 32px;
		border-top: 1px solid @gray-lighter;

		.content {
			display: flex;
			flex-direction: column;
			width: @contentWidth;

			.greeting {
				font-size: 20px;
				font-weight: 300;
			}

			.last-update {
				align-self: flex-end;
				font-size: @font-size-base-minus;
				font-style: italic;
			}

			.email-content-wrapper {
				display: flex;
				flex-direction: column;
				margin-top: 10px;
				margin-bottom: 18px;

				.message {
					font-size: @font-size-base-plus;
				}

				.char-limit {
					width: 100%;
					display: flex;
					align-content: center;
					justify-content: space-between;
					font-size: @font-size-base-minus;
					font-style: italic;
				}

				.limit-exceeded {
					color: @color-red;
				}
			}
		}

		.video-link {
			margin-bottom: 30px;
		}

		.bottom-panel {
			border-top: 12px solid @color-red;
			border-radius: 4px;
			background-color: white;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
			margin-bottom: 30px;

			.amount-panel {
				font-size: 20px;
				font-weight: 300;
				padding: 24px;
				text-align: center;

				.dollar-value {
					font-weight: 500;
				}
			}

			.button-panel {
				display: flex;
				justify-content: center;
				padding: 24px;
				background-color: @color-form;
				border-top: 1px solid @gray-lightest;
			}

			.download-statement-btn {
				pointer-events: none;
			}
		}

		.enquiry {
			font-size: 13px;
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: @color-form-alt;
		padding-bottom: 64px;
		border-bottom: 1px solid @gray-lighter;

		.pushpay-logo {
			width: 150px;
		}

		.footer-divider {
			color: @gray-lighter;
			width: @contentWidth;
			height: 2px;
		}

		.copyright {
			font-size: 13px;
			color: @gray;
		}
	}
}

.btn-group {
	display: flex;
	justify-content: center;
	background-color: white;
	padding: 20px 40px;

	:global(.btn) {
		flex-grow: 1;
		max-width: 260px;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: 10px;
		margin-right: 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

@media @small {
	.btn-group {
		flex-wrap: wrap;
		padding-left: 20px;
		padding-right: 20px;

		:global(.btn) {
			margin: 0;
			max-width: none;
			flex-basis: 100%;

			&:not(:global(.btn-default)) {
				order: 1;
				margin-top: 20px;
			}
		}
	}
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(77, 77, 77, 0.2);
}

.bust-overlay {
	position: relative;
	z-index: 1;
}

.action-container {
	height: 42px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.save-as-draft-button {
	background-color: #ffffff;

	&:hover {
		background-color: @color-red;
	}
}
