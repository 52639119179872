#error-list {
	background-color: lighten(#f00, 45%);
	z-index: 1000;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	ul {
		list-style: none;
		padding: 0;
		margin-bottom: 0;

		li {
			padding-left: 10px;
			padding-right: 10px;
			border-top: 1px solid lighten(#f00, 40%);
			font-size: 12px;
			@media screen and (min-width: 1025px) {
				padding-left: @sidebar-cap-width + 5px;
			}
		}
	}
}
