@import '../../bootstrap-variables';

//
// Nav bar
// --------------------------------------------------
@media @desktop {
	.noauth {
		.navbar-default {
			.container-fluid {
				display: none;
			}

			height: 12px;
			min-height: 12px;
		}

		#shell-header {
			display: none;
			height: @shell-header-height;
		}

		// Remove with UseNewPushpayShell
		#cap-header {
			header {
				display: none;
			}
			height: @navbar-cap-height-noauth;
			min-height: @navbar-cap-height-noauth;
		}
	}
}


header.navbar {

	height: @navbar-height; // should be fixed;

	.navbar-nav {
		.logo {
			.svg {
				color: white;
			}
			padding-top: 9px;
		}
	}

	.fancy-logo {

		li {
			width: @sidebar-width;
			text-align: center;
		}

		.fancy-logo-slide-in();

		.svg {
			color: white;
		}
	}

	.navbar-header {
		display: none;
	}

	// we set the position of the burger with absolute
	// so we can easily center other items in mobile view
	.navbar-toggle {
		position: absolute;
		left: 0;
		top: 0;
	}

	// Note
	// there is two different drop down menus one for mobile one for desktop
	// just the way bootstrap renders the top nav's
	.organizations {

		margin-right: 20px;

		> li > a,
		> a {
			span {
				.truncate;
				.no-wrap;
				max-width: 350px;
				padding-right: 10px;
				display: inline-block;
				vertical-align: middle; // get's rid of the mystery margin
			}
		}

		.dropdown {
			> a {
				.arrow-hollow-down-after;
				padding-right: 10px;

				&::after {
					position: relative;
				}
			}
		}

		.dropdown-menu {
			left: @sidebar-width;
		}
	}

	.profile {
		@media @desktop {
			margin-right: 40px;
		}
		a {
			font-size: @font-size-base;
			padding: 10px 15px;
			color: @white-70;

			> span {
				padding-right: 6px;
				.arrow-hollow-down-after;


				&::after {

					margin-top: 11px;
				}
			}

			.gravatar {
				display: inline-block;
				.round(40);
				vertical-align: middle;

			}
		}
	}

	// mobile
	@media @mobile {

		.navbar-header {
			text-align: center;
		}

		.navbar-nav {
			display: inline-block;
		}

		.navbar-brand {
			padding-top: @navbar-padding-vertical;
			height: auto;
			float: none;
		}

		.profile {

			position: absolute;
			top: 0;
			right: 0;
			display: table;

			> a {
				.sliding-hover(darken(@brand-primary, 10%));
				.flyout-fadeout();
				height: @navbar-height;
				display: table-cell;
				vertical-align: middle;

				// user name
				> span {
					display: none;
				}
			}
		}

		//
		// override bootstrap positioning of the dropdown
		.organizations {

			margin-left: 0;
			margin-right: 0;

			> a{
				span {
					max-width: 50vw;
					padding-right:9px;
				}
			}

			&.dropdown {
				> a {
					.arrow-hollow-down-after;
					padding-right: 10px;
					&::after {
						position: relative;
					}
				}
			}

			.dropdown-menu {
				left: 0;
				max-height: 255px;
			}
		}
	}
}

#shell-header {
	top: 0;
	width: 100%;
	height: @shell-header-height;
	position: fixed;
	z-index: @zindex-navbar-fixed + 1;
}

// Remove with UseNewPushpayShell
#cap-header {
	top: 0;
	width: 100%;
	height: @navbar-cap-height;
	position: fixed;
	z-index: @zindex-navbar-fixed + 1;

	@media @mobile {
		height: @navbar-cap-height-mobile;
		box-shadow: none;
	}

	div {
		font-family: @cap-font-family;
		font-size: @cap-font-size;
	}
}
