@import '../../../Content/LoggedInWeb/bootstrap-variables.less';

@full-size: 160px;
@mobile-size: 85px;
@vertical-spacing: 30px;

.step-container {
	margin: @vertical-spacing 0;
	display: flex;

	@media @small {
		flex-direction: column;
		border-top: 1px solid @gray-lighter;
		border-bottom: 1px solid @gray-lighter;
		margin: @vertical-spacing -@grid-gutter-width;
	}
}

.step-pillar {
	display: flex;
	flex: 1 1 0;
	flex-direction: column;
	align-items: center;

	&:hover {
		text-decoration: none;

		.title {
			text-decoration: underline;
		}
	}

	@media @small {
		flex: 1 1 130px;
		flex-direction: row;
		padding: 0 @grid-gutter-width;

		& + .step-pillar {
			border-top: 1px solid @gray-lighter;
		}
	}
}

.step-content {
	width: @full-size;

	@media @small {
		flex-grow: 1;
		width: auto;
		margin-left: @grid-gutter-width;
	}
}

.container {
	display: flex;

	@media @small {
		flex-direction: column;
	}
}

.letter-container {
	flex-grow: 1;
}

.signature {
	width: 250px;
	margin-bottom: 15px;
}

.heading {
	font-weight: bold;
	font-size: @font-size-large;
	padding-top: @vertical-spacing;
	color: @text-color;

	@media @small {
		padding-top: 15px;
	}
}

.title {
	font-size: @font-size-base-plus;
	color: @brand-primary;
}

.step-icon-container {
	position: relative;
}

.step-icon {
	max-width: @full-size;

	@media @small {
		max-width: @mobile-size;
	}
}

.step-complete {
	position: absolute;
	top: 0;
	left: 0;
	max-width: @full-size;
	background-color: fade(@color-green, 70);
	border-radius: 50%;
	height: 100%;
	color: white;

	@media @small {
		max-width: @mobile-size;
		height: @mobile-size;
	}
}

.panel-heading {
	margin-top: 0;
	margin-bottom: 0;
}

.panel-guarantee {
	display: flex;
	align-items: center;
}

.icon-pdf {
	fill: @color-red;
	height: 32px;
	width: 32px;
	margin-right: 10px;
}

.video-container {
	min-width: 400px;
	padding: 0 @vertical-spacing;

	@media @small {
		margin: 0 -@grid-gutter-width;
	}
}

.video-header {
	margin: 0 0 @grid-gutter-width;
	font-weight: 600;
}

.video-title {
	margin-top: 0;
	margin-bottom: 3px;
	font-size: 15px;
	font-weight: 600;
}

.video {
	position: relative;
	width: 100%;
	height: auto;
	padding-bottom: 56.25%;
	margin-bottom: @vertical-spacing;
}

.video-frame {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
}
