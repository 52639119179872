#insights {

	.line-chart {
		margin-top: @panel-body-padding;

		.linearea {
			fill: none;
			stroke-width: 2;
			stroke: @gray;
		}
		.dot {
			fill: white;
			stroke: @gray;
			stroke-width: 2px;
		}
	}
}
