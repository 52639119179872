@import "mixins";
@import "../bootstrap/mixins/size.less";
@import "../bootstrap/mixins/vendor-prefixes";

.block {
	display: block;
}

.relative {
	position: relative;
}

.hidden {
	display: none;
	visibility: hidden;
}
.no-wrap {
	white-space: nowrap;
}
.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
}

.equal-height-columns {
	display: flex;
	flex-wrap: wrap;
	> div {
		display: flex;
	}
}

.colored-circle(@color, @diameter) {
	background-color: @color;
	.square(@diameter);
	border-radius: 50%;
}


.overflow-container(@direction: overflow-y) {
	overflow: hidden;
	// must be 'scroll' for iOS momentum scrolling to be applied, not 'auto'
	@{direction}: scroll;
	-webkit-overflow-scrolling: touch;
}

.display-flex {
	display: flex;
}

.flex-auto {
	flex: auto;
}

.flex-none {
	flex: none;
}

.flex-even-space {
	display: flex;
	place-content: space-between;
}
