@import 'donut-chart';
@import 'bar-chart';
@import 'line-chart';
@import 'insights-tooltip';

#insights {
	.insights-vars;

	.chart {
		display: inline-block;

		.axis {
			font-size: @font-size-small;

			&.sub-heading {
				color: @gray;
			}
		}
	}

	// less than 550px
	@media (max-width: @panel-max-width) {
		.chart {
			.axis {
				font-size: @font-size-small;
			}
		}
	}

	.linebar-chart {

		.top-text {
			font-size: @font-size-base;
			fill: @gray;
		}

		.axis {


			// this is the axis line
			> path {
				fill: none;
				stroke: none;
			}

			line {
				fill: none;
				stroke: none;
			}

			text {
				fill: @gray;
			}
		}

		.horizontal-lines {
			stroke-width: 1;
			stroke: @gray-lighter;
			fill: none;

			&.bottom {
				stroke-width: 2;
				stroke: @gray-lighter;
				fill: none;
			}
		}
	}
}
