@import '../../bootstrap-variables';

.affix-wrapper {
	.affix {
		position: fixed;
		transform: translate3d(0, 0, 0);
		z-index: @zindex-dropdown + 1;
		left: 0;
		right: 0;

		@media (min-width: @screen-sm-min) {
			left: @grid-gutter-width;
			right: @grid-gutter-width;
		}

		@media (min-width: @screen-md-min) {
			left: 298px;
			right: 40px;
			max-width: @article-max-width - @grid-gutter-width * 2 - @navbar-padding-horizontal * 2;
		}

		&.affix-top {
			top: 0;
		}

		&.affix-bottom {
			bottom: 0;
		}
	}

	.affix-top:not(.affix),
	.affix-bottom:not(.affix) {
		position: relative;
	}

	.position-sensor {
		 position: fixed;
		 height: 0;
		 width: 0;
		 visibility: collapse;
		 backface-visibility: hidden;
	}

	.window-height-sensor {
		 position: fixed;
		 height: 0;
		 width: 0;
		 bottom: 0;
		 visibility: collapse;
		 backface-visibility: hidden;
	}

	.resize-sensor {
		 position: absolute;
		 left: 0;
		 top: 0;
		 bottom: 0;
		 width: 0;
		 backface-visibility: hidden;
	}
}
