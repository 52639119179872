.loading-spinner-text {
	&-enter {
		opacity: 0.01;
	}

	&-enter&-enter-active {
		opacity: 1;
		transition: opacity 200ms linear;
	}

	&-leave {
		opacity: 1;
	}

	&-leave&-leave-active {
		opacity: 0.01;
		transition: opacity 200ms linear;
	}
}
