@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@popup-background-color: white;
@popup-width: 210px;
@popup-notch-size: 15px;
@popup-notch-position: -8px;
@popup-position: @popup-width + @popup-notch-size;

.popup {
	position: absolute;
	top: 0;
	width: @popup-width;
	padding: 14px;
	z-index: @zindex-notification-popup;
	background: @popup-background-color;
	box-shadow: 0 0 10px @gray-lighter;
	line-height: @line-height-small;
	border-radius: 4px;
	font-size: @font-size-small;
	color: @text-color;

	&-title {
		margin-top: 0px;
		margin-bottom: 10px;
	}

	&-btn-dismiss {
		position: absolute;
		top: 12px;
		right: 12px;
	}

	&-btn-ok {
		font-size: @font-size-small;
	}
}

.popup-notch {
	&:before {
		top: @popup-notch-size;
		height: @popup-notch-size;
		width: @popup-notch-size;
		background-color: @popup-background-color;
		border: 0;
		box-shadow: -2px -2px 10px -2px @gray-lighter;
	}
}

.left {
	left: -@popup-position;
	&:before {
		transform: rotate(135deg);
		right: @popup-notch-position;
	}
}

.right {
	right: -@popup-position;
	&:before {
		transform: rotate(-45deg);
		left: @popup-notch-position;
	}
}
