.member-type-mapping-list-item {
  background-color: #f7f7f8;
}
.member-type-mapping-label {
  padding-left: 20px;
}
@media screen and (max-width: 1024px) {
  .member-type-mapping-label {
    padding-left: 0px;
  }
}
