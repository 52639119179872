@import "../../bootstrap-variables";

.giving-statements {
	.prep {
		.circled-svg-md {
			.colored-circle(@color-complementary-green, 50px);
			padding: 15px;
			float: left;
			color: white;

			.svg-tax-label {
				width: 90px;
				height: 20px;
				margin-left: -6px;
			}

			.svg-fund-single-box {
				width: 34px;
				height: 27px;
				margin-left: -10px;
				margin-top: -2px;
			}

			.svg-cal-white {
				width: 22px;
				height: 23px;
				margin-top: -2px;
				margin-left: -1px;
			}

			.svg-vt-tax {
				width: 32px;
				height: 30px;
				margin-left: -6px;
				margin-top: -6px;
			}

			.svg-transactions-transparent {
				width: 32px;
				height: 32px;
				margin-left: -6px;
				margin-top: -6px;
			}

			.svg-community-outline {
				width: 32px;
				height: 32px;
				margin-left: -6px;
				margin-top: -6px;
			}
		}
	}

	.prep-container {

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin: @padding-base-horizontal 0 @padding-base-horizontal 0;

		.prep-text {
			margin-left: @padding-base-horizontal;
		}

		@media @mobile {
			flex-direction: column;
			margin: @padding-base-horizontal 0 @padding-base-horizontal * 2;

			.circled-svg-md {
				margin: 0 0 @padding-base-horizontal;
			}
		}
	}
}
