@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.process-header {
	margin-bottom: 24px;
	line-height: 1.5;

	@media @small {
		display: none;
	}
}

.btn-container {
	display: flex;
	justify-content: center;
	border-bottom: 1px solid @gray-lightest;
	margin-bottom: 40px;
	padding: 40px 0;

	@media @small {
		padding: 30px 0;
	}

	> button {
		width: 210px;

		@media @small {
			width: 100%;
		}
	}
}
