@easing: cubic-bezier(0.770, 0.000, 0.175, 1.000);	// CSS Easing Equation customisation at http://matthewlein.com/ceaser/

// Hover state transitions for global links and buttons
.sliding-hover(@hover-color) {
	position: relative;
	&::before {
		z-index: -1;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 40%;
		height: 100%;
		background-color: transparent;
		transition: background-color 0.2s ease-out,
			width 0s 0.2s;
	}
	&:hover {
		&:before {
			width: 100%;
			background-color: @hover-color;
			transition: background-color 0.2s ease-out,
				width 0.2s ease-out;
		}
	}
}

// Open transitions for flyouts (mobile nav and profile)
@flyout-transition-timing: ~"366ms";
@flyout-transition-easing: ~"ease-out";
@flyout-transform-transition: ~"transform @{flyout-transition-timing} @{flyout-transition-easing}";
@flyout-opacity-transition: ~"opacity @{flyout-transition-timing} @{flyout-transition-easing}";
@flyout-visibility-transition: ~"visibility 0s";
@flyout-visibility-transition-delayed: ~"visibility 0s @{flyout-transition-timing}";

.flyout-transition() {
	visibility: hidden;
	opacity: 0;
	top: 40px;
	z-index: @zindex-popover;
	position: fixed;
	transform-origin: top left;
	transition: @flyout-transform-transition,
		@flyout-opacity-transition,
		@flyout-visibility-transition-delayed;
	&.open {
		visibility: visible;
		opacity: 1;
		transform: translate3d(0, 20px, 0);
		transition: @flyout-transform-transition,
		@flyout-opacity-transition,
		@flyout-visibility-transition;
	}
}
.flyout-fadein() {
	visibility: hidden;
	opacity: 0;
	transition: @flyout-visibility-transition-delayed,
		@flyout-opacity-transition;
	&.open {
		visibility: visible;
		opacity: 1;
		transition: @flyout-visibility-transition,
			@flyout-opacity-transition
	}
}
.flyout-fadeout() {
	transition: @flyout-visibility-transition,
		@flyout-opacity-transition;
	&.open {
		visibility: hidden;
		opacity: 0;
		transition: @flyout-visibility-transition-delayed,
			@flyout-opacity-transition;
	}
}

.fancy-logo-slide-in() {

	li {
		i {
			opacity: 0;
			left: -113px;
			position: absolute;
			transform-origin: left;
			transition: opacity 450ms ease-out,
				transform 0ms ease-out 451ms;
		}
	}

	&.in {
		li {
			i {
				opacity: 1;
				transform: translate3d(162px,0, 0);
				transition: transform 450ms ease-out,
					opacity 450ms ease-out 200ms;
			}
		}
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
