.svg {
	fill: currentColor;
	font-style: normal;
	text-decoration: none;
}

.svg(@width, @height) {
	.svg {
		width: @width;
		height: @height;
	}
}

.svg-pushpay-logo-footer {
	fill: initial;
}
