.close {
	color: @gray;
	opacity: 1;
	transition: color .2s ease-in-out;

	&:hover,
	&:focus {
		color: @gray-mid;
		opacity: 1;
	}
}
