@giving-summary-item-width: 70px;

.giving-summary-months {
	max-width: 420px;

	label {
		min-width: @giving-summary-item-width;

		&:first-child > span {
			border-bottom-left-radius: 0;
		}

		&:last-child > span {
			border-top-right-radius: 0;
		}

		&:nth-child(6) > span {
			border-top-right-radius: @radio-grid-border-radius;
		}

		&:nth-child(7) > span {
			border-bottom-left-radius: @radio-grid-border-radius;
		}
	}
}

.giving-summary-days {
	max-width: 500px;

	> label {
		min-width: @giving-summary-item-width;
	}
}
