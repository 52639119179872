@import "../../bootstrap-variables";
@import "../../mixins/helpers";


@data-grid-form-horizontal-margin: -15px;
@data-grid-form-horizonal-shadow-size: 4px;
@data-grid-row-clickable-hover-offset: 6px;
@data-grid-row-clickable-hover-background: @gray-lightest;
@data-grid-row-clickable-hover-border: @gray;
@data-grid-row-new-background: @color-green-lighter;
@data-grid-row-new-border: @color-green;
@data-grid-sorted-border-width: 4px;
@data-grid-icon-width: 16px;
@data-grid-icon-height: 14px;
@data-grid-header-padding: @table-cell-padding - 4px;
@data-grid-cell-padding: @table-cell-padding @table-cell-padding-horizontal;
@data-grid-cell-padding-mobile: 3px;
@data-grid-row-padding-vertical-mobile: 10px;
@data-grid-row-padding-horizontal-mobile: 10px;

.data-grid {
	@media @mobile {
		border-top: 1px solid @border-color;
	}

	&-cell {
		overflow: hidden;
		text-overflow: ellipsis;
		padding: @data-grid-cell-padding;
		white-space: nowrap;

		@media @mobile {
			padding-top: @data-grid-cell-padding-mobile;
			padding-bottom: @data-grid-cell-padding-mobile;
		}

		&.data-grid-cell-sortable {
			cursor: pointer;

			> .icon {
				display: inline-block;
				fill: @gray-light;
				width: @data-grid-icon-width;
				height: @data-grid-icon-height;
				margin-left: 4px;
				vertical-align: middle;
			}

			&.data-grid-cell-sortable-sorted {
				padding-bottom: @data-grid-header-padding - @data-grid-sorted-border-width;
				border-bottom: @data-grid-sorted-border-width solid @gray-dark;
			}

			&-sorted,
			&:hover,
			&:focus {
				outline: none;

				> .icon {
					fill: currentColor;
				}
			}
		}
	}

	&-empty-message {
		color: @gray;

		@media not @mobile {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 35px;
			height: 400px;
			text-align: center;
			border-bottom: 1px solid @gray;
		}
		@media @mobile {
			font-size: 25px;
			padding: @grid-gutter-width 0;
		}
	}
}

.data-grid-row-new-background-border {
	background-color: @data-grid-row-new-background;
	border-color: @data-grid-row-new-border;
}

.data-grid-row-new-data {
	.data-grid-row-new-background-border;

	&.data-grid-row-data-clickable {
		&:active,
		&:hover,
		&:focus {
			.data-grid-row-new-background-border;
		}
	}
}

.data-grid-row {
	&:nth-child(even) {
		.data-grid-row-data {
			background-color: @table-bg-accent;

			&.data-grid-row-data-clickable {
				.data-grid-row-data-clickable-background;
			}
		}

		&.data-grid-row-new {
			.data-grid-row-data {
				.data-grid-row-new-data;
			}
		}
	}

	&-new {
		position: relative;
		z-index: 1;

		.data-grid-row-data {
			.data-grid-row-new-data;
		}
	}

	&-header,
	&-data {
		display: flex;
		align-items: baseline;

		// override flexbox with grid if available
		@media screen {
			body:not(.no-cssgrid) & {
				display: grid;
			}
		}

		@media @mobile {
			flex-wrap: wrap;
		}
	}

	&-header {
		border-top: 1px solid @gray;
		border-bottom: 1px solid @gray;
		font-size: @font-size-data-grid-header;
		font-weight: 700;

		.data-grid-cell {
			padding: @data-grid-header-padding @table-cell-padding-horizontal;
		}
	}

	&-data {
		border-top: 1px solid @border-color;
		border-bottom: 1px solid @border-color;
		margin-top: -1px;
		transition: background 0.15s ease-in-out;

		@media @mobile {
			padding: @data-grid-row-padding-vertical-mobile @data-grid-row-padding-horizontal-mobile;
		}

		.data-grid-cell {
			&:focus {
				outline: none;
			}
		}
	}

	&-panel {
		margin: 0 @data-grid-form-horizontal-margin;
		.box-shadow-small;
		background: white;

		&:focus {
			outline: none;
		}

		@media @mobile {
			position: fixed;
			z-index: @zindex-navbar-fixed + 1;
			top: 0;
			left: 0;
			margin: 0;
			border-radius: 0;
			width: 100vw;
			height: 100%;
			.overflow-container();
		}
	}
}

.data-grid-row-data-clickable-background {
	&:active,
	&:hover,
	&:focus {
		background-color: @data-grid-row-clickable-hover-background;
	}
}

.data-grid-row-data-clickable {
	cursor: pointer;

	.data-grid-row-data-clickable-background;

	&:active,
	&:hover,
	&:focus {
		position: relative;
		margin-left: -@data-grid-row-clickable-hover-offset;
		margin-right: -@data-grid-row-clickable-hover-offset;
		border-radius: 3px;
		outline: none;
		padding-left: @data-grid-row-clickable-hover-offset - 1;
		padding-right: @data-grid-row-clickable-hover-offset - 1;
		z-index: 1;

		@media screen {
			border: 1px solid @data-grid-row-clickable-hover-border;
		}

		@media @mobile {
			padding-left: @data-grid-row-clickable-hover-offset + @data-grid-row-padding-horizontal-mobile - 1;
			padding-right: @data-grid-row-clickable-hover-offset + @data-grid-row-padding-horizontal-mobile - 1;
		}
	}
}

// This stops the page from scrolling in the background when a check is being edited on a mobile device
html,
body {
	&.data-grid-is-expanded {
		@media @mobile {
			position: relative;
			height: 100vh;
			overflow: hidden;
		}
	}
}
