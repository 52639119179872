@import "../bootstrap-variables.less";

.table {
	width: 100%;
	max-width: 100%;

	&.table-striped {

		> tbody > tr {

			&:nth-of-type(odd) {
				background-color: @table-bg-accent;
			}
		}
	}

	@media (min-width: @screen-sm-min) {
		& > tbody > tr.selected,
		&.table-striped > tbody > tr.selected {
			background-color: @color-green-lighter;

			td {
				border-top: 1px solid @white-50;
			}
		}
	}

	thead {
		tr {

			background-color: @gray-mid;

			th {
				padding: 12px 10px;
				font-weight: normal;
				background-color: @gray-mid;
				color: white;
				font-size: @table-header-font-size;

				&:first-child {
					.roundSome(@border-radius-base, 0, 0, 0);

					@media (max-width: @screen-sm-min) {
						.roundSome(0, 0, 0, 0);
					}
				}

				&:last-child {
					.roundSome(0, @border-radius-base, 0, 0);

					@media (max-width: @screen-sm-min) {
						.roundSome(0, 0, 0, 0);
					}
				}
				&.dark {
					background-color: @gray-dark;
				}
			}

			label {
				color: white;
			}
		}
	}

	tbody {
		tr {
			td {
				font-size: @table-font-size;
				padding: @table-cell-padding @table-cell-padding-horizontal;

				&.tr-truncate {
					overflow: auto;
					text-overflow: ellipsis;
					overflow-x: hidden;
				}
			}
		}
	}

	&[data-selectable="true"] tbody tr,
	tbody tr[data-row-url]  {
		cursor: pointer;

		&:hover {
			background-color: @gray-tr-hover;
		}

		@media (min-width: @screen-sm-min) {
			&.selected:hover {
				background-color: darken(@color-green-lighter, 5%);
			}
		}
	}

	&.table-line {
		thead {
			tr, th {

				background-color: transparent;
				color: @text-color;
				font-weight: bold;

				th {
					border-bottom: 1px solid @gray;
					border-top: 1px solid @gray !important;
				}
			}
		}

		tbody {
			td {
				border-bottom: 1px solid @gray-lighter !important;
			}
		}
	}


}
