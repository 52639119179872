//
// footer
// --------------------------------------------------
main {
	> footer {
		color: @gray-footer-text;
		font-size: 14px;
		font-weight: 200;
		margin-top: 100px;
		align-self: flex-end;
		width: 100%;
		max-width: 1920px;
		padding: 0 20px;

		.pushpay-brand-padding {
			margin-top: 10px;
		}

		.get-app {
			text-align: right;
			padding: 0px 10px 0px 0px;

			h5 {
				line-height: 24px;
			}

			.get-app-info, .app-icons {
				display: inline-block;
				vertical-align: middle;
			}

			.app-icons {
				.svg {
					color: @gray-light;
				}

				// override default
				a {
					&:hover {
						text-decoration: none;
					}
				}

				.svg-apple {
					&:hover {
						fill: @gray-dark;
					}
				}

				.svg-android {
					&:hover {
						fill: @color-green-android;
					}
				}
			}

			.get-app-info {
				padding: 0px 10px 0px 0px;

				span {
					white-space: nowrap;
				}
			}

			.app-icons {
				i {
					margin: 1px 5px 0px 0px;
				}
			}
		}

		.row {
			padding-bottom: 20px;
			margin: 0;
		}

		@media @mobile {
			padding-left: 0;
			padding-right: 0;

			background-color: @color-form;
			border-top: 1px solid @panel-inner-border;
			.text-center;

			.get-app {
				.text-center;
				padding: 0px;

				.get-app-info {
					padding: 0px;
				}
			}

			.row {
				padding: 20px;
			}
		}

		hr {
			margin-top: 0;
			margin-bottom: 10px;
		}
	}

	#cap-footer {
		font-family: @cap-font-family;
		font-size: @cap-font-size;
	}
}
