@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.summary-panel {
	display: flex;
}

.panel-left {
	flex: 0 0 auto;
	width: 200px;
	padding-right: 25px;
	border-right: 2px solid @border-color;
}

.panel-right {
	flex: 1 1 auto;
	padding-left: 25px;
}

.chart {
	color: @color-blue;
	font-size: 16px;
	line-height: 1.5em;
	border-bottom: 2px solid @border-color;
	padding-bottom: 20px;

	p {
		margin: 0;
	}
}

.chart-wrapper {
	margin-bottom: 15px;
}

.uploaded-summary {
	padding: 20px 0;

	h2 {
		margin: 0 0 0.5em;
		font-size: 14px;
		font-weight: 700;
	}

	strong {
		font-weight: 400;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		margin: 0 0 0.5em;
	}
}

.segment-card {
	padding: 0 0 25px;
	margin: 0 0 25px;
	border-bottom: 2px solid @border-color;

	header {
		display: flex;
		margin: 0 0 10px;

		h1 {
			margin: 0;
			font-size: 18px;
			font-weight: 700;
			line-height: normal;
			flex: 1 1 auto;
		}

		a {
			flex: 0 0 auto;
			white-space: nowrap;
		}
	}
}

.segment-detail {
	font-size: 14px;

	strong {
		font-size: 24px;
		font-weight: 400;
		padding-right: 5px;
	}
}

@media @small {
	.summary-panel {
		flex-direction: column;
	}

	[class*='panel'] > .summary-panel {
		padding: 0;
	}

	.panel-left {
		width: 100%;
		padding: 0;
		border: none;
	}

	.chart {
		text-align: center;
		padding: 25px 25px 50px;

		p {
			max-width: 240px;
			margin: auto;
		}
	}

	.chart-wrapper {
		width: 160px;
		margin: 0 auto 15px;
	}

	.uploaded-summary {
		padding: 20px;
		border-bottom: 2px solid @border-color;

		h2 {
			font-size: 18px;
		}

		strong {
			font-size: 24px;
			font-weight: 400;
			padding-right: 5px;
		}
	}

	.panel-right {
		padding: 0;
	}

	.segment-card {
		padding: 25px;
		margin: 0;
	}
}

.tooltip {
	color: @gray-light;

	svg {
		fill: currentColor;
		margin-bottom: -4px;
	}
}
