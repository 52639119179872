#insights {
	.insights-vars;

	.giving-by-week {

		.chart {

			.horizontal-lines {

				&.bottom {
					stroke: @color-purple;
				}
			}
		}
	}
}
