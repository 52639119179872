#insights {
	.insights-vars;

	.weekly-giving-total {

		.panel-body {
			.divider {
				margin-top: @panel-body-padding;
				margin-bottom: 0;
				border-bottom: 0;

				h4 {
					margin-bottom: 0;
				}
			}

			padding-bottom: 22px;
		}
	}
}
