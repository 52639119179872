@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@bubble-bg-color: white;
@bubble-font-size: 12px;
@bubble-gutter-width: 10px;
@button-height: 30px;
@tail-size: 8px;
@tail-offset: 30px;
@tail-shadow-offset: 3px;

@bubble-offset: ~'calc(50% - ' @tail-offset + @tail-size * pow(2, 0.5) ~')';

.container {
	position: absolute;
	top: -@tail-size;
	background: @bubble-bg-color;
	color: @gray-darker;
	cursor: default;
	font-size: @bubble-font-size;
	color: @gray-darker;
	border-radius: 8px;
	animation: scale-in 0.15s cubic-bezier(0.2, 0, 0.13, 1.5) 0s 1 normal forwards;

	:global(.btn-primary) {
		width: 130px;
		height: @button-height;
		line-height: @button-height;
		font-weight: normal;
		font-size: @bubble-font-size;
		margin-right: 5px;
	}

	@media not @small {
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			box-sizing: border-box;
			border: @tail-size solid @bubble-bg-color;
			border-color: @bubble-bg-color;
			transform-origin: 0 0;
			transform: rotate(-45deg);
			transform-origin: -@tail-shadow-offset 50%;
			box-shadow: -@tail-shadow-offset @tail-shadow-offset 3px 0 rgba(0, 0, 0, 0.3);
		}

		&.right {
			left: @bubble-offset;

			&:after {
				left: @tail-offset;
			}
		}

		&.left {
			right: @bubble-offset;

			&:after {
				right: @tail-offset;
			}
		}
	}

	@media @small {
		animation-name: scale-in-mobile;
		position: fixed;
		top: 50%;
		left: 50%;
		box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.3);
		z-index: 5;
	}
}

@keyframes scale-in {
	0% {
		opacity: 0;
		transform: translateY(-105%) scale(0.5);
	}

	100% {
		opacity: 1;
		transform: translateY(-105%) scale(1);
	}
}

@keyframes scale-in-mobile {
	0% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.5);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
}
