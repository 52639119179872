.delete-icon {
	width: 20px;
	height: 20px;
	fill: currentColor;
	margin-right: 4px;
}

.delete-button {
	font-size: 14px;
}
