/* Modified version of https://davidwalsh.name/css-flip */

@flip-transition: transform 0.15s ease-in-out;

/* entire container, keeps perspective */
.flip-container {
	transform-origin: center center;
	transform-style: preserve-3d;

	// Perspective causes a glitch in the animation in Edge browser so only add this class if not
	// Use Source/Pushpay.Public/JavaScript/LoggedInWeb/utils/browser-detection.ts to detect
	&.flip-container-perspective {
		perspective: 10000px;
	}
}

/* flip speed goes here */
.flip-flipper {
	transition: @flip-transition;
	transform-style: preserve-3d;
}

.flip-front,
.flip-back {
	position: absolute;
	top: 0;
	left: 0;
	backface-visibility: hidden;
	transition: @flip-transition;
	transform-style: preserve-3d;
}

/*  front pane, placed above back */
.flip-front {
	z-index: 2;
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.flip-back {
	transform: rotateY(-180deg);
}

/*  flip the pane */
.flip-container-flipped {
	.flip-back {
		transform: rotateY(0deg);
	}

	.flip-front {
		transform: rotateY(180deg);
	}
}
