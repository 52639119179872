@import "../../bootstrap-variables";

.cd-allocation-header {
	display: flex;
	align-items: center;
	margin-bottom: @grid-gutter-width*1.5;

	@media @small {
		flex-direction: column;
		.text-left;
	}

	> h1 {
		display: flex;
		flex: 1 1 auto;
		align-items: center;
		margin-right: @grid-gutter-width;

		@media @small {
			.block;
			width: 100%;
			margin-bottom: @grid-gutter-width*1.5;
			margin-right: 0;
		}

		.cd-allocation-batch-name {
			margin-right: @grid-gutter-width*1.5;

			@media @small {
				.block;
				margin-right: 0;
			}
		}
	}
}

.cd-allocation-header,
.cd-allocation-footer {
	.btn-default {
		flex: 0 1 auto;
		padding: 0 @panel-body-padding;

		@media @small {
			width: 100%;
		}
	}
}

.cd-allocation-footer {
	display: flex;
	align-items: center;
	margin: @grid-gutter-width*1.5 0;

	@media @small {
		flex-direction: column;
	}

	.cd-allocation-complete-button {
		order: 1;

		@media @small {
			order: 0;
			margin-bottom: @grid-gutter-width;
		}
	}
}

.cd-allocation-footer-left {
	flex: 1 1 auto;
	order: 0;

	@media @small {
		order: 1;
		margin-bottom: @grid-gutter-width;
	}

	.btn-link {
		padding-left: 0;
		padding-right: 0;
	}
}
