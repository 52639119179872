.cd-return-page {
	background-color: @color-form;
}

.cd-return-page-main {
	flex: 1 0 auto;
	flex-direction: column;
}

.cd-return-page-box {
	.round(4px);
	margin: 0 auto 40px;	
	padding: 80px;
	.text-center;
	background-color: white;
	border: 1px solid @panel-default-border;
	flex: 0 0 auto;
	max-width: 730px;
}

.cd-return-page-icon {
	margin-bottom: 12px;
}

.cd-return-page-title {
	margin-bottom: 24px;
}

.cd-return-page-content {
	margin-bottom: 30px;
}

.cd-return-page-footer {
	display: flex;
	align-self: flex-end;
	justify-content: center;
	width: 100%;
	font-size: @font-size-small-minus;
	text-transform: uppercase;
	color: @gray;
	margin-bottom: 60px;
}
