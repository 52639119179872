@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.button {
	position: relative;

	@media not @small {
		margin-top: @grid-gutter-width;
	}
	@media @small {
		width: 100%;
	}
}
