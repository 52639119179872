@import '../../bootstrap-variables.less';
@import '../dashboard/page-dashboard.less';

@pillars-sidebar-width: 314px;

.page-head.pillar-steps {
	margin-bottom: 34px;
	border-bottom: none;

	h1 {
		font-size: 32px;
		padding-left: 0;
		margin-bottom: 0;
		border-bottom: none;

		@media @mobile {
			font-size: 28px;
		}

		.step-status {
			float: right;
			font-size: 18px;
			line-height: 44.8px;
			margin-right: 31px;
			position: relative;
			top: -4px;

			@media @mobile {
				float: none;
				display: block;
				position: static;
				margin-right: 0;
			}

			label {
				font-weight: normal;
				cursor: pointer;
			}

			input[type='checkbox'] {
				display: none;
			}

			input[type='checkbox'] + input[type='hidden'] + span.tick {
				content: '';
				display: inline-block;
				margin-right: 10px;
				width: 30px;
				height: 30px;
				border: 1px solid @border-color;
				border-radius: @border-radius-base;
				position: relative;
				top: 8px;
				text-align: center;
				.svg {
					color: white;
				}
				.svg(18px, 30px);
			}

			input[type='checkbox']:checked + input[type='hidden'] + span.tick {
				background-color: @color-green;
				border-color: @color-green;
				.svg {
					display: inline-block;
				}
			}
			input[type='checkbox']:checked + input[type='hidden'] + span.tick + span.text-content {
				color: @color-green;
			}
		}
	}

	p.steps {
		font-size: 18px;
		margin: 14px 0 5px 0;
		color: @text-color;
		font-weight: 400;
	}

	@media @mobile {
		padding: 11px 0 25px;
		text-align: center;
	}
}

.pillar-steps-content {
	.highlight-panel {
		margin-top: 10px;
		background-color: #e9e9e9;
		padding: 5px 10px;
	}

	.spacing-right {
		margin-right: 40px;
		padding-bottom: 30px;

		&:first-child {
			padding-bottom: 0;
		}

		@media @mobile {
			margin-right: 0;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.pushpay-red-text {
		color: @color-red;
	}

	.table-container {
		width: 100%;

		.mobile-nav {
			display: table;
			border-bottom: 1px solid @border-color;
			border-top: 1px solid @border-color;

			@media @mobile {
				padding: 0 20px;
			}
		}

		.long-heading {
			font-size: 21px;
			color: @gray-darker;
			padding-top: 30px;
			padding-bottom: 12px;
			margin-top: 0;

			@media @mobile {
				margin-right: 0;
				padding: 30px 6px 12px 6px;
				text-align: center;
			}
		}

		.heading {
			font-size: 21px;
			line-height: 1.6;
			color: @gray-darker;
			padding-top: 22px;
			margin-top: 0;
			margin-bottom: 12px;

			&:first-child {
				padding-top: 30px;
			}

			@media @mobile {
				text-align: center;
			}
		}

		.first-paragraph {
			padding-top: 35px;
		}

		span.word-wrapped {
			white-space: nowrap;
		}

		.sub-heading {
			font-size: 18px;
			line-height: 1.6;
			font-weight: normal;
			padding-top: 14px;
			color: @gray-darker;
			margin-top: 0;

			@media @mobile {
				text-align: center;
			}
		}

		.padding-bottom-congregation-list {
			padding-bottom: 18px;
		}

		.relevant-email-nav {
			margin-bottom: 0;

			@media @small {
				display: flex;

				.relevant-email-header {
					padding: 15px 10px;
					font-size: @font-size-base-minus;
				}
			}
		}

		.migrate-users-email-text {
			padding-top: 25px;
			border-top-width: 0;
			border-color: @nav-tabs-border-color;

			// ResourceCenter_ContentUpdate
			&.without-tab {
				margin-top: 18px;
				padding-top: 15px;
				border-color: initial;
				border-top-width: 1px;
			}
		}

		ol {
			padding-left: 20px;

			li {
				&.li-padding-bottom {
					padding-bottom: 18px;
				}
			}

			@media @mobile {
				padding: 0 0 7px 20px;
				text-align: left;
			}
		}

		// ResourceCenter_ContentUpdate
		.complete-list {
			padding-left: 25px;
			list-style: none;

			li {
				.svg(10px, 9px);
				.svg {
					color: @brand-success;
					margin-right: 8px;
				}
			}
		}

		a.underline {
			text-decoration: underline;
		}

		.e-church-logo {
			padding-top: 10px;
		}

		.circle-block {
			padding: 25px 0;
			width: 100%;
			text-align: center;

			a:hover,
			a:link {
				text-decoration: none;
			}
			a.space-right {
				margin-right: 6px;

				@media @mobile {
					margin-right: 0;
				}
			}

			span {
				@media @mobile {
					display: none;
				}
			}

			@media @mobile {
				padding: 25px 0;
			}
		}

		.border-bottom {
			border-bottom: 1px solid @border-color;

			@media @mobile {
				margin: 0 15px;
			}
		}

		@media @mobile {
			display: block;

			p {
				font-size: 16px;
			}
		}

		.text-template-image {
			width: 100%;
		}

		.image-link {
			margin-top: 30px;
			max-width: 900px;
			width: 100%;
		}

		.no-spacing {
			margin: 0;
		}

		.text-engagement {
			display: flex;
			flex-flow: wrap;
			align-items: center;
			justify-content: center;
			margin-bottom: 30px;
		}

		.text-engagement-content {
			flex: 1 1 400px;
		}

		.svg-centralchurch-text {
			fill: none;
			stroke: none;
		}
	}

	.circle-stages {
		border-radius: 80px;
		color: #b3b3b3;
		border: 1px solid @border-color;
		width: 35px;
		height: 35px;
		.transition( all 0.1s );
		text-align: center;
		padding-top: 5px;
		display: inline-block;

		&:hover {
			color: white;
			background-color: @gray-lighter;
			.transition( all 0.1s );

			a {
				color: white;
				.transition( all 0.1s );

				@media @mobile {
					display: none;
				}
			}
		}

		&.circle-complete {
			background-color: @brand-success;
			border: 1px solid @brand-success;
			color: white;

			a {
				color: white;
			}
		}

		&.circle-active {
			background-color: @gray-dark;
			border: 1px solid @gray;
			color: white;

			a {
				color: white;
			}
		}

		@media @mobile {
			width: 15px;
			height: 15px;
			background-color: fade(@gray-lighter, 20%);
			border: none;
		}
	}

	.table-column {
		display: table-cell;
		vertical-align: top;

		&.padding-right {
			padding-right: 15px;

			@media @mobile {
				padding-right: 0;
				display: block;
			}
		}
	}

	.next {
		vertical-align: middle;
		color: @btn-link-color;
		width: 8%;
		padding-right: 40px;
		padding-left: 8px;

		a:hover,
		a:link {
			text-decoration: none;
		}

		span {
			font-size: 16px;
			margin-bottom: 0;
			display: inline-block;
		}

		.triangle {
			transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866);
			margin-top: 8px;
			background-color: @btn-link-color;
			display: inline-block;
			vertical-align: 4px;
		}

		.triangle::before,
		.triangle::after {
			content: '';
			position: absolute;
			background-color: inherit;
		}

		.triangle,
		.triangle::before,
		.triangle::after {
			width: 5px;
			height: 5px;
			border-top-right-radius: 40%;
		}

		.triangle::before {
			transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
		}

		.triangle::after {
			transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
		}

		.triangle:hover {
			background: @btn-link-hover-color;
		}

		a.nowrap {
			white-space: nowrap;
		}

		a.nowrap:hover > span.triangle {
			background: @btn-link-hover-color;
		}

		&.invisible {
			opacity: 0;
		}

		@media @mobile {
			padding: 0;
		}
	}

	.previous {
		vertical-align: middle;
		width: 8%;

		a:hover,
		a:link {
			text-decoration: none;
		}

		span {
			font-size: 16px;
			margin-bottom: 0;
			display: inline-block;
		}

		span.previous {
			@media @mobile {
				display: none;
			}
		}

		span.prev {
			display: none;

			@media @mobile {
				display: inline-block;
			}
		}

		.triangle {
			transform: rotate(90deg) skewX(-30deg) scale(1, 0.866);
			margin-top: 8px;
			background-color: @btn-link-color;
		}

		.triangle::before,
		.triangle::after {
			content: '';
			position: absolute;
			background-color: inherit;
		}

		.triangle,
		.triangle::before,
		.triangle::after {
			width: 5px;
			height: 5px;
			border-top-right-radius: 40%;
			vertical-align: 0;

			@media @mobile {
				vertical-align: 2px;
			}
		}

		.triangle::before {
			transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
		}

		.triangle::after {
			transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
		}

		.triangle:hover {
			background: @btn-link-hover-color;
		}

		a.nowrap {
			white-space: nowrap;
			padding-left: 6px;
		}

		a.nowrap:hover > span.triangle {
			background: @btn-link-hover-color;
		}

		a.nowrap:hover > span.triangle + span.previous {
			color: @btn-link-hover-color;
		}

		&.invisible {
			opacity: 0;
		}

		@media @mobile {
			padding: 0;
		}
	}

	.table-column.main-col {
		width: ~'calc(100% - @{pillars-sidebar-width})';

		@media @mobile {
			display: block;
			width: 100%;
		}
	}

	.table-column.side-col {
		width: @pillars-sidebar-width;
		min-height: 100%;
		background: @color-form;
		border: 1px solid @border-color;

		@media @mobile {
			display: block;
			width: 100%;
			margin-left: 0;
			padding: 0;
			border: none;
		}

		.table-container > .table-column {
			@media @mobile {
				display: block;
			}
		}

		.target-completion-date {
			min-width: 312px;
			background-color: #fff;
			padding: 20px 30px;
			text-align: left;
			font-size: 18px;
			line-height: 45px;
			color: @text-color;
			border-bottom: 1px solid @border-color;

			&.completed {
				background-color: @color-green;
				font-weight: normal;
				color: #fff;

				.date-container .month {
					background-color: @text-color;
				}
			}

			.date-container {
				display: inline-block;
				width: 43px;
				float: right;
				text-align: center;

				.month {
					display: block;
					font-size: 8px;
					line-height: 8px;
					color: white;
					background-color: @btn-link-color;
					text-transform: uppercase;
					padding: 3px;
					border-radius: 3px 3px 0 0;
				}

				.day {
					display: block;
					border-radius: 0 0 3px 3px;
					border: @border-color solid;
					border-width: 0 1px 1px 1px;
					line-height: 29px;
					color: @text-color;
					background-color: white;
				}
			}
		}

		.contents {
			padding: 15px 30px 0 30px;
			vertical-align: top;

			@media @mobile {
				padding: 40px 15px;
			}
		}

		.top-tips-icon {
			width: 45px;
			height: 45px;
			margin-right: 20px;
			margin-top: 6px;

			@media @mobile {
				margin: 0 auto;
				display: block;
			}
		}

		ul {
			list-style: disc outside none;
			padding: 10px 10px 0 20px;

			li {
				font-size: 15px;
				padding-bottom: 18px;
			}
		}

		p.heading {
			font-size: 21px;
			padding: 15px 0 18px;

			@media @mobile {
				text-align: center;
				padding: 25px 10px 0;
			}
		}

		a {
			outline: none;
		}

		.intercom-img {
			width: 255px;
			height: 290px;
			margin-left: -3px;
			display: block;
			outline: none;
			padding-top: 20px;

			@media @mobile {
				display: block;
				margin: 0 auto;
			}
		}

		.side-col-title {
			margin: 10px 0;
			font-size: 15px;
			line-height: 24px;
		}

		.side-col-sub-title {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 15px;
			line-height: 24px;
			font-weight: 600;
		}

		.side-col-row {
			margin: 20px 0;
		}

		.with-icon {
			display: flex;
			align-items: center;

			.svg {
				fill: @btn-link-color;
				stroke: none;
				margin-right: 10px;
			}

			.svg-cabinet {
				border: 1px solid @btn-link-color;
				border-radius: 50%;
				padding: 3px;
			}
		}

		.side-col-items {
			margin: 10px 0;
			.with-icon {
				list-style: none;
				margin-left: -20px;
			}
		}

		.keywords {
			margin-bottom: 10px;
			line-height: 2;
		}
	}

	textarea.text-field {
		width: 100%;
		height: 242px;
		margin: 0 0 30px 0;
		resize: none;
		padding: 15px 20px;
		font-family: monospace;

		@media @mobile {
			margin-top: 22px;
		}
	}

	.btn.btn-red {
		@media @mobile {
			width: 100%;
		}
	}

	// Merchant Listing Chooser
	.merchant-picker {
		padding: 0;

		.wrapper {
			margin: @panel-body-padding / 2;
			padding: 0;
			flex: 0 1 auto;
			display: flex;
			flex-flow: row wrap;
			align-items: flex-end;
		}

		.control {
			padding: @panel-body-padding / 4;
			flex: 1 0 67%;

			&.no-padding {
				padding: 0;
			}

			&.second-colum {
				flex: 1 0 33%;
				min-width: 250px;
			}

			input.form-control[readonly] {
				background-color: white;
			}

			.radio-group {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				height: 44px;

				label {
					flex: 0 0 50%;
					white-space: nowrap;
					padding: 0 5px 0 0;
					font-weight: normal;
				}
			}

			.btn {
				width: 100%;
				margin-bottom: 1px;
			}
		}
	}
}
