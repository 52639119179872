.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.title {
  grid-column: 1 / span 3;
}
@media screen and (max-width: 1024px) {
  .title {
    grid-column: 1 / span 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.details {
  grid-column: 1 / span 3;
  grid-row: 2;
}
.total {
  grid-column: 4;
}
@media screen and (max-width: 1024px) {
  .total {
    grid-row: 3;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0;
  }
  .total .total-prefix {
    top: initial;
    font-size: 15px;
    margin-right: 0;
  }
}
.count {
  grid-column: 4;
  grid-row: 2;
}
@media screen and (max-width: 1024px) {
  .count {
    grid-column: 1 / span 3;
    grid-row: 3;
    text-align: left;
  }
}
.deposit {
  grid-column: 1 / span 2;
  grid-row: 3;
}
@media screen and (max-width: 1024px) {
  .deposit {
    grid-column: 1 / span 4;
    grid-row: 2;
  }
  .deposit .checkbox-green {
    margin-top: 0;
  }
}
.icon-completed {
  width: 22px;
  height: 22px;
  fill: #27A86C;
  margin-bottom: -2px;
}
.qbo-row {
  grid-column: 3 / span 2;
  grid-row: 3;
  color: #27A86C;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .qbo-row {
    grid-column: 1 / span 4;
    grid-row: 4;
    margin-top: 12px;
    justify-content: space-between;
  }
}
@media screen and (max-width: 1024px) {
  .qbo-row .qbo-total {
    font-size: 15px;
  }
}
.qbo-row .qbo-total .qbo-total-prefix {
  margin-right: 2px;
}
@media screen and (max-width: 1024px) {
  .qbo-row .qbo-total .qbo-total-prefix {
    top: initial;
    font-size: 15px;
    margin-right: 0;
  }
}
.qbo-row .sent-text {
  display: flex;
  margin: 10px 0;
}
.empty-row {
  margin: 10px 0;
  height: 22px;
}
@media screen and (max-width: 1024px) {
  .empty-row {
    display: none;
  }
}
