@import "../../bootstrap-variables";

.unsupported-browser {

	.unsupported{
		text-align: center;
		margin: 8% auto 0 auto;
		max-width: 1920px;

		&-title {
			margin-bottom: 24px;
			color: @gray-darker;
		}

		&-text {
			margin: 0 auto;
			max-width: 640px;
		}
	}

	.browsers {
		display: flex;
		max-width: @screen-sm;
		padding-top: 65px;
		margin: 0 auto;
		justify-content: center;
	}

	.browser {
		text-align: center;
		flex: 1;

		&-link {
			padding-top: @padding-large-horizontal;
			margin: 0;
			color: #1173bc;
		}

		&-name {
			margin: 24px 0 13px 0;
			font-weight: 600;
			line-height: 24px;
			font-size: @font-size-base-plus;
			color: @gray-darker;
		}

		&-image {
			margin: 0 auto;
			max-width: 70px;
		}
	}

	.header-margin {
		margin: 20px;
	}

	.mobile-button-container {
		margin-top: 20px;
		margin-bottom: 40px;
	}

	.version {
		color: #b2b2b2;
	}
}
