@import "../../bootstrap-variables";

@cd-allocation-panel-header-padding: 5px;
@cd-allocation-image-width-aspect-ratio: 375/900;
@cd-allocation-image-width: 750px;
@cd-allocation-image-width-small: 600px;
@cd-allocation-image-height: unit(round(@cd-allocation-image-width-aspect-ratio*@cd-allocation-image-width), px);
@cd-allocation-image-height-small: unit(round(@cd-allocation-image-width-aspect-ratio*@cd-allocation-image-width-small), px);
@cd-allocation-image-height-percentage: unit(@cd-allocation-image-width-aspect-ratio*100, %);
@cd-allocation-image-border-width: 1px;
@cd-allocation-image-back-border-offset: unit(@cd-allocation-image-border-width*2, px);
@cd-allocation-image-back-offset: ~"calc(-100% + @{cd-allocation-image-back-border-offset})";
@cd-allocation-image-flip-button-width: 50px;
@cd-allocation-image-control-button-height: 30px;

.cd-allocation-item-form {
	.panel:focus {
		outline: none;
	}

	.panel-heading {
		display: flex;
		padding: @cd-allocation-panel-header-padding 0;
		font-size: @font-size-base-plus;

		@media @small {
			flex-wrap: wrap;
			display: grid;
			grid-template-rows: repeat(2, auto);
			grid-template-columns: 1fr min-content;
		}
	}

	.panel-body {
		&.cd-allocation-item-form-header {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.panel-footer {
		display: flex;
		justify-content: center;

		@media @mobile {
			padding-left: @panel-body-padding-mobile;
			padding-right: @panel-body-padding-mobile;
		}

		@media @small {
			flex-direction: column;
		}

		.btn {
			flex: 0 1 auto;

			@media @mobile {
				flex: 1 1 auto;
			}

			@media @small {
				width: 100%;
			}

			&:not(.btn-ghost) {
				order: 1;

				@media @small {
					order: 0;
				}
			}
		}

		.btn-ghost {
			order: 0;

			margin-right: @grid-gutter-width;

			@media @small {
				order: 1;
				margin-right: 0;
				margin-top: 20px;
			}
		}
	}
}

.cd-allocation-item-form-header-title {
	flex: 0 1 100%;
	grid-column: ~"1 / span 2";
	.text-center;
	line-height: 40px;
	margin: 0;
	border-bottom: 1px solid @panel-default-inner-border;
	padding-bottom: @cd-allocation-panel-header-padding;
}

.cd-allocation-item-form-header-left,
.cd-allocation-item-form-header-right {
	display: flex;
	align-items: center;

	@media @small {
		padding-top: @cd-allocation-panel-header-padding*2;
		padding-bottom: @cd-allocation-panel-header-padding;
	}

	> [class^="cd-allocation-item-form-"] {
		flex: 0 1 auto;

		&.cd-allocation-item-form-row-number,
		&.cd-allocation-item-form-check-details,
		&.cd-allocation-item-form-images-control {
			padding-right: 30px;
		}
	}
}

.cd-allocation-item-form-header-left {
	flex: 0 1 auto;

	@media @small {
		flex-basis: 70%;
		grid-column: 1;
		grid-row: 2;
	}
}

.cd-allocation-item-form-images-control {
	.btn {
		padding: 0;
		font-weight: normal;
	}
}

.cd-allocation-item-form-missing-data-indicator {
	width: @panel-body-padding;
	.text-center;

	@media @mobile {
		width: @panel-body-padding-mobile;
	}
}

.cd-allocation-item-form-check-details,
.cd-allocation-item-form-amount {
	font-size: @font-size-large;

	@media @small {
		font-size: @font-size-base;
		font-weight: 700;
	}
}

.cd-allocation-item-form-header-right {
	flex: 1 1 auto;
	justify-content: flex-end;

	@media @small {
		flex-basis: 30%;
		padding-right: @panel-body-padding-mobile;
		grid-column: 2;
		grid-row: 2;
	}

	.btn {
		display: flex;
		align-items: center;
		height: 40px;
		padding: 0 @panel-body-padding;
		.text-center;
		color: @gray-mid;

		&:focus {
			color: @link-hover-color;
		}

		@media @small {
			position: absolute;
			top: @cd-allocation-panel-header-padding;
			right: 0;
			padding-right: @panel-body-padding-mobile;
		}
	}
}

.cd-allocation-item-form-header-close-icon {
	fill: currentColor;
	.square(18px);
}

.cd-allocation-item-form-search {
	border-radius: 0;
	margin: -1px -(@panel-body-padding + 1) 0;
	z-index: 1;

	@media @mobile {
		margin-left: -(@panel-body-padding-mobile + 1);
		margin-right: -(@panel-body-padding-mobile + 1);
	}
}

.cd-allocation-item-form-images-container {
	display: flex;
	justify-content: center;
	overflow: hidden;
	margin-left: -(@panel-body-padding);
	margin-right: -(@panel-body-padding);

	@media @mobile {
		margin-left: -(@panel-body-padding-mobile);
		margin-right: -(@panel-body-padding-mobile);
	}
}

.cd-allocation-item-form-sticky-image {
	position: relative;
	z-index: 10000;

	@media @small {
		position: static;
	}
}

.cd-allocation-item-form-images {
	display: inline-block;
	// VelocityJS slideUp/slideDown applies `overflow: hidden` to the element while animating
	// The check image can be wider than the container, so we need to override this to stop it getting cut off
	overflow: visible !important;
	width: ~"calc(100% - " @panel-body-padding*2 ~")";
	max-width: @cd-allocation-image-width;
	margin-bottom: 30px;
	margin-top: 30px;

	@media @small {
		width: 100%;
		position: relative;
		margin-top: 5px;
		background-color: white;
	}

	.cd-allocation-item-form-images-dialog-container & {
		margin-top: @padding-large-vertical;
	}
}

.cd-allocation-item-form-image-container {
	position: relative;
	z-index: 1;

	@media @small {
		position: static;
		box-sizing: content-box;
		.overflow-container(overflow-x);
		height: @cd-allocation-image-height-small;
		padding-top: 2px;
		padding-bottom: 4px;
		width: 100%;

		.flip-container,
		.flip-flipper {
			height: 100%;
		}

		.flip-flipper {
			box-sizing: content-box;
			width: @cd-allocation-image-width-small;
			padding-left: @panel-body-padding-mobile;
			padding-right: @panel-body-padding-mobile;
		}
	}
}

.cd-allocation-item-form-image {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 0;
	padding-bottom: @cd-allocation-image-height-percentage;
	border: @cd-allocation-image-border-width solid @border-color;
	background: white;
	.box-shadow-small;

	&.flip-back {
		margin-top: -@cd-allocation-image-height-percentage;

		@media @small {
			margin-top: -@cd-allocation-image-height-small;
		}

		> img {
			position: absolute;
			left: 0;
			top: @cd-allocation-image-back-offset;
		}
	}

	@media @small {
		height: 100%;
		width: @cd-allocation-image-width-small;
		padding-bottom: 0;
	}

	> img {
		width: 100%;

		@media @small {
			width: @cd-allocation-image-width-small;
		}
	}
}

.cd-allocation-item-form-image-flip-button {
	position: absolute;
	bottom: -10px;
	right: -(@cd-allocation-image-flip-button-width/2);
	height: auto;
	min-width: @cd-allocation-image-flip-button-width;
	opacity: 0.5;
	border-radius: 50%;
	padding: 10px;
	font-size: @font-size-small-minus;
	line-height: 1;
	color: white;
	background: rgba(0, 0, 0, 0.85);
	// Fix z-index bug in Safari by moving button 1000px in front of check images
	transform: translateZ(1000px);

	&:hover {
		opacity: 0.75;
	}

	&:active {
		opacity: 0.85;
	}

	.icon-flip {
		height: 20px;
		width: 26px;
		margin-right: 0;
		margin-bottom: 1px;
	}
}

.cd-allocation-item-form-image-controls {
	margin-top: 20px;
	justify-content: center;
}

.cd-allocation-item-form-images-controls-mobile {
	display: flex;
	align-items: center;
	padding: 10px 0;

	.cd-allocation-item-form-images-controls-side {
		flex: 0 1 auto;
		display: flex;
		align-items: center;
	}

	.cd-allocation-item-form-images-control {
		flex: 1 1 auto;
	}

	.btn-link,
	.btn-tertiary,
	.cd-allocation-item-form-image-controls-side-selected {
		display: inline-block;
		font-size: @font-size-base-minus;
		line-height: @cd-allocation-image-control-button-height;
		height: @cd-allocation-image-control-button-height;
	}
}

.cd-allocation-item-form-images-controls-dialog {
	&:extend(.cd-allocation-item-form-images-controls-mobile all);
	margin-top: -22px;
	padding: 0;

	@media @mobile {
		margin-top: -@padding-large-vertical;
	}
}

.cd-allocation-item-form-images-controls-side .btn-tertiary,
.cd-allocation-item-form-image-controls-side-selected {
	&:first-child {
		position: relative;
		margin-right: @grid-gutter-width;
		min-width: 35px;

		&:before {
			border-right: 1px solid @border-color;
			content: '';
			position: absolute;
			right: -@grid-gutter-width;
			top: 0;
			height: 100%;
			width: 0;
		}
	}

	&:last-child {
		margin-left: @grid-gutter-width;
	}
}

.cd-allocation-item-form-body {
	padding-top: 20px;
	.overlay(disabled, 3);
}

.cd-fund-allocation {
	border-bottom: 1px solid @panel-default-inner-border;
	margin-bottom: @form-group-margin-bottom;

	@media @mobile {
		padding-left: @panel-body-padding-mobile / 2;
		padding-right: @panel-body-padding-mobile / 2;
		margin-left: -@panel-body-padding-mobile;
		margin-right: -@panel-body-padding-mobile;

		.cd-amount-label {
			display: none;
		}
	}
}

.cd-add-fund {
	margin-bottom: @form-group-margin-bottom;
	height: @btn-font-size;
	line-height: 1em;
	text-align: left;
	padding: 0;

	.icon {
		width: @font-size-base-plus;
		height: @font-size-base-plus;
		display: inline-block;
		margin-right: (@grid-gutter-width / 2);
	}
}

.cd-remove-fund {
	& > button {
		padding: 0;
	}

	@media @mobile {
		margin-top: @grid-gutter-width / 4;
		margin-bottom: @grid-gutter-width / 4;
		width: 100%;
	}

	.icon {
		margin-right: 0;
	}
}

.cd-check-split-row {
	clear: both;

	@media @mobile {
		margin-left: @panel-body-padding-mobile / 2;
		margin-right: @panel-body-padding-mobile / 2;
		margin-bottom: @form-group-margin-bottom;
		border-bottom: 1px solid @panel-default-inner-border;

		.cd-check-split-form-fields {
			display: flex;
			flex-wrap: wrap;

			.cd-fund-selector {
				margin-bottom: 0;
				width: 66%;
				padding: 0;

				.form-control {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			.cd-fund-amount-field {
				padding-left: 0;
				padding-right: 0;
				width: 33%;

				.form-control {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}

.cd-fund-amount  {
	display: block;
	position: relative;

	&::after { // Note: ::after cannot be applied to inputs for some reason
		content: '$';
		position: absolute;
		line-height: 1;
		top: ~"calc(50% - "@font-size-base-plus / 2~")";
		left: ceil(@grid-gutter-width / 2) + 5px;
	}

	> .form-control {
		padding-left: 28px;
	}
}

.cd-check-split-summary {
	display: flex;

	@media @mobile {
		justify-content: space-between;
	}
}

.cd-check-split-total-amount {
	text-align: right;
	font-size: @font-size-base-plus;
	line-height: @line-height-base;
	margin-top: -5px;

	.cd-check-split-total-amount-success {
		color: @state-success-text;
	}
}

.cd-check-split-amount-validation-error {
	color: @state-danger-text;
	font-size: @font-size-small;
	text-align: right;
	line-height: @font-size-small;

	@media @small {
		text-align: left;
	}
}

.cd-check-split-note {
	font-size: @font-size-small;
	margin-bottom: @form-group-margin-bottom;
}
