#insights {

	.payment-by-source {

		.donut-chart {

			text {
				fill: @color-blue;
			}
		}

		li {

			&:first-child {
				.svg {
					// override the base-color
					color: @gray;
				}
			}
		}
	}
}
