@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.qbo-heading {
	display: flex;
}

.qbo-title {
	flex: 1 0 auto;
}

.qbo-edit-link {
	flex: 0 0 auto;
	width: auto;
	line-height: normal;
	height: auto;
}

.panel-footer {
	border-bottom: 1px solid @border-color;
}
