@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	display: flex;
	justify-content: space-between;
	margin: 20px 0 50px;

	@media @small {
		flex-direction: column;
	}
}

.title {
	flex: auto;
	margin: 0;

	@media @small {
		display: none;
	}
}
