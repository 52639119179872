@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@step-circle-size: 32px;
@step-number-font-size: @font-size-base-plus;
@step-label-font-size: @font-size-base-minus;
@step-border-width: 2px;
@max-separator-width: 250px;
@min-separator-width: 25px;

.container {
	margin: @panel-body-padding 0;
	text-align: center;
}

.steps {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
}

.step {
	width: @step-circle-size;
	flex: 0 0 auto;
}

.step-separator {
	margin-top: @step-circle-size / 2 - (@step-border-width / 2);
	border-top: @step-border-width solid @gray-light;
	max-width: @max-separator-width;
	min-width: @min-separator-width;
	flex: 1 0 auto;
}

.step-circle {
	width: @step-circle-size;
	height: @step-circle-size;
	border-radius: 100%;
	border: @step-border-width solid @gray-light;
	color: @gray-light;
	font-size: @step-number-font-size;
	font-weight: 500;
	line-height: @step-circle-size - (@step-border-width * 2);
	vertical-align: middle;
}

.step-label {
	width: @step-circle-size;
	height: @step-label-font-size;
	color: @gray-light;
	position: relative;
	margin: 5px 0 0;
	font-size: @step-label-font-size;
	font-weight: 400;

	@media @mobile {
		display: none;
	}
}

.step-label-text {
	position: absolute;
	left: -100px;
	right: -100px;
	margin: auto;
	text-align: center;
}

.step-circle-current {
	background-color: @gray-mid;
	border-color: @gray-mid;
	color: white;
}

.step-label-current {
	color: @gray-mid;
	font-weight: 500;
}
