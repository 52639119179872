@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@batch-title-font-size: @font-size-h1;
@clear-icon-spacing: 16px;
@clear-icon-size: 18px;
@clear-icon-padding: 5px;
@amount-wrapper-width: 28px;
@label-height: 27px;
@expander-width: 16px;

.title {
	&-container {
		position: relative;
	}

	&-input-wrapper {
		margin-bottom: 0;
	}

	&-input {
		font-size: @batch-title-font-size;
		border: 0;
		padding: 0 @clear-icon-spacing + @clear-icon-size 0 0;
	}

	&-buttons {
		margin-left: -@padding-base-horizontal;

		@media @small {
			display: flex;
			justify-content: space-between;
			margin-bottom: @grid-gutter-width;
		}
	}

	&-display {
		font-size: @font-size-h1;
		margin-bottom: @padding-base-vertical;
	}
}

.clear {
	&-container {
		padding-right: @clear-icon-spacing + @clear-icon-size;
		height: 0;
		overflow: hidden;
	}
	&-spacer {
		font-size: @batch-title-font-size;
		display: inline-block;
		visibility: hidden;
		height: 0;
		max-width: 100%;
	}
	&-btn {
		position: absolute;
		top: 38px;
		margin-left: @clear-icon-padding;
	}
	&-icon {
		width: @clear-icon-size;
		height: @clear-icon-size;
		fill: currentColor;
	}
}

.panel-body {
	padding-top: @grid-gutter-width + @padding-large-vertical;
	padding-bottom: @grid-gutter-width + @padding-large-vertical;

	@media @small {
		padding-top: @padding-large-vertical;
		padding-bottom: @padding-large-vertical;
		border-bottom: 1px solid @border-color;
	}
}

.border-bottom {
	border-bottom: 1px solid @border-color;
}

.history {
	&-panel {
		display: flex;
		align-items: center;

		@media @small {
			flex-direction: column;
			border: 0;
		}
	}
	&-btn {
		@media @small {
			font-size: @font-size-base-minus;
		}
	}
}

.header {
	margin-bottom: @grid-gutter-width;
	display: flex;
	justify-content: space-between;

	@media @small {
		flex-direction: column;
	}
}

.totals-message {
	font-size: @font-size-base-minus;
	@media not @small {
		margin-top: @label-height;
	}
}

.amount {
	&-wrapper {
		position: relative;
		&:before {
			position: absolute;
			top: @label-height;
			font-size: @font-size-base-plus;
			text-align: center;
			height: @input-height-base;
			line-height: @input-height-base;
			width: @amount-wrapper-width;
			content: '$';
		}
	}
	&-input {
		padding-left: @amount-wrapper-width;
	}
}

.responsive-bold {
	@media not @small {
		font-weight: bold;
	}
}

.add-service-time {
	margin-top: 34px;
	@media @small {
		margin-top: -@padding-xs-vertical;
		margin-bottom: @form-group-margin-bottom;
	}
}

.listing {
	&-heading {
		overflow: hidden;
		margin-bottom: @padding-base-vertical;
		@media @small {
			margin-bottom: 0;
		}
	}
	&-name {
		@media not @small {
			font-size: @font-size-h2;
		}
	}
}

.show-default {
	flex: none;

	@media not @small {
		margin-left: @grid-gutter-width;
	}
}

.default-settings:before {
	right: 56px;

	@media @small {
		right: ~'calc(50% - @{expander-width} / 2)';
	}
}

.field-title {
	font-size: @font-size-base-minus;
	font-weight: bold;
}

.status-label {
	margin-left: @padding-base-horizontal;
	margin-bottom: @padding-small-vertical;
}
