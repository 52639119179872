
@import "../../bootstrap-variables";

@tile-border-color: #CCCCCC;
@tile-border-color-active: #3B3B3B;

.giving-animation-picker {
	.animation-inputs{
		display: flex;
		margin: 5px 0 15px 0;
	}

	input[type="radio"] {
		-webkit-appearance: none;
	}

	input[type="radio"] + label {
		opacity: 0.5;
	}

	input[type="radio"]:checked + label {
		border-color: @tile-border-color-active;
		opacity: 1;
	}

	label {
		box-sizing: border-box;
		height: 44px;
		width: 44px;
		border: 1px solid @tile-border-color;
		border-radius: 4px;
		background-color: #FFFFFF;
		margin: 0 20px 0 0;
		position: relative;

		&:hover {
			border-color: @tile-border-color-active;
			opacity: 1;
			cursor: pointer;
		}

		> span {
			font-size: 12px;
			font-family: @font-family-sans-serif;
			font-weight: normal;
			line-height: 14px;
			position: absolute;
			top: 90%;
			left: 50%;
			transform: translate(-50%, 80%);
		}
	}

	svg {
		&.svg-close-cross-small {
			width: 20px;
			height: 20px;
		}

		&.animation-type-icon {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translate(-50%, -80%);
		}
	}

	.animation-replay {
		transition: opacity 0.3s;
		display: inline-block;
		font-size: @font-size-base-minus;
		padding: 20px 0 0;
		color: @color-red;
		margin: 0;
		line-height: 18px;
		background: transparent;
		border: none;
		opacity: 0;

		&:hover{
			color: @color-red-dark;
		}

		.animation-replay-icon {
			height: 20px;
			width: 20px;
			float: right;
			margin: 0 5px;
		}
	}
}
