@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	color: @text-color;
}

.heading {
	margin: 0 0 16px 0;

	@media @small {
		margin: 16px 0 24px 0;
	}
}

.item-container {
	padding-left: 20px;
	margin: 0;
}

.item {
	font-size: @font-size-base-plus;
	line-height: @line-height-small;
	margin-top: 8px;

	> span {
		margin-right: 8px;
	}

	> a {
		margin-right: 8px;
		font-weight: 400;
	}

	> svg {
		width: 14px;
		height: 14px;
		fill: @link-color;
	}
}
