#FeatureFlagList {
	border-top: 1px solid lightgray;
	border-left: 1px solid lightgray;
	background: #fffbf4;
	position: fixed;
	right: 0;
	bottom: 0;
	padding: 4px;
	z-index: 1000;
	width: inherit;
	font-size: 12px;
	.roundSome(10, 0, 0, 0);
	a {
		font-weight: bold;
		text-decoration: none;
		color: #e51b00;
		&:hover {
			color: #9e1008;
		}
		&.feature-state {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	b {
		cursor: auto;
	}
	hr {
		margin: 6px 0;
	}
	.feature-state {
		font-weight: bold;
		&.feature-off {
			color: #dd0000
		}
		&.feature-on {
			color: #009933
		}
	}
}
