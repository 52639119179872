@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@icon-size: 88px;

.container {
	z-index: 1050;
}

.backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
	opacity: 0;
}

.modal {
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	outline: 0;
	opacity: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	z-index: 1050;
}

.dialog {
	max-height: ~'calc(100% - @{padding-large-vertical} * 2)';

	@media not @small {
		width: 600px;
	}

	@media @small {
		margin: @padding-large-vertical;
	}
}

.form {
	@media not @small {
		width: 680px;
		max-height: ~'calc(100% - @{padding-large-vertical} * 2)';
		margin-top: @padding-large-vertical;
		margin-bottom: @padding-large-vertical;
	}

	@media @small {
		max-height: 100%;
		margin: 0;
	}
}

.icon {
	width: @icon-size;
	height: @icon-size;
}

.cancel-btn {
	&:disabled {
		cursor: not-allowed;
	}
}
