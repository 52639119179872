@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@form-label-width-desktop: 25%;
@form-control-width-desktop: 50%;
@grid-gutter: @grid-gutter-width / 2;

.frequency-selector {
	:global(.listing-selector-container) {
		@media (max-width: @screen-sm-max) {
			flex-direction: column;
			align-items: stretch;
		}
	}
	:global(.listing-selector-label) {
		padding-left: @grid-gutter;
		padding-right: @grid-gutter;
		margin-bottom: 0;
		@media (min-width: @screen-md-min) {
			width: @form-label-width-desktop;
		}
	}
	:global(.listing-selector) {
		padding-left: @grid-gutter;
		padding-right: @grid-gutter;
		@media (min-width: @screen-md-min) {
			width: @form-control-width-desktop;
		}
	}
}
