@import "../../bootstrap-variables";

.integration-logo {
	@media (max-width: @screen-xs-max) {
		max-width: 100%;
		height: 80px;
		object-fit: cover;
	}
}

