@import "../../bootstrap-variables.less";

@login-footer-desktop-vertical-spacing: 100px;
@login-footer-mobile-vertical-spacing: 30px;
@login-footer-divider: 2px solid @gray-lighter;

.login-footer {
	margin-top: @login-footer-desktop-vertical-spacing;
	font-size: @font-size-small;
	color: @gray;
	line-height: 1;
	text-align: left;

	@media @small {
		margin-top: @login-footer-mobile-vertical-spacing;
	}
}

.login-footer-info {
	display: flex;
	align-items: center;

	@media @small {
		display: block;
		text-align: center;
	}
}

.login-footer-links {
	flex: auto;
}

.login-footer-links > :not(:first-child),
.login-footer-logo > :not(:first-child) {
	margin-left: @padding-base-horizontal;
}

.login-footer-link {
	text-decoration: underline;
	color: @gray;

	&:hover,
	&:active,
	&:focus {
		color: @gray-dark;
	}
}

.login-footer-logo {
	display: inline-flex;
	align-items: center;
	margin: @padding-large-vertical 0;

	@media @small {
		margin-top: @login-footer-mobile-vertical-spacing;
	}
}

.login-footer-copyright {
	border-top: @login-footer-divider;
	padding: 2 * @padding-large-vertical 0;
	text-align: right;

	@media @small {
		border-top: none;
		padding-top: 0;
		text-align: center;
	}
}
