@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.header {
	display: flex;
	margin-bottom: @grid-gutter-width;
	align-items: center;

	@media @small {
		flex-direction: column;
		align-items: flex-start;
	}
}

.title {
	flex: auto;
}

.button-add {
	flex: none;
	text-align: left;
}

.filter {
	margin-bottom: @grid-gutter-width;
}

.panel-title {
	margin: 0;
}

.listing {
	&:not(:last-child) {
		margin-bottom: @grid-gutter-width;
	}
}
