@import "../../bootstrap-variables";
@import "../../mixins/mixins";
@import "../../modules/ionicons/ionicons";

@carousel-min-width: @tipbox-min-width - @tipbox-padding-mobile-horizontal - @tipbox-padding-mobile-horizontal;

.carousel {
	display: flex;
	flex-flow: column nowrap;
	min-width: @carousel-min-width;

	.tipbox & {
		margin: 0 -@tipbox-padding-horizontal;
		min-height: 260px;

		@media @mobile {
			margin: 0;
		}
	}

	.slides {
		flex: 1 1 auto;
		display: flex;
		justify-content: space-between;

		.carousel-slide {
			flex: 1 1 auto;
			opacity: 0;
			transition: opacity 500ms;
		}

		&.visible {
			.carousel-slide {
				opacity: 1;
			}
		}

		.slide-control {
			flex: 0 0 auto;
			width: 57px;
			color: @btn-default-bg;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			padding: 0 10px;
			text-align: center;

			@media @mobile {
				display: none;
			}

			.arrow {
				font-size: 30px;

				&:before {
					.ion;
				}

				&.previous-arrow:before {
					content: @ionicon-var-chevron-left;
				}

				&.next-arrow:before {
					content: @ionicon-var-chevron-right;
				}
			}

			&-hidden {
				visibility: hidden;
			}
		}
	}

	.slide-indicators {
		width: 100%;
		text-align: center;
		flex: 1 1 auto;
		padding-top: 20px;

		.slide-indicator {
			border:2px solid @gray-lighter;
			border-radius: 100%;
			background-color: @gray-lighter;
			display: inline-block;
			width: 10px;
			height:10px;
			margin: 0 5px;
			cursor: pointer;

			&.current-slide {
				border-color:  @btn-default-border;
				background-color: @btn-default-bg;
			}
		}
	}
}

