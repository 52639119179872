@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';
@import '../../../../../Content/LoggedInWeb/modules/waiting-indicator.less';

@media @small {
	.publish-btn {
		width: 100%;
	}
}

.waiting {
	.waiting-indicator();
}
