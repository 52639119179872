@chalkduster-font-family: ~"Chalkduster";
@chalkduster-font-path: ~"/Content/LoggedInWeb/assets/fonts/chalkduster-azAZ.ttf";

@font-face {
	font-family: @chalkduster-font-family;
	src:~"url(@{chalkduster-font-path})" format("truetype");
	font-weight: normal;
	font-style: normal;
}

.chalkduster {
	font-family: @chalkduster-font-family;
}
