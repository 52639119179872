#insights {

	.pattern {
		position: relative;

		&::before {
			content: ' ';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: .03;
			background-image: url(/Content/LoggedInWeb/assets/images/insights/pattern_crosshatch.png);
			background-repeat: repeat;
			background-position: 50% 0;
			background-size: initial;
		}
	}

	.tipbox {
		.clearfix();

		.tipbox-icon  {
			.svg {
				color: @color-green;
			}
		}
	}
}
