@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@campaignCardTransitionTime: 1000ms;

:export {
	campaignCardTransitionTime: @campaignCardTransitionTime;
}

.container,
.cards {
	display: flex;
	flex-direction: column;
}

.group-header {
	display: flex;
	justify-content: space-between;

	& + .group-header {
		padding-top: @grid-gutter-width;
		border-top: 1px solid @border-color;
	}

	&:last-child {
		padding-bottom: @grid-gutter-width;
		border-bottom: 1px solid @border-color;
	}
}

.container {
	margin: 0 0 50px;

	&:last-child {
		margin: 0;
	}

	.load-more-btn {
		color: @btn-ghost-color;
		height: 30px;
		min-width: 120px;
		border: 1px solid @btn-ghost-border;
		border-radius: 4px;
		align-self: center;
		transition: color, background-color 0.5s;
		margin-bottom: 15px;
		background-color: @btn-ghost-bg;

		&:disabled:not(.loading) {
			color: @btn-ghost-disabled-color;
		}

		&:not(:disabled):hover {
			color: @btn-ghost-hover-color;
			background-color: @btn-ghost-hover-bg;
		}

		@media @small {
			border: none;
			text-decoration: underline;

			&:hover {
				color: @btn-ghost-hover-color;
				background-color: @btn-ghost-hover-bg;
			}
		}
	}

	.loading {
		border: none;
		background-color: @btn-ghost-bg;
		color: @btn-ghost-color;
	}
}

@media @small {
	.expand-btn {
		margin: 0 10px;

		&:hover {
			cursor: pointer;
		}

		&:before {
			content: '';
			display: block;
			border: solid @gray-mid;
			border-width: 0 3px 3px 0;
			display: inline-block;
			padding: 5px;
			transform: rotate(45deg);
			transition: 0.5s ease transform;
		}
	}

	.collapse-btn {
		.expand-btn();

		&:before {
			transform: rotate(-135deg);
		}
	}

	.cards {
		&.collapsed {
			display: none;
		}
	}
}

.card-appear {
	&.card-appear-active {
		animation: appear @campaignCardTransitionTime ease 0s 1 normal forwards;
	}
}

.card-leave {
	opacity: 1;

	&.card-leave-active {
		opacity: 0.01;
		transition: opacity 0.3s ease;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
		transform: translateY(-40%) scaleY(0.2);
	}

	50% {
		opacity: 0;
		transform: translateY(-40%) scaleY(0.2);
	}

	100% {
		max-height: 100vh;
		opacity: 1;
		transform: translateY(0) scaleY(1);
	}
}

.nav-container {
	button {
		padding: 10px 40px;
	}

	@media @small {
		li {
			min-width: calc(100% / 3);

			button {
				width: 100%;
				padding: 10px 20px;
			}
		}
	}
}

.emptyCampaignMessage {
	padding-top: 10px;
}
