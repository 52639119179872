@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.scroll-to-top-button {
	display: block;
	width: 48px;
	min-width: 1px;
	height: 48px;
	padding: 13px;
	margin: 7px; // allow for box-shadow clearance
	border-radius: 100%;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
}

.scroll-to-top-icon {
	display: block;
}
