@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.wrapper {
	position: relative;
	display: block;
	margin: 10px 0;

	&[class~='checkbox-green'] {
		&[class~='checked']:not([class~='disabled']) {
			.fake-checkbox {
				background-color: @color-green;
				border-color: @color-green;
				color: white;
			}
		}
	}
}

.label {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	font-size: @font-size-base;
	font-weight: normal;
	line-height: normal;
	margin: 0;
	cursor: pointer;
	min-height: @line-height-computed; // Ensure the input doesn't jump when there is no text
	vertical-align: middle;
}

.disabled-label {
	color: @input-color-disabled;
	cursor: not-allowed;
}

.checkbox-input {
	opacity: 0;
	position: absolute;

	&:disabled ~ .fake-checkbox {
		background-color: @input-bg-disabled;
		border-color: @input-border-disabled;
		opacity: @input-opacity-disabled;
	}

	&:focus ~ .fake-checkbox {
		border-color: @input-border-focus;
	}
}

.fake-checkbox {
	flex: 0 0 auto;
	width: @checkbox-input-size;
	height: @checkbox-input-size;
	border-radius: @input-border-radius;
	border: 1px solid @input-border;
	background-color: white;
	display: inline-block;
	margin-right: 10px;
	position: relative;
	top: 2px;
	overflow: hidden;
}

.tick-svg {
	width: @checkbox-input-size;
	height: @checkbox-input-size + 2;
	color: currentColor;
	position: relative;
	bottom: 2px;
	right: 1px;
}

.label-text {
	flex: 1 1 auto;
	display: inline-block;
	min-height: @line-height-computed; // Ensure the input doesn't jump when there is no text
	line-height: @line-height-computed;
}

.tooltip-icon {
	fill: @gray-light;
	vertical-align: middle;

	button {
		vertical-align: middle;
	}
}
