@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.background {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 80px;
	white-space: pre;
	border-radius: @border-radius-base;
}
