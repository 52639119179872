.vt-recent-gifts {
	margin-top: 30px;

	&-transactions-link:after {
		vertical-align: text-bottom;
	}

	&-transactions-link{
		@media @mobile{
			display:block;
		}
	}
}
