@import "../../bootstrap-variables";

@floating-label-font-size: @font-size-base-plus;
@floating-label-height: 24px;
@form-control-padding: 10px;

[data-has-floating-label]:not([data-floating-label-ready]) {
	margin-top: @floating-label-height;
}

.floating-label-placeholder(@elementHeight){
	transform: translate3d(0, @elementHeight/2 + @floating-label-font-size/2, 0);

	&:after,
	span {
		transform: translate3d(0, -@floating-label-height, 0);
	}
}

.floating-label {
	height: @floating-label-height;
	display: block;
	margin: 0;
	@transition: transform 0.25s;
	transition: @transition;
	transform: translate3d(0, 0, 0);
	overflow-y: hidden;
	overflow-x: visible;

	span {
		height: @floating-label-height;
		display: inline-block;
		cursor: default;
		position: relative;
		transition: @transition;
		transform: translate3d(0, 0, 0);
		@media (min-width: @screen-sm-min) {
			font-size: @font-size-small;
		}
	}

	&:after {
		cursor: text;
		transition: @transition;
		transform: translate3d(0, 0, 0);
		margin-left: @form-control-padding + 1;
		content: attr(data-placeholder);
		font-size: @floating-label-font-size;
		font-weight: normal;
		display: block;
		color: @input-color-placeholder;
		height: @floating-label-height;
		white-space: nowrap;
	}

	&.as-placeholder {
		.floating-label-placeholder(@input-height-base);

		+ .select-wrapper select:not([data-error-highlight]) {
			color: @gray-lighter;
			option {
				color: @text-color;
			}
		}
	}
}
