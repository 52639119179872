@import "../../bootstrap-variables";

.transaction-import-progress {
	@progress-tooltip-width: 50px;
	@progress-tooltip-bottom-position: @line-height-computed + @padding-small-vertical;

	margin-top: @line-height-computed * 2.5;

	.progress {
		overflow: visible;
	}

	.progress-bar {
		position: relative;
		border-top-left-radius: @border-radius-base;
		border-bottom-left-radius: @border-radius-base;

		&.slow {
			.transition(width 3s ease);
		}

		&:after { // Tooltip content wrapper
			position: absolute;
			content: attr(data-tooltip-text);
			max-width: @tooltip-max-width;
			padding: 3px 8px;
			color: @tooltip-color;
			text-align: center;
			background-color: @tooltip-bg;
			border-radius: @border-radius-base;
			width: @progress-tooltip-width;
			left: ~'calc(100% - @{progress-tooltip-width} / 2)';
			bottom: @progress-tooltip-bottom-position + @tooltip-arrow-width;
		}

		&:before { // Tooltip arrow
			position: absolute;
			content: '';
			width: 0;
			height: 0;
			bottom: @progress-tooltip-bottom-position;
			left: 100%;
			margin-left: -@tooltip-arrow-width;
			border-color: transparent;
			border-style: solid;
			border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
			border-top-color: @tooltip-arrow-color;
		}
	}
}
