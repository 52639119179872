@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.panel {
	header.panel-header, & > header.panel-header {
		border-bottom: none;
	}
}

.panel-highlight {

	.panel-header {
		background-color: white;
		border: none;
	}
}

.panel-header {
	padding: @padding-base-vertical * 2 @padding-base-horizontal *3;

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
		font-size: @font-size-h3;
		font-weight: 500;
	}
}

.panel-body {
	border-top: 1px solid @gray-lighter;
}
