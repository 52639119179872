@import '../../../../Content/LoggedInWeb/bootstrap-variables';

.qr-code-payment-giving-link-text-caption-on {
	@qr-code-spinner-size: 120px;
	@qr-code-image-border: 8px;
	@qr-code-spinner-bordered-offset: @qr-code-spinner-size / 2 - @qr-code-image-border / 2; // accounts for white space added by generated QR code image
	@qr-code-spinner-offset: @qr-code-spinner-size / 2;

	&.qr-code-viewer-wrapper {
		@media @mobile {
			margin-top: 14px;
		}
	}

	.qr-code-viewer-panel {
		margin: 4px 20px 0 20px;
		max-width: 360px;

		@media (max-width: @screen-lg) {
			margin: 4px 0 0 0;
		}

		@media @mobile {
			max-width: 355px;
		}

		&:global(.panel) {
			// override .panel.panel-color
			margin-bottom: 0;
		}

		&:global(.panel .panel-body) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.qr-code-viewer-panel-heading {
		:global(.panel) &:global(.panel-heading.fill) {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		@media @mobile {
			display: none;
		}
	}

	.qr-code-viewer-panel-body {
		position: relative;

		:global(.panel) &:global(.panel-body) {
			// override .panel.panel-color .panel-body
			padding: 30px;

			@media @mobile {
				border-top: 1px solid @gray-lighter;
				border-top-left-radius: @panel-border-radius - 1;
				border-top-right-radius: @panel-border-radius - 1;
			}
		}
	}

	.qr-code-image-wrapper {
		background-color: transparent;
		border: 1px dashed @gray-mid;
		padding: 20px;
		min-height: 256px;
		min-width: 256px;

		@media @mobile {
			padding: 10px;
		}
		&.qr-code-generated {
			padding: 11px;
			margin-bottom: 0;
			border: 1px dashed transparent;

			@media @mobile {
				padding: 0;
			}
		}
	}

	.qr-code-spinner-overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-color: white;
	}

	.qr-code-spinner-container {
		position: relative;
		width: 100%;
		padding-bottom: 100%;
	}

	.qr-code-spinner {
		position: absolute;
		width: @qr-code-spinner-size;
		height: @qr-code-spinner-size;
		border-width: 8px;
		border-color: @progress-bar-light-bg;
		border-top-color: @progress-bar-bg;
		top: ~'calc(50% - ' @qr-code-spinner-bordered-offset~ ')';
		left: ~'calc(50% - ' @qr-code-spinner-offset~ ')';
		margin-right: 0;
	}

	.qr-code-image-container {
		position: relative;
		width: 100%;
	}

	.qr-code-image {
		display: block;
		width: 100%;
	}

	.qr-code-placeholder-image-container {
		position: relative;
	}

	.qr-code-placeholder-image {
		display: block;
		opacity: 0.07;
	}

	.qr-code-placeholder-caption {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		font-size: @font-size-base-plus;
		font-weight: bold;
		color: @gray-mid;
		width: 100%;
		padding: 20px;
	}

	.qr-code-viewer-panel-footer {
		:global(.panel) &:global(.panel-footer) {
			// override .panel.panel-color .panel-footer
			padding: 20px 30px;
		}
	}

	.qr-code-download-btn {
		:global(.panel-footer.panel-footer-btn-group) & {
			// override .panel-footer.panel-footer-btn-group > .btn
			max-width: 200px;
		}

		:global(.panel-footer.panel-footer-btn-group) &:global(.btn:not(.btn-default)) {
			// override .panel-footer.panel-footer-btn-group > .btn:not(.btn-default)
			@media @mobile {
				margin-top: 0;
			}
		}
	}
}
