@import "../chalkduster/chalkduster";
@import "../../bootstrap-variables";
@import "../../mixins/svgs";

.hint-component {
	font-family: @chalkduster-font-family;
	display: inline-block;
	color: @gray;
	margin-bottom: 16px;
	position: relative;

	.svg(14px, 14px);
	.svg {
		margin-bottom: -15px;
		margin-right: 8px;
	}

	span {
		font-size: @font-size-base-minus;
		display: inline-block;
		transform: rotate3d(0, 0, 1, -4deg);
	}

	&.white {
		color: white;
		.svg {
			fill: white;
			stroke: white;
		}
	}
}
