@import 'giving-by-week';
@import 'giving-by-time-of-day';
@import 'payment-by-source';
@import 'five-steps';
@import 'weekly-giving-total';
@import 'suggestion';
@import 'loading';
@import 'funds-update';

// set how the browser will treat these custom components
error-summary,
insight-component,
loading-component {
	display: block;
}
