@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@color: #4d4c4e;
@border-radius: 3px;
@input-bg: #e9e9e9;
@border-color: @input-bg;
@s: 12px;

.keywords-list {
	margin-top: @s;
	margin-bottom: @s*2;

	.clipboard {
		margin-bottom: @s;
	}

	.input {
		font-size: 14px;
		padding: 7px @s;
		height: 40px;
		background-color: @input-bg;
		border-radius: @border-radius;
		border-color: @border-color;
		padding-right: 0;

		&[readonly] {
			color: @color;
		}
		& + :global(.input-group-addon) {
			color: @color;
			padding: 0 @s;
			background-color: @input-bg;
			border-top-right-radius: @border-radius;
			border-bottom-right-radius: @border-radius;
			border-color: @border-color;
			:global(svg) {
				width: 16px;
			}
		}
	}
}
