@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@transition-duration: 0.1s;
@transition-easing: ease-in-out;

.list {
	padding-left: 0;
}

.title {
	margin-top: 0;
}

.item-hover() {
	&:hover {
		color: white;
		background: @color-complementary-green-darker;
		border-color: @color-complementary-green-darker;
	}
}

.item {
	display: flex;
	align-items: center;
	list-style: none;
	border-radius: @border-radius-small;
	background: white;
	border: 1px solid @border-color;
	margin-bottom: 5px;
	transition-property: border-color, background-color;
	transition-duration: @transition-duration;
	transition-timing-function: @transition-easing;

	.item-hover;

	&-activated {
		color: white;
		background: @color-complementary-green;
		border-color: @color-complementary-green;

		.item-hover;

		.tick {
			fill: @color-complementary-green;
		}
	}

	&-activated,
	&:hover {
		.label span[class~='fake-checkbox'] {
			border-color: white;
		}
	}
}

.checkbox,
.button {
	padding: @padding-base-vertical @padding-large-horizontal;
}

.checkbox {
	flex: auto;
	margin: 0;
	overflow: hidden;
}

.label {
	span[class='fake-checkbox'] {
		transition-property: border-color;
		transition-duration: @transition-duration;
		transition-timing-function: @transition-easing;
	}
}

.button {
	flex: none;
	height: auto;
	line-height: 0;
	&:hover {
		background-color: @theme_colors_common-transparent;
	}
}

.item-activated,
.item:hover {
	.button {
		color: white;
	}
}

.icon {
	fill: currentColor;
	width: 24px;
	height: 24px;
}
