@import "../waiting-indicator";

.select-wrapper.custom-select {
	position: relative;

	button {
		text-align: left;
		overflow: hidden;
		white-space: nowrap;
    	text-overflow: ellipsis;
    	padding-right: 30px;
	}

	select {
		display: inline-block;
		width: 100%;
	}

	.dropdown-menu {
		max-height: 225px;
		overflow-y: auto;
		min-width: 100%;

		.btn-link {
			background-color: @theme_colors_common-transparent;
		}
	}

	.unavailable-menu-item {
		.waiting-indicator();
	}

	@media (max-width: @screen-xs-max) {
		&.mobile-enabled {
			button {
				display: none;
			}
		}
	}

	@media (min-width: @screen-sm-min) {
		select {
			display: none;
		}
	}
}

// [FeatureFlagWillMakeThisCodeObsolete(FeatureName.ReliableOrgAndCampusBranding)]
// NOTE: Please merge this to the top when removing FF
.ReliableOrgAndCampusBranding_IsEnabled {
	.select-wrapper.custom-select {
		button {
			display: inline-flex;
			align-items: baseline;
			gap: 8px;

			@media @small {
				flex-wrap: wrap;
			}
		}
		.dropdown-menu {
			@media @small {
				max-width: -webkit-fill-available;
			}
		}
	}
}
