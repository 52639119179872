@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.intro-paragraph {
	margin: 0 0 25px;
}

.mapping-row {
	display: flex;
	padding: 25px 0;
	border-top: 1px solid @gray-lighter;
}

.mapping-type {
	flex: 0 0 auto;
	width: 230px;
}

.type-name {
	font-size: @font-size-large;
	font-weight: 500;
	color: @gray-dark;
	margin: 0 0 13px;
}

.type-listings {
	font-size: @font-size-base-minus;
	color: @gray-mid;
	line-height: 20px;
}

.mapping-enabled {
	flex: 0 0 auto;
	width: 40px;
	max-width: 40px;
	margin: 27px @panel-body-padding 0;
}

.mapping-accounts {
	flex: 1 0 auto;
}

.account-label {
	display: block;
	font-size: @font-size-base-minus;
}

.account-select {
	width: 400px;
}

.disabled {
	opacity: 0.5;
}
