@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

@padding-vertical: 10px;
@padding-horizontal: 7px;
@selected-border-width: 3px;
@duration: 300ms;

.tab-list {
	display: flex;
	margin: 0;
	padding: 0;
	border-bottom: solid 1px @text-muted;
}

.tab {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.tab-button {
	background: none;
	padding: @padding-vertical @padding-horizontal;
	font-size: @font-size-base-plus;
	line-height: 1.5;
	position: relative;
	bottom: -1px;
	cursor: pointer;
	border: none;
	border-bottom: @selected-border-width solid @btn-link-border;
	transition: all @duration ease-in-out;

	&.active,
	&:focus,
	&:hover {
		color: @btn-link-color;
		border-bottom: @selected-border-width solid @btn-link-color;
		outline: none;
	}

	&.active {
		font-weight: bold;
	}
}
