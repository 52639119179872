@import "../bootstrap-variables";
@radio-width: 20px;
@radio-checked-width: 12px;
@radio-text-distance: 4px;
@radio-checked-offset: (@radio-width - @radio-checked-width)/2;
@radio-border-focus-darken-percentage: 20%;
@radio-icon-top: -3px;

input[type="radio"] {
	opacity: 0;
	position: absolute;
	& + .radio-inner {
		font-size: @font-size-base;
		position: relative;
		padding-left: @radio-width + @radio-text-distance;

		&:before {
			position: absolute;
			left: 0;
			top: @radio-icon-top;
			content: ' ';
			display: inline-block;
			width: @radio-width;
			height: @radio-width;
			border: 1px solid @input-border;
			border-radius: 50%;
			background-color: white;
			.radio-grid & {
				display: none;
			}
		}
	}
	&:disabled + .radio-inner,
	fieldset[disabled] & + .radio-inner  {
		cursor: not-allowed;
		color: @input-color-disabled;
		&:before {
			background-color: @input-bg-disabled;
			border-color: @input-border-disabled;
			opacity: @input-opacity-disabled;
		}
	}
	&:focus + .radio-inner {
		&:before {
			border-color: darken(@input-border, @radio-border-focus-darken-percentage);
		}
	}
	&:checked + .radio-inner {
		&:after {
			position: absolute;
			left: @radio-checked-offset;
			top: @radio-icon-top + @radio-checked-offset;
			content: ' ';
			display: inline-block;
			width: @radio-checked-width;
			height: @radio-checked-width;
			border-radius: 50%;
			background-color: currentColor;
			.radio-grid & {
				display: none;
			}
		}
	}
}

.radio-section-container {
	@radio-circle-border-width: 1px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: nowrap;

	@media @small {
		display: block;
	}

	.radio-item {
		display: flex;
		flex: 1;
		padding-right: 5px;
		cursor: pointer;

		&:not(:last-of-type) {
			border-right: 1px solid @panel-inner-border;
			margin-right: 10px;

			@media @small {
				border-right: none;
				margin-right: 0;
			}
		}

		@media @small {
			width: 100%;
		}
	}

	.radio-circle {
		height: @radio-width;

		// use min-width instead of flex-basis, ensure it works in ie11
		min-width: @radio-width;
		flex: 0 0;

		border: @radio-circle-border-width solid @input-border;
		border-radius: 50%;
		background: white;
		margin-right: @radio-text-distance;
		padding: @radio-checked-offset - @radio-circle-border-width;
		cursor: pointer;

		// center the circle-checked div, otherwise, the display is funny in firefox
		display: flex;
		align-content: center;
		justify-content: center;
	}


	label input[type="radio"]:focus + .radio-circle {
		border-color: darken(@input-border, @radio-border-focus-darken-percentage);
	}

	.radio-circle-checked {
		height: @radio-checked-width;
		width:  @radio-checked-width;
		border-radius: 50%;
		background-color: currentColor;
	}

	.radio-text {
		font-size: @font-size-base;
		font-weight: normal;
	}

	.radio-tooltip-container {
		flex-shrink: 0;
		flex-grow: 0;
		color: @gray-light;

		.info-tooltip, .radio-tooltip {
			position: static;
		}

		.tooltip-icon {
			fill: currentColor;
		}
	}
}
