@import '../bootstrap-variables';

.modal-container {
	z-index: 1050;
}

.modal {
	@media (min-width: @screen-sm-min) {
		.modal-content {
			.box-shadow-large;
		}
	}

	@media @small {
		&:not(.modal-form) {
			padding: 10px;
		}
	}
}

.modal-close() {
	@close-button-size: 44px;
	margin: 10px 10px 0 0;
	outline: none;
	width: @close-button-size;
	height: @close-button-size;

	&:before {
		.ion;
		content: @ionicon-var-ios-close-empty;
		line-height: 48px;
		font-size: 64px;
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.modal-content {
	.box-shadow-medium;

	.close {
		.modal-close;
		margin: 10px 10px 0 0;
	}

	.alert-icon {
		svg {
			height: 85px;
			width: 96px;
		}
		margin-bottom: 30px;
	}
}

.modal.visible {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.modal-dialog {
	max-height: 100%;

	@media @small {
		width: 100%;
		margin: 0;
	}
}

.modal-header {
	border-bottom: none;

	&.lg {
		font-size: @font-size-h1;
	}
}

.modal-body {
	margin: 65px @modal-inner-padding @modal-inner-padding;
	padding:0;
	font-size: @font-size-h3;
	text-align: center;

	&.left-align {
		text-align: left;
	}
}

.modal-text {
	font-size: @font-size-base;
	margin-top: 28px;
}

.modal-header + .modal-body {
	margin-top: @modal-inner-padding;
}

.modal-footer {
	border-top: none;
	padding: @modal-footer-padding;
	&:extend(.responsive-btn-group-centered all);

	@media @small {
		display: flex;
		flex-wrap: wrap;

		.btn + .btn {
			margin-left: 0;
		}

		> .btn {
			flex: 1 1 100%;
			margin: 0;
			max-width: none;

			&.btn-cancel,
			&.btn-ghost {
				order: 1;
				margin-top: 20px;
			}
		}
	}
}

/* IE11 flex bug https://connect.microsoft.com/IE/feedbackdetail/view/951267/horizontal-scrolling-with-flex-grow-max-width */
_:-ms-fullscreen, :root .modal-footer .btn {
	@media (min-width: 391px) {
		&:first-child:last-child {
			margin-left: -242px;
		}
	}
}

/* Modal Forms */
.modal-form {
	.modal-dialog {

		@media @small {
			min-height: 100%;
		}

		@media (min-width: @screen-md-min) {
			width: 960px;
		}
	}

	.modal-dialog-labelled {
		@media (min-width: @screen-sm-min) {
			width: 680px;
		}
	}
}

.modal-form-close {
	.close;
	.modal-close;
	margin: 13px 14px 0 0;
}
