@import "../../bootstrap-variables";

.schedule-import-title {
	.schedule-import-step-description {
		font-size: @font-size-base-plus;
		margin-top: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

.schedule-import-steps {
	display: flex;
	justify-content: space-between;

	@media @small {
		display: block;
	}
}

.schedule-import-step {
	width: 20%;

	@media @small {
		display: flex;
		align-items: center;
		border-bottom: 1px solid @gray-lightest;
		width: auto;
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}

	&-image {
		margin: @grid-gutter-width auto;
		max-width: 140px;

		@media @small {
			margin: 20px;
			width: 30%
		}
	}

	&-arrow {
		width: 22px;
		height: 15px;
		margin-top: 80px;
		fill: @gray-light;
		stroke: @gray-light;
		flex: none;

		@media @small {
			display: none;
		}
	}

	&-text {
		text-align: center;

		@media @small {
			width: 70%;
			text-align: left;
			font-weight: bold;
		}
	}
}

.schedule-import-start-button {
	text-align: center;

	@media @small {
		border-bottom: 1px solid @gray-lightest;
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}

	.btn {
		margin-top: @grid-gutter-width * 3;
		margin-bottom: @grid-gutter-width * 2;
	}
}

.schedule-import-button-group {
	text-align: center;
	padding-top: @grid-gutter-width * 3;
	padding-bottom: @grid-gutter-width * 2;

	&:extend(.responsive-btn-group-centered all);

	@media @small {
		border-bottom: 1px solid @gray-lightest;
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
		padding-left: @grid-gutter-width;
		padding-right: @grid-gutter-width;
		flex-wrap: wrap;

		> .btn {
			flex-basis: 100%;
			max-width: none;
			margin: 0;

			&.btn-ghost {
				order: 1;
				margin-top: 20px;
			}
		}
	}
}

.schedule-import-list {
	margin-bottom: 100px;

	@media @small {
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}
}

