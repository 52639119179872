@import "../../bootstrap-variables";

.autocomplete {
	@item-padding: 13px 15px;
	@item-height: 44px;

	position: relative;

	.display-items {
		.hidden-xs;
		max-height: 266px;
		overflow-y: auto;
		width: 100%;
		margin: 0;
		padding: 0;

		.btn-link {
			.truncate;
			height: @item-height;
			font-size: @font-size-base-plus;
			line-height: @font-size-large;
			color: @gray-dark;
			padding: @item-padding;

			&:disabled {
				background-color: white;
			}
		}

		.list {
			list-style: none;
			padding: 0;
			margin: 0;
			position: relative;
		}

		.item {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}

		li:not(:last-child) .btn-link {
			border-bottom: 1px solid @gray-lighter;
		}

		.selected .btn-link {
			background-color: #eff1f2;
		}

		li.no-items {
			height: @item-height;
			padding: @item-padding;
			line-height: @font-size-large;
			font-style: italic;
			color: @gray;
		}
	}

	[data-error-highlight] {
		& ~ .select-wrapper .form-control, & ~ .selected-item-container {
			border: 1px solid @color-red-validation;
			.placeholder(@color-red-validation-placeholder);
		}

		& ~ .floating-label:after {
			color: @color-red-validation-placeholder;
		}
	}

	.floating-label.as-placeholder {
		position: relative;

		@media (min-width: @screen-sm-min) {
			z-index: 10;
		}
	}

	.autocomplete-select-mobile {
		.visible-xs;
	}

	input.form-control {
		.hidden-xs;

		&::-ms-clear {
			display: none;
		}
	}
}
