@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@border-props: 1px solid @border-color;

.container {
	width: 100%;
	border-top: @border-props;
	margin: @grid-gutter-width*2 0;
	padding: @grid-gutter-width*2 @grid-gutter-width;
}

.steps {
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media @small {
		flex-direction: column;
		border-top: @border-props;
	}
}

.card {
	width: 190px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	margin: 0 9px;

	@media @small {
		width: 100%;
		flex-direction: row;
		padding: @panel-body-padding / 2 0;
		border-bottom: @border-props;
	}
}

.card-icon {
	width: 160px;
	height: 160px;
	margin: 0 0 25px;
	flex: 0 0 auto;

	@media @small {
		width: 80px;
		height: 80px;
		margin: 0 @panel-body-padding / 2 0 0;
	}

	svg {
		width: 160px;
		height: 160px;

		@media @small {
			width: 80px;
			height: 80px;
		}
	}
}

.card-description {
	color: @gray-dark;
	text-align: center;
	font-size: @font-size-base-plus;
	max-width: 100%;

	@media @small {
		text-align: left;
	}
}

.divider {
	width: 22px;
	padding-top: 73px;
	margin: 0 0 9px;
	color: @gray-light;

	@media @small {
		display: none;
	}
}

.divider-icon {
	width: 22px;
	height: 15px;
	stroke: none;
	fill: currentColor;
}

.actions {
	width: 100%;
	text-align: center;
	padding: @grid-gutter-width * 3 0;

	@media @small {
		border-bottom: @border-props;
		padding: @grid-gutter-width 0;
	}
}
