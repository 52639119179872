.recurring-history-item {
	padding: 5px 0px 5px 0px;
	border: none;
}

.recurring-history-item .failed-payment {
	color: @color-red;
}
.recurring-history-item .success-payment {
	color: @color-green;
}

.recurring-panel-header-wrap {
	align-items: center;
	justify-content: space-between;

	.resume-btn {
		color: @color-green;
	}
}

.recurring-form-divider {
	margin: 0px 40px;
}

.recurring-form-group {
	margin-bottom: 0px !important;
}

.panel .old-layout-migration .recurring-form-group .card-reference .reference {
	display: table-cell;
}


	.recurring-form-group .card-reference {
		display: table;

		.reference {
			display: table-cell;
			vertical-align: middle;

			h3 {
				margin: 0;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					display: inline;
					padding-right: 15px;
					font-size: 14px;
				}
			}
		}
	}

	.recurring-form-group .card-reference .avi-wrapper .avi {
		width: 50px;
		height: 50px;
		display: block;
		position: relative;
		border-radius: @border-radius-base;
		margin-right: 21px;
		overflow: hidden;
		background: #fff;
		border: 1px solid @gray-lighter;
		padding: 3px;
	}

	.recurring-form-group .card-reference .avi-wrapper .avi img {
		width: 40px;
	}

	.recurring-form-group .card-reference .reference {
		display: flex;
		flex-direction: column;
	}

	.panel-recurring {
		.make-row(0);
		max-width: @screen-md-min;
		background-color: @theme_colors_grey-300;
		padding: @panel-body-padding-mobile @panel-body-padding;

		.panel-recurring-section {
			.make-sm-column(6, 0);
			.text-center();

			.amount {
				font-size: 42px;

				span {
					padding-left: 5px;
					padding-right: 3px;
				}

				sup {
					font-size: 55%;
				}
			}

			strong {
				display: block;
				font-size: @font-size-base-plus;
				padding-bottom: 20px;
			}

			&:first-child {
				border-right: 2px solid @panel-inner-border;
			}
		}

		@media (max-width: @screen-xs-max) {
			padding: @panel-body-padding-mobile 16px;

			.panel-recurring-section {
				&:first-child {
					border: 0;
					border-bottom: 2px solid @panel-inner-border;
				}
			}
		}
	}
