// basic layout in codepen - http://codepen.io/barendb/pen/bewaZq

@import "../fonts.less";

@sidebar-width: 240px;
@article-max-width: 1920px;

body {
	padding-top: @navbar-height;
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;

	@media @desktop {
		&.noauth {
			padding-top: 10px;

			// Remove with UseNewPushpayShell
			.cap-full-height {
				// height = fullviewheight - paddingtop - topnavheight - footerheight
				min-height: ~"calc(100vh - @{navbar-height} - @{navbar-cap-height-noauth} - @{footer-cap-height})";
			}
		}
	}

	@media print {
		padding-top: 0;
	}

	&.bannerless {
		padding-top: 0;
	}

	&.no-scroll {
		position: fixed;
	}

	.body-content {
		max-width: 100vw;

		@media @desktop {
			flex: 1 1 auto;
			display: flex;

			main {
				flex: 1 1 auto;
				display: flex;
				flex-flow: row wrap;
				align-items: flex-start;
				//Remove with UseNewPushpayShell
				max-width: ~"calc(100vw - @{sidebar-width})";
			}
		}
	}

	&.use-shell {
		padding-top: @shell-header-height;

		//Remove with UseNewPushpayShell
		.body-content main {
			max-width: unset;
		}
	}
}

body.chromeless {
	padding-top: 0;

	&.chromeless-top-border {
		border-top: solid 8px @gray-dark;
	}

	&.chromeless-top-border-mobile-only {
		@media @small {
			border-top: solid 8px @gray-dark;
		}
	}

	.body-content {
		@media @desktop {
			main {
				max-width: initial;
			}
		}
	}
}

//
// Main
// --------------------------------------------------

body {
	main {
		margin-top: @navbar-height;

		@media print {
			margin-top: 0;
		}

		@media @mobile {
			margin-top: @navbar-height / 2;
		}

		> section {
			width: 100%;

			&.shell-full-height {
				min-height: ~"calc(100% - @{shell-footer-height})";
			}

			> article {
				max-width: @article-max-width;

				&.article-align-left {
					margin-left: 0;

					@media print {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}

	&.bannerless {

		main {
			margin-top: 50px;
		}
	}
}


// Banners
.banner {
	.make-row(@grid-gutter-width);
	padding: 42px 0 35px;
	border-bottom: 1px solid @gray-lighter;
	.banner-content {
		.make-xs-column(12);
		.text-center();
		h2 {
			margin-top: 0;
		}
		p {
			margin-bottom: 30px;
			font-size: @font-size-base-plus;
		}
		.banner-highlighted {
			color: @brand-primary;
		}
	}
	@media @mobile {
		&.grey {
			background-color: @color-form;
		}
	}
}

@import "navbar";
@import "sidebar";
@import "footer";
@import "steps-banner";
@import "headers";
@import "print";
