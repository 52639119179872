@cd-batch-empty-view-list-item-icon-size: 160px;
@cd-batch-empty-view-list-vertical-padding: 20px;

.cd-batch-empty-view-description {
	padding-bottom: 40px;
	border-bottom: 1px solid @border-color;
}

.cd-batch-empty-view-list {
	padding: @padding-base-vertical 0 @cd-batch-empty-view-list-vertical-padding;
}

.cd-batch-empty-view-list-item {	
	padding-top: @cd-batch-empty-view-list-vertical-padding;
	padding-bottom: @cd-batch-empty-view-list-vertical-padding;	

	@media (min-width: @screen-sm-min) {
		.text-center;
		padding-left: @grid-gutter-width;
		padding-right: @grid-gutter-width;
	}
}

.cd-batch-empty-view-list-item-image-wrapper {
	position: relative;

	.cd-batch-empty-view-list-item-arrow {
		position: absolute;
		top: 50%;
		margin-top: -7px;
		right: -21px;
		fill: @gray-light;
		stroke: @gray-light;
		width: 22px;
		height: 15px;
	}	
}

.cd-batch-empty-view-list-item-image {
	margin: 0 auto;
	max-width: @cd-batch-empty-view-list-item-icon-size;
}

.cd-batch-empty-view-list-item-separator {
	margin: 0 @grid-gutter-width / 2 ;
	border-bottom: 1px solid @gray-lightest;
}

.cd-batch-empty-view-list-item-name {
	margin-top: @cd-batch-empty-view-list-vertical-padding;

	@media not @small {
		font-weight: 700;
		margin-top: @cd-batch-empty-view-list-vertical-padding;
	}
	@media @small {
		font-size: @font-size-large;
	}
}

.cd-batch-empty-view-list-item-text {
	@media @small {
		font-weight: 700;
	}
}

.cd-batch-empty-view-cta {
	.text-center;
	margin-bottom: 40px;

	@media @small {
		margin-bottom: 20px;
	}	
}

.cd-batch-empty-view-help {
	.text-center;
	padding: 25px 0;
	color: @gray-mid;
	border-top: 1px solid @gray-lightest;
	border-bottom: 1px solid @border-color;
}
