.email-template {
	background-color: @gray-lightest;

	button {
		background-color: @color-yellow;
		border-color: @color-yellow;
		// allow line breaks in button
		line-height: 1.2em;
		white-space: normal;
		height: auto;
		padding-top: (@btn-static-height - @btn-font-size * 1.2) / 2;
		padding-bottom: (@btn-static-height - @btn-font-size * 1.2) / 2;
	}

	.head {
		border-top: 12px solid @color-yellow;
		border-bottom: 1px solid @gray-light;
		padding-top: @padding-large-vertical*2;
		padding-bottom: @padding-base-vertical*2;
		background-color: white;
		text-align: center;

		hr {
			width: 276px;
			margin-top: 13px;
			margin-bottom: 13px;
		}
	}

	.body {
		.content {
			width: 550px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.footer {
		padding: 20px;
		text-align: center;
		color: @gray-light;

		hr {
			width: 550px;
			max-width: 100%;
		}

		svg {
			fill: @gray-light;
			stroke: @gray-light;
		}
	}

	.panel-heading {
		color: @gray-dark !important;
	}
}
