@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@notification-gutter-width: 10px;
@notification-width: 310px;

.container {
	display: flex;
	position: absolute;
	top: @notification-gutter-width;
	right: @notification-gutter-width;
	border: 1px solid @brand-primary;
	border-radius: @border-radius-base;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
	background: white;
	color: @gray-dark;
	padding: @notification-gutter-width;
	animation: show-notification 0.15s cubic-bezier(0.2, 0, 0.13, 1.5) 0s 1 normal forwards;

	@media @small {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 80%;
		max-width: @notification-width;
		height: 380px;
		z-index: 99;
		animation-name: show-notification-mobile;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: @notification-gutter-width * 3 @notification-gutter-width * 2;
		box-shadow: 0 0 0 100vh @white-50;
	}
}

.content {
	width: @notification-width;
	margin-left: @notification-gutter-width;

	@media @small {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: auto;
		margin: 0;
	}
}

.title {
	margin: 0 0 @notification-gutter-width / 2;
	white-space: pre;

	@media @small {
		font-size: @font-size-base-plus;
		white-space: pre-wrap;
		text-align: center;
		margin: @notification-gutter-width * 3 0;
	}
}

.icon {
	height: 35px;
	width: 35px;

	@media @small {
		height: @notification-width * 0.3;
		width: @notification-width * 0.3;
	}
}

.button {
	font-size: @font-size-small;
	line-height: 1em;
	height: auto;
	padding: 0;

	& + .button {
		margin-left: @notification-gutter-width * 2;
	}

	&:hover {
		cursor: pointer;
	}

	@media @small {
		font-size: @font-size-base-plus;
		line-height: 1.5;
		width: 100%;
		text-align: center;
		padding: @notification-gutter-width;
		margin-top: @notification-gutter-width;
		border: 1px solid @btn-ghost-border;

		& + .button {
			margin-left: 0;
		}
	}
}

@keyframes show-notification {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes show-notification-mobile {
	0% {
		opacity: 0;
		transform: scale(0.5) translate(-50%, -50%);
	}

	100% {
		opacity: 1;
		transform: scale(1) translate(-50%, -50%);
	}
}
