.webhooks-edit-panel .checkbox-list {
   margin-top: @font-size-base;
}

.webhooks-form-control {
   display: flex;
   background-color: transparent;
   border: none;
   font-weight: normal;
   font-size: @font-size-base;
   margin-bottom: 0px;
}

.webhooks-form-control input {
   margin-right: 3px;
}