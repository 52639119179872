@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.details-grid {
	font-size: @font-size-small;

	:global {
		.data-grid-row-header,
		.data-grid-row-data {
			grid-template-columns: 170px 1fr 1fr 1fr 1fr 1fr 1fr 55px;
			align-items: center;

			@media @small {
				grid-template-columns: 40px 2fr 1fr;
			}
		}

		.data-grid-row-data {
			border-top: 1px solid rgba(0, 0, 0, 0);
			border-bottom: 1px solid rgba(0, 0, 0, 0);
		}

		.data-grid-row-data-clickable {
			&:active,
			&:hover,
			&:focus {
				border: 1px solid @gray;
			}
		}

		.data-grid-cell {
			line-height: 1;
			height: 100%;
		}

		.data-grid-cell-status {
			display: flex;
			align-items: center;
			grid-column: 1;
		}

		.data-grid-cell-giver {
			grid-column: 2;
		}

		.data-grid-cell-hasseenemail {
			grid-column: 3;
		}

		.data-grid-cell-fund {
			grid-column: 4;

			@media @small {
				grid-column: 2;
				grid-row: 2;
			}
		}

		.data-grid-cell-giftfrequency {
			grid-column: 5;
		}

		.data-grid-cell-amount {
			grid-column: 6;
			margin-left: auto;

			@media @small {
				grid-column: 3;
			}
		}

		.data-grid-cell-monthlytotal {
			grid-column: 7;
			margin-left: auto;
		}

		.data-grid-cell-viewlink {
			grid-column: 8;
			color: @btn-link-color;
			margin-left: auto;
		}
	}
}

.status {
	display: flex;
	align-items: center;
	line-height: 1;
}

.status-active {
	color: @color-green;
}

.status-declined,
.status-unreached {
	color: @color-red;
}

.status-awaiting {
	color: @gray-dark;
}

.status-icon {
	width: 20px;
	height: 20px;
	fill: currentColor;
	margin-right: 10px;
}
