@import '../../../Content/LoggedInWeb/bootstrap-variables.less';

@merchant-selector-gutter-width: 15px;
@editor-gutter-width: 35px;
@transitionDuration: 200ms;
@fontWeight: 400;

.main-container {
	:global {
		.btn-default,
		.btn-link,
		.btn-ghost {
			font-size: @btn-font-size;
			padding: @btn-padding;
			transition: @btn-transition-duration;
			line-height: 1.2;
			min-width: @btn-min-width;
			white-space: nowrap;

			&[disabled] {
				opacity: 1;
			}
		}

		.form-control,
		.form-control-description {
			font-weight: @fontWeight;
		}
	}
}

.panel-heading {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	padding: 0;
}

.panel-body-content {
	display: flex;
	flex: 1 0 100%;
	order: 1;
	padding: 0;
	overflow: hidden;
	font-size: @font-size-base;
	border-top: 1px solid @panel-default-border;
}

.preview-container {
	flex: 1 0;
	min-width: 50%;
	transition: all @transitionDuration;

	&.expanded {
		min-width: 100%;
		margin-right: -40px;
	}
}

.editor-container {
	padding: @editor-gutter-width @panel-body-padding;
	background: @body-bg;
}

.editor-heading {
	display: flex;
	justify-content: space-between;
	margin-bottom: @editor-gutter-width;
}

.editor-heading-content {
	font-size: @font-size-h2;
	color: @gray-darker;
}

.merchant-listing-selector {
	margin-bottom: 0;
	flex-wrap: wrap;
	align-self: center;
	padding: @merchant-selector-gutter-width * 1.5 0 @merchant-selector-gutter-width * 1.5
		@merchant-selector-gutter-width * 2;

	:global {
		.listing-selector-label {
			font-weight: bold;
			font-size: @font-size-base-plus;
			white-space: nowrap;
			margin-bottom: 0;

			&:after {
				content: ':';
			}
		}

		.listing-selector {
			width: 250px;
			padding: 0;
			font-weight: @fontWeight;
		}

		.listing-name {
			font-size: @font-size-base-plus;
			white-space: nowrap;
			padding: 0;
			margin-bottom: 0;
			font-weight: @fontWeight;
		}

		.form-control {
			padding-left: @merchant-selector-gutter-width;
		}
	}

	@media @small {
		margin-bottom: @editor-gutter-width;
		flex-basis: unset;

		:global(.listing-selector) {
			width: 100%;
			padding: 0 @padding-base-horizontal;
		}
	}

	@media @smallDesktop {
		flex-basis: 250px;
	}

	@media @mobile {
		padding: 0;
	}
}

.preview-toggle-container {
	flex: 1 0 150px;
	align-self: center;
	margin-left: 10px;
}

.btn-container {
	display: flex;
	justify-content: flex-end;
	align-self: center;
	flex-wrap: wrap-reverse;
	padding: @merchant-selector-gutter-width * 1.5 @merchant-selector-gutter-width * 2 @merchant-selector-gutter-width *
		1.5 0;

	@media @small {
		flex-basis: unset;
	}

	@media @smallDesktop {
		flex-basis: 200px;
	}
}

.discard-draft {
	padding: 0 @grid-gutter-width;
}

.merchant-campus-selector {
	margin-bottom: 0;
	flex-wrap: wrap;
	align-self: center;
	padding: @merchant-selector-gutter-width * 1.5 0 @merchant-selector-gutter-width * 1.5 0;

	:global {
		.campus-selector-label {
			font-weight: bold;
			font-size: @font-size-base-plus;
			white-space: nowrap;
			margin-top: 7px;

			&:after {
				content: ':';
			}
		}

		.campus-selector {
			width: 250px;
			font-weight: @fontWeight;
		}

		.campus-name {
			font-size: @font-size-base-plus;
			white-space: nowrap;
			padding: 0;
			margin-top: 7px;
			font-weight: @fontWeight;
		}

		.form-control {
			padding-left: @merchant-selector-gutter-width;

			&[disabled] {
				color: #4d4d4d;
			}
		}
	}

	@media @small {
		margin-bottom: @editor-gutter-width;
		flex-basis: unset;

		:global(.campus-selector) {
			width: 100%;
			padding: 0 @padding-base-horizontal;
		}
	}

	@media @smallDesktop {
		flex-basis: 250px;
	}
}
