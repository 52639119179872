@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.container {
	margin-top: @padding-large-vertical * 4;
	margin-bottom: @padding-large-vertical * 3;
}

.title {
	margin-top: 0;
}

.message {
	display: flex;
	align-items: baseline;

	@media @small {
		flex-direction: column;
		align-items: flex-start;
	}

	&-text {
		flex: auto;
		padding-right: @grid-gutter-width;

		@media @mobile {
			width: 100%;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}
}

.lead {
	font-size: @font-size-large;
}

.btn-anon {
	font-size: @font-size-large;
	vertical-align: baseline;
}

.btn-new-member {
	flex: none;

	@media @small {
		padding-left: 0;
		margin-top: 20px;
	}
}
