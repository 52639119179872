.batch-entry-deposit-failures-dialog-grid {
	margin-top: 15px;

	.data-grid {
		.data-grid-row-header,
		.data-grid-row-data {
			display: flex;
			flex: auto;

			@media @small {
				flex-direction: column;
			}
		}
	}	

	.data-grid-cell-name {
		font-weight: bold;
		
		@media @tablet {
			width: 100%;
			flex: 0 0 182px;
		}

		@media @desktop {
			flex-basis: 210px;
		}
	}

	.data-grid-cell-reason {
		@media @tablet {
			width: 100%;
			flex: 1 0 276px;
		}

		@media @desktop {
			flex-basis: 496px;
		}
	}
}
