@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.tips-container {
	border: 1px solid @gray-lighter;
	border-radius: @border-radius-base;
	padding: 25px;
	display: flex;

	@media @small {
		display: block;
	}
}

.progress-wrapper {
	margin-right: 25px;

	@media @small {
		margin-left: auto;
		margin-right: auto;
		width: 160px;
	}
}

.color-blue {
	color: @color-blue;
}

.color-gray {
	color: @gray;
}
