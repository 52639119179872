@notch-size: 18px;
@notch-size-small: 16px;

.notch (@notch-border-width: @panel-border-width) {
	position: relative;

	&:before {
		position: absolute;
		content: '';
		top: -10px;
		.square(@notch-size);
		transform: rotate(45deg);
		border: @notch-border-width solid @border-color;
		border-right-width: 0;
		border-bottom-width: 0;

		@media @small {
			top: -8px;
			.square(@notch-size-small);
		}
	}
}

// Need this to reference notch outside of Content\..., as the notch function
// does not produce a css class.
.notch {
	.notch;
}