@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

@iconWidth: 160px;
@iconHeight: 160px;
@arrowWidth: 22px;
@arrowHeight: 15px;

.container {
	display: flex;
	align-items: flex-start;

	@media @small {
		flex-direction: column;
	}
}

.item {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: @iconWidth;

	@media @small {
		width: 100%;
		flex-direction: row;
		padding: 20px;
		border-bottom: 1px solid @gray-lightest;
	}

	> svg {
		width: @iconWidth;
		height: @iconHeight;

		@media @small {
			width: @iconWidth / 2;
			height: @iconHeight / 2;
			margin-right: 20px;
		}
	}

	> div {
		width: @iconWidth;
		display: flex;
		flex-direction: column;
	}

	h4 {
		text-align: center;
		font-weight: 700;
		margin: 32px 0 8px 0;

		@media @small {
			text-align: left;
			font-size: @font-size-base;
			margin: 0;
			line-height: 1.5;
		}
	}

	span {
		text-align: center;
		font-size: @font-size-base-plus;
		line-height: 1.5;

		@media @small {
			text-align: left;
			font-size: @font-size-base;
			font-weight: 700;
		}
	}
}

.arrow {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: @iconHeight;

	> svg {
		width: @arrowWidth;
		height: @arrowHeight;
		fill: @gray-light;
	}

	@media @small {
		display: none;
	}
}
