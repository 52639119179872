#insights {
	.funds-update {
		li {
			&:first-child {
				display: inline-block;
				line-height: 1.1;
			}
		}
	}
}
