@import '../../bootstrap-variables';

.vt-payment-method-selector {
	.form-control {
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.custom-select .dropdown-menu {
		// using a more specific selector to override max-height
		max-height: 500px;
	}

	.dropdown-menu {
		background-color: @color-form;

		.dropdown-menu-item {
			padding-right: 0;
		}

		.divider {
			margin: 0;
		}
	}

	.btn {
		font-size: @font-size-base;
	}
}

.vt-payment-method-existing {
	&-option,
	&-item .dropdown-menu-item {
		display: flex;
		align-items: center;
	}

	&-btn-select {
		flex: auto;
		padding-left: 0;
		padding-right: 0;
		color: @text-color;
		font-weight: normal;

		.active & {
			color: @btn-link-color;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-btn-delete {
		flex: 0 1 auto;
		padding-left: 10px;
		padding-right: @grid-gutter-width / 2;

		.icon {
			margin: 0;
		}
	}

	&-btn-view-all {
		padding-left: 2px;
		flex: auto;
	}

	&-type {
		flex: 0 1 auto;
		min-width: 50px;
	}

	&-item-icon {
		margin-bottom: -2px;
	}

	&-content {
		flex: auto;
		padding-left: @grid-gutter-width / 2;
	}
}

.vt-payment-method-recorded-check {
	display: flex;

	@media @small {
		flex-direction: column;
	}

	.vt-payment-method-routing-number {
		flex: none;
		@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
			max-width: 196px;
		}
	}

	.vt-payment-method-check-number {
		flex: none;
		@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
			max-width: 188px;
		}
	}

	.vt-payment-method-check-number-only {
		width: 100%;
	}

	.vt-payment-method-account-number {
		flex: auto;
		@media not @small {
			margin-left: @grid-gutter-width;
			margin-right: @grid-gutter-width;
		}
	}
}

