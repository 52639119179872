.transaction-import-summary-grid-loading-wrapper {
	@summary-grid-loading-width: 150px;
	@summary-grid-loading-height: 140px;

	position: relative;
	.overlay(loading);

	.loading-box {
		top: ~'calc(50% - @{summary-grid-loading-height} / 2)';
		left: ~'calc(50% - @{summary-grid-loading-width} / 2)';
		width: @summary-grid-loading-width;
		height: @summary-grid-loading-height;
	}
}
