//
// grid-mvc run within a bootstrap style grid, so all plain HTML table styling is done in
// bootstrap/tables.less
// bootstrap-theme/tables.less -- overrides the standard theme
// modules/grid-mvc.less -- (this file) overrides the themed table when grid-mvc is used
// ---------------------------------------------------------------------------------------------------------------------
.grid-mvc {
	&:extend(.table-responsive);

	@media (max-width: @screen-xs-max) {
		width: auto;
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}

	.right-align {
		text-align: right;
	}

	.center-align {
		text-align: center;
	}

	thead {
		tr {
			th {

				&.sorted-tablehead-bg {
					background-color: @gray-dark;
				}

				div {

					&.sorted {
						background-color: @gray-dark;

						a {
							color: white;
						}
					}
				}
			}
		}
	}

	.sorted-desc {
		.grid-sort-arrow {
			.arrow-solid-down-after;

			&::after {
				font-size: 16px;
				margin-top: 0;
			}
			padding-right: 20px;

		}
	}

	.sorted-asc {
		.grid-sort-arrow {
			.arrow-solid-up-after;
			&::after {
				font-size: 16px;
				margin-top: 0;
			}
			padding-right: 20px;

		}
	}

	@media @desktop {
		tfoot {
			tr {
				td {
					padding: 0;

					> div {

						position: relative;
						border-top: 1px solid @pagination-border;
						background-color: @pagination-bg;
						text-align: center;
						line-height: @pagination-line-height;

						ul {
							list-style: none;
							padding: 0;
							margin: 0;
							display: inline-block;
							font-size: @font-size-base;

							li {
								color: @gray-mid;
								padding: 20px 10px;
								display: inline-block;

								&.active {

									&::after {
										.ion;
										content: @ionicon-var-android-arrow-dropdown;
										position: absolute;
										top: -15px;
										margin-left: -17px;
										font-size: 38px;
										width: 30px;
										color: white;
										text-shadow: rgba(0, 0, 0, 0.4) 0 1px 0;
									}
								}
							}
						}
						.previous, .next {

							padding-left: 30px;
							padding-right: 30px;
							font-size: @table-footer-base-font-size;

							i {
								margin: 22px 7px 0 7px;
							}
						}
					}
				}
			}

		}
	}
}
