@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.split-panel {
	display: flex;

	& > .sub-panel {
		width: 340px;
		padding: 0 @panel-body-padding;

		&:not(:first-of-type) {
			border-left: 1px solid @panel-inner-border;
		}
	}

	@media @mobile {
		display: block;

		& > .sub-panel {
			margin: auto -@panel-body-padding-mobile;
			width: auto;
			padding: @panel-body-padding;

			&:not(:first-of-type) {
				border-left: none;
				border-top: 1px solid @panel-inner-border;
			}
		}
	}
}

.button-group {
	margin-bottom: 20px;
}
