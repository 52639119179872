@import "../../bootstrap-variables";

.transaction-import-fund-row {
	@row-label-top-margin: 20px;
	@row-padding: 20px 40px 30px 40px;

	padding: @row-padding;
	border-top: 1px solid @gray-lighter;
	background-color: @color-form;
	display: flex;
	align-items: center;

	&.matched {
		background-color: @color-green-lighter;
		border-top-color: white;
	}

	.import-fund-label {
		margin-top: @row-label-top-margin;
		font-size: @font-size-base-plus;
	}

	.ask-donor-for-fund-container {
		display: flex;
	}

	.ask-donor-for-fund-tooltip {
		margin-left: 5px;
		margin-top: 8px;
		width: 24px;
		height: 24px;
		fill: @gray-light;
	}

	.import-fund-validation-container {
		margin-top: @row-label-top-margin;

		.match-success {
			display: flex;
			align-items: center;

			.match-success-icon {
				stroke: none;
				fill: @color-green;
				flex: none;
			}

			.match-success-message {
				font-size: @font-size-small;
				margin-left: @padding-small-horizontal;
				color: @color-green;
			}
		}
	}

	@media @small {
		display: block;
		padding-right: @padding-small-horizontal;
		padding-left: @padding-small-horizontal;
		padding-top: @padding-small-vertical;

		.import-fund-label {
			font-size: @font-size-panel-header;
			font-weight: normal;
		}
	}
}

