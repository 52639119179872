@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';
@import (reference) '../../branding-settings-main.less';
@width: 40px;
@borderRadius: 5px;
@iconWidth: 8px;
@iconHeight: 14px;

.wrapper {
	flex: 0 0 50%;
	max-width: 500px;
	position: relative;
	box-shadow: -20px 0 20px 0 rgba(0, 0, 0, 0.1);
	transition: all @transitionDuration;
}

.button {
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	height: @width * 1.5;
	width: @width * 4;
	border: 0;
	border-radius: @borderRadius 0 0 @borderRadius;
	cursor: pointer;
	position: absolute;
	top: @width * 1.5;
	left: @width * -4;
	color: @btn-link-color;
	z-index: 1000;
	transition: all @transitionDuration;

	&:focus {
		color: @btn-link-hover-color;
	}
}

.icon {
	width: @iconWidth;
	height: @iconHeight;
	fill: currentColor;
	transform: rotate(180deg);
	transition: transform @transitionDuration;
}

.text {
	overflow: hidden;
	font-weight: bold;
	width: 40px;
	padding-left: 10px;
	transition: all @transitionDuration;
}

.opened {
	&.wrapper {
		box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.1);
	}

	.button {
		width: @width;
		left: -@width;
	}

	.icon {
		transform: none;
	}

	.text {
		width: 0;
		padding-left: 0;
	}
}
