//
// Grid system
// --------------------------------------------------

.container-fluid {

	// we use the full gutter width not half
	padding-left: @grid-gutter-width * 2;
	padding-right: @grid-gutter-width * 2;

	@media @mobile {
		padding-left: @grid-gutter-width;
		padding-right: @grid-gutter-width;
	}
}

//
// provide a 5th's grid, this allow us to do 5 column
// layouts
// --------------------------------------------------
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
	position: relative;
	min-height: 1px;
	padding-left: ceil((@grid-gutter-width / 2));
	padding-right: floor((@grid-gutter-width / 2));
}



.col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: @screen-sm-min) {
	.col-sm-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: @screen-md-min) {
	.col-md-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: @screen-lg-min) {
	.col-lg-5ths {
		width: 20%;
		float: left;
	}
}
