@import "../bootstrap/mixins/vendor-prefixes";
@import "../bootstrap-variables";

.box-shadow-small {
	.box-shadow(0 2px 4px @box-shadow-color);
}

.box-shadow-medium {
	.box-shadow(0 3px 9px @box-shadow-color);
}

.box-shadow-large {
	.box-shadow(0 5px 15px @box-shadow-color);
}
