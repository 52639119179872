@import "../../bootstrap-variables";

@related-check-payments-grid-num-columns: 7;
@related-check-payments-paymentstatus-width-min: 85px;
@related-check-payments-paymentstatus-width-max: 120px;
@related-check-payments-paymentstatus-width-mobile: 32px;
@related-check-payments-amount-width: 110px;

.related-check-payments-grid {
	@media print {
		page-break-inside: avoid;
	}

	@media @mobile {
		width: 100vw;
		overflow: hidden;
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;

		.data-grid {
			.data-grid-row-header {
				display: none;
			}
		}

		// NOTE: these selectors need to be more specific to get Safari to work
		.data-grid-cell {
			&.data-grid-cell-paymentstatus {
				order: 1;
				flex-basis: @related-check-payments-paymentstatus-width-mobile;
				text-align: center;
			}

			&.data-grid-cell-recipient {
				order: 2;
				font-weight: bold;
				flex-grow: 1;
				flex-basis: 60%;
			}

			&.data-grid-cell-funddisplayvalue {
				order: 3;
				flex-grow: 1;
				flex-basis: 20%;
			}

			&.data-grid-cell-amountasmoney {
				order: 4;
			}

			&.data-grid-cell-createdon {
				flex-basis: 100%;
				order: 5;
			}

			&.data-grid-cell-paymentid,
			&.data-grid-cell-createdontime {
				display: none;
			}
		}
	}

	@media @small {
		.data-grid-cell {
			&.data-grid-cell-recipient {
				flex-basis: 64%;
			}

			&.data-grid-cell-amountasmoney {
				order: 3;
			}

			&.data-grid-cell-createdon {
				order: 4;
				flex-basis: 50%;
			}

			&.data-grid-cell-funddisplayvalue {
				order: 5;
				flex-basis: 50%;
				text-align: right;
			}
		}
	}

	.related-check-payments-grid-info {
		@media @mobile {
			padding: 0 @grid-gutter-width;
		}

		h3 {
			font-size: @font-size-h3;
			margin-bottom: @grid-gutter-width/2;
		}

		p {
			margin-bottom: 25px;
		}
	}

	.data-grid-cell {
		flex: 1 1 unit(100/@related-check-payments-grid-num-columns, %);

		@media @mobile {
			flex-grow: 0;
			flex-basis: 70%;
		}
	}

	.data-grid-cell-paymentstatus {
		flex-basis: @related-check-payments-paymentstatus-width-max;
		font-size: @font-size-small;
		white-space: nowrap;

		@media @small {
			order: 1;
			flex-basis: 6%;
		}

		.svg {
			stroke: none;
			fill: currentColor;
			vertical-align: bottom;
			margin-right: 6px;

			@media @mobile {
				margin-right: 0;
				flex-basis: 30%;
			}
		}
	}

	.data-grid-cell-amountasmoney {
		flex-basis: @related-check-payments-amount-width;
		text-align: right;
	}

	.data-grid-row-totalamount {
		border-bottom: 1px solid @border-color;

		@media @mobile {
			padding: @grid-gutter-width/2;
		}

		.data-grid-cell-totalamount {
			text-align: right;
			font-size: @font-size-base-plus;
			padding-top: @grid-gutter-width - @padding-small-vertical;
			padding-bottom: @grid-gutter-width - @padding-small-vertical;

			@media @mobile {
				padding-top: @padding-small-vertical;
				padding-bottom: @padding-small-vertical;
			}
		}
	}
}

/* Grid Override --------------------------------------------------------------
 * The next block overrides flexbox with grid if available
*/

.related-check-payments-grid {
	.data-grid-row {
		&-header,
		&-data {
			grid-template-columns:
				minmax(@related-check-payments-paymentstatus-width-min, @related-check-payments-paymentstatus-width-max)
				minmax(0, 2fr)
				minmax(0, 3fr)
				repeat(2, minmax(0, 2fr))
				minmax(0, 3fr)
				@related-check-payments-amount-width;

			@media @mobile {
				grid-template-columns:
					@related-check-payments-paymentstatus-width-mobile
					minmax(0, 5fr)
					minmax(0, 2fr)
					minmax(@related-check-payments-amount-width, 1fr);
				grid-template-rows: repeat(2, auto);
			}

			@media @small {
				grid-template-columns:
					@related-check-payments-paymentstatus-width-mobile
					minmax(0, 4fr)
					minmax(@related-check-payments-amount-width, 3fr);
				grid-template-rows: repeat(2, auto);
			}
		}
	}

	.data-grid-cell-paymentid {
		grid-column: 2;
	}

	.data-grid-cell-recipient {
		grid-column: 3;

		@media @mobile {
			grid-column: 2;
			grid-row: 1;
		}
	}

	.data-grid-cell-createdon {
		grid-column: 4;

		@media @mobile {
			grid-column: 2;
			grid-row: 2;
		}
	}

	.data-grid-cell-createdontime {
		grid-column: 5;
	}

	.data-grid-cell-funddisplayvalue {
		grid-column: 6;

		@media @mobile {
			grid-column: 3;
			grid-row: 1;
		}

		@media @small {
			grid-column: 3;
			grid-row: 2;
		}
	}

	.data-grid-cell-amountasmoney {
		grid-column: 7;

		@media @mobile {
			grid-column: 4;
			grid-row: 1;
		}

		@media @small {
			grid-column: 3;
			grid-row: 1;
		}
	}
}
