@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.header {
	display: flex;
	align-items: baseline;

	h1 {
		flex: 1 1 auto;
	}

	@media @small {
		flex-wrap: wrap;
	}
}

.upload-link {
	width: 225px;
	text-align: right;
	font-size: 16px;
	flex: 0 0 auto;

	@media @small {
		text-align: left;
		flex-basis: 100%;
		padding-left: 0;
	}
}

.icon-add {
	fill: currentColor;
}

.summary-filter {
	display: flex;
	margin: @padding-large-vertical * 4 0 @padding-base-horizontal * 3.5;
}

.filter-wrapper {
	margin-right: @padding-base-horizontal * 3.5;
}

.filter-label {
	display: block;
}

.filter-select {
	width: 280px;
}

.details {
	position: relative;
	margin-top: @padding-large-vertical * 3;
}

.panels {
	display: flex;
}

.migration-summary-panel {
	margin-right: 20px;
	flex: 1 1 auto;
}

.timeline-panel {
	flex: 0 0 auto;
	width: 286px;
}

@media @small {
	.summary-filter {
		flex-direction: column;
		margin: 0 0 @padding-base-horizontal * 1.5;
	}

	.filter-wrapper {
		margin: 0 0 @padding-base-horizontal * 2;
	}

	.filter-select {
		width: 100%;
	}

	.panels {
		display: block;
	}

	.migration-summary-panel {
		margin-right: -@navbar-padding-horizontal;
	}

	.timeline-panel {
		width: auto;
	}
}
