@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.wrapper {
	height: auto;
}
.input-count {
	font-size: 13px;
	text-align: right;
}
.exceed-max {
	color: @color-red;
}
.textarea {
	display: block;
	width: 100%;
	box-sizing: content-box;
	border-width: 0;
	resize: none;
	outline: none;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: @input-color-placeholder;
	}
}
