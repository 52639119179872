.listing-selector {
	&-container {
		margin-bottom: 20px;
		display: block;
	}

	@media not @small {
		&-container {
			display: flex;
			align-items: center;
		}

		&-multiline-container {
			display: block;
			align-items: center;
		}

		&-label {
			width: auto;
		}

		&-multiline-child {
			display: block;
			float: none;
		}
	}
}
