@import '../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.image-error-example {
	margin: 20px 0;
	position: relative;
	text-align: center;
	img {
		max-width: 100%;
	}
}

.error-content {
	width: 100%;
	font-size: @font-size-base-minus;
	font-weight: 500;
	line-height: @font-size-large;
	text-align: center;
}
