@import '../../../../../../Content/LoggedInWeb/bootstrap-variables.less';

.intro-paragraph {
	margin: 0 0 25px;
}

.optional {
	font-weight: normal;
}

.mapping-form {
	display: table;
	width: 100%;
	border-top: 1px solid @border-color;
}

.mapping-row {
	display: table-row;
	padding: 25px 0;
}

.fund-name {
	display: table-cell;
	width: 230px;
	font-size: @font-size-large;
	padding: 25px 0;
}

.fund-code {
	color: @gray-light;
	font-size: @font-size-base-plus;
	font-weight: 400;
}

.select-field {
	display: table-cell;
	padding-left: 15px;
}

.hidden-label {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
}

.column-header {
	font-size: @font-size-base-minus;
	font-weight: 700;
}
