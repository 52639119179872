@import '../../../../Content/LoggedInWeb/bootstrap-variables.less';

.wrapper {
	width: 160px;
	height: 160px;
	position: relative;
}

.progress-count {
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
}

.progress-count-number {
	font-size: 38px;
	font-weight: 500;
	line-height: 40px;
	text-align: center;
}

.progress-count-units {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
}

.progress-circle {
	stroke: currentColor;
	transform: rotate(90deg);
}

.progress-circle-border {
	stroke: @gray-lighter;
}
