#insights {

	.insights-vars;

	.five-steps {

		@circleWidth: 50px;

		.steps-container {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;

			.step {
				text-align: center;
				max-width: @circleWidth;
				margin: 15px 7px 15px 7px;
				position: relative;

				img {
					width: @circleWidth;
					height: @circleWidth;
					z-index: 3;
				}

				a {
					color: @gray-dark;
				}

				.line {
					position: absolute;
					left: -15px;
					width: 16px;
					height: 3px;
					background-color: @gray;
					top: 24px;
					z-index: 2;
				}

				h5 {
					font-size: @font-size-small;
				}

				&.complete {
					color: @color-complementary-green;

					a {
						color: @color-complementary-green;
					}

					.line {
						background-color: @color-complementary-green;
					}

					.svg {
						fill: white;
						stroke: white;
						font-style: normal;
						text-decoration: none;
						content: "";
						display: block;
						position: absolute;
						left: 0;
						top: 0;
						width: @circleWidth;
						height: @circleWidth;
						border-radius: @circleWidth;
						background-color: rgba(14,157,75,.7);
					}
				}
			}
		}

		.three-column {
			padding-bottom: @panel-body-padding;
		}

		.days {
			.svg {
				color: @color-complementary-green;
				vertical-align: baseline;
			}
		}

		.chart-container {
			background-color: white;
			border-top: 1px solid @gray-lightest;
			margin-left: -@panel-body-padding;
			margin-right: -@panel-body-padding;
			margin-bottom: -@panel-body-padding;
			padding-left: @panel-body-padding;
			padding-right: @panel-body-padding;
			padding-bottom: @panel-body-padding;
		}

		.panel-footer {
			h3 {
				color: @gray-darker;
			}
		}

		@media @desktopPanel {
			@circleWidth: 75px;

			.steps-container {
				.step {

					max-width: @circleWidth;

					img {
						width: @circleWidth;
						height: @circleWidth;
					}

					.line {
						top: 36px;
					}

					&.complete {
						.svg {
							width: @circleWidth;
							height: @circleWidth;
							border-radius: @circleWidth;
						}
					}
				}
			}
		}
	}
}


