@import "../../bootstrap-variables";

@cd-allocation-grid-num-columns: 9;
@cd-allocation-grid-cell-left-mobile-padding: @table-cell-padding-horizontal;
@cd-allocation-missing-data-indicator-width: 26px;
@cd-allocation-grid-complete-num-columns: 6;
@cd-allocation-paymentstatus-width-min: 80px;
@cd-allocation-paymentstatus-width-max: 115px;
@cd-allocation-paymentstatus-width-mobile: 32px;
@cd-allocation-row-number-width: 50px;
@cd-allocation-amount-width: 170px;
@cd-allocation-actions-width: 70px;
@cd-allocation-paymentstatus-icon-size: 22px;

.cd-missing-data-indicator {
	display: inline-block;
	color: @color-purple;
	font-size: @font-size-large-plus;
	vertical-align: middle;
	font-weight: normal;
}

.cd-note {
	font-size: @font-size-small;

	.cd-missing-data-indicator {
		width: @cd-allocation-missing-data-indicator-width;
		margin-top: -3px;
		.text-center;

		@media @mobile {
			margin-right: 3px;
			width: auto;
		}
	}
}

.cd-allocation-grid-title {
	@media @mobile {
		padding: 0 @grid-gutter-width;
	}
}

.cd-allocation-grid-info {
	@media @mobile {
		padding: 0 @grid-gutter-width;
	}
}

.cd-allocation-grid {
	.data-grid-row {
		&:nth-child(even) .data-grid-row-data {
			background-color: white;
			.data-grid-row-data-clickable;
		}

		.data-grid-row-data {
			&.cd-seen {
				background-color: @table-bg-accent;
				.data-grid-row-data-clickable;
			}
		}

		.cd-cell-row-number {
			color: @gray;
		}
	}

	.data-grid {
		.overlay(disabled, 3);

		&:after {
			margin-left: @data-grid-form-horizontal-margin - @data-grid-form-horizonal-shadow-size;
			margin-right: @data-grid-form-horizontal-margin - @data-grid-form-horizonal-shadow-size;
		}
	}
}

.data-grid-cell {
	.cd-allocation-grid & {
		flex-basis: unit(100/@cd-allocation-grid-num-columns, %);
	}

	.cd-allocation-grid-complete & {
		flex-basis: unit(100/@cd-allocation-grid-complete-num-columns, %);
	}
}

.cd-allocation-grid,
.cd-allocation-grid-complete {
	@media @mobile {
		width: 100vw;
		overflow: hidden;
		margin-left: -@grid-gutter-width;
		margin-right: -@grid-gutter-width;
	}

	.data-grid-cell {
		flex-grow: 1;
		flex-shrink: 1;

		@media @mobile {
			flex-grow: 0;
			flex-basis: 70%;
		}

		&.cd-cell-empty {
			@media @mobile {
				visibility: hidden;
				padding: 0;
			}
		}
	}
}

.cd-allocation-grid {
	.cd-cell-missing-data-indicator {
		flex: 0 1 @cd-allocation-missing-data-indicator-width;
		line-height: 0.8;
		overflow: visible;
		padding-left: 0;
		padding-right: 0;
		.text-center;

		@media @mobile {
			flex-basis: auto;
			width: 0;
		}

		.cd-missing-data-indicator {
			@media @mobile {
				margin-left: -5px;
			}
		}
	}

	.cd-cell-row-number {
		flex: 0 1 @cd-allocation-row-number-width;
	}

	.cd-cell-giver-name {
		&.cd-unallocated {
			color: @color-purple;
			font-size: @font-size-small-minus;
			padding-top: @table-cell-padding + 4;

			@media @mobile {
				font-size: @font-size-base;
				padding-top: @data-grid-cell-padding-mobile;
			}
		}

		@media @mobile {
			order: 1;
			flex-basis: 50%;
			padding-left: @cd-allocation-grid-cell-left-mobile-padding;
		}

		@media @small {
			flex-basis: 70%;
		}
	}

	.cd-cell-giver-email {
		@media @mobile {
			order: 3;
			padding-left: @cd-allocation-grid-cell-left-mobile-padding;
			flex-basis: auto;
		}

		@media @small {
			flex-basis: 60%;
		}
	}

	.cd-cell-giver-mobile {
		@media @mobile {
			flex-grow: 1;
			flex-basis: auto;
			order: 5;
		}

		@media @small {
			.text-right;
			flex-basis: 40%;
		}
	}

	.cd-cell-check-info {
		@media @mobile {
			order: 1;
			flex-basis: 30%;
			.text-right;
			padding-left: @cd-allocation-grid-cell-left-mobile-padding;
		}

		@media @small {
			flex-basis: 70%;
			order: 6;
			.text-left;
		}
	}

	.cd-cell-actions {
		flex: 0 1 @cd-allocation-actions-width;
		color: @btn-link-color;
		.text-right;

		> .icon {
			stroke: currentColor;
			fill: currentColor;
			width: 16px;
			height: 18px;
			position: relative;
			top: 3px;
		}

		@media @mobile {
			flex-basis: auto;
			order: 7;
		}

		@media @small {
			flex-basis: 30%;
		}
	}

	.cd-cell-amount {
		.text-right;

		@media @mobile {
			flex-basis: 20%;
			order: 2;
		}

		@media @small {
			flex-basis: 30%;
		}
	}
}

.cd-allocation-grid-complete {
	.data-grid-row-data {
		.data-grid-cell-paymentstatus {
			font-size: @font-size-small;

			.icon {
				.square(@cd-allocation-paymentstatus-icon-size);
				fill: currentColor;
				vertical-align: bottom;
				margin-right: 6px;

				@media @mobile {
					margin-right: 0;
				}
			}
		}
	}

	.data-grid-cell-paymentstatus {
		flex-basis: @cd-allocation-paymentstatus-width-max;

		@media @mobile {
			flex-basis: @cd-allocation-paymentstatus-width-mobile;
			.text-center;
		}
	}

	.data-grid-cell-payerdisplayname {
		@media @mobile {
			flex-grow: 1;
			flex-basis: ~"calc(70% - @{cd-allocation-paymentstatus-width-mobile} - @{cd-allocation-amount-width})";
		}
	}

	.data-grid-cell-amountasmoney {
		flex-basis: @cd-allocation-amount-width;
		.text-right;

		@media @mobile {
			order: 2;
		}
	}

	.data-grid-cell-createdon {
		@media @mobile {
			flex-basis: 100%;
			order: 3;
		}
	}
}


/** Grid Override --------------------------------------------------------------
 * The next 2 blocks override flexbox with grid if available
*/

.cd-allocation-grid {
	.data-grid-row {
		&-header,
		&-data {
			grid-template-columns:
				@cd-allocation-missing-data-indicator-width
				@cd-allocation-row-number-width
				minmax(0, 2fr)
				minmax(0, 3fr)
				repeat(2, minmax(0, 2fr))
				minmax(0, 3fr)
				minmax(@cd-allocation-amount-width, max-content)
				@cd-allocation-actions-width;

			@media @mobile {
				grid-template-columns: 0 min-content repeat(2, minmax(min-content, 1fr)) minmax(@cd-allocation-amount-width, max-content);
				grid-template-rows: repeat(2, auto);
			}

			@media @small {
				grid-template-columns: 0 3fr 2fr;
				grid-template-rows: repeat(3, auto);
			}
		}
	}

	.cd-cell-row-number {
		grid-column: 2;
	}

	.cd-cell-giver-name {
		grid-column: 3;

		@media @mobile {
			grid-column: 2;
		}
	}

	.cd-cell-giver-email {
		grid-column: 4;

		@media @mobile {
			grid-column: 2;
			grid-row: 2;
		}
	}

	.cd-cell-giver-mobile {
		grid-column: 5;

		@media @mobile {
			grid-column: 3;
			grid-row: 2;
		}
	}

	.cd-cell-funds {
		grid-column: 6;
	}

	.cd-cell-check-info {
		grid-column: 7;

		@media @mobile {
			grid-column: 4;
			grid-row: 1;
		}

		@media @small {
			grid-column: 2;
			grid-row: 3;
		}
	}

	.cd-cell-amount {
		grid-column: 8;

		@media @mobile {
			grid-column: 5;
			grid-row: 1;
		}

		@media @small {
			grid-column: 3;
		}
	}

	.cd-cell-actions {
		grid-column: 9;

		@media @mobile {
			grid-column: 5;
			grid-row: 2;
		}

		@media @small {
			grid-column: 3;
			grid-row: 3;
		}
	}
}

.cd-allocation-grid-complete {
	.data-grid-row {
		&-header,
		&-data {
			grid-template-columns:
				minmax(@cd-allocation-paymentstatus-width-min, @cd-allocation-paymentstatus-width-max)
				minmax(0, 2fr)
				minmax(0, 3fr)
				repeat(2, minmax(0, 2fr))
				@cd-allocation-amount-width;

			@media @mobile {
				grid-template-columns: @cd-allocation-paymentstatus-width-mobile minmax(0, max-content) minmax(@cd-allocation-amount-width, 1fr);
				grid-template-rows: repeat(3, auto);
			}
		}
	}

	.data-grid-cell-payerdisplayname {
		grid-column: 2;

		@media @mobile {
			grid-column: 2;
			grid-row: 1;
		}
	}

	.data-grid-cell-payerdisplayemail {
		grid-column: 3;
	}

	.data-grid-cell-createdon {
		grid-column: 4;

		@media @mobile {
			grid-column: 2;
			grid-row: 2;
		}
	}

	.data-grid-cell-funddisplayvalue {
		grid-column: 5;
	}

	.data-grid-cell-amountasmoney {
		grid-column: 6;

		@media @mobile {
			grid-column: 3;
			grid-row: 1;
		}
	}
}
