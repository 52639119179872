.profile-flyout {

	@bg-color: #f5f5f6; // TODO add to var list
	.flyout-transition();

	width: 400px;
	background-color: @bg-color;
	border-bottom-left-radius: @border-radius-base;
	border-bottom-right-radius: @border-radius-base;
	.box-shadow(0 2px 4px rgba(0, 0, 0, 0.35));
	right: 35px;
	padding: 20px;

	.avatar {
		vertical-align: top;
		text-align: left;
		display: inline-block;
		position: relative;
		width: 120px;

		img {
			.round(100);
		}
	}

	.details {
		vertical-align: top;
		display: inline-block;
		overflow-x: hidden;
		max-width: 200px;

		h3 {
			font-weight: @headings-font-weight;
			margin-top: 0;
			margin-bottom: 0;
		}

		> span {
			display: block;
			padding: 1px 0px;
		}

		i {
			margin-right: 5px;
			.svg {
				color: @color-red;
			}
		}
	}

	.logout {

		hr {
			margin-top: 30px;
			margin-bottom: 30px;
		}

		.btn {
			width: 100%;
		}
	}

	.arrow {
		.arrow(10px, @bg-color);
		position: absolute;
		right: 71px;
		top: -10px;
	}

	// mobile view
	@media @mobile {

		left: 0;
		right: 0;
		bottom: 20px;
		width: auto;
		overflow-y: scroll;
		.box-shadow(none);

		.avatar {
			margin: auto;
			margin-bottom: 30px;
		}

		.avatar,
		.details {
			display: block;
			max-width: none;
			.text-center;
			max-width: none;
		}

		.arrow {
			display: none;
		}
	}
}
